import React, {useRef} from 'react';
import {connect} from 'react-redux';
import {useParams} from 'react-router-dom';
import {useMount, useUpdateEffect, useSetState} from 'ahooks';
import PropTypes from 'prop-types';
import moment from '../../library/moment';
import _ from 'lodash';
import ordinal from 'ordinal';

import {Row, Col, Input, Space, DatePicker, TimePicker} from 'antd';

import './style.scss';

import api from '../../library/api';

const defaultState = {
	id : '',
	project_id : '',
	date : null,
	item : '',
	location : '',
	comments : '',
	details : '',
	visit : [],
};

const {TextArea} = Input;

const EditSchedule = (props) => {
	const {defaultValue, onChange } = props;

	let {ProjectID} = useParams();

	const schedData = useRef(defaultState);

	const [scheduleData, setScheduleData] = useSetState(defaultState);

	useMount(() => {
		let updateData = {
			project_id : ProjectID,
		};

		_.each(schedData.current, (v, k) => {
			updateData[k] = defaultValue[k];
		});

		schedData.current = updateData;
		setScheduleData(updateData);
	});

	useUpdateEffect(() => {
		const arr = _.assign(schedData.current, scheduleData);
		onChange(arr);
	}, [scheduleData]);

	const datePickerValue = (arrTime) => {
		const start =  moment.utc(arrTime.start_time).local() ,
				end =  moment.utc(arrTime.end_time).local();
		if (!start.isValid() || !end.isValid()) {
			return ['', ''];
		}
		return [start, end];
	};
	return (
			<div id="edit-schedule-section">
				<Row gutter={[24, 16]}>
					<Col span={12}>
						<Space direction={'vertical'} size={0}>
							<label htmlFor={'date'}>Date : </label>
							<DatePicker
									name={'date'}
									size={'default'}
									format={api.utils.MOMENTFORMAT.date}
									value={scheduleData.date ? moment(scheduleData.date) : null}
									inputReadOnly={true}
									onChange={(e) => {
										setScheduleData({
											date: moment(e).local().isValid() ? moment(e).local().format(api.utils.MOMENTFORMAT.serverDate).valueOf() : null,
										});
									}}
							/>
						</Space>
						{/*<Space direction={'vertical'} size={0}>
							<label htmlFor={'item'}>Item : </label>
							<Input
									name={'item'}
									value={scheduleData.item}
									onChange={(e) => {
										setScheduleData({
											item : e.target.value,
										});
									}}
							/>
								</Space>*/}
						<Space direction={'vertical'} size={0}>
							<label htmlFor={'detalis'}> Detail: </label>
							<Input
									name={'detalis'}
									value={scheduleData.details}
									onChange={(e) => {
										setScheduleData({
											details : e.target.value,
										});
									}}
									maxLength={255}
									showCount={255}
							/>
						</Space>

						<Space direction={'vertical'} size={0}>
							<label htmlFor={'location'}>Location : </label>
							<Input
									name={'location'}
									value={scheduleData.location}
									onChange={(e) => {
										setScheduleData({
											location : e.target.value,
										});
									}}
									maxLength={255}
									showCount={255}

							/>
						</Space>
						<Space direction={'vertical'} size={0} hidden>
							<label htmlFor={'comments'}>Comments : </label>
							<TextArea
									name={'comments'}
									value={scheduleData.comments}
									rows={5}
									onChange={(e) => {
										setScheduleData({
											comments : e.target.value,
										});
									}}
							/>
						</Space>
					</Col>

					<Col span={12}>
						{scheduleData.visit.map((v, i) => (
								<Space key={i} direction={'vertical'} size={0}>
									<label htmlFor={`visit${i}`}> {`${ordinal(i + 1)} Visit :`}</label>
									<TimePicker.RangePicker
											name={`visit${i}`}
											inputReadOnly={false}
											value={datePickerValue(scheduleData.visit[i])}
											format={api.utils.MOMENTFORMAT.time24}
											onChange={(e, w) => {
												let visitArr = scheduleData.visit;

												if (w.length === 2) {
													visitArr[i].start_time = moment(w[0], api.utils.MOMENTFORMAT.time24);
													visitArr[i].end_time = moment(w[1], api.utils.MOMENTFORMAT.time24);
													setScheduleData({
														visit : visitArr,
													});
												}
											}}
									/>
								</Space>
						))}


					</Col>
				</Row>
			</div>
	);
};

EditSchedule.defaultProps = {};

EditSchedule.propTypes = {
	defaultValue : PropTypes.object.isRequired,
	onChange : PropTypes.func.isRequired
};

export default connect()(EditSchedule);
