import { Modal } from "antd";
import React from "react";

const expireModal = (props) => {
    return (
                <Modal 
                    title = {'Session expired'}
                    width = {400}
                    visible={props.isShowModal}
                    centered={true}
                    destroyOnClose={true}
                    okText="Keep me signed in"
                    cancelText="Log me out"
                    className='ant-modal-add-visits'
                    onCancel={props.onLogout}
                    onOk={props.onOk}
                >
                        <div style={{padding : '20px'}}>
                            <p>Your session will expire soon, do you want to extend the session?</p>
                        </div>
                </Modal>
    )
}
export default expireModal;