import { Radio, Space } from "antd";
import React from "react";

/*Modal for asking admin which notification option to choose when project is updated*/
const TellUsers = ({
    bodyModal,
    onChange,
    value,
    options
}) => {
    return (
        <div className={"tellUsers"}> 
                <p>
                    {bodyModal}
                </p>
                <Radio.Group onChange={onChange} value={value}>
                    <Space direction="vertical">
                       {options && Array.isArray(options) && options.map((item,index) => {
                           return   <Radio key={index} value={index+1}>{item}</Radio>
                        })  
                       }
                    </Space>
                </Radio.Group>
        </div>
    )
}
export default TellUsers;