import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useMount, useUpdateEffect } from 'ahooks';
import { Promise } from 'bluebird';
import moment from '../../library/moment';
import _ from 'lodash';
import ordinal from 'ordinal';

import { PageHeader, Table, Select, Descriptions, Breadcrumb, Modal , Form, DatePicker } from 'antd';

import { Backdrop, Button, IconButton, CircularProgress, Tooltip } from '@material-ui/core';
import { VerticalAlignBottom, DeleteForever, Edit, AssignmentOutlined, FileCopyOutlined, Search } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import { exportExcel } from '../../utility/xslx';
import urls from '../../utility/urls';
import { errorMapper } from '../../utility';
import api from '../../library/api';

import EditProject from './EditProject';
import CopyToClipboard from '../../components/CopyToClipboard';
import EditMultipleItems from '../../components/EditMultipleItems';
import PopConfirm from '../../components/PopConfirm';
import EditSchedule from './EditSchedule';
import AddNewVisit from '../../components/AddNewVisit/AddNewVisit'
import AddNewTimeSlot from '../../components/AddNewTimeSlot'

import './style.scss';
import TellUsers from '../../components/TellUsers';
import { ROLES } from '../../utility/constant';
import AuthHelper from '../../library/helpers/AuthHelper';
import CustomTemplate from './CustomTemplate';
import { Resizable } from 'react-resizable';
const ResizableTitle = (props) => {
    const { onResize, width, ...restProps } = props;
  
    if (!width) {
      return <th {...restProps} />;
    }
  
    return (
      <Resizable
        width={width}
        height={0}
        handle={
          <span
            className="react-resizable-handle"
            onClick={(e) => {
              e.stopPropagation();
            }}
          />
        }
        onResize={onResize}
        draggableOpts={{
          enableUserSelectHack: false,
        }}
      >
        <th {...restProps} />
      </Resizable>
    );
  };

class Dropdown extends React.PureComponent {
    handleChange = value => {
        if(value) {
            this.props.setSelectedKeys([value]);
            setTimeout(() => this.props.confirm(), 10);
        }else {
            this.props.setSelectedKeys([]);
            setTimeout(() => this.props.confirm(), 10);
        }
    
    };
    render() {
        return (
          <DatePicker.RangePicker
            format="YYYY-MM-DD"
            placeholder={["from date", "to date"]}
            onChange={this.handleChange}
            ranges={{
              Today: [moment(), moment()],
              "2 days": [
                moment()
                  .subtract(2, "days")
                  .startOf("day"),
                moment().endOf("day")
              ],
              'This Month': [moment().startOf('month'), moment().endOf('month')],
            }}
          />
        );
      }
}

const ProjectDetailsPage = (props) => {
    let { ProjectID } = useParams();

    const updateData = useRef({});
    const projectObj = useRef({
        project: {},
        appointments: {},
    });
    const schedObj = useRef({});
    const updateSchedData = useRef({});
    const updateMultipleProjectData = useRef([{}])
    const updatedCheckedBoxValue = useRef(false)
    const multipleObject=useRef({})
    const [selectedWeek, setSelectedWeek] = useState();
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [columns, setColumns] = useState([]);
    const [totalWeeks, setTotalWeeks] = useState([]);
    const [isRecurring, setIsRecurring] = useState(false);
    const [editProj, setEditProj] = useState(false);
    const [editSched, setEditSched] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedBooked, setSelectedBooked] = useState([]);
    const [panelist, setPanelist] = useState([]);
    const [editMultipleItems,setEditMultipleItems] = useState(false)
    const [isUpdatedProject , setIsUpdatedProject]=useState(false)
    const [value, setValue] = React.useState(1);
    const [is_published,setIs_Published]=React.useState(false)
    const [VisibleAddNewVisitModal,setVisibleAddNewVisitModal]= useState(false)
    const [VisibleAddNewTimeSlot,setVisibleAddNewTimeSlot]= useState(false)
    const [confirmLoading, setConfirmLoading] = React.useState(false);
    const [visitColumns, setVisitColumns] = React.useState(false);
    const [showActions , setShowActions] = React.useState(true)
    const [showFirstName , setShowFirstName] = React.useState(false)
    const [isChangeDateTime ,setIsChangeDateTime] = React.useState(false)
    const [emailEditor, setEmailEditor] = useState(false);

    const [showCustomTemplateModal , setShowCustomTemplateModal] = useState(false)
    const [form] = Form.useForm();
    //filters 
    const [detailsFilter , setDetailsFilter] = useState([])
    const [locationFilter , setLocationFilter] = useState([])


    const historyLink = useHistory();
    const role_admin  = new AuthHelper().getRole()
    const { enqueueSnackbar } = useSnackbar();

    const handleResize =
    (index) =>
    (_, { size }) => {
      const newColumns = [...columns];
      newColumns[index] = { ...newColumns[index], width: size.width };
      setColumns(newColumns);
    };

    const mergeColumns = columns.map((col, index) => ({
        ...col,
        onHeaderCell: (column) => (
        {
        width: column.width,
        onResize: handleResize(index),
        }),
    }));

    useMount(async () => {
        await fetchData(ProjectID);
    });
    useUpdateEffect(() => {
            setColumns(columnsProvider(visitColumns, isRecurring))
    },[selectedRowKeys , detailsFilter ,locationFilter ])
    useUpdateEffect(() => {
            setColumns(columnsProvider(visitColumns, isRecurring))
            fetchData(ProjectID);
    },[showActions ])
    useEffect(() => {
       (role_admin === ROLES.projAdmin || role_admin === ROLES.adminSuper ) && (async() => {
            api.AdminPerProject
                .getProjectAdminByProjectId(ProjectID)
                .then((d) => {
                    setIsLoading(false);

                    if (d.messages === 'success') {
                            if(d.data === ROLES.adminFnameOnly) {
                                setShowFirstName(true)
                            }
                            if(d.data === ROLES.adminFnameOnly || d.data === ROLES.adminView) {
                                setShowActions(false)
                            }else {
                                setShowActions(true)
                            }
                            
                    } else {
                        enqueueSnackbar(errorMapper(d.messages), { variant: 'warning' });
                    }
                })
                .catch((e) => {
                    setIsLoading(false);
                    enqueueSnackbar('Server error!', { variant: 'error' });
                });
        })()
        
    },[])
    const processedRawData = (proj, appnt, panelist) => {
        setIs_Published(proj.is_published)
        setPanelist(panelist);
        //convert time to our locally 
        appnt.map((i) => {
            return i.visit.map( j => {
                return {...j , start_time : moment.utc(j.start_time).local() , end_time : moment.utc(j.end_time).local()}
            })
        })
        //sort date  
        appnt.sort((a,b) => a.date.diff(b.date))
        //sort time
        appnt.sort((a, b) =>  {
            /*if(moment(a.date).isSame(moment(b.date),'day') && moment(a.date).isSame(moment(b.date),'year')&&moment(a.date).isSame(moment(b.date),'month')) {
                if(moment.utc(a.visit[0].start_time).local().isSame(moment.utc(b.visit[0].start_time).local())) {
                    return a.item - b.item 
                }
                return moment.utc(a.visit[0].start_time).local().isSame(moment.utc(b.visit[0].start_time).local())
            }*/
           return  moment(moment.utc(a.date).local().format("YYYY-MM-DD") + " " + moment.utc(a.visit[0].start_time).local().format("HH:mm:ss")).diff(moment(moment.utc(b.date).local().format("YYYY-MM-DD") + " " + moment.utc(b.visit[0].start_time).local().format("HH:mm:ss"))) != 0 
           ? moment(moment.utc(a.date).local().format("YYYY-MM-DD") + " " + moment.utc(a.visit[0].start_time).local().format("HH:mm:ss")).diff(moment(moment.utc(b.date).local().format("YYYY-MM-DD") + " " + moment.utc(b.visit[0].start_time).local().format("HH:mm:ss"))) 
           : moment(moment.utc(a.date).local().format("YYYY-MM-DD") + " " + moment.utc(a.visit[0].end_time).local().format("HH:mm:ss")).diff(moment(moment.utc(b.date).local().format("YYYY-MM-DD") + " " + moment.utc(b.visit[0].end_time).local().format("HH:mm:ss")))
        })
        const totalDays = Math.abs(proj.project_end_date.diff(proj.project_start_date, 'days')),
        
            weeksTotal = totalDays > 0 ? Math.ceil(totalDays / 7) : 1,
            is_recurring = proj.is_recurring;

        let visitColumns = 0;
        setIsRecurring(proj.is_recurring);

        let totalWeeksArr = [];

        if (is_recurring) {
            for (let i = 0; i < weeksTotal; i++) {
                totalWeeksArr.push({
                    label: `${ordinal(i + 1)} Week`,
                    value: i + 1,
                });
            }
        } else {
            for (let i = 0; i < proj.projectsched.length; i++) {
                totalWeeksArr.push({
                    label: `${ordinal(i + 1)} Visit`,
                    value: i + 1,
                });
            }
        }

        setTotalWeeks(totalWeeksArr);
        let arrDetailsFilter = []
        let arrLocationFilter = []
        _.each(appnt, (v, i) => {
            const weeks = Math.abs(v.date.diff(proj.project_start_date, 'weeks')) + 1;
            appnt[i].week = weeks;

            const indexSched = _.findIndex(proj.projectsched, (o) => {
                return o.id === v.projectsched_id;
            });

            appnt[i].visits = indexSched !== -1 ? proj.projectsched[indexSched].visit_number : -1;

            visitColumns = appnt[i].visit.length > visitColumns ? appnt[i].visit.length : visitColumns;
            if(appnt[i].details !== '') {
                arrDetailsFilter.push( {text : appnt[i].details , value : appnt[i].details})
            }   
            if(appnt[i].location !== '') {
                arrLocationFilter.push( {text : appnt[i].location , value : appnt[i].location})
            }
        });

        projectObj.current.project = proj;
        projectObj.current.appointments = appnt;
        setColumns(columnsProvider(visitColumns, is_recurring));
        setData(appnt);
        setVisitColumns(visitColumns)
        //filter occurrences 
        var result = arrDetailsFilter.reduce((unique, o) => {
            if(!unique.some(obj => obj.text === o.text && obj.value === o.value)) {
              unique.push(o);
            }
            return unique;
        },[]);
        var resultLocation = arrLocationFilter.reduce((unique, o) => {
            if(!unique.some(obj => obj.text === o.text && obj.value === o.value)) {
              unique.push(o);
            }
            return unique;
        },[]);
        setDetailsFilter(result)
        setLocationFilter(resultLocation)
        };
    const fetchData = (id) => {
        setIsLoading(true);

        Promise.all([api.projects.fetchById(id), api.appointments.fetchByProjId(id), api.participants.fetchById(id)])
            .then((d) => {
                setIsLoading(false);

                if (d[0].messages === 'success' && d[1].messages === 'success' && d[2].messages === 'success') {
                    processedRawData(d[0].data, d[1].data, d[2].data);
                } else {
                    enqueueSnackbar(
                        errorMapper(d[0].messages) + errorMapper(d[1].messages) + errorMapper(d[2].messages),
                        { variant: 'warning' }
                    );
                }
            })
            .catch((e) => {
                setIsLoading(false);
                enqueueSnackbar('An error occurred while getting project information. Please contact system admin for help.', { variant: 'error' });
            });
    };

    const deleteSchedule = (rec) => {
        setIsLoading(true);

        api.appointments
            .delete(rec.id , rec.project_id)
            .then((d) => {
                setIsLoading(false);

                if (d.messages === 'success') {
                    fetchData(ProjectID);
                    enqueueSnackbar('Deleted successfully', { variant: 'success' });
                } else {
                    enqueueSnackbar(errorMapper(d.messages), { variant: 'warning' });
                }
            })
            .catch((e) => {
                setIsLoading(false);
                enqueueSnackbar('An error occurred while deleting appointment. Please contact system admin for help.', { variant: 'error' });
            });
    };
    const deleteMultipleSchedule = (arrID) => {
        let arr = [];

        setIsLoading(true);

       /* _.each(arrID, (v) => {
           arr.push(api.appointments.delete(v ,projectObj.current.project.id));
        });*/

        api.appointments.deleteMultiple(arrID,ProjectID)
        .then((d) => {
            setIsLoading(false);
            enqueueSnackbar('Successfully deleted schedule/s', { variant: 'success' });
            setSelectedRowKeys([]);
            setSelectedRows([]);
            fetchData(ProjectID);
        }).catch((e) => {
            setIsLoading(false);
            enqueueSnackbar('An error occurred while deleting appointments. Please contact system admin for help.', { variant: 'error' });
        });
    };

    const updateProjectData = (data) => {
        setIsLoading(true);

        api.projects
            .update({ ...projectObj.current.project, ...data })
            .then((d) => {
                setIsLoading(false);

                if (d.messages === 'success') {
                    fetchData(ProjectID);
                    setEditProj(false);
                    enqueueSnackbar('Updated successfully', { variant: 'success' });
                } else {
                    enqueueSnackbar(errorMapper(d.messages), { variant: 'warning' });
                }
            })
            .catch((e) => {
                setIsLoading(false);
                enqueueSnackbar('An error occurred while updating project information. Please contact system admin for help.', { variant: 'error' });
            });
    };
    const updateAppointmentData = (data) => {
        setIsLoading(true);
        api.appointments
            .update(data)
            .then((d) => {
                setIsLoading(false);

                if (d.messages === 'success') {
                    fetchData(ProjectID);
                    setEditSched(false);
                    setIsUpdatedProject(true)
                    enqueueSnackbar('Updated successfully', { variant: 'success' });
                } else {
                    enqueueSnackbar(errorMapper(d.messages), { variant: 'warning' });
                    setIsUpdatedProject(false)
                }
            })
            .catch((e) => {
                setIsLoading(false);
                enqueueSnackbar('An error occurred while updating appointment. Please contact system admin for help.', { variant: 'error' });
            });
    };
    const onEditMultipleSchedule = () => {
        multipleObject.current=selectedRows
        setEditMultipleItems(true)
    }
    const updateMultipleAppointmentData = async (data) => {
        let arr = [];

        setIsLoading(true);
       await _.each(selectedRows, (v) => {
            let index = data.findIndex(item => item.id === v.id)
            let b = _.cloneDeep(data[index]);
            b.id = v.id;
            for (let i = 0; i < b.visit.length; i++) {
                if (v.visit[i]) {
                    b.visit[i].id = v.visit[i].id || null;
                    b.visit[i].seq = v.visit[i].seq || '';
                } else {
                    b.visit[i].id = null;
                    b.visit[i].seq = 0;
                }

                b.visit[i].appointment_id = v.id;
            }
            arr.push(b);
        });
        await api.appointments.updateMultiple(arr,ProjectID)
            .then((d) => {
                setIsLoading(false);
                setIsUpdatedProject(true)
                enqueueSnackbar('Successfully updated multiple schedule/s', { variant: 'success' });
                fetchData(ProjectID);
                setEditSched(false);
                setEditMultipleItems(false)
            
            })
            .catch((e) => {
                setIsLoading(false);
                setIsUpdatedProject(false)
                setEditMultipleItems(false)
                enqueueSnackbar('An error occurred while updating appointments. Please contact system admin for help.', { variant: 'error' });
            });
    };

    const onEditSchedule = (rec) => { 
            schedObj.current = rec;
            setEditSched(true);
      
    };

    const onDeleteSchedule = (rec) => {
        deleteSchedule(rec);
    };
    const columnsProvider = (visitNum, recurring) => {
        let infoArr = [
                {
                    title: recurring ? 'Week' : 'Visits',
                    align: 'left',
                    width: 100,
                    dataIndex: recurring ? 'week' : 'visits',
                    key: recurring ? 'week' : 'visits',
                    sorter : {
                        compare : (a,b) => recurring ? a.week - b.week : a.visits - b.visits,
                        multiple : 3
                    },
                    render: (text, record) => {
                        return recurring ? `${ordinal(text)} Week` : `${ordinal(record.visits)} Visit`;
                    },
                },
                {
                    title: 'Date',
                    align: 'center',
                    width: 140,
                    dataIndex: 'date',
                    key: 'date',
                    defaultSortOrder: 'ascend',
                    sorter: {
                    compare: (a, b) => {
                            if (a.date.isBefore(b.date, 'day')) {
                                return -1;
                            }

                            if (a.date.isAfter(b.date, 'day')) {
                                return 1;
                            }

                            return 0;
                        },
                        multiple: 2,
                    },
                    filterDropdown: props => <Dropdown {...props} />,
                    onFilter: (value, item) => {
                        if(value) {
                            const [from, to] = value;

                            if (!from || !to) return true;
                            if (to.isBefore(item.date, 'day')) {
                                return false;
                            }
                            if (from.isAfter(item.date, 'day')) {
                                return false;
                            }
                            return true
                        }else {
                            return true;
                        }
                    },
                    sortDirections: ['descend', 'ascend'],
                    render: (text, record) => {
                        return moment(text).format(api.utils.MOMENTFORMAT.date);
                    },
                },
                /*{
                    title: 'Item',
                    align: 'center',
                    width: 140,
                    dataIndex: 'item',
                    key: 'item',
                    sorter : {
                        compare : (a,b) => a.item - b.item,
                        multiple : 1
                    },
                    sortDirections: ['descend', 'ascend'],
                },*/
                {
                    title: 'Detail',
                    align: 'center',
                    width: 140,
                    dataIndex: 'details',
                    key: 'details',
                    filters :detailsFilter,
                    onFilter: (value , record) => record.details.startsWith(value),
                    filterSearch:(input, record) => record.details.indexOf(input) > -1,
                    filterMode: 'tree',
                    sorter: {
                        compare: (a, b) => {
                            const idA = (a.details || '').toUpperCase();
                            const idB = (b.details || '').toUpperCase();
        
                            if (idA < idB) {
                                return -1;
                            }
        
                            if (idA > idB) {
                                return 1;
                            }
        
                            return 0;
                        },
                        multiple: 2,
                    },
                    sortDirections: ['descend', 'ascend'],
                },
            ],
            actionArr = [
                {
                    title: 'Location',
                    align: 'center',
                    width: 300,
                    dataIndex: 'location',
                    key: 'location',
                    sorter: {
                        compare: (a, b) => {
                            const idA = (a.location || '').toUpperCase();
                            const idB = (b.location || '').toUpperCase();
        
                            if (idA < idB) {
                                return -1;
                            }
        
                            if (idA > idB) {
                                return 1;
                            }
        
                            return 0;
                        },
                        multiple: 2,
                    },
                    sortDirections: ['descend', 'ascend'],
                    filters :locationFilter,
                    onFilter: (value , record) => record.location.startsWith(value),
                    filterSearch: true,
                    filterMode: 'tree',
                },
                {
                    title: showFirstName ? 'First Name' : 'Full Name',
                    align: 'center',
                    width: 350,
                    dataIndex: 'participant',
                    key: 'participant',
                    render: (text, record) => {
                        let fn = '',
                            ln = '';

                        if (text) {
                            fn = text.first_name || '';
                            ln = text.last_name || '';
                            if(showFirstName) {
                                if (fn.length > 0 || ln.length > 0) {
                                    return `${fn}`;
                                } else {
                                    return `${text.email || ''}`;
                                }
                            }else {
                                if (fn.length > 0 || ln.length > 0) {
                                    return `${fn} ${ln}`;
                                } else {
                                    return `${text.email || ''}`;
                                }
                            }
                          
                        }

                        return '';
                    },
                },
                showActions && {
                    title: 'Action',
                    width: 100,
                    editable: false,
                    align: 'center',
                    fixed: 'right',
                    key: 'action',
                    className :!showActions || (selectedRowKeys.length > 1) ? 'hide':'' ,
                    render: (text, record) => showActions && (
                        <div className="project-management-page-table-row-ctrl">
                          {(selectedRowKeys.length <= 1) && showActions &&  
                          <Tooltip title={<p style={{fontSize: '14px'}}>{"View / Edit row"}</p>} >
                                <IconButton
                                        size="small"
                                        disableRipple={true}
                                        aria-label="Edit"
                                        className={selectedRowKeys.length <= 1 ? '':'hide'}
                                        onClick={onEditSchedule.bind(this, record)}
                                    >
                                        <Edit />
                                    </IconButton>
                            </Tooltip>
                          }
                            {(selectedRowKeys.length <= 1) && showActions &&<PopConfirm
                                title={record.status !== 'booked' ? "Are you sure you want to delete this schedule?" : `This time slot has already been booked by panelist ${showFirstName ? record?.participant?.first_name || ""  : record?.participant?.fullName || record.panelist || ""}, are you sure you want to remove it?`}
                                onConfirm={onDeleteSchedule.bind(this, record)}
                            >
                                   <Tooltip title={<p style={{fontSize: '14px'}}>{"Delete row"}</p>} >
                                        <IconButton size="small" className={selectedRowKeys.length <= 1 ? '':'hide'} disableRipple={true} aria-label="delete" color="secondary">
                                            <DeleteForever />
                                        </IconButton>
                                    </Tooltip>
                            </PopConfirm> }
                        </div> 
                    ),
                },
            ];

        for (let i = 1; i <= visitNum; i++) {
            if (visitNum === 1) {
                infoArr.push({
                    title: 'Start Time',
                    align: 'center',
                    width: 140,
                    dataIndex: 'visit',
                    key: 'visit',
                    defaultSortOrder: 'ascend',
                    render: (v, r) => {
                        if (v.length >= i) {
                            return moment.utc(v[i - 1].start_time).local().format(api.utils.MOMENTFORMAT.time24);
                        }
                        return '';
                    },
                });
                infoArr.push({
                    title: 'End Time',
                    align: 'center',
                    width: 140,
                    dataIndex: 'visit',
                    key: 'visit',
                    defaultSortOrder: 'ascend',
                    render: (v, r) => {
                        if (v.length >= i) {
                            return moment.utc(v[i - 1].end_time).local().format(api.utils.MOMENTFORMAT.time24);
                        }

                        return '';
                    },
                });
            } else {
                infoArr.push({
                    title: `${ordinal(i)} Visit`,
                    align: 'center',
                    width: 140,
                    dataIndex: 'visit',
                    key: 'visit',
                    defaultSortOrder: 'ascend',
                    render: (v, r) => {
                        if (v.length >= i) {
                            const start =moment.utc(v[i - 1].start_time).local().format(api.utils.MOMENTFORMAT.time24),
                                end = moment.utc(v[i - 1].end_time).local().format(api.utils.MOMENTFORMAT.time24);

                            return `${start} - ${end}`;
                        }

                        return '';
                    },
                });
            }
        }

        return _.concat(infoArr, actionArr);
    };
    const rowSelection = {
        checkStrictly: true,
        fixed: true,
        selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys(selectedRowKeys);
            setSelectedRows(selectedRows);
            setSelectedBooked(selectedRows.filter(stat => stat.status === 'booked'))
        },
        onSelect: (record, selected, selectedRows) => {
        },
        onSelectAll: (selected, selectedRows, changeRows) => {},
    };

    const onPanelistClick = () => {
        historyLink.push(`${urls.ADMIN_PROJECT_LIST_PANELIST_DETAILS}/${ProjectID}`);
    };

    const onClickBookingLogTracker = () => {
        historyLink.push(`${urls.ADMIN_BOOKING_LOG_TRACKER}/${ProjectID}`);
    }

    const onFilterWeeks = (value) => {
        if (value) {
            const filteredData = _.filter(projectObj.current.appointments, (obj) => {
                return isRecurring ? obj.week === value : obj.visits === value;
            });
            setSelectedWeek(value);
            setData(filteredData);
        } else {
            setSelectedWeek(null);
        }
    };
    const onClearFilter = () => {
        setData(projectObj.current.appointments);
    };

    const onEditProjectClick = () => {
        setEditProj(true);
    };

    const onMultipleDeleteSchedule = () => {
        deleteMultipleSchedule(selectedRowKeys);
    };

    const onSaveEditProjectDetails = () => {
        updateProjectData(updateData.current);
    };

    const onCancelEditProjectDetails = () => {
        setEditProj(false);
    };

    const onSaveEditSchedule = () => {
        if (selectedRowKeys.length === 1) {
            if(moment(updateSchedData.current.date).isAfter(moment(schedObj.current.date),'day')|| moment(updateSchedData.current.date).isBefore(moment(schedObj.current.date),'day')){
                setIsChangeDateTime(true)
                        
            }
            if(schedObj.current.visit.map((v, i) => {
                if(moment(updateSchedData.current.visit[i].start_time).isAfter(moment(schedObj.current.visit[i].start_time)) || moment(updateSchedData.current.visit[i].start_time).isBefore(moment(schedObj.current.visit[i].start_time))) {
                    setIsChangeDateTime(true)
                }
            }))
            updateAppointmentData(_.cloneDeep(updateSchedData.current));
        }
    };
    const onCancelEditSchedule = () => {
        setEditSched(false);
    };
    const onEditMultipleItems = () => {
        if(updatedCheckedBoxValue.current) {
            updateMultipleAppointmentData(updateMultipleProjectData.current);
        }else{
            enqueueSnackbar("Please select the field(s) you want to update",{variant : 'error'})
        }
    }
    const _generateExportData = () => {
        const fileName = `project-detail-${projectObj.current.project.name}${
            selectedWeek
                ? `-${ordinal(selectedWeek)} ${projectObj.current.project.is_recurring ? 'Week' : 'Visit'}`
                : ''
        }`;
        const headers = [
            ...(projectObj.current.project.is_recurring
                ? [{ label: 'Week', key: 'week' }]
                : [{ label: 'Visit', key: 'visits' }]),
            { label: 'Date', key: 'date' },
            //{ label: 'Item', key: 'item' },
            {
                label : 'Detail', 
                key : 'details',
            },
        ];

       /* if (projectObj.current.project.is_recurring) {
            headers.push({ label: 'Start Time', key: 'start_time' }, { label: 'End Time', key: 'end_time' });
        }*/

        data.forEach((project) => {
            project.visit.forEach((vis, index) => {
                    if (!headers.find((head) => head.key === `${ordinal(index + 1)} Visit`)) {
                        headers.push({
                            label: `${ordinal(index + 1)} Visit`,
                            key: `${ordinal(index + 1)} Visit`,
                        });
                    }
            });
        });

        if(showFirstName) {
            headers.push(
                { label: 'Location', key: 'location' },
                {
                    label: 'First Name',
                    key: 'participant.first_name',
                },
                {
                    label: 'Panelist ID',
                    key: 'participant.panelist_id',
                },
                {
                    label: 'T-number',
                    key: 'participant.t_number',
                },
                {
                    label: 'Comments',
                    key: 'participant.comment',
                }
            );

            const outputData = _.cloneDeep(data).map((project) => {
                const output = {};
    
                headers.forEach((head) => {
                    if (!head.key.includes('participant')) {
                        output[head.key] = project[head.key];
                    } else {
                        const panelistFound = panelist.find((pan) => pan.id === project.participant.id);
                        const headWithoutParent = head.key.replace('participant.', '');
    
                        if (panelistFound) {
                            if(headWithoutParent === 'comment') {
                                output[head.key] = panelistFound['project_participant'];
                            }else {
                                output[head.key] = panelistFound[headWithoutParent];
                            }
                           
                        } else {
                            output[head.key] = _.get(project, head.key);
                        }
                    }
                });
    
                output.date = moment(project.date).format(api.utils.MOMENTFORMAT.date);
    
                if (projectObj.current.project.is_recurring) {
                    output.week = `${ordinal(project.week)} Week`;
                } else {
                    output.visits = `${ordinal(project.visits)} Visit`;
                }
    
                project.visit.forEach((vis, index) => {
                    const start = moment.utc(vis.start_time).local().format(api.utils.MOMENTFORMAT.time24),
                    end = moment.utc(vis.end_time).local().format(api.utils.MOMENTFORMAT.time24);
    
                    if (projectObj.current.project.is_recurring) {
                        output.start_time = start;
                        output.end_time = end;
                    } else {
                        output[`${ordinal(index + 1)} Visit`] = `${start} - ${end}`;
                    }
                });
                return output;
            });

            return {
                fileName,
                header: [
                    ['ID', projectObj.current.project.pr_id],
                    ['Name', projectObj.current.project.name],
                    ['Type', projectObj.current.project.type],
                    ['Instruction', projectObj.current.project.book_instructions],
                    ['Number of panelist to be scheduled', projectObj.current.project.panel_count],
                    ['Number of booked', projectObj.current.project.booked_cnt],
                    [],
                    headers.map((head) => head.label),
                ],
                data: outputData,
            };

        }else {
            headers.push(
                { label: 'Location', key: 'location' },
                { label: 'Full Name', key: 'panelist' },
                {
                    label: 'Last Name',
                    key: 'participant.last_name',
                },
                {
                    label: 'First Name',
                    key: 'participant.first_name',
                },
                {
                    label: 'Email Address',
                    key: 'participant.email',
                },
                {
                    label: 'Phone Number',
                    key: 'participant.mobile',
                },
                {
                    label : 'Employment Status',
                    key: 'participant.employee_status',
                },
                {
                    label: 'Panelist ID',
                    key: 'participant.panelist_id',
                },
                {
                    label: 'T-number',
                    key: 'participant.t_number',
                },
                {
                    label: 'Comments',
                    key: 'participant.comment',
                },
            );
    
            const outputData = _.cloneDeep(data).map((project) => {
                const output = {};
    
                headers.forEach((head) => {
                    if (!head.key.includes('participant')) {
                        output[head.key] = project[head.key];
                    } else {
                        const panelistFound = panelist.find((pan) => pan.id === project.participant.id);
                        const headWithoutParent = head.key.replace('participant.', '');
                        if (panelistFound) {
                            if(headWithoutParent === 'comment') {
                                output[head.key] = panelistFound['project_participant'][0].comment;
                            }else {
                                output[head.key] = panelistFound[headWithoutParent];
                            }
                        } else {
                            output[head.key] = _.get(project, head.key);
                        }
                    }
                });
    
                output.date = moment(project.date).format(api.utils.MOMENTFORMAT.date);
    
                if (projectObj.current.project.is_recurring) {
                    output.week = `${ordinal(project.week)} Week`;
                } else {
                    output.visits = `${ordinal(project.visits)} Visit`;
                }
    
                project.visit.forEach((vis, index) => {
                    const start = moment.utc(vis.start_time).local().format(api.utils.MOMENTFORMAT.time24),
                        end = moment.utc(vis.end_time).local().format(api.utils.MOMENTFORMAT.time24);
                    if (projectObj.current.project.is_recurring) {
                        output[`${ordinal(index + 1)} Visit`] = `${start} - ${end}`;
                    } else {
                        output[`${ordinal(index + 1)} Visit`] = `${start} - ${end}`;
                       
                    }
                });
                return output;
            }); 
                
            return {
                fileName,
                header: [
                    ['ID', projectObj.current.project.pr_id],
                    ['Name', projectObj.current.project.name],
                    ['Type', projectObj.current.project.type],
                    ['Instruction', projectObj.current.project.book_instructions],
                    ['Number of panelist to be scheduled', projectObj.current.project.panel_count],
                    ['Number of booked', projectObj.current.project.booked_cnt],
                    [],
                    headers.map((head) => head.label),
                ],
                data: outputData,
            };
        }

      
    };
    const onOkModal = () => {
        setIsLoading(true)
        const updatePanelist = { 
            notifyAll : null , 
            project_id : projectObj.current.project.id,
            appointment_ids : selectedRows.map(item => item.id),
            isChangeDateTime : isChangeDateTime
        }
        if(value === 1) {
            updatePanelist.notifyAll = true
        }else if(value === 2) {
            updatePanelist.notifyAll = false
        }else{
            /*don't send anything to user's*/
            updatePanelist.notifyAll = 'No Notify'
            setIsChangeDateTime(false)
            setIsUpdatedProject(false)
        }
        if(updatePanelist.notifyAll !== null && updatePanelist.notifyAll !== 'No Notify' && panelist.length > 0) {
            api.participants
            .notifyPanelist(updatePanelist)
                .then((d) =>{
                        setIsLoading(false)
                        if (d.messages === 'success') {
                            enqueueSnackbar('Notification action successfully completed', { variant: 'success' });
                            setIsUpdatedProject(false)
                            setIsChangeDateTime(false)

                        } else {
                            enqueueSnackbar(errorMapper(d.messages), { variant: 'warning' });
                            setIsUpdatedProject(false)
                            setIsChangeDateTime(false)
                        }

                }).catch(err => {
                    setIsLoading(false);
                    setIsUpdatedProject(false)
                    setIsChangeDateTime(false)
                    enqueueSnackbar('An error occurred while sending a notification. Please contact system admin for help.', { variant: 'error' });
            })

        }else{
            if(updatePanelist.notifyAll !== 'No Notify') { 
                enqueueSnackbar('Project does not contain any panelists', { variant: 'warning' });
                setIsUpdatedProject(false)
                setIsChangeDateTime(false)
            }
            setIsLoading(false)
        }
    }
    const onDontSend = () => {
        setIsUpdatedProject(false)
    }

    const onAddNewVisit = () => {
        setVisibleAddNewVisitModal(true)
    }

    const cancelAddVisitModal = () => {
        setVisibleAddNewVisitModal(false)
    }
    const onAddNewTimeSlot = () => {
        setVisibleAddNewTimeSlot(true)
    }

    const onCancelNewTimeSlot = () => {
        setVisibleAddNewTimeSlot(false)
    }    
    const onCreateNewVisits = (values) => {
        const project =  {
            ...projectObj.current.project
        }

        const lastElement =projectObj.current.appointments[projectObj.current.appointments.length - 1]
        let updateVisit
        if(project.is_recurring) {
             updateVisit = { 
                id : project.id , 
                is_recurring :project.is_recurring ? 1 : 0,
                location:values.location,
                visit_config_rec : values.visit_config_rec,
                old_selected_days : project.recurring_dates,
                selectedDays  : values.selectedDays,
                old_seq : lastElement.visit[lastElement.visit.length - 1].seq ,
                old_slot : lastElement.slot,
            }

        }else{
             updateVisit = {
                id : project.id , 
                is_recurring :0,
                number_of_visits: lastElement.visits + values.number_of_visits ,
                number_last_visit:lastElement.visits,
                location:values.location,
                visit_config : values.visit_config,
                project_start_date : project.project_start_date,
                project_end_date : project.project_end_date,
                old_slot : lastElement.slot ,
                old_item : lastElement.item ,
                old_seq : lastElement.visit[lastElement.visit.length - 1].seq ,
              }
        }

        api.projects.createVisits(updateVisit)
            .then((d) => {
                if (d.messages === 'success') {
                    fetchData(d.idProject);
                    enqueueSnackbar('Added new visit(s) successfully', { variant: 'success' });
                    setConfirmLoading(false)
                    setVisibleAddNewVisitModal(false)
                } else {
                    enqueueSnackbar(errorMapper(d.messages), { variant: 'warning' });
                }
          })
        .catch((e) => {
                enqueueSnackbar('An error occurred while creating visit(s). Please contact system admin for help.', { variant: 'error' });
        });
    }

    const onCreateNewTimeSlot = (values) => {
        const filteredData = _.filter(projectObj.current.appointments, (obj) => {
            return obj.visits === values.select_visit;
        });
        const project =  {
            ...projectObj.current.project
        }

        const projectsched_id_visit = _.filter(projectObj.current.project?.projectsched, (obj) => {
            return obj.visit_number === values.select_visit;
        });

        if(filteredData)
        {
            const payload = {
                id : ProjectID,
                is_recurring :0,
                projectsched_id : filteredData.length === 0 ?  projectsched_id_visit[0].id: filteredData[0].projectsched_id,
                visit_config :  values,
                project_start_date : project.project_start_date,
                project_end_date : project.project_end_date,
                location : values.location,
                duration : values.duration,
                timeInterval : values.timeInterval
            }
           
            api.projects.createTimeSlots(payload)
                .then((d) => {
                    if (d.messages === 'success') {
                            fetchData(ProjectID);
                            enqueueSnackbar('Added new slot(s) successfully', { variant: 'success' });
                            setVisibleAddNewTimeSlot(false)
                            onClearFilter()
                    } else {
                        enqueueSnackbar(errorMapper(d.messages), { variant: 'warning' });
                    }
                  })
                .catch((e) => {
                    enqueueSnackbar('An error occurred while creating slot(s). Please contact system admin for help.', { variant: 'error' });
                });
        }else { 
            enqueueSnackbar('error!', { variant: 'error' });
        }
    }

    const onCLoseEditEmailTemplate = () => {
        setEmailEditor(false);
    };

    const showCustomEmailTemplate = () => {
        setShowCustomTemplateModal(true)
        setEmailEditor(true)
    }

    const onSaveData = (data) => {
        setIsLoading(true)
        api.email.updateEmailTemplate(data)
        .then((d) => {
            if (d.messages === 'success') {
                    enqueueSnackbar('Change email template successfully', { variant: 'success' });
                    historyLink.push(`${urls.ADMIN_BOOKING_PANELIST_DETAILS}/${ProjectID}`);
                    setIsLoading(false)
                    setEmailEditor(false)
                    setEditProj(false)
            } else {
                setIsLoading(false)
                setEmailEditor(false)
                setEditProj(false)
                enqueueSnackbar(errorMapper(d.messages), { variant: 'warning' });
            }
          })
        .catch((e) => {
            setEmailEditor(false)
            setIsLoading(false)
            setEditProj(false)
            enqueueSnackbar('An error occurred while changing email template. Please contact system admin for help.', { variant: 'error' });
        });
    }

    return (
        <div id="project-management-page">
            <Breadcrumb>
                <Breadcrumb.Item>
                    <a href={urls.ADMIN_PROJECT_LIST}>Project List</a>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Project Details</Breadcrumb.Item>
            </Breadcrumb>
            <PageHeader
                ghost={false}
                title="Project Details"
                onBack={() => {
                    historyLink.push(urls.ADMIN_PROJECT_LIST);
                }}
                extra={showActions && [
                    <Button
                        key={1}
                        disabled={editProj}
                        variant="contained"
                        color="primary"
                        startIcon={<Edit />}
                        disableRipple={true}
                        onClick={onEditProjectClick}
                    >
                        Edit Project
                    </Button>,
                ]}
            >
                <div className="page-header-desc">
                    <div className="page-header-desc-left">
                        <Descriptions size="default" column={1}>
                            <Descriptions.Item label="Project ID">{projectObj.current.project.pr_id}</Descriptions.Item>
                            <Descriptions.Item label="Project Type">
                                {projectObj.current.project.type}
                            </Descriptions.Item>
                            <Descriptions.Item label="Instruction">
                                {projectObj.current.project.book_instructions}
                            </Descriptions.Item>
                        </Descriptions>
                    </div>
                    <div className="page-header-desc-right">
                        <Descriptions size="default" column={1}>
                            <Descriptions.Item label="Project Name">
                                {projectObj.current.project.name}
                            </Descriptions.Item>
                            <Descriptions.Item label="Panelist">
                                {projectObj.current.project.panel_count}
                            </Descriptions.Item>
                            <Descriptions.Item label="Booked">
                                {projectObj.current.project.booked_cnt}
                            </Descriptions.Item>
                        </Descriptions>
                    </div>
                </div>
            </PageHeader>

            <div className="project-management-page-content">
                <div className="project-management-page-table-container">
                    <div className="project-management-page-table-controls">
                        <div className="project-management-page-table-controls-left">
                            <Select
                                placeholder={isRecurring ? 'Select Week' : 'Select Visit'}
                                labelInValue={false}
                                onChange={onFilterWeeks}
                                onClear={onClearFilter}
                                allowClear={true}
                                options={totalWeeks}
                            ></Select>
                        </div>

                        <div className="project-management-page-table-controls-right">
                        {selectedRowKeys.length > 1 && showActions && (
                                    <Button startIcon={<Edit />} disableRipple={true}  onClick={onEditMultipleSchedule} >
                                          Edit({selectedRowKeys.length})
                                     </Button>
                        )}
                            {!isRecurring && showActions && <Button startIcon={<FileCopyOutlined />} disableRipple={true} onClick={onAddNewTimeSlot} >
                                                    Add A New Time Slot(s)
                                             </Button>
                            }
                            {showActions && <Button startIcon={<FileCopyOutlined />} disableRipple={true} onClick={onAddNewVisit} >
                                    Add A New Visit(s)
                            </Button>}
                            <CopyToClipboard
                                label={projectObj.current.project.name}
                                value={api.utils.getURL(`${urls.PARTICIPANTS_HOME}/${ProjectID}`)}
                            >
                                <Button startIcon={<FileCopyOutlined />} disableRipple={true}>
                                    Copy Project Link
                                </Button>
                            </CopyToClipboard>

                            {!showFirstName && <Button startIcon={<AssignmentOutlined />} onClick={onPanelistClick} disableRipple={true}>
                                View Panelist
                            </Button> }
                            <Button
                                 startIcon={<Search />}
                                 isableRipple={true}
                                 onClick={onClickBookingLogTracker}
                            >
                                Log Tracker
                            </Button>
                            <Button
                                startIcon={<VerticalAlignBottom />}
                                disableRipple={true}
                                onClick={() => exportExcel(_generateExportData())}
                            >
                                Export
                            </Button>

                            {selectedRowKeys.length > 0 && showActions && (
                                <PopConfirm
                                    title={selectedBooked.length > 0 ?  "Some or all slots selected are booked, are you sure you want to delete?" : "Are you sure you want to delete this schedule?"}
                                    onConfirm={onMultipleDeleteSchedule}
                                >
                                    <Button startIcon={<DeleteForever />} disableRipple={true}>
                                        Delete
                                    </Button>
                                </PopConfirm>
                            )}
                        </div>
                    </div>

                    <Table
                        className="project-management-page-table"
                        columns={mergeColumns}
                        bordered={false}
                        rowKey={'id'}
                        sticky={true}
                        showHeader={true}
                        filterMultiple={true}
                        components = {{
                            header : {
                                cell : ResizableTitle ,
                            }
                        }}
                        tableLayout="fixed"
                        rowSelection={{ ...rowSelection }}
                        pagination={{
                            showTotal: (total, range) => {
                                return `${range[0]}-${range[1]} of ${total} items`;
                            },
                            defaultCurrent: 1,
                            defaultPageSize: 100,
                            pageSizeOptions: [10, 20, 30, 50, 100, 150, 200],
                            showSizeChanger: true,
                            position: ['bottomRight'],
                            total: data.length,
                        }}
                        dataSource={data}
                        scroll={{
                            y: '37vh',
                            x: '100%',
                        }}
                    ></Table>
                </div>
            </div>

            <Modal
                title="Edit Project"
                width={800}
                visible={editProj && !emailEditor}
                centered={true}
                destroyOnClose={true}
                okText="Save"
                onOk={onSaveEditProjectDetails}
                cancelText="Cancel"
                onCancel={onCancelEditProjectDetails}
            >
                <EditProject
                    defaultValue={projectObj.current.project}
                    onChange={(value) => {
                        updateData.current = value;
                    }}
                    showCustomEmailTemplate = {showCustomEmailTemplate}
                />
            </Modal>

            {
                
                showCustomTemplateModal &&  ( <CustomTemplate
                    onCLoseEditEmailTemplate = {onCLoseEditEmailTemplate}
                    emailEditor = {emailEditor}
                    form = {form}
                    defaultValue={projectObj.current.project}
                    onSubmit={onSaveData}
                />)
            }

            <Modal
                title={'Edit Schedule'}
                width={600}
                visible={editSched && selectedRowKeys.length === 1}
                centered={true}
                destroyOnClose={true}
                okText="Save"
                cancelText="Cancel"
                onOk={onSaveEditSchedule}
                onCancel={onCancelEditSchedule}
            >
                <EditSchedule
                    defaultValue={schedObj.current}
                    onChange={(value) => {
                        updateSchedData.current = value;
                    }}
                />
            </Modal>
            <Modal title ="Edit Multiple Schedule"
                    id={"ModalMultipleItems"}
                   width={600}
                   visible={editMultipleItems}
                   centered={true}
                   destroyOnClose={true}
                   okText="Save"
                   cancelText="Cancel"
                   onOk={onEditMultipleItems}
                   onCancel={() => setEditMultipleItems(false)}
            >
                <EditMultipleItems  defaultValue={multipleObject.current}
                                    onChange={(value) => {
                                        updateMultipleProjectData.current = value
                                    }}
                                    onChnageCheckedBoxValue={(value) => {
                                        updatedCheckedBoxValue.current = value
                                                                        }}
                />
            </Modal>

            <Modal
                    title ="Notify others about this update"
                    width = {600}
                    visible={false}
                    centered={true}
                    destroyOnClose={true}
                    okText="Ok"
                    onOk={onOkModal}
                    onCancel={onDontSend}
                    cancelButtonProps={{
                        style: {
                          display: "none",
                        },
                      }}
            >
                <TellUsers
                    bodyModal = {"What would you like to do to notify others about this update? "}
                    value={value}
                    options={[
                        "Notify all panelists",
                        "Notify affected panelists only",
                        "No need to notify anyone"
                    ]}
                    onChange={e => {
                        setValue(e.target.value)
                    }}    
                /> 
            </Modal>

            <AddNewVisit   
                         noOfVisits = {projectObj.current && projectObj.current.appointments
                                        &&projectObj.current.appointments.length
                                        &&projectObj.current
                                        .appointments[projectObj.current.appointments.length -1].visits}
                         visible={VisibleAddNewVisitModal}
                         cancelAddVisitModal={cancelAddVisitModal}
                         onCreateNewVisits={onCreateNewVisits}
                         confirmLoading={confirmLoading}
                         IsRecurring={projectObj.current && projectObj.current.project.is_recurring}
                         visit_per_slot = {projectObj && projectObj.current.project.visits_per_slot}
                         weeks_per_project = {projectObj && projectObj.current.project.weeks_per_project}
                         />

            <AddNewTimeSlot  
                IsRecurring={projectObj.current && projectObj.current.project.is_recurring}
                VisibleAddNewTimeSlot = {VisibleAddNewTimeSlot}
                onCancelNewTimeSlot={onCancelNewTimeSlot}
                onCreateNewTimeSlot={onCreateNewTimeSlot}
                totalWeeks={totalWeeks}
                dataObj = {projectObj}
            />
            <Backdrop id="loading-dialog" open={isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
};

export default connect()(ProjectDetailsPage);
