import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useMount, useUpdateEffect } from 'ahooks';
import { Resizable } from 'react-resizable';
import { PageHeader, Table, Input, Modal, Select } from 'antd';

import { Backdrop, Button, IconButton, CircularProgress, TextField } from '@material-ui/core';
import { SearchOutlined, DeleteForever, Add, Search } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import moment from 'moment';

import { errorMapper, regex } from '../../../utility';
import api from '../../../library/api';
import { ROLES } from '../../../utility/constant'
import PopConfirm from '../../../components/PopConfirm';
import RegexTextField from '../../../components/RegexTextField';

import '../../ProjectManagement/style.scss';
import AuthHelper from '../../../library/helpers/AuthHelper';
import urls from '../../../utility/urls';
import { useHistory, useParams } from 'react-router-dom';

const { Option } = Select;


const ResizableTitle = (props) => {
    const { onResize, width, ...restProps } = props;

    if (!width) {
        return <th {...restProps} />;
    }

    return (
        <Resizable
            width={width}
            height={0}
            handle={
                <span
                    className="react-resizable-handle"
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                />
            }
            onResize={onResize}
            draggableOpts={{
                enableUserSelectHack: false,
            }}
        >
            <th {...restProps} />
        </Resizable>
    );
};

const CreateAdminPage = (props) => {

    const historyLink = useHistory();
    const currentRowData = useRef({});

    const [data, setData] = useState({ original: [], filtered: [] });
    const [isLoading, setIsLoading] = useState(false);
    const [openCreatePanelModal, setOpenCreatePanelModal] = useState(false);
    const [IsCreateModal, setIsCreateModal] = useState(true);

    const [email, setEmail] = useState('');
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [mobile, setMobile] = useState('');
    const [role, setRole] = useState('');
    const [sites, setSites] = useState([])
    const [site, setSite] = useState('Select site')
    const [column, setColumn] = useState([])


    const authHelper = new AuthHelper();

    const { enqueueSnackbar } = useSnackbar();

    useMount(() => {

        api.admins
            .getSitesAdmin()
            .then((d) => {
                if (d.messages === 'success') {
                    setSites(d.data)
                }
            })
            .catch((e) => {
                enqueueSnackbar('An error occurred while getting list of sites. Please contact system admin for help.', { variant: 'error' });
            });
        fetchDataPRTTeam();
    });
    useUpdateEffect(() => { }, [email, firstname, lastname, mobile]);
    useUpdateEffect(() => { fetchDataPRTTeam() }, [sites]);

    const fetchDataPRTTeam = () => {
        setIsLoading(true);
        api.admins.fetchAdmins()
            .then(res => {
                setIsLoading(false);
                if (res.messages === 'success') {
                    let array_options_sites = []
                    sites.map((option) => {
                        option.options.map((item) => {
                            array_options_sites.push(item)
                        })
                    })
                    const finalData = res.data.map((panelist) => ({ ...panelist, site_id: array_options_sites.map((option) => option.id === panelist.site_id && option.name).filter(iter => !!iter)[0] }))
                    setData({ original: finalData, filtered: finalData });
                    setColumn(columns);
                } else {
                    enqueueSnackbar(errorMapper(res.messages), { variant: 'warning' });
                }
            }).catch(err => {
                setIsLoading(false);
                enqueueSnackbar('An error occurred while getting list of admins. Please contact system admin for help.', { variant: 'error' });
            })
    };
    const deletePRTTeam = (id) => {
        setIsLoading(true);

        api.admins
            .deleteAdmin(id)
            .then((d) => {
                setIsLoading(false);

                if (d.messages === 'success') {
                    fetchDataPRTTeam();
                    enqueueSnackbar('Deleted successfully', { variant: 'success' });
                } else {
                    enqueueSnackbar(errorMapper(d.messages), { variant: 'warning' });
                }
            })
            .catch((e) => {
                setIsLoading(false);
                enqueueSnackbar('An error occurred while deleting admin. Please contact system admin for help.', { variant: 'error' });
            });
    };

    const createPRTTeam = (obj) => {
        setIsLoading(true);
        api.admins
            .createAdmin(obj)
            .then((d) => {
                setIsLoading(false);

                if (d.messages === 'success') {
                    fetchDataPRTTeam();
                    onAddPRTTeam();
                    setOpenCreatePanelModal(false);
                    enqueueSnackbar('Created successfully', { variant: 'success' });
                } else {
                    enqueueSnackbar(errorMapper(d.messages), { variant: 'warning' });
                }
            })
            .catch((e) => {
                setIsLoading(false);
                enqueueSnackbar('An error occurred while creating admin. Please contact system admin for help.', { variant: 'error' });
            });
    };
    const columns = [
        {
            title: 'Email Address',
            align: 'left',
            width: 250,
            fixed: 'left',
            dataIndex: 'email',
            key: 'email',
            render: (text, record) => <span>{`${text}`}</span>,
        },
        {
            title: 'Last Name',
            align: 'left',
            width: 250,
            dataIndex: 'last_name',
            key: 'last_name',
            render: (text, record) => <span>{text}</span>,
        },
        {
            title: 'First Name',
            align: 'left',
            width: 250,
            dataIndex: 'first_name',
            key: 'first_name',
            render: (text, record) => <span>{text}</span>,
        },
        {
            title: 'Date Created',
            align: 'center',
            width: 140,
            dataIndex: 'date_created',
            sorter: {
                compare: (a, b) => {
                    if (moment(a.date_created).isBefore(moment(b.date_created), 'day')) {
                        return -1;
                    }

                    if (moment(a.date_created).isAfter(moment(b.date_created), 'day')) {
                        return 1;
                    }

                    return 0;
                },
                multiple: 1,
            },
            sortDirections: ['descend', 'ascend'],
            render: (v, r) => {
                return moment(v).format(api.utils.MOMENTFORMAT.date);
            },
        },
        {
            title: 'Role',
            align: 'left',
            width: 250,
            dataIndex: 'role',
            key: 'role',
            render: (text, record) => {
                if (text === 'proj-admin') {
                    let ULProjectsAdmin =
                        <ul style={{ listStyleType: 'decimal', color: '#00a3e0', paddingLeft: '10px' }}>
                            {
                                record && record?.project_admins.map(rec => {
                                    return (
                                        <li>
                                            <a href={`${urls.ADMIN_PROJECT_LIST_DETAILS}/${rec?.project?.id}`}>{rec?.project?.name}</a>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    return (
                        <>
                            <span>{`Project Admin on project(s):`}</span>
                            {ULProjectsAdmin && ULProjectsAdmin}
                        </>
                    )
                }
                return <span>{text}</span>
            },
        },
        {
            title: 'Site',
            align: 'left',
            width: 250,
            dataIndex: 'site_id',
            key: 'site_id',
            //     render: (text, record) => <span>{sites?.map(option => 
            //     {   
            //         return option.options.map(item => item.id===text && item)[0].name
            //     })}
            //    </span>,
        },
        {
            title: 'Action',
            width: 100,
            editable: false,
            fixed: 'right',
            align: 'center',
            render: (text, record) => (
                <div className="project-management-page-table-row-ctrl">
                    <PopConfirm
                        title="Are you sure you want to delete this admin?"
                        onConfirm={onDeletePRTTeam.bind(this, record)}
                    >
                        <IconButton size="small" disableRipple={true} aria-label="delete" color="secondary">
                            <DeleteForever />
                        </IconButton>
                    </PopConfirm>
                </div>
            ),
        },
    ];

    const handleResize =
        (index) =>
            (_, { size }) => {
                const newColumns = [...column];
                newColumns[index] = { ...newColumns[index], width: size.width };
                setColumn(newColumns);
            };

    const mergeColumns = column.map((col, index) => ({
        ...col,
        onHeaderCell: (column) => (
            {
                width: column.width,
                onResize: handleResize(index),
            }),
    }));

    const onDeletePRTTeam = (rec) => {
        currentRowData.current = rec;
        deletePRTTeam(rec.id);
    };

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
        },
        onSelect: (record, selected, selectedRows) => {
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
        },
    };

    const onAddPRTTeam = () => {
        setEmail('');
        setFirstname('');
        setLastname('');
        setMobile('');
        setRole('');
        setSite('')
        setIsCreateModal(true);
        setOpenCreatePanelModal(true);
    };

    const onSaveCreatePRTTeam = () => {
        if (!email) {
            enqueueSnackbar('Email Address is required!', { variant: 'warning' });
            return;
        }

        if (!regex.email.test(email)) {
            enqueueSnackbar('Email Address is invalid!', { variant: 'warning' });
            return;
        }

        if (!firstname) {
            enqueueSnackbar('First Name is required!', { variant: 'warning' });
            return;
        }

        if (!lastname) {
            enqueueSnackbar('Last Name is required!', { variant: 'warning' });
            return;
        }
        if (!role) {
            enqueueSnackbar('Role is required!', { variant: 'warning' });
            return;
        }

        if (IsCreateModal) {
            createPRTTeam({
                email: email,
                password: '',
                first_name: firstname,
                last_name: lastname,
                mobile: mobile,
                role: role,
                site_id: authHelper.getRole() === ROLES.adminGlobal && (role === ROLES.adminGlobal || role === ROLES.siteAdmin) ? site : authHelper.getUserDetailsAdmin().site_id
            });
        }
    };
    const onResetCreatePRTTeam = (e) => {
        const closeBtn = e.currentTarget.getAttribute('aria-label') === 'Close';

        if (closeBtn) {
            setOpenCreatePanelModal(false);
        } else {
            setEmail('');
            setFirstname('');
            setLastname('');
            setMobile('');
            setRole('');
            setSite('')
        }
    };

    const _handleSearch = (e) => {
        const text = e.target.value;

        setData({
            ...data,
            filtered: data.original.filter(
                (admins) =>
                    admins?.first_name?.toLowerCase().includes(text.toLowerCase()) ||
                    admins?.last_name?.toLowerCase().includes(text.toLowerCase()) ||
                    admins?.email?.toLowerCase().includes(text.toLowerCase()) ||
                    admins?.role?.toLowerCase().includes(text.toLowerCase())
            ),
        });
    };
    const _handleClearSearch = (e) => {
        const text = e.target.value;
        if (text === '') {
            setData({
                ...data,
                filtered: data.original.filter(
                    (admins) =>
                        admins?.first_name?.toLowerCase().includes(text.toLowerCase()) ||
                        admins?.last_name?.toLowerCase().includes(text.toLowerCase()) ||
                        admins?.email?.toLowerCase().includes(text.toLowerCase()) ||
                        admins?.role?.toLowerCase().includes(text.toLowerCase())
                ),
            });
        }
    }

    const onClickAdminLogTracker = () => {
        historyLink.push(`${urls.ONTHEDOT_ADMIN_LOG_TRCKER}`);
    }

    return (
        <div id="project-management-page">
            <PageHeader ghost={false}
                extra={[
                    <Button
                        key="1"
                        variant="contained"
                        color="primary"
                        startIcon={<Add />}
                        disableRipple={true}
                        onClick={onAddPRTTeam}
                    >
                        Add User
                    </Button>,
                ]}
                className={"site-page-header-responsive"} title="Admin Users"> </PageHeader>

            <div className="project-management-page-content">
                <div className="project-management-page-table-container">
                    <div className="project-management-page-table-controls">
                        <div className="project-management-page-table-controls-left">
                            <Input
                                className="searchbox"
                                size="middle"
                                prefix={<SearchOutlined />}
                                placeholder="Search"
                                onPressEnter={_handleSearch}
                                onChange={_handleClearSearch}
                            />
                        </div>

                        <div className="project-management-page-table-controls-right">
                            <Button
                                startIcon={<Search />}
                                disableRipple={true}
                                onClick={onClickAdminLogTracker}
                            >
                                Log Tracker
                            </Button>
                        </div>
                    </div>

                    <Table
                        className="project-management-page-table"
                        columns={mergeColumns}
                        bordered={false}
                        rowKey={'id'}
                        sticky={true}
                        components={{
                            header: {
                                cell: ResizableTitle,
                            }
                        }}
                        showHeader={true}
                        tableLayout="fixed"
                        rowSelection={{ ...rowSelection }}
                        pagination={{
                            showTotal: (total, range) => {
                                return `${range[0]}-${range[1]} of ${total} items`;
                            },
                            defaultCurrent: 1,
                            defaultPageSize: 10,
                            pageSizeOptions: [10, 20, 30, 50, 100],
                            showSizeChanger: true,
                            position: ['bottomRight'],
                            total: data.filtered.length,
                        }}
                        dataSource={data.filtered}
                        scroll={{
                            y: '50vh',
                            x: '100%',
                        }}
                    ></Table>
                </div>
            </div>

            <Modal
                title={'Add New Admin User'}
                width={450}
                visible={openCreatePanelModal}
                centered={true}
                okText="Save"
                onOk={onSaveCreatePRTTeam}
                cancelText="Reset"
                onCancel={onResetCreatePRTTeam}
            >
                <TextField
                    required={true}
                    label="Email Address"
                    disabled={!IsCreateModal}
                    value={email}
                    onChange={(e) => {
                        setEmail(e.target.value);
                    }}
                    placeholder="Email Address"
                    variant="outlined"
                    fullWidth={true}
                    error={email !== '' && !regex.email.test(email)}
                    helperText={email && !regex.email.test(email) ? 'Invalid format.' : ''}
                />
                <RegexTextField
                    required={true}
                    label="First Name"
                    regex={regex.charsAndSpaces}
                    value={firstname}
                    onChange={(e) => {
                        setFirstname(e.target.value);
                    }}
                    placeholder="First Name"
                    variant="outlined"
                    fullWidth={true}
                />


                <RegexTextField
                    required={true}
                    label="Last Name"
                    regex={regex.charsAndSpaces}
                    value={lastname}
                    onChange={(e) => {
                        setLastname(e.target.value);
                    }}
                    placeholder="Last Name"
                    variant="outlined"
                    fullWidth={true}
                />
                <RegexTextField
                    required={false}
                    label="Mobile"
                    regex={regex.mobileNumber}
                    value={mobile}
                    onChange={(e) => {
                        setMobile(e.target.value);
                    }}
                    placeholder="Mobile"
                    variant="outlined"
                    fullWidth={true}
                />
                <Select
                    placeholder={'Role'}
                    allowClear={true}
                    style={{ width: '100%', marginBottom: '10px' }}
                    dropdownStyle={{ zIndex: 2000 }}
                    value={role}
                    onChange={(e) => {
                        setRole(e)
                    }}
                    onClear={e => {
                        setRole('')
                    }}
                >
                    <Option key={ROLES.adminGlobal}>
                        Global Admin
                    </Option>
                    <Option key={ROLES.siteAdmin}>
                        Site Admin
                    </Option>
                    <Option key={ROLES.adminSuper}>
                        Super Admin
                    </Option>
                </Select>
                {ROLES.adminGlobal === authHelper.getRole() && (role === ROLES.adminGlobal || role === ROLES.siteAdmin) &&
                    <Select
                        placeholder={'Select site'}
                        allowClear={true}
                        value={site}
                        style={{ width: '100%', marginBottom: '10px' }}
                        dropdownStyle={{ zIndex: 2000 }}
                        onChange={(e) => {
                            setSite(e)
                        }}
                        onClear={e => {
                            setSite('')
                        }}
                        options={sites || []}
                    />
                }
            </Modal>

            <Backdrop id="loading-dialog" open={isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
};

export default connect()(CreateAdminPage);
