import React from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';

import urls from '../../utility/urls';

import LoginAdminPage from '../Login/LoginAdminPage';
import LoginUserPage from '../Login/LoginUserPage';
import Error404Page from '../Error/404/404';
import ForgetPassword from '../../components/ForgotPassword';
import ResetPassword from '../../components/ResetPassword'

import './style.scss';
import AuthHelper from '../../library/helpers/AuthHelper';
import { ROLES } from '../../utility/constant';
import { useAuth } from '../../components/Auth/auth';

const IndexPage = (props) => {
    let location = useLocation();
    const {authTokens} = useAuth()
    const authHelper = new AuthHelper();
    const userRole = authHelper.getRole();
    
    if(authTokens && (userRole === ROLES.adminGlobal || userRole === ROLES.projAdmin )) {
        return <Redirect to={{ pathname:urls.ADMIN_HOME, state: { referer: props.location } }} />
    }
    if(authTokens && userRole === ROLES.PANELIST) {
        return <Redirect to={{ pathname:urls.USER_PAGE, state: { referer: props.location } }} />
    }
    return (
        <div id="public-layout">                       
            <div id="content">
                <Switch location={location}>
                    <Route path={urls.LOGIN_PAGE}  component={LoginAdminPage} />
                    <Route path={urls.USER_LOGIN} component={LoginUserPage} /> 
                    <Route path={urls.FORGOT_PASSWORD}  component={ForgetPassword}/>
                    <Route path={urls.ResetPassword}  component={ResetPassword}/>              
                    <Route component={Error404Page} />
                </Switch>
            </div>
        </div>
    );
};

export default connect()(IndexPage);
