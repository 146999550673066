import React, { Fragment, useState, useEffect } from "react";
import { Backdrop, CircularProgress, Button } from "@material-ui/core";
import { PageHeader, Result, Space } from "antd";
import { Book, CalendarToday, Schedule, Tv, Close } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import { useHistory, useParams, Link } from "react-router-dom";
import _ from "lodash";

import moment from "../../library/moment";
import api from "../../library/api";
import { errorMapper } from "../../utility";
import urls from "../../utility/urls";
import Modal from "../../components/Modal";
import BookingsPreview from "../BookingSupport/BookingsPreview";

import "./BookingDetail.scss";
import swal from "sweetalert";

const BookingDetail = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { participantId, projectId } = useParams();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const [reschedule, setReschedule] = useState();
  const [cancelModal, setCancelModal] = useState({
    visible: false,
    data: null,
  });
  const [cancelSuccessModal, setCancelSuccessModal] = useState(false);
  const [rescheduleModal, setRescheduleModal] = useState({
    visible: false,
    data: null,
  });
  const [rescheduleSuccessModal, setRescheduleSuccessModal] = useState(false);

  const [numberOfBooked, setNumberOfBooked] = useState(0)
  const [iSBeforeNowDate, setISBeforeNowDate] = useState(false);
  const [showModalRemoveAll, setShowModalRemoveAll] = useState(false);
  const [contactAdmins, setContactAdmins] = useState(false);

  const processedAppointmentData = (proj, appnts) => {
    const newAppnts = _.cloneDeep(appnts);

    _.each(newAppnts, (v, i) => {
      const weeks =
        Math.abs(
          moment(v.date).diff(moment(proj.project_start_date), "weeks")
        ) + 1;
      newAppnts[i].week = weeks;

      const indexSched = _.findIndex(proj.projectsched, (o) => {
        return o.id === v.projectsched_id;
      });

      newAppnts[i].visits =
        indexSched !== -1 ? proj.projectsched[indexSched].visit_number : -1;
    });

    return newAppnts;
  };

  const fetchData = () => {
    setIsLoading(true);

    Promise.all([
      api.appointments.fetchBookingsByProjectAndParticipant(
        projectId,
        participantId
      ),
      api.appointments.panelist.fetchAppointmentsByProjectId(projectId),
    ])
      .then((d) => {
        setIsLoading(false);

        if (d[0].messages === "success" && d[1].messages === "success") {
          const currentAppnts = processedAppointmentData(
            d[0].data[0].project,
            d[0].data
          );

          setData(currentAppnts);

          //sort date
          currentAppnts.sort((a, b) => a.date.diff(b.date));
          //sort time
          currentAppnts.sort((a, b) => {
            return moment(
              moment.utc(a.date).local().format("YYYY-MM-DD") +
              " " +
              moment.utc(a.visit[0].start_time).local().format("HH:mm:ss")
            ).diff(
              moment(
                moment.utc(b.date).local().format("YYYY-MM-DD") +
                " " +
                moment.utc(b.visit[0].start_time).local().format("HH:mm:ss")
              )
            ) != 0
              ? moment(
                moment.utc(a.date).local().format("YYYY-MM-DD") +
                " " +
                moment.utc(a.visit[0].start_time).local().format("HH:mm:ss")
              ).diff(
                moment(
                  moment.utc(b.date).local().format("YYYY-MM-DD") +
                  " " +
                  moment.utc(b.visit[0].start_time).local().format("HH:mm:ss")
                )
              )
              : moment(
                moment.utc(a.date).local().format("YYYY-MM-DD") +
                " " +
                moment.utc(a.visit[0].end_time).local().format("HH:mm:ss")
              ).diff(
                moment(
                  moment.utc(b.date).local().format("YYYY-MM-DD") +
                  " " +
                  moment.utc(b.visit[0].end_time).local().format("HH:mm:ss")
                )
              );
          });
          const checkedISBefore = currentAppnts.filter(i => participantId === i.participant?.id || null)
          const arrChecked = checkedISBefore.map((item) => {
            if (moment.utc(item.date).isBefore(moment())) {
              return true
            } else {
              return false
            }
          })
          const containsTrue = arrChecked.includes(true);
          setNumberOfBooked(
            checkedISBefore
          )

          setISBeforeNowDate(
            containsTrue
          )
          const availableAppnts = processedAppointmentData(
            d[0].data[0].project,
            d[1].data
          );
          setAppointments(availableAppnts);
        } else {
          enqueueSnackbar(
            errorMapper(d[0].messages) + errorMapper(d[1].messages),
            { variant: "warning" }
          );
        }
      })
      .catch((e) => {
        setIsLoading(false);
        enqueueSnackbar(
          "An error occurred while getting appointments. Please contact system admin for help.",
          { variant: "error" }
        );
      });
  };

  const _handleCancelSchedule = () => {
    if (numberOfBooked && numberOfBooked.length <= 1) {
      setIsLoading(true);

      api.appointments.panelist
        .cancelAppointment(cancelModal.data.id)
        .then((d) => {
          setIsLoading(false);

          if (d.messages === "success") {
            setCancelModal(false);
            setCancelSuccessModal(true);
          } else {
            setCancelModal(false);
            swal({ text: d.messages, icon: "warning" });
          }
        })
        .catch((e) => {
          console.log(e);
          setIsLoading(false);
          enqueueSnackbar(
            "An error occurred while canceling appointment. Please contact system admin for help.",
            { variant: "error" }
          );
        });
    } else if (numberOfBooked && numberOfBooked.length > 1 && iSBeforeNowDate) {
      setContactAdmins(true)
    } else {
      setShowModalRemoveAll(true)
    }

  };

  const onRemoveAllVistis = () => {
    //multiple cancle appoitment
    setIsLoading(true);
    let arr = []
    _.each(numberOfBooked, (v) => {
      arr.push(v.id)
    })
    api
      .appointments
      .panelist
      .multipleCancelAppointment(arr, projectId)
      .then((d) => {
        setIsLoading(false);
        if (d.messages === "success") {
          enqueueSnackbar("Schedule cancelled", { variant: "success" });
          setShowModalRemoveAll(false)
          redirectToHomePage()
        } else {
          swal({ text: d.messages, icon: "warning" });
        }
      })
      .catch((e) => {
        setIsLoading(false);
        setShowModalRemoveAll(false)
        enqueueSnackbar(
          "An error occurred while canceling appointment. Please contact system admin for help.",
          { variant: "error" }
        );
      });
  }

  const _handleConfirmReschedule = () => {
    setIsLoading(true);

    api.appointments.panelist
      .rescheduleAppointment(reschedule)
      .then((d) => {
        setIsLoading(false);

        if (d.messages === "success") {
          fetchData(reschedule.to_appointment_id);
          setRescheduleModal({ visible: false, data: null });
          setRescheduleSuccessModal(true);
          history.push(
            `${urls.PARTICIPANTS_SCHEDULE_DETAIL}/${reschedule.to_appointment_id}`
          );
        } else {
          setRescheduleModal({ visible: false, data: null });
          swal({ text: errorMapper(d.messages), icon: "warning" });
        }
      })
      .catch((e) => {
        setIsLoading(false);
        enqueueSnackbar(
          "An error occurred while rescheduling appointment. Please contact system admin for help.",
          { variant: "error" }
        );
      });
  };

  useEffect(() => {
    document.title = "OnTheDot Panelist Portal";
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return (
      <Backdrop id="loading-dialog" open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  if (!data[0]?.project) {
    return (
      <div className="flex-center">
        <Result
          status="error"
          title="Appointment Unavailable"
          icon={
            <img
              className="img-150"
              src={`${process.env.PUBLIC_URL}/assets/project-unavailable.png`}
              alt="project-unavailable"
            />
          }
          subTitle={
            <div>
              Please check if appointment link is valid. Or contact us via email{" "}
              <a href="mailto: onthedot.im@pg.com">onthedot.im@pg.com</a> if you
              need further assistance.
            </div>
          }
          extra={[
            <Button
              type="primary"
              key="reload"
              onClick={() => window.location.reload()}
            >
              Reload
            </Button>,
          ]}
        ></Result>
      </div>
    );
  }

  if (!data[0]?.project.is_published) {
    return (
      <div className="flex-center">
        <Result
          status="warning"
          title="Project Unavailable"
          icon={
            <img
              className="img-150"
              src={`${process.env.PUBLIC_URL}/assets/project-unavailable.png`}
              alt="project-unavailable"
            />
          }
          subTitle={
            <div>
              This project is no longer available. Please contact us via Get
              Help button or email{" "}
              <a href="mailto: onthedot.im@pg.com">onthedot.im@pg.com</a> if you
              need further assistance.
            </div>
          }
          extra={[
            <Link to={`${urls.PARTICIPANTS_HELP}/${data[0].project.id}`}>
              <Button variant="contained" key="get-help">
                Get Help
              </Button>
            </Link>,
          ]}
        />
      </div>
    );
  }

  if (data[0]?.status === "cancelled") {
    return (
      <div className="flex-center">
        <Result
          status="warning"
          title="Appointment Cancelled"
          icon={
            <img
              className="img-150"
              src={`${process.env.PUBLIC_URL}/assets/project-cancelled.png`}
              alt="project-cancelled"
            />
          }
          subTitle={
            <div>
              This appointment has been cancelled. Please contact us via Get
              Help button or email{" "}
              <a href="mailto: onthedot.im@pg.com">onthedot.im@pg.com</a> if you
              need further assistance.
            </div>
          }
          extra={[
            <Link to={`${urls.PARTICIPANTS_HELP}/${data[0].project.id}`}>
              <Button variant="contained" key="get-help">
                Get Help
              </Button>
            </Link>,
          ]}
        />
      </div>
    );
  }

  if (data[0]?.status !== "booked") {
    return (
      <div className="flex-center">
        <Result
          status="warning"
          title="This appointment is not booked"
          extra={[
            <Button
              type="primary"
              key="reload"
              onClick={() => window.location.reload()}
            >
              Reload
            </Button>,
          ]}
        />
      </div>
    );
  }

  const redirectToHomePage = () => {
    history.push(`${urls.USER_PROJECT_LIST}`);
  };

  const redirectToProjectDetailsPage = () => {
    // history.push(`${urls.USER_PROJECT_LIST_DETAILS}/${localStorage.getItem("appointmentsCurrentID")}`);

    // this condition will trigger when user come from  newProject Tab
    // if(localStorage.getItem("selectedTab") ==="NewProjectsTab"){
    //     history.push(`${urls.USER_PROJECT_LIST_DETAILS}/${localStorage.getItem("appointmentsCurrentID")}`);

    // }else if(localStorage.getItem("selectedTab") ==="ActiveBookingTab"){
    //     // this condition will trigger when user come from  activebooking Tab
    //     history.push(`${urls.USER_APPOINTMENT}/${localStorage.getItem("appointmentsID")}`);
    // }

    history.push(`${urls.USER_PROJECT_LIST}`);
  };

  return (
    <div id="participants-schedule-booking-detail-page">
      <PageHeader
        className="participants-schedule-header"
        ghost={true}
        title="Project Schedule"
      ></PageHeader>
      <div className="participants-booking-detail">
        <div className="form-header">
          <>
            <span className="close-button" onClick={() => redirectToHomePage()}>
              <Close />
            </span>
          </>
        </div>
        <div className="form-content">
          <div className="content-title">
            <Book /> Your Booking is Confirmed!
          </div>
          <div className="content-row">
            <span className="label">Project ID: </span>
            <span className="value">{data[0]?.project?.pr_id || ""}</span>
          </div>
          <div className="content-row">
            <span className="label">Project Name: </span>
            <span className="value">{data[0]?.project?.name || ""}</span>
          </div>
          <div className="content-row">
            <span className="label">Project Type: </span>
            <span className="value">{data[0]?.project?.type || ""}</span>
          </div>
          <hr className="separator" />
          {data.length > 0 &&
            data.map((app) => {
              return (
                <Fragment key={app.id}>
                  <div className="content-flex">
                    <div className="content-row space">
                      <div className="label">
                        Date <CalendarToday />
                      </div>
                      <div className="value">
                        {moment(app.date).format(api.utils.MOMENTFORMAT.date)}
                      </div>
                      <div className="label">
                        Time <Schedule />
                      </div>
                      {app.visit &&
                        app.visit.length > 0 &&
                        app.visit.map((visit, idx, arr) => {
                          return (
                            <span className="value" key={visit.id}>
                              {moment
                                .utc(visit.start_time)
                                .local()
                                .format(api.utils.MOMENTFORMAT.time24)}{" "}
                              -{" "}
                              {moment
                                .utc(visit.end_time)
                                .local()
                                .format(api.utils.MOMENTFORMAT.time24)}
                              {idx !== arr.length - 1 && ", "}
                            </span>
                          );
                        })}
                      <div className="label">
                        Status <Tv />
                      </div>
                      <div className="value">{app.status.toUpperCase()}</div>
                    </div>
                    <Space>
                      <Button
                        variant="contained"
                        color="secondary"
                        disableRipple={true}
                        onClick={() =>
                          setCancelModal({ visible: true, data: app })
                        }
                      >
                        Cancel Booking
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        disableRipple={true}
                        onClick={() =>
                          setRescheduleModal({ visible: true, data: app })
                        }
                      >
                        Reschedule
                      </Button>
                    </Space>
                  </div>
                  <hr className="separator" />
                </Fragment>
              );
            })}
        </div>
      </div>
      <Modal
        visible={cancelModal.visible}
        contentClass="align-center"
        footerClass="align-center"
        footer={
          <>
            <Button
              variant="contained"
              color="primary"
              disableRipple={true}
              onClick={_handleCancelSchedule}
            >
              Yes
            </Button>
            <Button
              variant="contained"
              color="primary"
              disableRipple={true}
              onClick={() => setCancelModal({ visible: false, data: null })}
            >
              No
            </Button>
          </>
        }
      >
        <div className="confirm-content">
          <div className="confirm-title">Cancellation</div>
          <div className="confirm-question">
            <div className="confirm-text">Are you sure you want to cancel</div>
            <div className="confirm-text-blue">
              SLOT {cancelModal.data?.item || ""}?
            </div>
          </div>
          <div className="confirm-description">
            Date selected:{" "}
            {moment(cancelModal.data?.date).format(api.utils.MOMENTFORMAT.date)}
          </div>
          <div className="confirm-description">
            Time:{" "}
            {cancelModal.data?.visit &&
              cancelModal.data?.visit.length > 0 &&
              cancelModal.data?.visit.map((visit, idx, arr) => {
                return (
                  <span className="value" key={visit.id}>
                    {moment
                      .utc(visit.start_time)
                      .local()
                      .format(api.utils.MOMENTFORMAT.time24)}{" "}
                    -{" "}
                    {moment
                      .utc(visit.end_time)
                      .local()
                      .format(api.utils.MOMENTFORMAT.time24)}
                    {idx !== arr.length - 1 && ", "}
                  </span>
                );
              })}
          </div>
        </div>
      </Modal>
      <Modal
        visible={cancelSuccessModal}
        contentClass="align-center"
        footerClass="align-center"
        footer={
          <Button
            variant="contained"
            color="primary"
            disableRipple={true}
            onClick={() => redirectToProjectDetailsPage()}
          // onClick={() => setCancelSuccessModal(false)}
          >
            Close
          </Button>
        }
      >
        <div className="confirm-content">
          <div className="confirm-title">Appointment</div>
          <div className="confirm-title">Successfully Cancelled</div>
        </div>
      </Modal>
      <Modal
        width={800}
        visible={rescheduleModal.visible}
        header="Reschedule"
        footer={
          <>
            <Button
              variant="contained"
              disableRipple={true}
              onClick={() => setRescheduleModal({ visible: false, data: null })}
            >
              Close
            </Button>
            <Button
              variant="contained"
              color="primary"
              disableRipple={true}
              onClick={_handleConfirmReschedule}
            >
              Confirm
            </Button>
          </>
        }
      >
        <BookingsPreview
          selected={rescheduleModal.data}
          defaultValue={{ project: data[0].project, appointments }}
          onChange={(v) => setReschedule(v)}
        />
      </Modal>
      <Modal
        visible={rescheduleSuccessModal}
        contentClass="align-center"
        footerClass="align-center"
        footer={
          <Button
            variant="contained"
            color="primary"
            disableRipple={true}
            onClick={() => redirectToProjectDetailsPage()}
          // onClick={() => setRescheduleSuccessModal(false)}
          >
            Close
          </Button>
        }
      >
        <div className="confirm-content">
          <div className="confirm-title">Appointment</div>
          <div className="confirm-title">Successfully Rescheduled</div>
        </div>
      </Modal>

      <Modal
        title="Visit Cancellation"
        visible={showModalRemoveAll}
        centered={true}
        destroyOnClose={true}
        footer={[
          <>
            <Button
              variant="contained"
              color="secondary"
              disableRipple={true}
              onClick={() => setShowModalRemoveAll(false)}
            >
              Close
            </Button>
            <Button
              variant="contained"
              color="primary"
              disableRipple={true}
              onClick={onRemoveAllVistis}
            >
              Cancel group of visits
            </Button>
          </>


        ]}>
        <p>
          You can’t cancel only this visit because it is part of a group of visits. You can either choose to reschedule it or cancel and rebook all the visits altogether.  For further assistance, contact your project coordinator directly.
        </p>
      </Modal>


      <Modal
        title="Visit Cancellation"
        visible={contactAdmins}
        centered={true}
        destroyOnClose={true}
        footer={[
          <>
            <Button
              variant="contained"
              color="secondary"
              disableRipple={true}
              onClick={() => setContactAdmins(false)}
            >
              Close
            </Button>
            <Button
              variant="contained"
              color="primary"
              disableRipple={true}
              onClick={() => history.push(`${urls.USER_APPOINTMENT}/${projectId}`)}
            >
              Redirect to My Appointment
            </Button>
          </>


        ]}>
        <p>
          You can’t cancel this visit because it is part of a group of visits some of which has already been completed. You can only reschedule it. For further assistance, contact your project coordinator directly.
        </p>
      </Modal>
    </div>
  );
};

export default BookingDetail;
