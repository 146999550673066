import moment from 'moment'
import { ROLES } from '../../utility/constant'
import urls from '../../utility/urls'
import api from '../api'
import codify from '../codify'

export default class AuthHelper { 
    getRole = () => {
        return window.localStorage.getItem('role')
    }

    setRole = (role) => {
        window.localStorage.setItem('role', role)
    }
    setUserDetailsAdmin = (user) => {
        window.localStorage.setItem('user_admin',codify.encode(JSON.stringify(user)))
    }
    getUserDetailsAdmin = () => {
        return JSON.parse(codify.decode(window.localStorage.getItem('user_admin')))
    }
    getUserDetails = () => {
       return JSON.parse(codify.decode(window.localStorage.getItem('profile_user')))
    }

    setUserDetails =(user_profile) => {
        window.localStorage.setItem('profile_user',codify.encode(JSON.stringify(user_profile)))
    }

    isLogging = () =>{
        //TODO: We add an expier token later
        const token = window.localStorage.getItem('tokens')
        return  token && !this.isTokenExpired(token)
    }

    isTokenExpired = (token) => {
        try {
            let exp = window.localStorage.getItem('expiryTime');
            if (moment(exp) < moment()) {
                this.logout();
                return true;
            } else {
                return false;
            }
        } catch (err) {
            return false;
        }
    }

    setExpiryTime = (time) => {
        let expireTime = moment().add(time, 'seconds').toString();
        window.localStorage.setItem('expiryTime', expireTime);
    }

    setToken = (token) => {
        window.localStorage.setItem('tokens', codify.encode(JSON.stringify(token)));
    }

    setRefreshToken = (refreshToken) => {
        window.localStorage.setItem('refresh_token', refreshToken);
    }
    getRefreshToken = () => {
        return window.localStorage.getItem('refresh_token')
    }

    getIsFirstTimeLogging = () => {
        return window.localStorage.getItem('must_chnage_password')
    }
    setIsFirstTimeLogging = (must_chnage_password) => {
        window.localStorage.setItem('must_chnage_password', must_chnage_password)
    }

    logout = async (history) => {
        // await logout();
        await api.user.logout()
        let historyUrl = urls.LOGIN_PAGE
        if (this.getRole() === ROLES.PANELIST ) {
            historyUrl = urls.USER_LOGIN
        }
        localStorage.clear()
        history.push(historyUrl)
        history.go(0)
    }
}