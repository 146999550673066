import React from 'react';
import { Popconfirm, Button } from 'antd';

const PopConfirm = ({ title = '', okText = 'Yes', cancelText = 'No', onConfirm, children }) => {
    return (
        <Popconfirm placement="bottom" title={title} onConfirm={onConfirm} okText={okText} cancelText={cancelText}>
            {children || <Button>Delete</Button>}
        </Popconfirm>
    );
};

export default PopConfirm;
