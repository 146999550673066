import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useMount, useUpdateEffect } from 'ahooks';
import _ from 'lodash';
import { Promise } from 'bluebird';

import { PageHeader, Table, Input, Modal, Select, Tag } from 'antd';
import { Resizable } from 'react-resizable';
import { Backdrop, Button, IconButton, CircularProgress, TextField } from '@material-ui/core';
import { SearchOutlined, DeleteForever, Edit, VerticalAlignBottom, Search } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { exportExcel } from '../../../utility/xslx';
import { errorMapper, regex } from '../../../utility';
import api from '../../../library/api';
import PopConfirm from '../../../components/PopConfirm';
import RegexTextField from '../../../components/RegexTextField';
import { ROLES, EMPLOYEE_STATUS } from '../../../utility/constant'

import '../../ProjectManagement/style.scss';
import urls from '../../../utility/urls';
import { useHistory, useParams } from 'react-router-dom';


const EmployestatusColor = {
    geekBlue: 'geekblue',
};

const ResizableTitle = (props) => {
    const { onResize, width, ...restProps } = props;

    if (!width) {
        return <th {...restProps} />;
    }

    return (
        <Resizable
            width={width}
            height={0}
            handle={
                <span
                    className="react-resizable-handle"
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                />
            }
            onResize={onResize}
            draggableOpts={{
                enableUserSelectHack: false,
            }}
        >
            <th {...restProps} />
        </Resizable>
    );
};
const PanelistPage = () => {

    const currentRowData = useRef({});
    const historyLink = useHistory()

    const [data, setData] = useState({ original: [], filtered: [] });
    const [isLoading, setIsLoading] = useState(false);
    const [openCreatePanelModal, setOpenCreatePanelModal] = useState(false);
    const [IsCreateModal, setIsCreateModal] = useState(true);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);


    const [email, setEmail] = useState('');
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [mobile, setMobile] = useState('');
    const [sites, setSites] = useState([])
    const [employeStatus, setEmployeeStatus] = useState("")
    const [selectedFile, setSelectedFile] = useState("")
    const [column, setColumn] = useState([])

    const [panelistID, setPanelistID] = useState("")
    const [tnumber, setTnumber] = useState("")
    const { enqueueSnackbar } = useSnackbar();

    useMount(() => {
        api.admins
            .getSitesAdmin()
            .then((d) => {
                if (d.messages === 'success') {
                    setSites(d.data)
                }
            })
            .catch((e) => {
                enqueueSnackbar('An error occurred while getting list of sites. Please contact system admin for help.', { variant: 'error' });
            });
        fetchDataPaneLists();
    });


    useUpdateEffect(() => { }, [email, firstname, lastname, mobile]);

    useUpdateEffect(() => {
        fetchDataPaneLists();

    }, [sites]);


    const columns = [
        {
            title: 'Email Address',
            align: 'left',
            width: 250,
            fixed: 'left',
            dataIndex: 'email',
            key: 'email',
            sorter: (a, b) =>
                a.email.toLowerCase() !== b.email.toLowerCase()
                    ? a.email.toLowerCase() < b.email.toLowerCase()
                        ? -1
                        : 1
                    : 0,
            // render: (text, record) => <span>{`${text}`}</span>,
        },
        {
            title: 'Last Name',
            align: 'left',
            width: 250,
            dataIndex: 'last_name',
            key: 'last_name',
            sorter: (a, b) =>
                a.last_name && b.last_name !== null
                    ? a.last_name.toLowerCase() < b.last_name.toLowerCase()
                        ? -1
                        : 1
                    : 0,
            // render: (text, record) => <span>{text}</span>,
        },
        {
            title: 'First Name',
            align: 'left',
            width: 250,
            dataIndex: 'first_name',
            key: 'first_name',
            sorter: (a, b) =>
                a.first_name && b.first_name !== null
                    ? a.first_name.toLowerCase() < b.first_name.toLowerCase()
                        ? -1
                        : 1
                    : 0,
            // render: (text, record) => <span>{text}</span>,
        },
        {
            title: 'Mobile',
            align: 'left',
            width: 250,
            dataIndex: 'mobile',
            key: 'mobile',
            sorter: (a, b) => a.mobile - b.mobile,
        },
        {
            title: 'Site',
            align: 'left',
            width: 250,
            dataIndex: 'site_id',
            key: 'site_id',
            // render: (text, record) => <span>{
            //     sites.map(option => 
            // {   

            //     return option.options.map(item => item.id===text && item)[0].name
            // })}
            //    </span>,
        },
        {
            title: 'Employment Status',
            align: 'center',
            width: 300,
            dataIndex: 'employee_status',
            key: 'employee_status',
            sorter: (a, b) =>
                a.employee_status && b.employee_status !== null
                    ? a.employee_status.toLowerCase() < b.employee_status.toLowerCase()
                        ? -1
                        : 1
                    : 0
            ,
            render: (text, record) => <Tag color={EmployestatusColor.geekBlue}>{text}</Tag>,
        },
        {
            title: 'Panelist ID',
            align: 'left',
            width: 250,
            dataIndex: 'panelist_id',
            key: 'panelist_id',
            render: (text, record) => <span>{text}</span>,
        },
        {
            title: 'T-number',
            align: 'left',
            width: 250,
            dataIndex: 't_number',
            key: 't_number',
            render: (text, record) => <span>{text}</span>,
        },
        {
            title: 'Date Created',
            align: 'center',
            width: 140,
            dataIndex: 'date_created',
            sorter: {
                compare: (a, b) => {
                    if (moment(a.date_created).isBefore(moment(b.date_created), 'day')) {
                        return -1;
                    }

                    if (moment(a.date_created).isAfter(moment(b.date_created), 'day')) {
                        return 1;
                    }

                    return 0;
                },
                multiple: 1,
            },
            // sortDirections: ['descend', 'ascend'],
            render: (v, r) => {
                return moment(v).format(api.utils.MOMENTFORMAT.date);
            },
        },
        {
            title: 'Action',
            width: 100,
            editable: false,
            fixed: 'right',
            align: 'center',
            render: (text, record) => (
                <div className="project-management-page-table-row-ctrl">
                    <IconButton
                        size="small"
                        disableRipple={true}
                        aria-label="Edit"
                        onClick={onEditPanelist.bind(this, record)}
                    >
                        <Edit fontSize="small" />
                    </IconButton>
                    <PopConfirm
                        title="Are you sure you want to delete this panelist?"
                        onConfirm={onDeletePanelists.bind(this, record)}
                    >
                        <IconButton size="small" disableRipple={true} aria-label="delete" color="secondary">
                            <DeleteForever />
                        </IconButton>
                    </PopConfirm>
                </div>
            ),
        },
    ];

    const handleResize =
        (index) =>
            (_, { size }) => {
                const newColumns = [...column];
                newColumns[index] = { ...newColumns[index], width: size.width };
                setColumn(newColumns);
            };

    const mergeColumns = column.map((col, index) => ({
        ...col,
        onHeaderCell: (column) => (
            {
                width: column.width,
                onResize: handleResize(index),
            }),
    }));

    const fetchDataPaneLists = () => {
        setIsLoading(true);
        api.panelist.fetchPanelist()
            .then(res => {
                setIsLoading(false);
                if (res.messages === 'success') {
                    let array_options_sites = []
                    sites.map((option) => {
                        option.options.map((item) => {
                            array_options_sites.push(item)
                        })
                    })
                    const finalData = res.data.map((panelist) => ({ ...panelist, site_id: array_options_sites.map((option) => option.id === panelist.site_id && option.name).filter(iter => !!iter)[0] }))
                    setData({ original: finalData, filtered: finalData });
                    setColumn(columns);
                } else {
                    enqueueSnackbar(errorMapper(res.messages), { variant: 'warning' });
                }
            }).catch(err => {
                setIsLoading(false);
                enqueueSnackbar('An error occurred while getting list of panelist. Please contact system admin for help.', { variant: 'error' });
            })
    };
    const onDeletePanelist = (id) => {
        setIsLoading(true);

        api.panelist
            .deletePaneList(id)
            .then((d) => {
                setIsLoading(false);

                if (d.messages === 'success') {
                    fetchDataPaneLists();
                    enqueueSnackbar('Deleted successfully', { variant: 'success' });
                } else {
                    enqueueSnackbar(errorMapper(d.messages), { variant: 'warning' });
                }
            })
            .catch((e) => {
                setIsLoading(false);
                enqueueSnackbar('An error occurred while deleting panelist. Please contact system admin for help.', { variant: 'error' });
            });
    };

    const onMultipleDeletePanelist = () => {
        let arr = [];

        setIsLoading(true);
        _.each(selectedRowKeys, (v) => {
            arr.push(api.panelist.deletePaneList(v));
        });

        Promise.mapSeries(arr, (arr, index, arrLength) => {
            return arr;
        })
            .then((d) => {
                setIsLoading(false);
                enqueueSnackbar('Successfully deleted Panelist', {
                    variant: 'success',
                });
                fetchDataPaneLists();
            })
            .catch((e) => {
                setIsLoading(false);
                enqueueSnackbar('An error occurred while deleting a list of panelists. Please contact system admin for help.', { variant: 'error' });
            });
    }

    const onUpdateFieldsPanelist = (obj) => {
        setIsLoading(true);

        api.panelist
            .updatePanelist(obj)
            .then((d) => {
                setIsLoading(false);

                if (d.messages === 'success') {
                    currentRowData.current = {};
                    fetchDataPaneLists();
                    setOpenCreatePanelModal(false);
                    enqueueSnackbar('Updated successfully', { variant: 'success' });
                } else {
                    enqueueSnackbar(errorMapper(d.messages), { variant: 'warning' });
                }
            })
            .catch((e) => {
                setIsLoading(false);
                enqueueSnackbar('An error occurred while updating panelist. Please contact system admin for help.', { variant: 'error' });
            });
    }


    const onDeletePanelists = (rec) => {
        currentRowData.current = rec;
        onDeletePanelist(rec.id);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys(selectedRowKeys);
        },
        onSelect: (record, selected, selectedRows) => {
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
        },
    };

    const onEditPanelist = (rec) => {
        currentRowData.current = rec;
        setIsCreateModal(false);
        setOpenCreatePanelModal(true);
        setEmail(currentRowData.current.email);
        setFirstname(currentRowData.current.first_name || '');
        setLastname(currentRowData.current.last_name || '');
        setMobile(currentRowData.current.mobile || '');
        setEmployeeStatus(currentRowData.current.employee_status)
        setPanelistID(currentRowData.current.panelist_id)
        setTnumber(currentRowData.current.t_number)
    };

    const onSavePanelist = () => {

        if (!IsCreateModal) {
            onUpdateFieldsPanelist({
                id: currentRowData.current.id,
                email: email,
                password: '',
                first_name: firstname || "",
                last_name: lastname || "",
                mobile: mobile || "",
                role: ROLES.PANELIST,
                employee_status: employeStatus,
                panelist_id: panelistID,
                t_number: tnumber

            });
        }
    };

    const onResetModal = (e) => {
        const closeBtn = e.currentTarget.getAttribute('aria-label') === 'Close';

        if (closeBtn) {
            setOpenCreatePanelModal(false);
        } else {
            setFirstname('');
            setLastname('');
            setMobile('');
            setPanelistID('')
            setTnumber('')
            setEmployeeStatus(null)
        }
    };

    const _handleSearch = (e) => {
        const text = e.target.value;
        setData({
            ...data,
            filtered: data.original.filter(
                (panelist) =>
                    (panelist?.first_name && panelist.first_name.toLowerCase().includes(text.toLowerCase())) ||
                    (panelist?.last_name && panelist.last_name.toLowerCase().includes(text.toLowerCase())) ||
                    (panelist?.email && panelist.email.toLowerCase().includes(text.toLowerCase())) ||
                    (panelist?.mobile && panelist.mobile.toLowerCase().includes(text.toLowerCase())) ||
                    (panelist?.employee_status && panelist.employee_status.toLowerCase().includes(text.toLowerCase()))

            ),
        });
    };



    const _handleClearSearch = (e) => {
        const text = e.target.value;
        if (text === '') {
            setData({
                ...data,
                filtered: data.original.filter(
                    (panelist) =>
                        (panelist?.first_name && panelist.first_name.toLowerCase().includes(text.toLowerCase())) ||
                        (panelist?.last_name && panelist.last_name.toLowerCase().includes(text.toLowerCase())) ||
                        (panelist?.email && panelist.email.toLowerCase().includes(text.toLowerCase())) ||
                        (panelist?.mobile && panelist.mobile.toLowerCase().includes(text.toLowerCase())) ||
                        (panelist?.employee_status && panelist.employee_status.toLowerCase().includes(text.toLowerCase()))
                ),
            });
        }
    }

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0])
    };
    const handleUpload = (event) => {
        event.preventDefault();
        if (!selectedFile) {
            return;
        }
        const data = new FormData();
        data.append('file', selectedFile);
        api.appointments.panelist
            .TestFiles(data)
            .then((d) => {
                console.log(d)
            })
            .catch((e) => {
                console.log(e)
            });
    };



    const _generateExportData = () => {

        const fileName = "project-list-panelist"

        const headers = [
            { label: 'Email Address', key: 'email' },
            { label: 'Last Name', key: 'last_name' },
            { label: 'First Name', key: 'first_name' },
            { label: 'Mobile', key: 'mobile' },
            { label: 'Site', key: 'site_id' },
            { label: 'Employment Status', key: 'employee_status' },
            { label: 'Panelist ID', key: 'panelist_id' },
            { label: 'T-Number', key: 't_number' },
            { label: 'Date Created', key: 'date_created' },
        ];

        const outputData = _.cloneDeep(data.original).map((project) => {
            const output = {};
            headers.forEach((head) => {
                output[head.key] = project[head.key];
            });
            output.date_created = moment(project.date_created).format(api.utils.MOMENTFORMAT.date);
            return output;
        });
        return {
            fileName,
            header: [headers.map((head) => head.label)],
            data: outputData,
        };
    };

    const onClickPanelistLogTracker = () => {
        historyLink.push(`${urls.ONTHEDOT_USERS_LOG_TRCKER}`);
    }

    return (
        <div id="project-management-page">
            <PageHeader ghost={false} className={"site-page-header-responsive"} title="Panelist"> </PageHeader>
            <div className="project-management-page-content">
                <div className="project-management-page-table-container">
                    <div className="project-management-page-table-controls">
                        <div className="project-management-page-table-controls-left">
                            <Input
                                className="searchbox"
                                size="middle"
                                prefix={<SearchOutlined />}
                                placeholder="Search"
                                onPressEnter={_handleSearch}
                                onChange={_handleClearSearch}
                            />

                        </div>
                        <div className='project-management-page-table-controls-right'>
                            <Button
                                startIcon={<Search />}
                                disableRipple={true}
                                onClick={onClickPanelistLogTracker}
                            >
                                Log Tracker
                            </Button>
                            <Button
                                startIcon={<VerticalAlignBottom />}
                                disableRipple={true}
                                onClick={() => exportExcel(_generateExportData())}
                            >
                                Export
                            </Button>
                            {selectedRowKeys.length > 0 && (
                                <PopConfirm
                                    title="Are you sure you want to delete this panelists?"
                                    onConfirm={onMultipleDeletePanelist}
                                >
                                    <Button startIcon={<DeleteForever />} disableRipple={true}>
                                        Delete
                                    </Button>
                                </PopConfirm>
                            )}
                        </div>
                    </div>

                    <Table
                        className="project-management-page-table"
                        columns={mergeColumns}
                        bordered={false}
                        rowKey={'id'}
                        sticky={true}
                        components={{
                            header: {
                                cell: ResizableTitle,
                            }
                        }}
                        showHeader={true}
                        tableLayout="fixed"
                        rowSelection={{ ...rowSelection }}
                        pagination={{
                            showTotal: (total, range) => {
                                return `${range[0]}-${range[1]} of ${total} items`;
                            },
                            defaultCurrent: 1,
                            defaultPageSize: 10,
                            pageSizeOptions: [10, 20, 30, 50, 100],
                            showSizeChanger: true,
                            position: ['bottomRight'],
                            total: data.filtered.length,
                        }}
                        dataSource={data.filtered}
                        scroll={{
                            y: '50vh',
                            x: '100%',
                        }}
                    ></Table>
                </div>
            </div>

            <Modal
                title={!IsCreateModal ? 'Edit Panelist' : ''}
                width={450}
                visible={openCreatePanelModal}
                centered={true}
                okText="Save"
                onOk={onSavePanelist}
                cancelText="Reset"
                onCancel={onResetModal}
            >
                <TextField
                    required={true}
                    label="Email Address"
                    disabled={!IsCreateModal}
                    value={email}
                    onChange={(e) => {
                        setEmail(e.target.value);
                    }}
                    placeholder="Email Address"
                    variant="outlined"
                    fullWidth={true}
                    error={email !== '' && !regex.email.test(email)}
                    helperText={email && !regex.email.test(email) ? 'Invalid format.' : ''}
                />
                <RegexTextField
                    label="First Name"
                    regex={regex.charsAndSpaces}
                    value={firstname}
                    onChange={(e) => {
                        setFirstname(e.target.value);
                    }}
                    placeholder="First Name"
                    variant="outlined"
                    fullWidth={true}
                />


                <RegexTextField
                    label="Last Name"
                    regex={regex.charsAndSpaces}
                    value={lastname}
                    onChange={(e) => {
                        setLastname(e.target.value);
                    }}
                    placeholder="Last Name"
                    variant="outlined"
                    fullWidth={true}
                />
                <TextField
                    required={false}
                    label="Panelist ID"
                    value={panelistID}
                    onChange={(e) => {
                        setPanelistID(e.target.value);
                    }}
                    placeholder="Panelist ID"
                    variant="outlined"
                    fullWidth={true}
                />
                <TextField
                    required={false}
                    label="T-number"
                    value={tnumber}
                    onChange={(e) => {
                        setTnumber(e.target.value);
                    }}
                    placeholder="T-number"
                    variant="outlined"
                    fullWidth={true}
                />
                <RegexTextField
                    label="Mobile"
                    regex={regex.mobileNumber}
                    value={mobile}
                    onChange={(e) => {
                        setMobile(e.target.value);
                    }}
                    placeholder="Mobile"
                    variant="outlined"
                    fullWidth={true}
                />

                <Select
                    placeholder="Select Employment Status"
                    allowClear={true}
                    value={employeStatus}
                    style={{ width: '100%', marginBottom: '10px' }}
                    dropdownStyle={{ zIndex: 2000 }}
                    onChange={(e) => {
                        setEmployeeStatus(e)
                    }}
                    onClear={e => {
                        setEmployeeStatus('')
                    }}
                    options={EMPLOYEE_STATUS || []}
                />
            </Modal>

            <Backdrop id="loading-dialog" open={isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
};

export default connect()(PanelistPage);
