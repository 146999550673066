import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { useMount } from "ahooks";
import { Promise } from "bluebird";
import moment from "../../library/moment";
import _ from "lodash";
import ordinal from "ordinal";
import swal from "sweetalert";
import {
  PageHeader,
  Table,
  Select,
  Descriptions,
  Breadcrumb,
  Tooltip,
  Modal as AntModal,
} from "antd";

import {
  Backdrop,
  Button,
  IconButton,
  CircularProgress,
} from "@material-ui/core";
import {
  VerticalAlignBottom,
  Add,
  AssignmentOutlined,
  CancelScheduleSend,
  Schedule,
} from "@material-ui/icons";
import { useSnackbar } from "notistack";
import { exportExcel } from "../../utility/xslx";
import { errorMapper } from "../../utility";
import urls from "../../utility/urls";
import api from "../../library/api";
import PopConfirm from "../../components/PopConfirm";
import Modal from "../../components/Modal";
import "./style.scss";

import PanelistModal from "./PanelistModal";
import BookingsPreview from "./BookingsPreview";
import AuthHelper from "../../library/helpers/AuthHelper";
import { ROLES } from "../../utility/constant";

const DefaultPanelist = {
  id: "",
  email: "",
  first_name: "",
  last_name: "",
  mobile: "",
  comment: "",
};

const BookingDetailsPage = () => {
  let { ProjectID } = useParams();

  const projectObj = useRef({
    project: {},
    appointments: {},
  });
  const bookingObj = useRef({
    panelist: DefaultPanelist,
  });
  const reschedObj = useRef({
    selected: {},
    api: {
      to_appointment_id: "",
      from_appointment_id: "",
      participant_id: "",
      comments: "",
    },
  });
  const panelListModalRef = useRef({});

  const [selectedWeek, setSelectedWeek] = useState();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [columns, setColumns] = useState([]);
  const [totalWeeks, setTotalWeeks] = useState([]);
  const [isRecurring, setIsRecurring] = useState(false);
  const [showPanelistInfo, setShowPanelistInfo] = useState(false);
  const [showReschedule, setShowReschedule] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [panelist, setPanelist] = useState([]);
  const [showActions, setShowActions] = React.useState(true);
  const [visitColumns, setVisitColumns] = React.useState(false);


  const auth = new AuthHelper();
  const historyLink = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  useMount(() => {
    fetchData(ProjectID);
  });

  useEffect(() => {
    setColumns(columnsProvider(visitColumns, isRecurring));
  }, [showActions]);

  useEffect(() => {
    (auth.getRole() === ROLES.projAdmin ||
      auth.getRole() === ROLES.adminSuper) &&
      (async () => {
        api.AdminPerProject.getProjectAdminByProjectId(ProjectID)
          .then((d) => {
            setIsLoading(false);

            if (d.messages === "success") {
              if (
                d.data === ROLES.adminFnameOnly ||
                d.data === ROLES.adminView
              ) {
                setShowActions(false);
              } else {
                setShowActions(true);
              }
            } else {
              enqueueSnackbar(errorMapper(d.messages), { variant: "warning" });
            }
          })
          .catch((e) => {
            setIsLoading(false);
            enqueueSnackbar("Server error!", { variant: "error" });
          });
      })();
  }, []);

  const processedRawData = (proj, appnt, panelist) => {
    setPanelist(panelist);
    const totalDays = Math.abs(
      proj.project_end_date.diff(proj.project_start_date, "days")
    ),
      weeksTotal = totalDays > 0 ? Math.ceil(totalDays / 7) : 1,
      is_recurring = proj.is_recurring;

    let visitColumns = 0;

    setIsRecurring(proj.is_recurring);

    let totalWeeksArr = [];

    if (is_recurring) {
      for (let i = 0; i < weeksTotal; i++) {
        totalWeeksArr.push({
          label: `${ordinal(i + 1)} Week`,
          value: i + 1,
        });
      }
    } else {
      for (let i = 0; i < proj.projectsched.length; i++) {
        totalWeeksArr.push({
          label: `${ordinal(i + 1)} Visit`,
          value: i + 1,
        });
      }
    }

    setTotalWeeks(totalWeeksArr);

    _.each(appnt, (v, i) => {
      const weeks = Math.abs(v.date.diff(proj.project_start_date, "weeks")) + 1;
      appnt[i].week = weeks;

      const indexSched = _.findIndex(proj.projectsched, (o) => {
        return o.id === v.projectsched_id;
      });

      appnt[i].visits =
        indexSched !== -1 ? proj.projectsched[indexSched].visit_number : -1;

      visitColumns =
        appnt[i].visit.length > visitColumns
          ? appnt[i].visit.length
          : visitColumns;
    });

    appnt.sort((a, b) => {
      return moment(
        moment.utc(a.date).local().format("YYYY-MM-DD") +
        " " +
        moment.utc(a.visit[0].start_time).local().format("HH:mm:ss")
      ).diff(
        moment(
          moment.utc(b.date).local().format("YYYY-MM-DD") +
          " " +
          moment.utc(b.visit[0].start_time).local().format("HH:mm:ss")
        )
      ) != 0
        ? moment(
          moment.utc(a.date).local().format("YYYY-MM-DD") +
          " " +
          moment.utc(a.visit[0].start_time).local().format("HH:mm:ss")
        ).diff(
          moment(
            moment.utc(b.date).local().format("YYYY-MM-DD") +
            " " +
            moment.utc(b.visit[0].start_time).local().format("HH:mm:ss")
          )
        )
        : moment(
          moment.utc(a.date).local().format("YYYY-MM-DD") +
          " " +
          moment.utc(a.visit[0].end_time).local().format("HH:mm:ss")
        ).diff(
          moment(
            moment.utc(b.date).local().format("YYYY-MM-DD") +
            " " +
            moment.utc(b.visit[0].end_time).local().format("HH:mm:ss")
          )
        );
    });

    projectObj.current.project = proj;
    projectObj.current.appointments = appnt;

    setColumns(columnsProvider(visitColumns, is_recurring));
    setData(appnt);
    setVisitColumns(visitColumns);
  };

  const fetchData = (id) => {
    setIsLoading(true);

    Promise.all([
      api.projects.fetchById(id),
      api.appointments.fetchByProjId(id),
      api.participants.fetchById(id),
    ])
      .then((d) => {
        setIsLoading(false);

        if (
          d[0].messages === "success" &&
          d[1].messages === "success" &&
          d[2].messages === "success"
        ) {
          processedRawData(d[0].data, d[1].data, d[2].data);
        } else {
          enqueueSnackbar(
            errorMapper(d[0].messages) +
            errorMapper(d[1].messages) +
            errorMapper(d[2].messages),
            { variant: "warning" }
          );
        }
      })
      .catch((e) => {
        setIsLoading(false);
        enqueueSnackbar(
          "An error occurred while getting appointments. Please contact system admin for help.",
          { variant: "error" }
        );
      });
  };

  const bookedPanelist = (data) => {
    setIsLoading(true);

    const defaultData = {
      project_id: ProjectID,
      participant_id: data.panelist.id,
      email: data.panelist.email,
      first_name: data.panelist.first_name,
      last_name: data.panelist.last_name,
      mobile: data.panelist.mobile,
      comment: data.panelist.comment
    };

    api.appointments
      .book({
        ...defaultData,
        appointment_id: selectedRows.map((sche) => sche.id),
      })
      .then((d) => {
        setIsLoading(false);

        if (d.messages === "success") {
          fetchData(ProjectID);
          enqueueSnackbar("Booked sucessfully!", { variant: "success" });
        } else {
          enqueueSnackbar(errorMapper(d.messages), { variant: "warning" });
        }
      })
      .catch((e) => {
        setIsLoading(false);
        enqueueSnackbar(
          "An error occurred while booking appointment. Please contact system admin for help.",
          { variant: "error" }
        );
      });
  };

  const cancelAppointment = (id) => {
    setIsLoading(true);

    api.appointments
      .cancel(id, ProjectID)
      .then((d) => {
        setIsLoading(false);

        if (d.messages === "success") {
          fetchData(ProjectID);
          enqueueSnackbar("Schedule cancelled", { variant: "success" });
        } else {
          swal({ text: d.messages, icon: "warning" });
        }
      })
      .catch((e) => {
        setIsLoading(false);
        enqueueSnackbar(
          "An error occurred while canceling appointment. Please contact system admin for help.",
          { variant: "error" }
        );
      });
  };

  const reschedAppointment = (data) => {
    setIsLoading(true);

    api.appointments
      .reschedule(data, ProjectID)
      .then((d) => {
        setIsLoading(false);

        if (d.messages === "success") {
          fetchData(ProjectID);
          enqueueSnackbar("Reschedule successfull", { variant: "success" });
        } else {
          swal({ text: errorMapper(d.messages), icon: "warning" });
        }
      })
      .catch((e) => {
        setIsLoading(false);
        enqueueSnackbar(
          "An error occurred while rescheduling appointment. Please contact system admin for help.",
          { variant: "error" }
        );
      });
  };

  const onCancelSchedule = (rec) => {
    cancelAppointment(rec.id);
  };

  const onReSchedule = (rec) => {
    reschedObj.current.selected = rec;
    setShowReschedule(true);
  };

  const columnsProvider = (visitNum, recurring) => {
    let infoArr = [
      {
        title: recurring ? "Week" : "Visits",
        align: "left",
        width: 100,
        dataIndex: recurring ? "week" : "visits",
        key: recurring ? "week" : "visits",
        render: (text, record) => {
          return recurring
            ? `${ordinal(text)} Week`
            : `${ordinal(record.visits)} Visit`;
        },
      },
      {
        title: "Date",
        align: "center",
        width: 140,
        dataIndex: "date",
        key: "date",
        render: (text, record) => {
          return moment(text).format(api.utils.MOMENTFORMAT.date);
        },
      },
      /*{
                  title: 'Item',
                  align: 'center',
                  width: 140,
                  dataIndex: 'item',
                  key: 'item',
              }*/
      {
        title: "Detail",
        align: "center",
        width: 140,
        dataIndex: "details",
        key: "details",
      },
    ],
      actionArr = [
        {
          title: "Location",
          align: "center",
          width: 300,
          dataIndex: "location",
          key: "location",
        },
        {
          title: "Full Name",
          align: "center",
          width: 350,
          dataIndex: "participant",
          key: "participant",
          render: (text, record) => {
            let fn = "",
              ln = "";

            if (text) {
              fn = text.first_name || "";
              ln = text.last_name || "";

              if (fn.length > 0 || ln.length > 0) {
                return `${fn} ${ln}`;
              } else {
                return `${text.email || ""}`;
              }
            }

            return "";
          },
        },
        {
          title: "Action",
          width: 100,
          editable: false,
          align: "center",
          fixed: "right",
          key: "action",
          className: !showActions ? "hide" : "",
          render: (text, record) =>
            showActions && (
              <div className="booking-manage-page-table-row-ctrl">
                <Tooltip
                  title={<p style={{ fontSize: "14px" }}>{"Reschedule"}</p>}
                >
                  <IconButton
                    size="small"
                    disableRipple={true}
                    aria-label="Reschedule"
                    disabled={
                      !projectObj?.current?.project?.is_published ||
                      String(record.participant_id).length < 36
                    }
                    onClick={onReSchedule.bind(this, record)}
                  >
                    <Schedule fontSize="small" />
                  </IconButton>
                </Tooltip>
                <Tooltip
                  title={
                    <p style={{ fontSize: "14px" }}>{"Cancel Schedule"}</p>
                  }
                >
                  <PopConfirm
                    title="Are you sure you want to cancel this schedule?"
                    onConfirm={onCancelSchedule.bind(this, record)}
                  >
                    <IconButton
                      size="small"
                      disableRipple={true}
                      disabled={
                        !projectObj?.current?.project?.is_published ||
                        String(record.participant_id).length < 36
                      }
                      aria-label="Cancel"
                    >
                      <CancelScheduleSend fontSize="small" />
                    </IconButton>
                  </PopConfirm>
                </Tooltip>
              </div>
            ),
        },
      ];

    for (let i = 1; i <= visitNum; i++) {
      if (visitNum === 1) {
        infoArr.push({
          title: "Start Time",
          align: "center",
          width: 140,
          dataIndex: "visit",
          key: "visit",
          render: (v, r) => {
            if (v.length >= i) {
              return moment
                .utc(v[i - 1].start_time)
                .local()
                .format(api.utils.MOMENTFORMAT.time24);
            }

            return "";
          },
        });
        infoArr.push({
          title: "End Time",
          align: "center",
          width: 140,
          dataIndex: "visit",
          key: "visit",
          render: (v, r) => {
            if (v.length >= i) {
              return moment
                .utc(v[i - 1].end_time)
                .local()
                .format(api.utils.MOMENTFORMAT.time24);
            }

            return "";
          },
        });
      } else {
        infoArr.push({
          title: `${ordinal(i)} Visit`,
          align: "center",
          width: 140,
          dataIndex: "visit",
          key: "visit",
          render: (v, r) => {
            if (v.length >= i) {
              const start = moment
                .utc(v[i - 1].start_time)
                .local()
                .format(api.utils.MOMENTFORMAT.time24),
                end = moment
                  .utc(v[i - 1].end_time)
                  .local()
                  .format(api.utils.MOMENTFORMAT.time24);

              return `${start} - ${end}`;
            }

            return "";
          },
        });
      }
    }

    return _.concat(infoArr, actionArr);
  };

  const onPanelistClick = () => {
    historyLink.push(`${urls.ADMIN_BOOKING_PANELIST_DETAILS}/${ProjectID}`);
  };

  const onFilterWeeks = (value) => {
    if (value) {
      const filteredData = _.filter(projectObj.current.appointments, (obj) => {
        return isRecurring ? obj.week === value : obj.visits === value;
      });
      setSelectedWeek(value);
      setData(filteredData);
    } else {
      setSelectedWeek(null);
    }
  };

  const onClearFilter = () => {
    setData(projectObj.current.appointments);
  };

  const _generateExportData = () => {
    const fileName = `booking-detail-${projectObj.current.project.name}${selectedWeek
      ? `-${ordinal(selectedWeek)} ${projectObj.current.project.is_recurring ? "Week" : "Visit"
      }`
      : ""
      }`;
    const headers = [
      ...(projectObj.current.project.is_recurring
        ? [{ label: "Week", key: "week" }]
        : [{ label: "Visit", key: "visits" }]),
      { label: "Date", key: "date" },
      //{ label: 'Item', key: 'item' },
      {
        label: "Detail",
        key: "details",
      },
    ];

    /* if (projectObj.current.project.is_recurring) {
            headers.push({ label: 'Start Time', key: 'start_time' }, { label: 'End Time', key: 'end_time' });
        }*/

    data.forEach((project) => {
      project.visit.forEach((vis, index) => {
        if (
          !headers.find((head) => head.key === `${ordinal(index + 1)} Visit`)
        ) {
          headers.push({
            label: `${ordinal(index + 1)} Visit`,
            key: `${ordinal(index + 1)} Visit`,
          });
        }
      });
    });

    headers.push(
      { label: "Location", key: "location" },
      { label: "Full Name", key: "panelist" },
      {
        label: "Last Name",
        key: "participant.last_name",
      },
      {
        label: "First Name",
        key: "participant.first_name",
      },
      {
        label: "Email Address",
        key: "participant.email",
      },
      {
        label: "Phone Number",
        key: "participant.mobile",
      },
      {
        label: "Employment Status",
        key: "participant.employee_status",
      },
      {
        label: "Panelist ID",
        key: "participant.panelist_id",
      },
      {
        label: "T-number",
        key: "participant.t_number",
      },
      {
        label: "Comments",
        key: "participant.comment",
      }
    );

    const outputData = _.cloneDeep(data).map((project) => {
      const output = {};

      headers.forEach((head) => {
        if (!head.key.includes("participant")) {
          output[head.key] = project[head.key];
        } else {
          const panelistFound = panelist.find(
            (pan) => pan.id === project.participant.id
          );
          const headWithoutParent = head.key.replace("participant.", "");
          if (panelistFound) {
            if (headWithoutParent === "comment") {
              output[head.key] =
                panelistFound["project_participant"][0].comment;
            } else {
              output[head.key] = panelistFound[headWithoutParent];
            }
          } else {
            output[head.key] = _.get(project, head.key);
          }
        }
      });

      output.date = moment(project.date).format(api.utils.MOMENTFORMAT.date);

      if (projectObj.current.project.is_recurring) {
        output.week = `${ordinal(project.week)} Week`;
      } else {
        output.visits = `${ordinal(project.visits)} Visit`;
      }

      project.visit.forEach((vis, index) => {
        const start = moment
          .utc(vis.start_time)
          .local()
          .format(api.utils.MOMENTFORMAT.time24),
          end = moment
            .utc(vis.end_time)
            .local()
            .format(api.utils.MOMENTFORMAT.time24);
        if (projectObj.current.project.is_recurring) {
          output[`${ordinal(index + 1)} Visit`] = `${start} - ${end}`;
        } else {
          output[`${ordinal(index + 1)} Visit`] = `${start} - ${end}`;
        }
      });
      return output;
    });

    return {
      fileName,
      header: [
        ["ID", projectObj.current.project.pr_id],
        ["Name", projectObj.current.project.name],
        ["Type", projectObj.current.project.type],
        ["Instruction", projectObj.current.project.book_instructions],
        [
          "Number of panelist to be scheduled",
          projectObj.current.project.panel_count,
        ],
        ["Number of booked", projectObj.current.project.booked_cnt],
        [],
        headers.map((head) => head.label),
      ],
      data: outputData,
    };
  };

  const checkAllowedSlot = async (appointments, selectSlot) => {
    try {
      switch (selectSlot) {
        case "day": {
          const appointmentDetails = await appointments.reduce(function (r, a) {
            a.date = moment.utc(a.date).local().format("YYYY-MM-DD");
            r[`${a.date}`] = r[`${a.date}`] || 0;
            r[`${a.date}`] += 1;
            return r;
          }, Object.create(null));
          let sameVisitAlready = [];
          await Object.keys(appointmentDetails).forEach((key) => {
            if (appointmentDetails[key] > 1) {
              sameVisitAlready.push(key);
            }
          });
          //check also for number must user book number
          const appointmentDetails_allRows = await data.reduce(function (r, a) {
            a.date = moment.utc(a.date).local().format("YYYY-MM-DD");
            r[`${a.date}`] = r[`${a.date}`] || 0;
            r[`${a.date}`] += 1;
            return r;
          }, Object.create(null));
          let arrayNumbertheSameDate = [];
          await Object.keys(appointmentDetails_allRows).forEach((key) => {
            if (appointmentDetails_allRows[key] > 1) {
              arrayNumbertheSameDate.push(key);
            }
          });
          if (sameVisitAlready.length) {
            swal({
              text: "Oops, sorry. You must select 1 slot per day.",
              icon: "warning",
            });
            return false;
          }
          if (
            selectedRows.length !== arrayNumbertheSameDate.length &&
            !projectObj?.current?.project?.is_recurring
          ) {
            swal({
              text: "Are you sure you booked only 1 slot for each day? please double check",
              icon: "warning",
            });
            return false;
          }
          return true;
        }
        case "project": {
          if (appointments.length > 1) {
            swal({
              text: "Oops, sorry. You must select 1 slot for this project.",
              icon: "warning",
            });
            return false;
          } else {
            return true;
          }
        }
        default:
          break;
      }
    } catch (err) {
      throw err;
    }
  };
  const checkConsecutiveWeek = async (appointments, countConsecWeeks) => {
    try {
      // check if appointments has a time gap lessthan or equal to countConsecWeeks
      const uniqueIds = [];
      const dates = await appointments.map((r) => moment(r.date).utc()).sort();
      const arrayWeeks = await appointments.sort((a, b) => a.week - b.week);
      const arrayWeeks_1 = await arrayWeeks.map((element) => {
        const isDuplicate = uniqueIds.includes(element.week);

        if (!isDuplicate) {
          uniqueIds.push(element.week);

          return true;
        }
      });
      const min = dates[0],
        max = dates[dates.length - 1];
      const duration = moment.duration(max.diff(min));
      const weeks = duration.asWeeks();

      if (weeks > countConsecWeeks || uniqueIds.length !== countConsecWeeks) {
        swal({
          text: `Count of consecutive weeks exceeded, please adjust bookings for ${countConsecWeeks} consecutive weeks`,
          icon: "warning",
        });
        return false;
      }
      if (uniqueIds.length === countConsecWeeks) {
        let check = false;
        for (let i = 0; i < uniqueIds.length; i++) {
          if (uniqueIds[i + 1] - uniqueIds[i] > 1) {
            check = true;
          }
        }
        if (check) {
          swal({ text: "Must chooes Consecutive weeks", icon: "warning" });
          return false;
        }
      }
      return true;
    } catch (err) {
      throw err;
    }
  };

  const checkDaysPerWeek = async (appointments, countPerWeek) => {
    try {
      const days = await [
        ...new Set(
          appointments.map((r) => ({
            day: moment(r.date).day(),
            week: moment(r.date).weeks(),
          }))
        ),
      ];
      const daysPerWeek = await days.reduce(function (r, a) {
        r[a.week] = r[a.week] || [];
        r[a.week].push(a.day);
        return r;
      }, Object.create(null));
      await Object.keys(daysPerWeek).forEach((key) => {
        let dayCount = [...new Set(daysPerWeek[key])].length;
        if (dayCount !== Number(countPerWeek)) {
          swal({
            text: `You need to select ${countPerWeek} consecutive days`,
            icon: "warning",
          });
          throw "";
        }
      });
      let check = false;
      await Object.values(daysPerWeek).map((value) => {
        value = value.sort();
        for (let i = 0; i < value.length; i++) {
          if (value[i + 1] - value[i] > 1) {
            check = true;
          }
        }
        if (check) {
          swal({
            text: `You need to select ${countPerWeek} consecutive days`,
            icon: "warning",
          });
        }
      });
      if (check) {
        return false;
      }
      return true;
    } catch (err) {
      throw err;
    }
  };

  const onAddBookingClick = () => {
    const projectDetail = projectObj?.current?.project;
    if (selectedRows.length > 0) {
      if (projectDetail.is_recurring) {
        if (projectDetail.select_slot === "project") {
          checkAllowedSlot(selectedRows, projectDetail.select_slot).then(
            (res) => {
              if (res) {
                setShowPanelistInfo(true);
              }
            }
          );
        } else {
          setShowPanelistInfo(true);
        }
      } else {
        if (projectDetail.select_slot === "visit") {
          setShowPanelistInfo(true);
        } else {
          setShowPanelistInfo(true);
        }
      }
    } else {
      swal({ text: "Select Appointment", icon: "warning" });
    }
  };

  const onSavePersonalinfo = () => {
    bookedPanelist(bookingObj.current);
    setShowPanelistInfo(false);
    setSelectedRows([]);
  };

  const onConfirmResched = () => {
    reschedAppointment(reschedObj.current.api);
    setShowReschedule(false);
  };

  return (
    <div id="booking-manage-page">
      <Breadcrumb>
        <Breadcrumb.Item>
          <a href={urls.ADMIN_BOOKING}>Project List</a>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Project Schedule</Breadcrumb.Item>
      </Breadcrumb>
      <PageHeader
        ghost={false}
        title="Project Schedule"
        onBack={() => {
          historyLink.push(urls.ADMIN_BOOKING);
        }}
      >
        <div className="page-header-desc">
          <div className="page-header-desc-left">
            <Descriptions size="default" column={1}>
              <Descriptions.Item label="Project ID">
                {projectObj.current.project.pr_id}
              </Descriptions.Item>
              <Descriptions.Item label="Project Type">
                {projectObj.current.project.type}
              </Descriptions.Item>
              <Descriptions.Item label="Instruction">
                {projectObj.current.project.book_instructions}
              </Descriptions.Item>
            </Descriptions>
          </div>
          <div className="page-header-desc-right">
            <Descriptions size="default" column={1}>
              <Descriptions.Item label="Project Name">
                {projectObj.current.project.name}
              </Descriptions.Item>
              <Descriptions.Item label="Panelist">
                {projectObj.current.project.panel_count}
              </Descriptions.Item>
              <Descriptions.Item label="Booked">
                {projectObj.current.project.booked_cnt}
              </Descriptions.Item>
            </Descriptions>
          </div>
        </div>
      </PageHeader>

      <div className="booking-manage-page-content">
        <div className="booking-manage-page-table-container">
          <div className="booking-manage-page-table-controls">
            <div className="booking-manage-page-table-controls-left">
              <Select
                placeholder={isRecurring ? "Select Week" : "Select Visit"}
                labelInValue={false}
                onChange={onFilterWeeks}
                onClear={onClearFilter}
                allowClear={true}
                options={totalWeeks}
              ></Select>
            </div>

            <div className="booking-manage-page-table-controls-right bookingPageadmin-export">
              {showActions && (
                <Button
                  startIcon={<Add />}
                  onClick={onAddBookingClick}
                  disabled={selectedRows.length === 0}
                  disableRipple={true}
                >
                  Add Booking
                </Button>
              )}
              <Button
                startIcon={<AssignmentOutlined />}
                onClick={onPanelistClick}
                disableRipple={true}
              >
                View Panelist
              </Button>
              <Button
                startIcon={<VerticalAlignBottom />}
                disableRipple={true}
                onClick={() => exportExcel(_generateExportData())}
              >
                Export
              </Button>
            </div>
          </div>

          <Table
            className="booking-manage-page-table"
            columns={columns}
            bordered={false}
            rowKey={"id"}
            sticky={true}
            showHeader={true}
            tableLayout="fixed"
            rowSelection={{
              checkStrictly: true,
              fixed: true,
              selectedRowKeys: selectedRows.map((r) => r.id),
              onSelect: (record, selected) => {
                if (!projectObj?.current?.project?.is_published) {
                  enqueueSnackbar("This project is unpublished", {
                    variant: "warning",
                  });
                } else if (record.participant_id.length < 36) {
                  if (selected) {
                    setSelectedRows([...selectedRows, record]);
                  } else {
                    setSelectedRows(
                      selectedRows.filter((r) => r.id !== record.id)
                    );
                  }
                } else {
                  enqueueSnackbar("Appointment schedule already taken", {
                    variant: "warning",
                  });
                }
              },
            }}
            pagination={{
              showTotal: (total, range) => {
                return `${range[0]}-${range[1]} of ${total} items`;
              },
              defaultCurrent: 1,
              defaultPageSize: 50,
              pageSizeOptions: [10, 20, 30, 50, 100],
              showSizeChanger: true,
              position: ["bottomRight"],
              total: data.length,
            }}
            dataSource={data}
            scroll={{
              y: "37vh",
              x: "100%",
            }}
          ></Table>
        </div>
      </div>

      <Modal
        visible={showPanelistInfo}
        closable={false}
        header={true}
        centered={true}
        destroyOnClose={true}
        footer={
          <>
            <Button
              disableRipple={true}
              onClick={() => {
                bookingObj.current.panelist = DefaultPanelist;
                setShowPanelistInfo(false);
              }}
            >
              Cancel
            </Button>
            <Button
              disableRipple={true}
              onClick={() => {
                panelListModalRef.current.reset();
              }}
            >
              Reset
            </Button>
            <Button
              variant="contained"
              color="primary"
              disableRipple={true}
              onClick={onSavePersonalinfo}
            >
              Confirm
            </Button>
          </>
        }
        onCancel={() => {
          bookingObj.current.panelist = DefaultPanelist;
          setShowPanelistInfo(false);
        }}
      >
        <div className="title">Personal Information</div>
        <PanelistModal
          ref={panelListModalRef}
          defaultValue={bookingObj.current.panelist}
          onChange={(v) => {
            bookingObj.current.panelist = v;
          }}
        />
      </Modal>

      <AntModal
        width={800}
        visible={showReschedule}
        centered={true}
        destroyOnClose={true}
        okText="Confirm"
        cancelText="Cancel"
        onOk={onConfirmResched}
        onCancel={() => {
          setShowReschedule(false);
        }}
      >
        <div className="title">Reschedule</div>
        <BookingsPreview
          selected={reschedObj.current.selected}
          defaultValue={projectObj.current}
          onChange={(v) => {
            reschedObj.current.api = v;
          }}
        />
      </AntModal>

      <Backdrop id="loading-dialog" open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default connect()(BookingDetailsPage);
