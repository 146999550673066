import { IconButton } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import BurgerIcon from './BurgerIcon';
import './style.scss';

import {smWindow} from '../../utility/settings'
const HeaderAdmin = (props) => {
    const {sizeWindow,showDrawer} = props
    return (
        <div id="headeradmin-section">
            <div id="logo-cont">
                <a href="https://www.pg.com/" rel="noopener noreferrer" target="_blank">
                    <img src="/assets/png_logo_admin.png" alt="Procter and Gamble Logo" />
                </a>
            </div>

            <div id="headeradmin-section-content">
                <h1>Welcome to OnTheDot</h1>
            </div>
            {sizeWindow && sizeWindow <= smWindow  && 
                <div>
                    <IconButton  color={'default'} disableFocusRipple onClick={showDrawer}>
                        <BurgerIcon />
                    </IconButton>
                 </div>
            }
        </div>
    );
};

export default connect()(HeaderAdmin);
