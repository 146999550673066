import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

export const exportExcel = ({ fileName, header, data }) => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(data, { origin: `A${header.length + 1}`, skipHeader: true });
    XLSX.utils.sheet_add_aoa(worksheet, header);
    XLSX.utils.book_append_sheet(workbook, worksheet);
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const output = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(output, fileName + fileExtension);
};
