import React, { useState} from 'react';
import {connect} from 'react-redux';
import {useUpdateEffect, useSetState} from 'ahooks';
import PropTypes from 'prop-types';
import moment from '../../library/moment';
import ordinal from 'ordinal';

import {Row, Col, Input, Space, DatePicker, TimePicker, Checkbox } from 'antd';

import './index.scss';

import api from '../../library/api';


const checked = false
const {TextArea} = Input;

const EditMultipleItems = (props) => {
	const {defaultValue , onChange , onChnageCheckedBoxValue} = props;

    const [scheduleData, setScheduleData] = useSetState(defaultValue);
    const [checkedComments,setCheckedComments] =useState(checked)
    const [checkedItem,setCheckedItem] =useState(checked)
    const [checkedLocation,setCheckedLocation] =useState(checked)
	const [checkedDetalis,setCheckedDetails] =useState(checked)
    const [checkedDate,setCheckedDate] =useState(checked)
	const [checkedVisit , setCheckedVisit] = useState(
		defaultValue[0].visit.length > 1 ?
			new Array(defaultValue[0].visit.length).fill(false)
			: checked
		)

    const change = (index , value ) => {
        let i;
		let j;
        if(index === 'date'){
            for ( i in scheduleData) {
                scheduleData[i].date = value
            }
        }else if(index === 'item') {
            for ( i in scheduleData) {
                scheduleData[i].item = value
            }
        }else if(index === 'visit') {
			let updateVisitObject ;
            for ( i in scheduleData) {
				for( j in scheduleData[i].visit) {
			 	updateVisitObject = {
					...scheduleData[i].visit[j],
					start_time :value[0].start_time,
					end_time : value[0].end_time,
					seq : value[0].seq,
					date: value[0].date,
				}
                scheduleData[i].visit[j] = updateVisitObject
				}
            }

        }else if(index === 'location'){
            for ( i in scheduleData) {
                scheduleData[i].location = value
            }
        }else if(index === 'details'){
            for ( i in scheduleData) {
                scheduleData[i].details = value
            }
        }else if(index === 'comments'){
            for ( i in scheduleData) {
                scheduleData[i].comments = value
            }
        }
        setScheduleData(scheduleData)
    }
	const changeMulVistis = (value , index) => {
		let updateVisitObject
		let i
		let j
            for ( i in scheduleData) {
				for( j in scheduleData[i].visit) {
					index.forEach( k =>   {
						if(Number(k) === Number(j)) {
							updateVisitObject = {
								...scheduleData[i].visit[j],
								start_time : value[k].start_time ,
								end_time : value[k].end_time ,
								seq :  value[k].seq ,
								date:  value[k].date ,
							}
						}else {
							updateVisitObject = {
								...scheduleData[i].visit[j]
							}
						}
						scheduleData[i].visit[j] = updateVisitObject
					})
				}
            }
			setScheduleData(scheduleData)
	}
	useUpdateEffect(() => {
        const arrayOfObj = Object.entries(scheduleData).map((e) => (  e[1]  ));
        const updateObject = arrayOfObj.map(item => {
            return {
                id : item.id,
                project_id : item.project_id,
                date : item.date,
                item : item.item,
				details : item.details,
                location : item.location,
                comments : item.comments,
                visit : [...item.visit],
            }
    })
		onChange(updateObject);
        onChnageCheckedBoxValue(checkedDate || checkedLocation || checkedItem ||checkedComments || checkedVisit || checkedDetalis)
	}, [scheduleData, checkedDate , checkedLocation ,checkedItem ,checkedComments ,checkedVisit , checkedDetalis]);

	const datePickerValue = (arrTime) => {
		const start = moment.utc(arrTime.start_time).local(),
				end = moment.utc(arrTime.end_time).local();
		if (!start.isValid() || !end.isValid()) {
			return ['',''];
		}
		return [start, end];
	};

	return (
			<div id="edit-schedule-section">
                 <label className={'label_update'}>update</label>
				<Row gutter={[24, 16]}>
					<Col span={24}>
						<Space direction={'horizontal'} size={0} align={'center'}>
                            <Checkbox checked={checkedDate}
                                    onChange={(e) => {
                                         setCheckedDate(e.target.checked)
                                    }}
                            />
							<label htmlFor={'date'} className={"padding-right-element"} >Date : </label>
							<DatePicker
									name={'date'}
									size={'default'}
									format={api.utils.MOMENTFORMAT.date}
									value={scheduleData[0].date ? moment(scheduleData[0].date) : null}
									inputReadOnly={true}
									onChange={(e) => {
                                        change('date', moment(e).local().isValid() ? moment(e).local().format(api.utils.MOMENTFORMAT.serverDate).valueOf() : null)
									}}
							/>
						</Space>
						<div style ={{display : 'flex' , flexDirection :'column'}}>
                        {scheduleData[0].visit.map((v, i) => (
								<Space key={i} direction={'horizontal'} size={0} align={'center'}>
                                    <Checkbox checked={defaultValue[0].visit.length > 1 ? checkedVisit[i] : checkedVisit}
                                            onChange={(e) => {
												let updatedCheckedState = e.target.checked
												if(defaultValue[0].visit.length > 1 ) {
														updatedCheckedState =
													checkedVisit.map((item, index) =>
														index === i ? e.target.checked : item
													)
												}
												setCheckedVisit(updatedCheckedState)

											}}/>
									<label htmlFor={`visit${i}`} className={`${Number(i) === 0   ? 'padding-right-element_4_zero' : Number(i) === 1 ? 'padding-right-element_4_2' :'padding-right-element_4' } padding-left-element_4`}>{`${ordinal(i + 1)} Visit :`}</label>
									<TimePicker.RangePicker
											name={`visit${i}`}
											inputReadOnly={false}
											value={datePickerValue(scheduleData[0].visit[i])}
											format={api.utils.MOMENTFORMAT.time24}
											onChange={(e, w) => {
												let indexTrue = -1;
												if(defaultValue[0].visit.length > 1){
													 indexTrue = checkedVisit.flatMap((bool, index) => bool ? index : [])
												}
												let visitArr = scheduleData[0].visit;
												if (w.length === 2) {
													visitArr[i].start_time =moment(w[0], api.utils.MOMENTFORMAT.time24);
													visitArr[i].end_time = moment(w[1], api.utils.MOMENTFORMAT.time24);
													defaultValue[0].visit.length > 1 ? changeMulVistis(visitArr,indexTrue)
													:
                                                    change('visit',visitArr)
												}
											}}
									/>
								</Space>
						))}
						</div>
						{/*<Space direction={'horizontal'}  size={0} align={'center'}>
                            <Checkbox checked={checkedItem}
                                    onChange={(e) => {
                                         setCheckedItem(e.target.checked)
                                    }}/>
							<label htmlFor={'item'}  className={"padding-right-element"}>Item : </label>
							<Input
                                    className={"input"}
									name={'item'}
									value={scheduleData[0].item}
									onChange={(e) => {
                                        change('item',e.target.value)
									}}
							/>
								</Space>*/}

						<Space direction={'horizontal'} size={0} align={'center'}>
                            <Checkbox   checked={checkedLocation}
                                        onChange={(e) => {
                                            setCheckedLocation(e.target.checked)
                                        }} />
							<label htmlFor={'location'}  className={"padding-right-element_2"}>Location : </label>
							<Input
									name={'location'}
                                    className={"input"}
									value={scheduleData[0].location}
									onChange={(e) => {
										change('location',e.target.value)
									}}
									showCount={255}
									maxLength={255}
							/>
						</Space>
						<Space direction={'horizontal'} size={0} align={'center'}>
                            <Checkbox   checked={checkedDetalis}
                                        onChange={(e) => {
                                            setCheckedDetails(e.target.checked)
                                        }} />
							<label htmlFor={'Detail'}  className={"padding-right-element"}>Detail : </label>
							<Input
									name={'Detail'}
                                    className={"input"}
									value={scheduleData[0].details}
									onChange={(e) => {
										change('details',e.target.value)
									}}
									showCount={255}
									maxLength={255}
							/>
						</Space>
						<Space direction={'horizontal'} size={0} align={'flex-start'} hidden>
                            <Checkbox    checked={checkedComments}
                                         onChange={(e) => {
                                          setCheckedComments(e.target.checked)
                                         }}/>
							<label htmlFor={'comments'}  className={"padding-right-element_3"}>Comments : </label>
							<TextArea
									name={'comments'}
									value={scheduleData[0].comments}
									rows={5}
									onChange={(e) => {
										change('comments',e.target.value)
									}}
							/>
						</Space>

					</Col>

				</Row>

                <Row>

                </Row>
            </div>
	);
};

EditMultipleItems.defaultProps = {};

EditMultipleItems.propTypes = {
	defaultValue : PropTypes.array.isRequired,
    onChange : PropTypes.func.isRequired,
	onChnageCheckedBoxValue : PropTypes.func.isRequired

};

export default connect()(EditMultipleItems);
