// environments
export const isNode = typeof window === 'undefined';
export const isBrowser = !isNode;

//global var
export const resizeDebounceTime = 10;
export const xxsWindow = 320;
export const xsWindow = 480;
export const smWindow = 576;
export const mdWindow = 768;
export const lgWindow =992;
export const xlWindow = 1200;
export const xxlWindow = 1600;
