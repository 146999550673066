import React , {useRef, useState} from 'react';
import { Route, Redirect } from 'react-router-dom';
import IdleTimer from 'react-idle-timer';
import History from '../../library/helpers/History';

import { useAuth } from '../Auth/auth';
import urls from '../../utility/urls';
import AuthHelper from '../../library/helpers/AuthHelper';
import {
    ROLES,
    WAIT_INACTIVE_USER,
    SESSION_EXPIRES_IN,
    DEBOUNCE
}from '../../utility/constant'
import ExpireModal from '../ModalExpireToken'

const PrivateRoute = ({ component: Component, ...rest }) => {
    const { authTokens } = useAuth();
    const authHelper = new AuthHelper();
    let userRole = authHelper.getRole();
    const [isShowModal, setIsShowModal] = useState(false);

    const sessionTimeOutRef = useRef(null)

    const handleOnIdle = e => {
        //console.log('last active', getLastActiveTime())
        //show Modale 5 min
        sessionTimeOutRef.current = setTimeout(() => authHelper.logout(History),WAIT_INACTIVE_USER)
        setIsShowModal(true)
    }
    const handleOnAction = e => {
        //clearTimeout(sessionTimeOutRef.current)
    }
    const handleOnActive = e => {
        //clearTimeout(sessionTimeOutRef.current)
    }
    const onOk = () => {
        // we can refresh token but we already build it in interceptor so just reload page 
        clearTimeout(sessionTimeOutRef.current)
        setIsShowModal(false)
    }   
    
    const onLogout = () => {
        setIsShowModal(false)
        clearTimeout(sessionTimeOutRef.current)
        authHelper.logout(History)
    }
    return (
        <>
            <IdleTimer
                element={document}
                onIdle={handleOnIdle}
                onAction = {handleOnAction}
                onActive = {handleOnActive}
                debounce={DEBOUNCE}
                crossTab={{
                    type: 'localStorage',
                    emitOnAllTabs: true
                  }}
                timeout={SESSION_EXPIRES_IN} />

            <ExpireModal 
                isShowModal = {isShowModal}
                onLogout={onLogout}
                onOk={onOk}
            />

            <Route
                        {...rest}
                        render={(props) => {
                            if(authTokens && userRole === ROLES.PANELIST) {
                                return <Redirect to={{ pathname:urls.USER_PAGE, state: { referer: props.location } }} />
                            }

                            // authorised so return component
                            return (
                                authTokens ? <Component {...props} /> 
                                :
                                <Redirect to={{ pathname: urls.LOGIN_PAGE, state: { referer: props.location } }} />
                            )
                        }}
                    />
        </>
           
    );
};

export default PrivateRoute;
