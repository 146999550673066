import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useMount, useUpdateEffect } from 'ahooks';
import _ from 'lodash';
import moment from 'moment';
import { Resizable } from 'react-resizable';

import { PageHeader, Table, Breadcrumb, Input, Descriptions } from 'antd';

import { Backdrop, CircularProgress, Button } from '@material-ui/core';
import { SearchOutlined, VerticalAlignBottom, } from '@material-ui/icons';
import { useSnackbar } from 'notistack';

import urls from '../../../utility/urls';
import { errorMapper } from '../../../utility';
import api from '../../../library/api';
import { exportExcel } from '../../../utility/xslx';

import '../../ProjectManagement/style.scss';


const ResizableTitle = (props) => {
    const { onResize, width, ...restProps } = props;

    if (!width) {
        return <th {...restProps} />;
    }

    return (
        <Resizable
            width={width}
            height={0}
            handle={
                <span
                    className="react-resizable-handle"
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                />
            }
            onResize={onResize}
            draggableOpts={{
                enableUserSelectHack: false,
            }}
        >
            <th {...restProps} />
        </Resizable>
    );
};
const AdminLogTracker = (props) => {
    const historyLink = useHistory();
    const projectObj = useRef({
        admins: {},
    });

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [columns, setColumns] = useState([]);

    const { enqueueSnackbar } = useSnackbar();

    useMount(async () => {
        await fetchData();
    });

    useUpdateEffect(() => {
        fetchData();
    }, []);
    const handleResize =
        (index) =>
            (_, { size }) => {
                const newColumns = [...columns];
                newColumns[index] = { ...newColumns[index], width: size.width };
                setColumns(newColumns);
            };
    const mergeColumns = columns.map((col, index) => ({
        ...col,
        onHeaderCell: (column) => (
            {
                width: column.width,
                onResize: handleResize(index),
            }),
    }));



    const fetchData = () => {
        setIsLoading(true);
        api.logs
            .fetchAdminLogActivity()
            .then((d) => {
                setIsLoading(false);
                if (d.messages === 'success') {
                    processedRawData(d.data);
                } else {
                    enqueueSnackbar(errorMapper(d.messages), { variant: 'warning' });
                }
            })
            .catch((e) => {
                setIsLoading(false);
                enqueueSnackbar('An error occurred while getting project information. Please contact system admin for help.', { variant: 'error' });
            });
    };

    const processedRawData = (adminLogs) => {
        let updateAdminlogs = []
        adminLogs.map((item) => {
            updateAdminlogs.push({
                ...item,
                first_name: item.log_activity.first_name,
                Action: item.log_activity.Action,
                ActionBy: item.log_activity.ActionBy || item.log_activity.email,
                email: item.log_activity.email,
                last_name: item.log_activity.last_name,
                role: item.log_activity.role,
                site_name: item.log_activity.site_name,
            })
        })

        if (updateAdminlogs) {
            updateAdminlogs = updateAdminlogs?.sort((a, b) => moment(a.insert_dttm) - moment(b.insert_dttm))
            updateAdminlogs = updateAdminlogs.reverse()
        }
        setData(updateAdminlogs)
        projectObj.current.admins = updateAdminlogs;
        setColumns(columnsProvider());
    }

    const columnsProvider = () => {
        let infoArr = [
            {
                title: 'Email Address',
                align: 'left',
                width: 250,
                fixed: 'left',
                dataIndex: 'email',
                key: 'email',
                render: (text, record) => <span>{`${text}`}</span>,
            },
            {
                title: 'Last Name',
                align: 'left',
                width: 250,
                dataIndex: 'last_name',
                key: 'last_name',
                render: (text, record) => <span>{text}</span>,
            },
            {
                title: 'First Name',
                align: 'left',
                width: 250,
                dataIndex: 'first_name',
                key: 'first_name',
                render: (text, record) => <span>{text}</span>,
            },
            {
                title: 'Role',
                align: 'left',
                width: 250,
                dataIndex: 'role',
                key: 'role',
                render: (text, record) => <span>{text}</span>,
            },
            {
                title: 'Site',
                align: 'left',
                width: 250,
                dataIndex: 'site_name',
                key: 'site_name',
            },
        ],
            actionArr = [
                {
                    title: 'Action',
                    align: 'left',
                    width: 140,
                    dataIndex: 'Action',
                    key: 'Action',
                },
                {
                    title: 'Action By',
                    align: 'left',
                    width: 140,
                    dataIndex: 'ActionBy',
                    key: 'ActionBy'
                },
                {
                    title: 'Timestamp',
                    align: 'left',
                    width: 140,
                    dataIndex: 'insert_dttm',
                    key: 'insert_dttm',
                    defaultSortOrder: 'descend',
                    sorter: {
                        compare: (a, b) => {
                            if (moment(a.insert_dttm).isBefore(b.insert_dttm, 'min')) {
                                return -1;
                            }

                            if (moment(a.insert_dttm).isAfter(b.insert_dttm, 'min')) {
                                return 1;
                            }

                            return 0;
                        },
                        multiple: 2,
                    },
                    render: (text, record) => {
                        return moment.utc(text).local().format(api.utils.MOMENTFORMAT.time24Date);
                    },
                }
            ];

        return _.concat(infoArr, actionArr);
    }

    const _handleSearch = (e) => {
        const text = e.target.value;

        setData(
            projectObj.current.admins.filter(
                (admin) =>
                    (admin?.email && admin?.email.toLowerCase().includes(text.toLowerCase())) ||
                    (admin?.first_name && admin?.first_name.toLowerCase().includes(text.toLowerCase())) ||
                    (admin?.last_name && admin?.last_name.toLowerCase().includes(text.toLowerCase())) ||
                    (admin?.Action && String(admin.Action).toLowerCase().includes(text.toLowerCase())) ||
                    (admin?.ActionBy && String(admin.ActionBy).toLowerCase().includes(text.toLowerCase()))
            ),
        );
    };



    const _handleClearSearch = (e) => {
        const text = e.target.value;
        if (text === '') {
            setData(projectObj.current.admins);
        }
    }


    const _generateExportData = () => {
        const fileName = `Admin-log-tracker`;

        const headers = [
            { label: 'Email Address', key: 'email' },
            { label: 'First Name', key: 'first_name' },
            { label: 'Last Name', key: 'last_name' },
            { label: 'Role', key: 'role' },
            { label: 'Site', key: 'site_name' },
            { label: 'Action', key: 'Action' },
            { label: 'Action By', key: 'ActionBy' },
            { label: 'Timestamp', key: 'insert_dttm' },
        ];
        const outputData = _.cloneDeep(data).map((project) => {
            const output = {};
            headers.forEach((head) => {
                output[head.key] = project[head.key];
            });
            output.insert_dttm = moment.utc(project.insert_dttm).local().format(api.utils.MOMENTFORMAT.time24Date);
            return output;
        });
        return {
            fileName,
            header: [headers.map((head) => head.label)],
            data: outputData,
        };
    }

    return (
        <div id="project-management-page">
            <Breadcrumb>
                <Breadcrumb.Item>
                    <a href={urls.CREATE_ADMIN_PRT_TEAM}>Admin List</a>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Admin Log Tracker</Breadcrumb.Item>
            </Breadcrumb>
            <PageHeader
                ghost={false}
                title="Admin Log Tracker"
                onBack={() => {
                    historyLink.push(`${urls.CREATE_ADMIN_PRT_TEAM}`);
                }}
            />
            <div className="project-management-page-content">
                <div className="project-management-page-table-container">
                    <div className="project-management-page-table-controls">
                        <div className="project-management-page-table-controls-left">
                            <Input
                                className="searchbox"
                                size="middle"
                                prefix={<SearchOutlined />}
                                placeholder="Search"
                                onPressEnter={_handleSearch}
                                onChange={_handleClearSearch}
                            />
                        </div>
                        <div className="project-management-page-table-controls-right">
                            <Button
                                startIcon={<VerticalAlignBottom />}
                                disableRipple={true}
                                onClick={() => exportExcel(_generateExportData())}
                            >
                                Export
                            </Button>
                        </div>
                    </div>

                    <Table
                        className="project-management-page-table"
                        columns={mergeColumns}
                        bordered={false}
                        rowKey={'id' + 'Action'}
                        sticky={true}
                        showHeader={true}
                        filterMultiple={true}
                        components={{
                            header: {
                                cell: ResizableTitle,
                            }
                        }}
                        tableLayout="fixed"
                        pagination={{
                            showTotal: (total, range) => {
                                return `${range[0]}-${range[1]} of ${total} items`;
                            },
                            defaultCurrent: 1,
                            defaultPageSize: 10,
                            pageSizeOptions: [10, 20, 30, 50, 100],
                            showSizeChanger: true,
                            position: ['bottomRight'],
                            total: data.length,
                        }}
                        dataSource={data}
                        scroll={{
                            y: '50vh',
                            x: '100%',
                        }}
                    ></Table>
                </div>
            </div>
            <Backdrop id="loading-dialog" open={isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
};

export default connect()(AdminLogTracker);
