import React from 'react';
import { connect } from 'react-redux';
import { Form ,Modal} from 'antd';
import './index.scss';
 
import RegForm from './RegulatedForm';
import NonRegForm from './NonRegForm'; 

import { useSnackbar } from 'notistack';
import ordinal from 'ordinal';

const AddNewVisit = (props) => {
    const { visible , cancelAddVisitModal , noOfVisits , onCreateNewVisits , confirmLoading , IsRecurring , visit_per_slot , weeks_per_project} = props;
    const [form] = Form.useForm();
    const { enqueueSnackbar } = useSnackbar();
    const [dateMultiple, setDateMultiple] = React.useState([]);


    const onSubmitValues = (values) => {
        let boolArray = [false]
            if(IsRecurring) {
                values.visit_config_rec.map((e,idx) => {
                    if(e.timeStartEnd.length === 2&&!e.timeStartEnd.includes("")&&e.duration !== null && e.timeInterval !== null){
                        boolArray[idx] = true
                    }else{
                        boolArray[idx]=false
                        enqueueSnackbar(`Fill ${ordinal(idx+1)} visit information, it's required`,{variant :'error'})
                    }
                })
            }else{
                values.visit_config.map((e,idx) => {
                    if(e.date !== null &&e.timeStartEnd.length === 2&&!e.timeStartEnd.includes("")&&e.duration !== null && e.timeInterval !== null){
                        boolArray[idx] = true
                    }else{
                        boolArray[idx]=false
                        enqueueSnackbar(`Fill ${ordinal(idx+1+noOfVisits)} visit information, it's required`,{variant :'error'})
                    }
                })
        }
        const isAllValueTrue =  boolArray.every(e => {
                return e === true
            })
            if(isAllValueTrue) {
                onCreateNewVisits(values);
                form.resetFields();
            }
    }
    return (
        
        <Modal
                title ="Add new visit(s)"
                width = {!!IsRecurring ? 880 : 600}
                visible={visible}
                centered={true}
                destroyOnClose={true}
                okText="Ok"
                cancelText="Cancel"
                className='ant-modal-add-visits'
                confirmLoading={confirmLoading}
                onCancel={cancelAddVisitModal}
                onOk={
                    async() => { 
                        form.submit()
                    }
                   
                }
        >
                <Form
                    name="AddNewVists"
                    form={form}
                    layout="vertical"
                    onFinish={onSubmitValues}
                    initialValues={{ visits_per_slot: visit_per_slot , weeksPerProject : weeks_per_project }}
                >
                    <div id="add-new-visits">
                        {!IsRecurring ? 
                            <div >
                                <NonRegForm form={form} noOfVisits={noOfVisits} /> 
                            </div>
                            :
                            <div >
                                <RegForm form={form} setDateMultiple={setDateMultiple} dateMultiple={dateMultiple} visit_per_slot={visit_per_slot} weeks_per_project={weeks_per_project} /> 
                            </div>
                        }           
                    </div>
                </Form>

        </Modal>
    );
};

export default connect()(AddNewVisit);
