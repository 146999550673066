import axios from 'axios';
import _ from 'lodash';
import moment from './moment';
import codify from './codify';
import AuthHelper from './helpers/AuthHelper';
import history from './helpers/History';
import urls from '../utility/urls';
import { ROLES } from '../utility/constant';

const env = () => {
    const data = [
        {
            name: 'localhost',
            value: 'pgconsumer-fa-prod.azurewebsites.net',
        },
        {
            name: 'pgconsumer-web.azurewebsites.net',
            value: 'pgconsumer-fa-prod.azurewebsites.net',
        },
        {
            name: 'pgconsumer-web-dev.azurewebsites.net',
            value: 'pgconsumer-fa-dev.azurewebsites.net',
        },
        {
            name: 'www.pgconsumer.ml',
            value: 'api.pgconsumer.ml',
        },
        {
            name: 'pgconsumer.ml',
            value: 'api.pgconsumer.ml',
        },
        {
            name: 'onthedot.pg.com',
            value: 'onthedot-api.pg.com',
        },
        {
            name: 'www.onthedot.pg.com',
            value: 'onthedot-api.pg.com',
        },
    ];
    const envdomain = window.location.hostname;
    return data.filter((value) => value.name === envdomain || '')[0].value;
};
const headersConfig = () => {
    let obj = {};
    const getTokens = codify.decode(localStorage.getItem('tokens'));

    obj = {
        Authorization: `Bearer ${String(getTokens).substring(1, getTokens.length - 1)}`,
        'Content-Type': process.env.NODE_ENV === 'production' ? 'text/plain' : 'application/javascript'
    };

    return obj;
};

const http = axios.create({
    baseURL: `https://${env()}`,
    responseType: 'json',
    responseEncoding: 'utf8',
    headers: headersConfig(),
});
http.interceptors.request.use(
    config => {
        const getTokens = codify.decode(localStorage.getItem('tokens'));
        if (getTokens) {
            config.headers['Authorization'] = `Bearer ${String(getTokens).substring(1, getTokens.length - 1)}`;
        }
        return config;
    },
    error => {
        Promise.reject(error)
    }
);

http.interceptors.response.use(response => {
    return response
}, async function (err) {
    const originalReq = err.config
    const auth = new AuthHelper()
    const refreshRoute = '/api/auth/refresh_token'
    const reject = (err) => {
        auth.logout(history);
        throw err;
    };
    if ((err.response.status === 404 || err.response?.data?.code === 403) && err.response?.data?.messages?.name === "TokenExpiredError" && originalReq.url !== refreshRoute && !originalReq._retry) {
        //will refresh the user if is active on website 
        console.log("Attempting to refresh token!")
        originalReq._retry = true;
        let result = user.refreshToken(localStorage.getItem('refresh_token')).then(res => {
            if (res.code === 200) {
                window.localStorage.setItem('tokens', codify.encode(JSON.stringify(res.token)));
                auth.setRefreshToken(res.refreshToken)
                originalReq.headers['Authorization'] = `Bearer ${String(res.token).substring(1, res.token.length - 1)}`
                return http(originalReq)
            }

        }).catch(err => {
            console.log('Something wrong on refresh Token **logout**!')
            reject(err)
        })

        return result;
    } else if (originalReq.url === refreshRoute) {
        let historyUrl = urls.LOGIN_PAGE
        if (auth.getRole() === ROLES.PANELIST) {
            historyUrl = urls.USER_LOGIN
        }
        history.push(historyUrl)
        history.go(0)
        localStorage.clear()
    } else if (err?.response?.status === 404 &&
        (err?.response?.data.messages?.localeCompare("You don't have permission to access / on this server.") === 0)
    ) {
        let historyUrl = urls.LOGIN_PAGE
        if (auth.getRole() === ROLES.PANELIST) {
            historyUrl = urls.USER_LOGIN
        }
        history.push(historyUrl)
        history.go(0)
        localStorage.clear()
    } else {
        throw err
    }
}
);
const utils = {
    MOMENTFORMAT: {
        time: 'hh:mm A',
        time24: 'HH:mm',
        date: 'DD MMM YYYY',
        serverDate: 'YYYY-MM-DD',
        timeDate: 'hh:mm:ss DD MMM YYYY',
        time24Date:'HH:mm:ss DD MMM YYYY'
    },
    getURL: function (path) {
        const loc = window.document.location;
        return loc.protocol + '//' + loc.host + (path || '');
    },
};
const mapping = {
    create: function (data) {
        const defaultData = {
            projID: '',
            type: '',
            projName: '',
            count: null,
            emails: ';',
            recurring: false,
            weeksPerProject: 1,
            selectedDays: [],
            number_of_visits: 5,
            // slots_per_visit : 1,
            visits_per_slot: 1,
            location: '',
            visit_config: [],
            visit_config_rec: [],
            count_consec_weeks: null,
            count_per_week: null,
            cancelTimeAllowance: 1,
            timeAllowanceUnit: 'm',
            // totalSlotAppointment : 2,
            allowSelectSlot: 1,
            notifRecipients: [],
            bookInstruction: '',
            emailTemplate: {
                subject: '',
                emailContent: {},
            },
            has_custom_template: false,
            is_published: false,
            isSaved: false,
            files: [],
            template_name: '',
            isCopyUrlProject: false,
            idprojectUuid: null,
        },
            newData = _.assign(defaultData, data),
            createObj = {
                pr_id: newData.projID,
                name: newData.projName,
                panel_count: newData.count,
                type: newData.type || '',
                panelist_emails: newData.emails,
                project_start_date: null,
                project_end_date: null,
                is_recurring: newData.recurring ? 1 : 0,
                number_of_visits: newData.number_of_visits,
                // slots_per_visit : newData.slots_per_visit,
                weeks_per_project: newData.weeksPerProject,
                recurring_dates: newData.selectedDays,
                visits_per_slot: newData.visits_per_slot,
                location: newData.location,
                count_consec_weeks: newData.count_consec_weeks,
                count_per_week: newData.count_per_week,
                update_time_allowance: newData.cancelTimeAllowance,
                total_slots: newData.totalSlotAppointment,
                select_slot: newData.allowSelectSlot,
                notif_recipient: newData.notifRecipients,
                book_instructions: newData.bookInstruction,
                template: JSON.stringify(newData.emailTemplate),
                has_custom_template: newData.has_custom_template ? 1 : 0,
                projectsched: [],
                is_published: newData.is_published ? 1 : 0,
                isSaved: newData.isSaved ? 1 : 0,
                files: newData.files,
                template_name: newData.template_name,
                isCopyUrlProject: newData.isCopyUrlProject,
                idprojectUuid: newData.idprojectUuid,
            },
            configArr = newData.recurring ? newData.visit_config_rec : newData.visit_config;

        _.each(configArr, (v, i) => {
            const obj = {
                date: createObj.is_recurring ? "" : moment(moment(v.date).format(utils.MOMENTFORMAT.serverDate) + " " + moment(v.timeStartEnd[0], utils.MOMENTFORMAT.time).format(utils.MOMENTFORMAT.time24)).utc().format(),
                start_time: createObj.is_recurring ? moment(moment(createObj.recurring_dates[0]).format(utils.MOMENTFORMAT.serverDate) + " " + moment(v.timeStartEnd[0], utils.MOMENTFORMAT.time).format(utils.MOMENTFORMAT.time24)).utc().format() : moment(moment(v.date).format(utils.MOMENTFORMAT.serverDate) + " " + moment(v.timeStartEnd[0], utils.MOMENTFORMAT.time).format(utils.MOMENTFORMAT.time24)).utc().format(),
                end_time: createObj.is_recurring ? moment(moment(createObj.recurring_dates[createObj.recurring_dates.length - 1]).format(utils.MOMENTFORMAT.serverDate) + " " + moment(v.timeStartEnd[1], utils.MOMENTFORMAT.time).format(utils.MOMENTFORMAT.time24)).utc().format() : moment(moment(v.date).format(utils.MOMENTFORMAT.serverDate) + " " + moment(v.timeStartEnd[1], utils.MOMENTFORMAT.time).format(utils.MOMENTFORMAT.time24)).utc().format(),
                participant_per_slot: v.panelistPerSlot,
                time_interval: v.timeInterval,
                duration: v.duration,
            };
            createObj.projectsched.push(obj);
        });
        if (newData.recurring) {
            _.each(createObj.recurring_dates, (v, i) => {
                createObj.recurring_dates[i] = moment(v.format(utils.MOMENTFORMAT.serverDate) + " " + moment(configArr[0].timeStartEnd[0], utils.MOMENTFORMAT.time).format(utils.MOMENTFORMAT.time24)).utc().format();
            });

            createObj.recurring_dates = createObj.recurring_dates.sort((a, b) => {
                const idA = moment(a, utils.MOMENTFORMAT.serverDate),
                    idB = moment(b, utils.MOMENTFORMAT.serverDate);

                if (idA.isBefore(idB)) {
                    return -1;
                }

                if (idA.isAfter(idB)) {
                    return 1;
                }

                return 0;
            });

            createObj.project_start_date = createObj.recurring_dates.length > 0 ? createObj.recurring_dates[0] : null;
            createObj.project_end_date =
                createObj.recurring_dates.length > 0
                    ? createObj.recurring_dates[createObj.recurring_dates.length - 1]
                    : null;
        } else {
            createObj.project_start_date = createObj.projectsched.length > 0 ? createObj.projectsched[0].date : null;
            createObj.project_end_date =
                createObj.projectsched.length > 0
                    ? createObj.projectsched[createObj.projectsched.length - 1].date
                    : null;
        }

        switch (newData.timeAllowanceUnit) {
            case 'h':
                createObj.update_time_allowance = createObj.update_time_allowance * 60;
                break;
            case 'd':
                createObj.update_time_allowance = createObj.update_time_allowance * 1440;
                break;
            default:
        }

        return createObj;
    },
    update: function (data) {
        let defaultData = {
            id: '',
            pr_id: '',
            name: '',
            panel_count: 0,
            type: '',
            count_consec_weeks: 0,
            count_per_week: 0,
            update_time_allowance: 0,
            total_slots: 0,
            select_slot: 'project',
            notif_recipient: [],
            book_instructions: '',
            is_published: false,
            time_unit: 'm',
            notify_admin: false
        },
            newData = _.assign(defaultData, data);

        switch (newData.time_unit) {
            case 'h':
                newData.update_time_allowance = newData.update_time_allowance * 60;
                break;
            case 'd':
                newData.update_time_allowance = newData.update_time_allowance * 1440;
                break;
            default:
        }

        newData.is_published = newData.is_published ? 1 : 0;
        newData.notify_admin = newData.notify_admin ? 1 : 0;

        delete newData.time_unit;

        return newData;
    },
    createVisits: function (payload) {
        const defaultData = {
            id: '',
            is_recurring: 0,
            number_of_visits: 0,
            number_last_visit: 0,
            location: '',
            visit_config: [],
            visit_config_rec: [],
            selectedDays: [],
            old_selected_days: [],
            project_start_date: null,
            project_end_date: null,
            old_slot: 0,
            old_item: '0',
            old_seq: 0,
        },
            updatePayload = _.assign(defaultData, payload),
            createObj = {
                id: updatePayload.id,
                is_recurring: updatePayload.is_recurring ? 1 : 0,
                number_of_visits: updatePayload.number_of_visits,
                number_last_visit: updatePayload.number_last_visit,
                location: updatePayload.location,
                projectsched: [],
                visit_config_rec: updatePayload.visit_config_rec,
                recurring_dates: updatePayload.selectedDays,
                old_recurring_dates: updatePayload.old_selected_days,
                updated_recurring_dates: [],
                project_start_date: updatePayload.project_start_date,
                project_end_date: updatePayload.project_end_date,
                old_slot: updatePayload.old_slot,
                old_item: updatePayload.old_item,
                old_seq: updatePayload.old_seq + 1,

            },
            configArr = updatePayload.is_recurring ? updatePayload.visit_config_rec : updatePayload.visit_config;
        _.each(configArr, (v, i) => {
            const obj = {
                date: createObj.is_recurring ? "" : moment(moment(v.date).format(utils.MOMENTFORMAT.serverDate) + " " + moment(v.timeStartEnd[0], utils.MOMENTFORMAT.time).format(utils.MOMENTFORMAT.time24)).utc().format(),
                start_time: createObj.is_recurring ? moment(moment(createObj.recurring_dates[0]).format(utils.MOMENTFORMAT.serverDate) + " " + moment(v.timeStartEnd[0], utils.MOMENTFORMAT.time).format(utils.MOMENTFORMAT.time24)).utc().format() : moment(moment(v.date).format(utils.MOMENTFORMAT.serverDate) + " " + moment(v.timeStartEnd[0], utils.MOMENTFORMAT.time).format(utils.MOMENTFORMAT.time24)).utc().format(),
                end_time: createObj.is_recurring ? moment(moment(createObj.recurring_dates[createObj.recurring_dates.length - 1]).format(utils.MOMENTFORMAT.serverDate) + " " + moment(v.timeStartEnd[1], utils.MOMENTFORMAT.time).format(utils.MOMENTFORMAT.time24)).utc().format() : moment(moment(v.date).format(utils.MOMENTFORMAT.serverDate) + " " + moment(v.timeStartEnd[1], utils.MOMENTFORMAT.time).format(utils.MOMENTFORMAT.time24)).utc().format(),
                participant_per_slot: v.panelistPerSlot,
                time_interval: v.timeInterval,
                duration: v.duration,
            };

            createObj.projectsched.push(obj);
        });

        if (createObj.is_recurring) {


            _.each(createObj.recurring_dates, (v, i) => {
                createObj.recurring_dates[i] = moment(v.format(utils.MOMENTFORMAT.serverDate) + " " + moment(configArr[0].timeStartEnd[0], utils.MOMENTFORMAT.time).format(utils.MOMENTFORMAT.time24)).utc().format();
            });
            _.each(createObj.old_recurring_dates, (v, i) => {
                createObj.old_recurring_dates[i] = moment(v.format(utils.MOMENTFORMAT.serverDate) + " " + moment(configArr[0].timeStartEnd[0], utils.MOMENTFORMAT.time).format(utils.MOMENTFORMAT.time24)).utc().format();
            });

            createObj.updated_recurring_dates = createObj.recurring_dates.filter(val => !createObj.old_recurring_dates.includes(val))
            createObj.updated_recurring_dates = createObj.updated_recurring_dates.concat(createObj.old_recurring_dates)

            createObj.updated_recurring_dates = createObj.updated_recurring_dates.sort((a, b) => {
                const idA = moment(a, utils.MOMENTFORMAT.serverDate),
                    idB = moment(b, utils.MOMENTFORMAT.serverDate);

                if (idA.isBefore(idB)) {
                    return -1;
                }

                if (idA.isAfter(idB)) {
                    return 1;
                }

                return 0;
            });


            createObj.project_start_date = createObj.updated_recurring_dates.length > 0 ? createObj.updated_recurring_dates[0] : null;
            createObj.project_end_date =
                createObj.updated_recurring_dates.length > 0
                    ? createObj.updated_recurring_dates[createObj.updated_recurring_dates.length - 1]
                    : null;

        } else {
            // we will upadate start/end date for project 
            createObj.projectsched &&
                Array.isArray(createObj.projectsched) &&
                createObj.projectsched.length > 0
                && createObj.projectsched.map(item => {
                    if (moment(item.date).isAfter(createObj.project_end_date)) {
                        createObj.project_end_date = item.date
                    }
                    if (moment(item.date).isBefore(createObj.project_start_date)) {
                        createObj.project_start_date = item.date
                    }
                })

        }
        return createObj
    },
    createTimeSlots: (payload) => {
        const defaultData = {
            id: '',
            is_recurring: 0,
            projectsched_id: '',
            visit_config: [],
            project_start_date: null,
            project_end_date: null,
            location: '',
            duration: 0,
            timeInterval: 0,
        },
            updatePayload = _.assign(defaultData, payload),
            createObj = {
                id: updatePayload.id,
                is_recurring: updatePayload.is_recurring ? 1 : 0,
                projectsched_id: updatePayload.projectsched_id,
                projectsched: [],
                project_start_date: updatePayload.project_start_date,
                project_end_date: updatePayload.project_end_date,
                location: updatePayload.location,
                duration: updatePayload.duration,
                timeInterval: updatePayload.timeInterval,
            },
            configArr = updatePayload.visit_config;
        //custom object
        const obj = {
            date: moment(moment(configArr.date).format(utils.MOMENTFORMAT.serverDate) + " " + moment(configArr.timeStartEnd[0], utils.MOMENTFORMAT.time).format(utils.MOMENTFORMAT.time24)).utc().format(),
            start_time: moment(moment(configArr.date).format(utils.MOMENTFORMAT.serverDate) + " " + moment(configArr.timeStartEnd[0], utils.MOMENTFORMAT.time).format(utils.MOMENTFORMAT.time24)).utc().format(),
            end_time: moment(moment(configArr.date).format(utils.MOMENTFORMAT.serverDate) + " " + moment(configArr.timeStartEnd[1], utils.MOMENTFORMAT.time).format(utils.MOMENTFORMAT.time24)).utc().format(),
            time_interval: configArr.timeInterval,
            duration: configArr.duration,
            participant_per_slot: configArr.panelistPerSlot,
            id: updatePayload.projectsched_id,
        };
        createObj.projectsched.push(obj);
        if (!createObj.is_recurring) {
            // we will upadate start/end date for project 
            createObj.projectsched &&
                Array.isArray(createObj.projectsched) &&
                createObj.projectsched.length > 0
                && createObj.projectsched.map(item => {
                    if (moment(item.date).isAfter(createObj.project_end_date)) {
                        createObj.project_end_date = item.date
                    }
                    if (moment(item.date).isBefore(createObj.project_start_date)) {
                        createObj.project_start_date = item.date
                    }
                })
        }
        return createObj
    },
    project: function (data) {
        let defaultData = {
            id: '',
            pr_id: '',
            name: '',
            panel_count: 0,
            type: '',
            panelist_emails: [],
            project_start_date: null,
            project_end_date: null,
            is_recurring: false,
            number_of_visits: 0,
            // slots_per_visit : 0,
            weeks_per_project: 0,
            recurring_dates: [],
            slots_per_day: 0,
            visits_per_slot: 0,
            location: '',
            count_consec_weeks: 0,
            count_per_week: 0,
            update_time_allowance: 0,
            total_slots: 0,
            select_slot: 'project',
            notif_recipient: '',
            book_instructions: '',
            template: {},
            has_custom_template: 0,
            projectsched: [],
            is_published: 0,
            participant_cnt: 0,
            booked_cnt: 0,
            creator: '',
        },
            newData = _.assign(defaultData, data);

        newData.is_recurring = newData.is_recurring === 1;
        newData.is_published = newData.is_published === 1;
        newData.has_custom_template = newData.has_custom_template === 1;
        newData.project_start_date = moment(newData.project_start_date);
        newData.project_end_date = moment(newData.project_end_date);

        _.each(newData.recurring_dates, (v, i) => {
            newData.recurring_dates[i] = moment(v, utils.MOMENTFORMAT.serverDate);
        });

        _.each(newData.projectsched, (v, i) => {
            newData.projectsched[i] = this.projectSched(v);
        });

        return newData;
    },
    projectSched: function (data) {
        const defaultData = {
            id: '',
            date: null,
            project_id: '',
            visit_number: -1,
            start_time: null,
            end_time: null,
            participant_per_slot: 0,
            time_interval: 0,
            duration: 0,
        },
            newData = _.assign(defaultData, data);

        newData.date = moment(newData.date);
        newData.start_time = moment(newData.start_time).utc();
        newData.end_time = moment(newData.end_time).utc();

        return newData;
    },
    appointments: {
        get: function (data) {
            const defaultData = {
                id: '',
                week: 0,
                date: null,
                project_id: '',
                participant_id: '',
                projectsched_id: '',
                item: '',
                slot: 0,
                resched_from_id: null,
                status: null,
                location: '',
                comments: '',
                visit: [],
                participant: {},
                insert_dttm: 0,
                update_dttm: 0,
            },
                newData = _.assign(defaultData, data);

            newData.participant = newData.participant_id === null ? {} : newData.participant;

            _.each(newData, (v, i) => {
                newData[i] = v || '';
            });

            newData.date = moment(newData.date);

            _.each(newData.visit, (v, i) => {
                newData.visit[i] = {
                    id: v.id,
                    appointment_id: v.appointment_id,
                    date: moment(v.date),
                    start_time: moment(v.start_time).utc(),
                    end_time: moment(v.end_time).utc(),
                    seq: v.seq || 0,
                };
            });

            return newData;
        },
        update: function (data) {
            const defaultData = {
                id: '',
                project_id: '',
                date: null,
                item: '',
                slot: 0,
                status: 'open',
                location: '',
                comments: '',
                visit: [],
            },
                newData = _.assign(defaultData, data);
            _.each(newData, (v, i) => {
                newData[i] = v || '';
            });
            newData.date = moment(moment(newData.date).format(utils.MOMENTFORMAT.serverDate) + " " + moment(newData.visit[0].start_time, utils.MOMENTFORMAT.time).local().format(utils.MOMENTFORMAT.time24)).utc().format()
            _.each(newData.visit, (v, i) => {
                newData.visit[i] = {
                    id: v.id,
                    appointment_id: v.appointment_id,
                    date: moment(moment(newData.date).format(utils.MOMENTFORMAT.serverDate) + " " + moment(v.start_time, utils.MOMENTFORMAT.time).local().format(utils.MOMENTFORMAT.time24)).utc().format(),
                    start_time: moment(moment(newData.date).format(utils.MOMENTFORMAT.serverDate) + " " + moment(v.start_time, utils.MOMENTFORMAT.time).local().format(utils.MOMENTFORMAT.time24)).utc().format(),
                    end_time: moment(moment(newData.date).format(utils.MOMENTFORMAT.serverDate) + " " + moment(v.end_time, utils.MOMENTFORMAT.time).local().format(utils.MOMENTFORMAT.time24)).utc().format(),
                    seq: v.seq || 0,
                };
            });
            //newData.date = newData.visit[0].date
            return newData;
        },
        booking: function (data) {
            const defaultData = {
                project_id: '',
                appointment_id: '',
                participant_id: '',
                email: '',
                first_name: '',
                last_name: '',
                mobile: '',
                comment: '',
                tz: moment.tz.guess()
            },
                newData = _.assign(defaultData, data);

            _.each(newData, (v, i) => {
                newData[i] = v || '';
            });
            return newData;
        },
        resched: function (data) {
            const defaultData = {
                to_appointment_id: '',
                from_appointment_id: '',
                participant_id: '',
                comments: '',
                tz: moment.tz.guess()
            },
                newData = _.assign(defaultData, data);

            _.each(newData, (v, i) => {
                newData[i] = v || '';
            });

            return newData;
        },
    },
    panelist: {
        create: function (data) {
            const defaultData = {
                project_id: '',
                email: '',
                first_name: '',
                last_name: '',
                mobile: '',
                comment: '',
                status: '',
            },
                newData = _.assign(defaultData, data);

            _.each(newData, (v, i) => {
                newData[i] = v || '';
            });

            return newData;
        },
        get: function (data) {
            const defaultData = {
                id: '',
                email: '',
                first_name: '',
                last_name: '',
                mobile: '',
                comment: '',
                status: '',
                employee_status: '',
                appointment: [],
            },
                newData = _.assign(defaultData, data);

            _.each(newData, (v, i) => {
                newData[i] = v || '';
            });

            _.each(newData.appointment, (v, i) => {
                newData.appointment[i] = mapping.panelist.appointment(v);
            });

            return newData;
        },
        appointment: function (data) {
            const defaultData = {
                id: '',
                project_id: '',
                participant_id: '',
                date: null,
                item: '',
                slot: '',
                resched_from_id: '',
                status: '',
                comments: '',
                insert_dttm: null,
                update_dttm: null,
            },
                newData = _.assign(defaultData, data);

            _.each(newData, (v, i) => {
                newData[i] = v || '';
            });

            newData.date = moment(newData.date);

            return newData;
        },
    },
};

const projects = {
    fetchAll: function (data) {
        const opt = {
            url: '/api/project',
            method: 'get',
        };

        let request = async function () {
            return await http(opt)
                .then((resp) => {
                    let d = resp.data;

                    _.each(d.data, (v, i) => {
                        d.data[i] = mapping.project(v);
                    });
                    return d;
                })
                .catch((err) => {
                    return err.response.data;
                });
        };

        return request();
    },
    createTempalte: (data) => {
        const opt = {
            url: `/api/project/template`,
            method: 'post',
            data: data
        }
        let request = async function () {
            return await http(opt)
                .then((resp) => {
                    return resp.data;
                })
                .catch((err) => {
                    return err.response.data;
                });
        };

        return request();
    },
    fetchById: function (data) {
        const opt = {
            url: `/api/project/${data}`,
            method: 'get',
        };

        let request = async function () {
            return await http(opt)
                .then((resp) => {
                    let d = resp.data;
                    d.data = mapping.project(d.data);
                    return d;
                })
                .catch((err) => {
                    return err.response.data;
                });
        };

        return request();
    },
    create: function (formData, data) {
        const dataDefault = mapping.create(data);
        formData.append("Data", new Blob([JSON.stringify(dataDefault)], {
            type: 'text/plain'
        }));
        const opt = {
            url: '/api/project/new',
            method: 'post',
            data: formData,
        };
        let request = async function () {
            return await http(opt)
                .then((resp) => {
                    return resp.data;
                })
                .catch((err) => {
                    return err.response.data;
                });
        }

        return request();
    },
    update: function (data) {
        const dataDefault = mapping.update(data),
            opt = {
                url: `/api/project/update?projectId=${data.id}`,
                method: 'post',
                data: dataDefault,
            };

        let request = async function () {
            return await http(opt)
                .then((resp) => {
                    return resp.data;
                })
                .catch((err) => {
                    return err.response.data;
                });
        };

        return request();
    },
    delete: function (data) {
        const opt = {
            url: `/api/project/delete/${data}`,
            method: 'post',
        };

        let request = async function () {
            return await http(opt)
                .then((resp) => {
                    return resp.data;
                })
                .catch((err) => {
                    return err.response.data;
                });
        };

        return request();
    },
    deleteMultipleProjects: function (data) {
        const opt = {
            url: `/api/project/delete/multiple-projects/automation`,
            method: 'post',
        };

        let request = async function () {
            return await http(opt)
                .then((resp) => {
                    return resp.data;
                })
                .catch((err) => {
                    return err.response.data;
                });
        };

        return request();
    },
    publish: function (data, ProjectId, rec, form_data) {
        const defaultData = {
            id: '',
            is_published: 0,
            ...rec
        };
        defaultData.is_published = defaultData.is_published ? 1 : 0;
        form_data.append("Data", new Blob([JSON.stringify(defaultData)], {
            type: 'text/plain'
        }));
        const opt = {
            url: `/api/project/publish?projectId=${ProjectId}`,
            method: 'post',
            data: form_data,
        };

        let request = async function () {
            return await http(opt)
                .then((resp) => {
                    return resp.data;
                })
                .catch((err) => {
                    return err.response.data;
                });
        };

        return request();
    },
    dupplicate: function (id) {
        const opt = {
            url: `/api/project/duplicate/${id}?projectId=${id}`,
            method: 'get',
        };

        let request = async function () {
            return await http(opt)
                .then((resp) => {
                    return resp.data;
                })
                .catch((err) => {
                    return err.response.data;
                });
        };

        return request();
    },
    createVisits: function (payload) {
        const defalutData = mapping.createVisits(payload),

            body = {
                url: `/api/project/create-visits?projectId=${defalutData.id}`,
                method: `post`,
                data: defalutData
            }

        let request = async function () {
            return await http(body)
                .then((res) => {
                    return res.data
                }).catch((err) => {
                    return err.response.data
                })
        }
        return request();
    },
    createTimeSlots: function (payload) {
        const defalutData = mapping.createTimeSlots(payload),

            body = {
                url: `/api/project/create-slots?projectId=${defalutData.id}`,
                method: `post`,
                data: defalutData
            }
        let request = async function () {
            return await http(body)
                .then((res) => {
                    return res.data
                }).catch((err) => {
                    return err.response.data
                })
        }
        return request();
    },
};

const appointments = {
    fetchByProjId: function (data) {
        const opt = {
            url: `/api/appointment/project/${data}`,
            method: 'get',
        };

        let request = async function () {
            return await http(opt)
                .then((resp) => {
                    let d = resp.data;

                    _.each(d.data, (v, i) => {
                        d.data[i] = mapping.appointments.get(d.data[i]);
                    });

                    return d;
                })
                .catch((err) => {
                    return err.response.data;
                });
        };

        return request();
    },
    fetchBookingsByProjectAndParticipant: function (project_id, participant_id) {
        const opt = {
            url: `/api/appointment/byprojandparticipant?projectId=${project_id}`,
            method: 'post',
            data: {
                project_id,
                participant_id,
            },
        };

        let request = async function () {
            return await http(opt)
                .then((resp) => {
                    let d = resp.data;

                    _.each(d.data, (v, i) => {
                        d.data[i] = mapping.appointments.get(d.data[i]);
                    });

                    return d;
                })
                .catch((err) => {
                    return err.response.data;
                });
        };

        return request();
    },
    update: function (data) {
        const opt = {
            url: `/api/appointment/update?projectId=${data.project_id}`,
            method: 'post',
            data: mapping.appointments.update(data),
        };
        let request = async function () {
            return await http(opt)
                .then((resp) => {
                    return resp.data;
                })
                .catch((err) => {
                    return err.response.data;
                });
        };

        return request();
    },
    book: function (data) {
        const defaultData = {
            id: '',
        },
            opt = {
                url: `/api/appointment/participant?projectId=${data.project_id}`,
                method: 'post',
                data: _.assign(defaultData, mapping.appointments.booking(data)),
            };
        let request = async function () {
            return await http(opt)
                .then((resp) => {
                    return resp.data;
                })
                .catch((err) => {
                    return err.response.data;
                });
        };

        return request();
    },
    reschedule: function (data, project_id) {
        const opt = {
            url: `/api/appointment/resched?projectId=${project_id}`,
            method: 'post',
            data: mapping.appointments.resched(data),
        };

        let request = async function () {
            return await http(opt)
                .then((resp) => {
                    return resp.data;
                })
                .catch((err) => {
                    return err.response.data;
                });
        };

        return request();
    },
    cancel: function (id, project_id) {
        const opt = {
            url: `/api/appointment/cancel/${id}?projectId=${project_id}&tz=${moment.tz.guess()}`,
            method: 'get',
        };

        let request = async function () {
            return await http(opt)
                .then((resp) => {
                    return resp.data;
                })
                .catch((err) => {
                    return err.response.data;
                });
        };

        return request();
    },
    delete: function (data, project_id) {
        const opt = {
            url: `/api/appointment/delete/${data}?projectId=${project_id}&tz=${moment.tz.guess()}`,
            method: 'post',
            data: project_id
        };

        let request = async function () {
            return await http(opt)
                .then((resp) => {
                    return resp.data;
                })
                .catch((err) => {
                    return err.response.data;
                });
        };

        return request();
    },
    deleteMultiple: (data, project_id) => {
        const opt = {
            url: `/api/appointment/multiple/delete?projectId=${project_id}&tz=${moment.tz.guess()}`,
            method: 'post',
            data: data
        };

        let request = async function () {
            return await http(opt)
                .then((resp) => {
                    return resp.data;
                })
                .catch((err) => {
                    return err.response.data;
                });
        };

        return request();
    },
    updateMultiple: (data, project_id) => {
        const updateData = data.map(item => {
            let newData = { visit: [] }
            newData.date = moment(moment(item.date).format(utils.MOMENTFORMAT.serverDate) + " " + moment(item.visit[0].start_time, utils.MOMENTFORMAT.time).local().format(utils.MOMENTFORMAT.time24)).utc().format()
            _.each(item.visit, (v, i) => {
                newData.visit[i] = {
                    id: v.id,
                    appointment_id: v.appointment_id,
                    date: moment(moment(item.date).format(utils.MOMENTFORMAT.serverDate) + " " + moment(v.start_time, utils.MOMENTFORMAT.time).local().format(utils.MOMENTFORMAT.time24)).utc().format(),
                    start_time: moment(moment(item.date).format(utils.MOMENTFORMAT.serverDate) + " " + moment(v.start_time, utils.MOMENTFORMAT.time).local().format(utils.MOMENTFORMAT.time24)).utc().format(),
                    end_time: moment(moment(item.date).format(utils.MOMENTFORMAT.serverDate) + " " + moment(v.end_time, utils.MOMENTFORMAT.time).local().format(utils.MOMENTFORMAT.time24)).utc().format(),
                    seq: v.seq || 0,
                };
            });
            return {
                ...item,
                data: newData.date,
                visit: newData.visit
            }
        })
        const opt = {
            url: `/api/appointment/multiple/update?projectId=${project_id}`,
            method: 'post',
            data: updateData
        };
        let request = async function () {
            return await http(opt)
                .then((resp) => {
                    console.log(resp)
                    return resp.data;
                })
                .catch((err) => {
                    return err.response.data;
                });
        };

        return request();
    },
    panelist: {
        fetchAppointmentsByProjectId: function (data) {
            const opt = {
                url: `/api/panelist/appointment/project/${data}`,
                method: 'get',
            };

            let request = async function () {
                return await http(opt)
                    .then((resp) => {
                        let d = resp.data;

                        _.each(d.data, (v, i) => {
                            d.data[i] = mapping.appointments.get(d.data[i]);
                        });

                        return d;
                    })
                    .catch((err) => {
                        return err.response.data;
                    });
            };

            return request();
        },
        fetchParticipantByEmail: function (id) {
            const opt = {
                url: `/api/panelist/participant/${id}`,
                method: 'get',
            };
            let request = async function () {
                return await http(opt)
                    .then((resp) => {
                        return resp.data;
                    })
                    .catch((err) => {
                        return err.response.data;
                    });
            };

            return request();
        },
        fetchAppointmentById: function (id) {
            const opt = {
                url: `/api/panelist/appointment/${id}`,
                method: 'get',
            };

            let request = async function () {
                return await http(opt)
                    .then((resp) => {
                        let d = resp.data;
                        d.data.project = mapping.project(d.data.project);
                        d.data = mapping.appointments.get(d.data);
                        return d;
                    })
                    .catch((err) => {
                        return err.response.data;
                    });
            };

            return request();
        },
        addBooking: function (data) {
            data.tz = moment.tz.guess()
            const opt = {
                url: '/api/panelist/appointment/participant',
                method: 'post',
                data,
            };

            let request = async function () {
                return await http(opt)
                    .then((resp) => {
                        return resp.data;
                    })
                    .catch((err) => {
                        return err.response.data;
                    });
            };

            return request();
        },
        cancelAppointment: function (id) {
            const opt = {
                url: `/api/panelist/appointment/cancel/${id}?tz=${moment.tz.guess()}`,
                method: 'get',
            };

            let request = async function () {
                return await http(opt)
                    .then((resp) => {
                        let d = resp.data;

                        return d;
                    })
                    .catch((err) => {
                        return err.response.data;
                    });
            };

            return request();
        },
        multipleCancelAppointment: function (ids, projectId) {
            const opt = {
                url: `/api/panelist/appointment/multiple-cancel/${projectId}?tz=${moment.tz.guess()}&ids=${ids}`,
                method: 'get',
            };

            let request = async function () {
                return await http(opt)
                    .then((resp) => {
                        let d = resp.data;

                        return d;
                    })
                    .catch((err) => {
                        return err.response.data;
                    });
            };

            return request();
        },
        rescheduleAppointment: function (data) {
            const opt = {
                url: '/api/panelist/appointment/resched',
                method: 'post',
                data: mapping.appointments.resched(data),
            };

            let request = async function () {
                return await http(opt)
                    .then((resp) => {
                        return resp.data;
                    })
                    .catch((err) => {
                        return err.response.data;
                    });
            };

            return request();
        },
        TestFiles: function (data) {
            const opt = {
                url: '/api/project/upload',
                method: 'post',
                data: data,
            };
            const getTokens = codify.decode(localStorage.getItem('tokens'));

            let request = async function () {
                return await axios.post(`https://${env()}/api/project/upload`, data, {
                    headers: {
                        Authorization: `Bearer ${String(getTokens).substring(1, getTokens.length - 1)}`,
                        "Content-Type": "application/x-www-form-urlencoded"
                    }
                })
                    .then((resp) => {
                        return resp.data;

                    })
                    .catch((err) => {
                        console.log(err)

                    });
            };

            return request();
        },
    },
};

const participants = {
    fetchById: function (data) {
        const opt = {
            url: `/api/participant/project/${data}`,
            method: 'get',
        };

        let request = async function () {
            return await http(opt)
                .then((resp) => {
                    let d = resp.data;

                    _.each(d.data, (v, i) => {
                        d.data[i] = mapping.panelist.get(d.data[i]);
                    });

                    return d;
                })
                .catch((err) => {
                    return err.response.data;
                });
        };

        return request();
    },
    create: function (data, form_data) {

        const defalutData = mapping.panelist.create(data)
        form_data.append("Data", new Blob([JSON.stringify(defalutData)], {
            type: 'text/plain'
        }));

        const opt = {
            url: `/api/participant/new?projectId=${data.project_id}`,
            method: 'post',
            data: form_data,
        };

        let request = async function () {
            return await http(opt)
                .then((resp) => {
                    return resp.data;
                })
                .catch((err) => {
                    return err.response.data;
                });
        };

        return request();
    },
    update: function (data) {
        const defaultData = {
            id: '',
        };
        const mappingDefaultValue = _.assign(defaultData, mapping.panelist.create(data))
        const form_data = new FormData()
        form_data.append("Data", new Blob([JSON.stringify(mappingDefaultValue)], {
            type: 'text/plain'
        }));
        const opt = {
            url: `/api/participant/new?projectId=${data.project_id}`,
            method: 'post',
            data: form_data,
        };

        let request = async function () {
            return await http(opt)
                .then((resp) => {
                    return resp.data;
                })
                .catch((err) => {
                    return err.response.data;
                });
        };

        return request();
    },
    delete: function (data, project_id) {
        const opt = {
            url: `/api/participant/delete/${data}?projectId=${project_id}`,
            method: 'post',
        };

        let request = async function () {
            return await http(opt)
                .then((resp) => {
                    return resp.data;
                })
                .catch((err) => {
                    return err.response.data;
                });
        };

        return request();
    },
    deleteMultipleParticipants: function (arrID, project_id) {
        const opt = {
            url: `/api/participant/multiple/delete?projectId=${project_id}`,
            method: 'post',
            data: arrID
        };

        let request = async function () {
            return await http(opt)
                .then((resp) => {
                    return resp.data;
                })
                .catch((err) => {
                    return err.response.data;
                });
        };

        return request();
    },
    notifyPanelist: (data) => {
        const opt = {
            url: `/api/participant/notify?projectId=${data.project_id}`,
            method: 'post',
            data: data
        };

        let request = async () => {
            return await http(opt)
                .then((resp) => {
                    return resp.data;
                })
                .catch((err) => {
                    return err.response.data;
                });
        };

        return request();
    },
};

const email = {
    getHelp: function (data) {
        const [first_name, last_name] = data.name.split(' ');
        data.first_name = first_name;
        data.last_name = last_name;

        const opt = {
            url: '/api/panelist/help',
            method: 'post',
            data,
        };

        let request = async function () {
            return await http(opt)
                .then((resp) => {
                    return resp.data;
                })
                .catch((err) => {
                    return err.response.data;
                });
        };

        return request();
    },

    getEmailTemplates: async () => {
        const opt = {
            url: '/api/emailtemplates',
            method: 'get',
        };
        let request = async function () {
            return await http(opt)
                .then((resp) => {
                    return resp.data;
                })
                .catch((err) => {
                    return err.response.data;
                });
        };

        return request();
    },
    updateEmailTemplate: (data) => {
        const opt = {
            url: `/api/emailtemplates/update?projectId=${data.id}`,
            method: 'post',
            data: data
        }
        let request = async function () {
            return await http(opt)
                .then((resp) => {
                    return resp.data;
                })
                .catch((err) => {
                    return err.response.data;
                });
        }

        return request();
    },
};

const user = {
    Login: async (data) => {
        const opt = {
            url: '/api/auth/login',
            method: 'post',
            data,
        };

        let request = async () => {
            return await http(opt)
                .then((res) => {
                    return res.data
                })
                .catch(err => {
                    return err.response.data
                })
        }
        return request()
    },
    fetchProjectByEmail: async (emailUser) => {
        const opt = {
            url: `/api/panelist/projects-user/${emailUser}`,
            method: 'get'
        };
        let request = async () => {
            return await http(opt)
                .then(async (resp) => {
                    let d = resp.data
                    await _.each(d.data[0], async (v, i) => {
                        d.data[0][i] = await mapping.project(v);
                    });
                    await _.each(d.data[1], async (v, i) => {
                        d.data[1][i] = await mapping.project(v);
                    });
                    await _.each(d.data[2], async (v, i) => {
                        d.data[2][i] = await mapping.project(v);
                    });
                    return d
                }).catch(err => {
                    return err.response.data
                })
        }
        return request()
    },
    changePassword: async (data) => {
        const opt = {
            url: `/api/user/changePassword`,
            method: 'post',
            data: data
        };
        let request = async () => {
            return await http(opt)
                .then(resp => {
                    return resp.data
                }).catch(err => {
                    return err.response.data
                })
        }
        return request()
    },
    forgetPassword: (data) => {
        const opt = {
            url: `/api/auth/panelist/password_reset`,
            method: 'post',
            data: data
        };
        let request = async () => {
            return await http(opt)
                .then(resp => {
                    return resp.data
                }).catch(err => {
                    return err.response.data
                })
        }
        return request()
    },
    resetPassword: (payload) => {
        const opt = {
            url: `/api/auth/panelist/password_reset_confirm/${payload.token}`,
            method: 'post',
            data: payload
        };
        let request = async () => {
            return await http(opt)
                .then(resp => {
                    return resp.data
                }).catch(err => {
                    return err.response.data
                })
        }
        return request()
    },
    refreshToken: async (refresh_token) => {
        const opt = {
            url: '/api/auth/refresh_token',
            method: 'post',
            data: { refresh_token: refresh_token }
        }

        let request = async () => {
            return await http(opt)
                .then(resp => {
                    return resp.data
                }).catch(err => {
                    console.log(err)
                    return err.response.data
                })
        }
        return request()
    },
    logout: async () => {
        const opt = {
            url: '/api/user/logout',
            method: 'post',
        }

        let request = async () => {
            return await http(opt)
                .then(resp => {
                    return resp.data
                }).catch(err => {
                    return err.response.data || err.response || 'server error'
                })
        }
        return request()
    }
}

const admins = {
    fetchAdmins: async () => {
        const opt = {
            url: `api/user/admins`,
            method: 'get'
        };
        let request = async () => {
            return await http(opt)
                .then(resp => {
                    return resp.data
                }).catch(err => {
                    return err.response.data
                })
        }
        return request()
    },
    deleteAdmin: async (id) => {
        const opt = {
            url: `api/user/admin/${id}`,
            method: 'post'
        };
        let request = async function () {
            return await http(opt)
                .then((resp) => {
                    return resp.data;
                })
                .catch((err) => {
                    return err.response.data;
                });
        };

        return request();
    },
    createAdmin: async (payload) => {
        const opt = {
            url: `api/user/admin/create`,
            method: 'post',
            data: payload
        };
        let request = async function () {
            return await http(opt)
                .then((resp) => {
                    return resp.data;
                })
                .catch((err) => {
                    return err.response.data;
                });
        };

        return request();
    },
    requestSiteAdmin: async (payload) => {
        const opt = {
            url: `api/admin/request-admin`,
            method: 'post',
            data: payload
        };
        let request = async function () {
            return await http(opt)
                .then((resp) => {
                    return resp.data;
                })
                .catch((err) => {
                    return err.response.data;
                });
        };

        return request();
    },
    getSitesAdmin: async () => {
        const opt = {
            url: `api/sites`,
            method: 'get',
        };
        let request = async function () {
            return await http(opt)
                .then((resp) => {
                    return resp.data;
                })
                .catch((err) => {
                    return err.response.data;
                });
        };

        return request();
    },
    exportStatistics: async (payload) => {
        const opt = {
            url: `api/project/export-statistics`,
            method: 'post',
            data: payload
        };
        let request = async function () {
            return await http(opt)
                .then((resp) => {
                    return resp.data;
                })
                .catch((err) => {
                    return err.response.data;
                });
        };

        return request();
    }
}

const panelist = {
    fetchPanelist: async () => {
        const opt = {
            url: `api/user/panelist`,
            method: 'get'
        };
        let request = async () => {
            return await http(opt)
                .then(resp => {
                    return resp.data
                }).catch(err => {
                    return err.response.data
                })
        }
        return request()
    },
    deletePaneList: async (id) => {
        const opt = {
            url: `api/user/panelist/${id}`,
            method: 'post'
        };
        let request = async function () {
            return await http(opt)
                .then((resp) => {
                    return resp.data;
                })
                .catch((err) => {
                    return err.response.data;
                });
        };
        return request();
    },
    updatePanelist: async (data) => {
        const opt = {
            url: '/api/user/panelist/update',
            method: 'post',
            data: data,
        };
        let request = async function () {
            return await http(opt)
                .then((resp) => {
                    return resp.data;
                })
                .catch((err) => {
                    return err.response.data;
                });
        };
        return request();
    }

}
const AdminPerProject = {
    getProjectAdminById: async (id) => {
        const opt = {
            url: `/api/project-admins/${id}?projectId=${id}`,
            method: 'get'
        };
        let request = async () => {
            return await http(opt)
                .then(resp => {
                    return resp.data
                }).catch(err => {
                    return err.response.data
                })
        }
        return request();
    },
    createProjectAdmins: async (data) => {
        const opt = {
            url: `/api/project-admins?projectId=${data.project_id}`,
            method: 'post',
            data: data,
        };
        let request = async function () {
            return await http(opt)
                .then((resp) => {
                    return resp.data;
                })
                .catch((err) => {
                    return err.response.data;
                });
        };
        return request();
    },
    deleteAdminProject: async (data) => {
        const opt = {
            url: `/api/project-admins/delete?projectId=${data.project_id}`,
            method: 'post',
            data: data
        };
        let request = async function () {
            return await http(opt)
                .then((resp) => {
                    return resp.data;
                })
                .catch((err) => {
                    return err.response.data;
                });
        };
        return request();
    },
    deleteAdminProjectAutomation: async (data) => {
        const opt = {
            url: `/api/project-admins/delete/automation`,
            method: 'post',
        };
        let request = async function () {
            return await http(opt)
                .then((resp) => {
                    return resp.data;
                })
                .catch((err) => {
                    return err.response.data;
                });
        };
        return request();
    },
    update: async (data) => {
        const opt = {
            url: `/api/project-admins/update/${data.id}?projectId=${data.project_id}`,
            method: 'post',
            data: data
        };
        let request = async function () {
            return await http(opt)
                .then((resp) => {
                    return resp.data;
                })
                .catch((err) => {
                    return err.response.data;
                });
        };
        return request();
    },
    getProjectAdminByProjectId: async (projetcid) => {
        const opt = {
            url: `/api/project-admins/admin/role?projectId=${projetcid}`,
            method: 'get'
        };
        let request = async () => {
            return await http(opt)
                .then(resp => {
                    return resp.data
                }).catch(err => {
                    return err.response.data
                })
        }
        return request();
    },


}

const logs = {
    fetchBookingLogActivityById: async (projetcid) => {
        const opt = {
            url: `/api/logs/booking_log_tracker?projectId=${projetcid}`,
            method: 'get'
        };
        let request = async () => {
            return await http(opt)
                .then(resp => {
                    return resp.data
                }).catch(err => {
                    return err.response.data
                })
        }
        return request();
    },
    fetchProjectLogActivity: async () => {
        const opt = {
            url: `/api/logs/project_log_tracker`,
            method: 'get'
        };
        let request = async () => {
            return await http(opt)
                .then(resp => {
                    return resp.data
                }).catch(err => {
                    return err.response.data
                })
        }
        return request();
    },
    fetchProjectPanelistLogActivity: async (projectid) => {
        const opt = {
            url: `/api/logs/project_panelist_log_tracker?projectId=${projectid}`,
            method: 'get'
        };
        let request = async () => {
            return await http(opt)
                .then(resp => {
                    return resp.data
                }).catch(err => {
                    return err.response.data
                })
        }
        return request();
    },

    fetchAdminLogActivity: async () => {
        const opt = {
            url: `/api/logs/admin_log_tracker`,
            method: 'get'
        };
        let request = async () => {
            return await http(opt)
                .then(resp => {
                    return resp.data
                }).catch(err => {
                    return err.response.data
                })
        }
        return request();
    },


    fetchPanelistLogActivity: async () => {
        const opt = {
            url: `/api/logs/panelist_log_tracker`,
            method: 'get'
        };
        let request = async () => {
            return await http(opt)
                .then(resp => {
                    return resp.data
                }).catch(err => {
                    return err.response.data
                })
        }
        return request();
    },
}
export default {
    utils,
    projects,
    appointments,
    participants,
    email,
    user,
    admins,
    panelist,
    AdminPerProject,
    logs
};
