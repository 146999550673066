import React, { useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useMount } from 'ahooks';

import { Form, Input, InputNumber, Row, Col } from 'antd';
import { Button, Paper } from '@material-ui/core';

import { InfoCircleOutlined } from '@ant-design/icons';


import './style.scss';

const { TextArea } = Input;

const StepOne = (props) => {
    const { onSubmit, defaultValue } = props;

    const formData = useRef(defaultValue);
    useMount(() => {});

    const onFormSubmit = (values) => {
        formData.current = values;
        onSubmit(formData.current);
    };

    return (
        <Form
            name="ProjectCreate"
            layout="horizontal"
            initialValues={defaultValue}
            onFinish={onFormSubmit}
        >
            <Paper className="paper-cont" elevation={0}>
                <div className="paper-cont-bar"></div>
                <div className="paper-cont-content">
                    <Row gutter={[24, 16]}>
                        <Col span={12} sm={12} xs={24}>
                            <Form.Item
                                label="Project ID"
                                name="projID"
                                tooltip={{
                                    title: 'For tests via PRT – use the study ID created via the ResearchIt app. For others, use the global study ID generator.',
                                    icon: <InfoCircleOutlined />,
                                }}
                                rules={[{ required: true, message: 'Please input project ID.' }]}
                            >
                                <Input tabIndex={1} />
                            </Form.Item>
                            <Form.Item
                                label="Type"
                                name="type"
                                tooltip={{
                                    title: 'Short test / project description.',
                                    icon: <InfoCircleOutlined />,
                                }}
                                rules={[{ required: false, message: 'Please input type.' }]}
                            >
                                <TextArea tabIndex={3} rows={4} maxLength={255} showCount={255} />
                            </Form.Item>                            
                        </Col>

                        <Col span={12} sm={12} xs={24}>
                            <Form.Item
                                label="Project Name"
                                name="projName"
                                rules={[{ required: true, message: 'Please input your project name.' }]}
                            >
                                <Input  name="projName" id='projName' tabIndex={2} showCount  maxLength={255}/>
                            </Form.Item>
                            <Form.Item
                                label="Panelist"
                                name="count"
                                tooltip={{
                                    title: 'Enter expected total number of panelists required for this project.',
                                    icon: <InfoCircleOutlined />,
                                }}
                                rules={[{ required: true, message: 'Please input panelist.' }]}
                            >
                                <InputNumber tabIndex={4} min={0} max={1000} />
                            </Form.Item>
                        </Col>
                    </Row>
                </div>
            </Paper>

            <div className="proj-create-page-content-controls">
                <Button type="submit" tabIndex={5} className="btn-next" variant="contained" color="primary">
                    Next
                </Button>
            </div>
        </Form>
    );
};

StepOne.defaultProps = {
    isInitialData: {},
};

StepOne.propTypes = {
    initialData: PropTypes.object,
    defaultValue: PropTypes.object,
    step: PropTypes.number.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default connect()(StepOne);
