import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useMount, useUpdateEffect } from 'ahooks';

import { PageHeader, Table, Tag, Breadcrumb, Input, Modal } from 'antd';

import {
    Backdrop,
    Button,
    IconButton,
    CircularProgress,
    Menu,
    MenuItem,
    ListItemIcon,
    Typography,
    TextField,
} from '@material-ui/core';
import { SearchOutlined, DeleteForever, Edit, Add, MoreVert, CancelScheduleSend } from '@material-ui/icons';
import { useSnackbar } from 'notistack';

import urls from '../../utility/urls';
import { errorMapper, regex } from '../../utility';
import api from '../../library/api';
import PopConfirm from '../../components/PopConfirm';
import RegexTextField from '../../components/RegexTextField';
import EmailFields from '../../components/EmailFields';

import './style.scss';
import AuthHelper from '../../library/helpers/AuthHelper';
import { ROLES } from '../../utility/constant';

const statusColor = {
    open: 'green',
    booked: 'blue',
    invited: 'yellow',
    cancelled: 'red',
    disabled: 'gray',
};

const BookingPanelistDetailsPage = (props) => {
    const historyLink = useHistory();
    let { ProjectID } = useParams();

    const currentRowData = useRef({});

    const [data, setData] = useState({ original: [], filtered: [] });
    const [anchorEl, setAnchorEl] = useState(null);
    const [showPopMenu, setShowPopMenu] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [openCreatePanelModal, setOpenCreatePanelModal] = useState(false);
    const [IsCreateModal, setIsCreateModal] = useState(true);

    const [email, setEmail] = useState('');
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [mobile, setMobile] = useState('');
    const [comments, setComments] = useState('');

    const [showLastName, setShowLastName] = useState(true);
    const [showActions, setShowActions] = React.useState(true)
    const auth = new AuthHelper()
    const { enqueueSnackbar } = useSnackbar();

    useMount(() => {
        fetchData(ProjectID);
    });

    useEffect(() => {
        (async () => {
            (auth.getRole() === ROLES.projAdmin || auth.getRole() === ROLES.adminSuper) && await checkUserIsFirstnameOnly(ProjectID)
        })();
    }, [])

    useUpdateEffect(() => { }, [email, firstname, lastname, mobile]);

    const fetchData = (id) => {
        setIsLoading(true);

        api.participants
            .fetchById(id)
            .then((d) => {
                setIsLoading(false);

                if (d.messages === 'success') {
                    setData({ original: d.data, filtered: d.data });
                } else {
                    enqueueSnackbar(errorMapper(d.messages), { variant: 'warning' });
                }
            })
            .catch((e) => {
                setIsLoading(false);
                enqueueSnackbar('An error occurred while getting list of panelists. Please contact system admin for help.', { variant: 'error' });
            });
    };

    const checkUserIsFirstnameOnly = (id) => {
        setIsLoading(true);

        api.AdminPerProject
            .getProjectAdminByProjectId(id)
            .then((d) => {
                setIsLoading(false);

                if (d.messages === 'success') {
                    if (d.data === ROLES.adminFnameOnly) {
                        setShowLastName(false)
                        setShowActions(false)
                    } else if (d.data === ROLES.adminView) {
                        setShowActions(false)
                        setShowLastName(true)
                    } else {
                        setShowLastName(true)
                        setShowActions(true)
                    }
                } else {
                    enqueueSnackbar(errorMapper(d.messages), { variant: 'warning' });
                }
            })
            .catch((e) => {
                setIsLoading(false);
                enqueueSnackbar('Server error!', { variant: 'error' });
            });
    }

    const deleteParticipants = (id) => {
        setIsLoading(true);

        api.participants
            .delete(id, ProjectID)
            .then((d) => {
                setIsLoading(false);

                if (d.messages === 'success') {
                    fetchData(ProjectID);
                    enqueueSnackbar('Deleted successfully', { variant: 'success' });
                } else {
                    enqueueSnackbar(errorMapper(d.messages), { variant: 'warning' });
                }
            })
            .catch((e) => {
                setIsLoading(false);
                enqueueSnackbar('An error occurred while deleting panelist. Please contact system admin for help.', { variant: 'error' });
            });
    };

    const createParticipants = (obj) => {
        setIsLoading(true);
        api.participants
            .create(obj)
            .then((d) => {
                setIsLoading(false);

                if (d.messages === 'success') {
                    fetchData(ProjectID);
                    setOpenCreatePanelModal(false);
                    enqueueSnackbar('Created successfully', { variant: 'success' });
                } else {
                    enqueueSnackbar(errorMapper(d.messages), { variant: 'warning' });
                }
            })
            .catch((e) => {
                setIsLoading(false);
                enqueueSnackbar('An error occurred while creating panelist. Please contact system admin for help.', { variant: 'error' });
            });
    };

    const updateParticipants = (obj) => {
        setIsLoading(true);
        api.participants
            .update(obj)
            .then((d) => {
                setIsLoading(false);

                if (d.messages === 'success') {
                    currentRowData.current = {};
                    fetchData(ProjectID);
                    setOpenCreatePanelModal(false);
                    enqueueSnackbar('Updated successfully', { variant: 'success' });
                } else {
                    enqueueSnackbar(errorMapper(d.messages), { variant: 'warning' });
                }
            })
            .catch((e) => {
                setIsLoading(false);
                enqueueSnackbar('An error occurred while updating panelist. Please contact system admin for help.', { variant: 'error' });
            });
    };

    const getColumns = () => [
        {
            title: 'Email Address',
            align: 'left',
            width: 250,
            fixed: 'left',
            dataIndex: 'email',
            key: 'email',
            render: (text, record) => <span>{`${text}`}</span>,
        },
        {
            title: 'Last Name',
            align: 'left',
            width: 250,
            dataIndex: 'last_name',
            key: 'last_name',
            className: !showLastName ? 'hide' : '',
            render: (text, record) => <span>{text}</span>,
        },
        {
            title: 'First Name',
            align: 'left',
            width: 250,
            dataIndex: 'first_name',
            key: 'first_name',
            render: (text, record) => <span>{text}</span>,
        },
        {
            title: 'Phone Number',
            align: 'center',
            width: 230,
            dataIndex: 'mobile',
            key: 'mobile',
        },
        {
            title: 'Status',
            align: 'center',
            width: 140,
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => <Tag color={statusColor[text]}>{String(text).toUpperCase()}</Tag>,
        },
        {
            title: 'Comments',
            align: 'left',
            width: 300,
            dataIndex: 'project_participant[0].comment',
            key: 'comment',
            render: (text, record) => {
                return record.project_participant[0].comment
            }
        },
        {
            title: 'Action',
            width: 100,
            editable: false,
            fixed: 'right',
            align: 'center',
            className: !showActions ? 'hide' : '',
            render: (text, record) => showActions && (
                <div className="project-management-page-table-row-ctrl">
                    <IconButton
                        size="small"
                        disableRipple={true}
                        aria-label="Edit"
                        onClick={onEditPanelist.bind(this, record)}
                    >
                        <Edit fontSize="small" />
                    </IconButton>
                    <PopConfirm
                        title="Are you sure you want to delete this panelist?"
                        onConfirm={onDeletePanelist.bind(this, record)}
                    >
                        <IconButton size="small" disableRipple={true} aria-label="delete" color="secondary">
                            <DeleteForever />
                        </IconButton>
                    </PopConfirm>
                </div>
            ),
        },
    ];

    const onEditPanelist = (rec) => {
        currentRowData.current = rec;
        setIsCreateModal(false);
        setOpenCreatePanelModal(true);
        setEmail(currentRowData.current.email);
        setFirstname(currentRowData.current.first_name || '');
        setLastname(currentRowData.current.last_name || '');
        setMobile(currentRowData.current.mobile || '');
        setComments(currentRowData.current?.project_participant[0]?.comment || '');

    };

    const onDeletePanelist = (rec) => {
        currentRowData.current = rec;
        deleteParticipants(rec.id);
    };

    const actionOptions = [
        {
            label: 'Edit',
            icon: <Edit fontSize="small" />,
            onClick: (e) => {
                setIsCreateModal(false);
                setOpenCreatePanelModal(true);
                setEmail(currentRowData.current.email);
                setFirstname(currentRowData.current.first_name || '');
                setLastname(currentRowData.current.last_name || '');
                setMobile(currentRowData.current.mobile || '');
                setComments(currentRowData.current?.project_participant[0]?.comment || '');
            },
        },
        {
            label: 'Cancel',
            icon: <CancelScheduleSend fontSize="small" />,
            onClick: (e) => {
                console.info('Cancel');
                currentRowData.current = {};
            },
        },
        /*{
            label : "Reschedule",
            icon : <Schedule fontSize="small"/>,
            onClick : (e) => {
                console.info("Reschedule");
                currentRowData.current = {};
            }
        }*/
    ];

    const onClickMoreMenu = (record, e) => {
        currentRowData.current = record;
        setAnchorEl(e.currentTarget);
        setShowPopMenu(true);
    };

    const onCloseMoreMenu = (e) => {
        setAnchorEl(null);
        setShowPopMenu(false);
    };

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
        },
        onSelect: (record, selected, selectedRows) => {
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
        },
    };

    const onAddPanelistClick = () => {
        setEmail('');
        setFirstname('');
        setLastname('');
        setMobile('');
        setComments('');
        setIsCreateModal(true);
        setOpenCreatePanelModal(true);
    };

    const onClickPopMenuItem = (e) => {
        actionOptions[e.target.getAttribute('aria-valuenow')].onClick(e);
    };

    const onSaveCreatePanelist = () => {
        if (!email) {
            enqueueSnackbar('Email Address is required!', { variant: 'warning' });
            return;
        }

        if (!regex.email.test(email) && !IsCreateModal) {
            enqueueSnackbar('Email Address is invalid!', { variant: 'warning' });
            return;
        }

        if (IsCreateModal) {
            createParticipants({
                project_id: ProjectID,
                isCreated: true,
                email: email,
                first_name: firstname,
                last_name: lastname,
                mobile: mobile,
            });
        } else {
            updateParticipants({
                id: currentRowData.current.id,
                isCreated: false,
                project_id: ProjectID,
                email: email,
                first_name: firstname,
                last_name: lastname,
                mobile: mobile,
                comment: comments
            });
        }
    };

    const onResetCreatePanelist = (e) => {
        const closeBtn = e.currentTarget.getAttribute('aria-label') === 'Close';

        if (closeBtn) {
            setOpenCreatePanelModal(false);
        } else {
            setEmail(IsCreateModal ? [] : email);
            setFirstname('');
            setLastname('');
            setMobile('');
            setComments('');
        }
    };

    const _handleSearch = (e) => {
        const text = e.target.value;

        setData({
            ...data,
            filtered: data.original.filter(
                (panelist) =>
                    panelist.first_name.toLowerCase().includes(text.toLowerCase()) ||
                    panelist.last_name.toLowerCase().includes(text.toLowerCase())
            ),
        });
    };

    return (
        <div id="booking-manage-page">
            <Breadcrumb>
                <Breadcrumb.Item>
                    <a href={urls.ADMIN_BOOKING}>Project List</a>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <a href={`${urls.ADMIN_BOOKING_DETAILS}/${ProjectID}`}>Project Schedule</a>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Panelist Details</Breadcrumb.Item>
            </Breadcrumb>
            <PageHeader
                ghost={false}
                title="Panelist Details"
                /*</div>extra={showActions &&[
                    <Button
                        key="1"
                        variant="contained"
                        color="primary"
                        startIcon={<Add />}
                        disableRipple={true}
                        onClick={onAddPanelistClick}
                    >
                        Panelist
                    </Button>,
                ]}*/
                onBack={() => {
                    historyLink.push(`${urls.ADMIN_BOOKING_DETAILS}/${ProjectID}`);
                }}
            ></PageHeader>

            <div className="booking-manage-page-content">
                <div className="booking-manage-page-table-container">
                    <div className="booking-manage-page-table-controls">
                        <div className="booking-manage-page-table-controls-left">
                            <Input
                                className="searchbox"
                                size="middle"
                                prefix={<SearchOutlined />}
                                placeholder="Search"
                                onPressEnter={_handleSearch}
                            />
                        </div>

                        <div className="booking-manage-page-table-controls-right"></div>
                    </div>

                    <Table
                        className="booking-manage-page-table"
                        columns={getColumns()}
                        bordered={false}
                        rowKey={'id'}
                        sticky={true}
                        showHeader={true}
                        tableLayout="fixed"
                        rowSelection={{ ...rowSelection }}
                        pagination={{
                            showTotal: (total, range) => {
                                return `${range[0]}-${range[1]} of ${total} items`;
                            },
                            defaultCurrent: 1,
                            defaultPageSize: 10,
                            pageSizeOptions: [10, 20, 30, 50, 100],
                            showSizeChanger: true,
                            position: ['bottomRight'],
                            total: data.filtered.length,
                        }}
                        dataSource={data.filtered}
                        scroll={{
                            y: '50vh',
                            x: '100%',
                        }}
                    ></Table>
                </div>
            </div>

            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={showPopMenu}
                onClose={onCloseMoreMenu}
                PaperProps={{
                    style: {
                        maxHeight: 216,
                        width: '14ch',
                    },
                }}
            >
                <MenuItem dense={true}>
                    <PopConfirm
                        title="Are you sure you want to delete this panelist?"
                        onConfirm={() => {
                            deleteParticipants(currentRowData.current.id);
                        }}
                    >
                        <ListItemIcon>
                            <DeleteForever fontSize="small" />
                        </ListItemIcon>
                        <Typography variant="inherit">Delete</Typography>
                    </PopConfirm>
                </MenuItem>
                {actionOptions.map((v, i) => (
                    <MenuItem key={i} dense={true} value={i}>
                        <ListItemIcon>{v.icon}</ListItemIcon>
                        <Typography variant="inherit" onClick={onClickPopMenuItem} aria-valuenow={i}>
                            {v.label}
                        </Typography>
                    </MenuItem>
                ))}
            </Menu>

            <Modal
                title={IsCreateModal ? 'Add Panelist' : 'Edit Panelist'}
                width={450}
                visible={openCreatePanelModal}
                centered={true}
                okText="Save"
                onOk={onSaveCreatePanelist}
                cancelText="Reset"
                onCancel={onResetCreatePanelist}
            >
                {!IsCreateModal ? <TextField
                    required={true}
                    label="Email Address"
                    disabled={!IsCreateModal}
                    value={email}
                    onChange={(e) => {
                        setEmail(e.target.value);
                    }}
                    placeholder="Email Address"
                    variant="outlined"
                    fullWidth={true}
                    error={email !== '' && !regex.email.test(email)}
                    helperText={email && !regex.email.test(email) ? 'Invalid format.' : ''}
                />
                    :
                    <EmailFields
                        value={email}
                        onChange={(e) => {
                            setEmail(e);
                        }}
                    />
                }
                {!IsCreateModal &&
                    <>
                        <RegexTextField
                            required={true}
                            label="First Name"
                            regex={regex.charsAndSpaces}
                            value={firstname}
                            onChange={(e) => {
                                setFirstname(e.target.value);
                            }}
                            placeholder="First Name"
                            variant="outlined"
                            fullWidth={true}
                        />
                        <RegexTextField
                            required={true}
                            label="Last Name"
                            regex={regex.charsAndSpaces}
                            value={lastname}
                            onChange={(e) => {
                                setLastname(e.target.value);
                            }}
                            placeholder="Last Name"
                            variant="outlined"
                            fullWidth={true}
                        />
                        <RegexTextField
                            required={false}
                            label="Mobile"
                            regex={regex.mobileNumber}
                            value={mobile}
                            onChange={(e) => {
                                setMobile(e.target.value);
                            }}
                            placeholder="Mobile"
                            variant="outlined"
                            fullWidth={true}
                        />
                        <RegexTextField
                            required={false}
                            label="Comments"
                            value={comments}
                            onChange={(e) => {
                                setComments(e.target.value);
                            }}
                            placeholder="Comments"
                            variant="outlined"
                            fullWidth={true}
                        />
                    </>
                }
            </Modal>

            <Backdrop id="loading-dialog" open={isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
};

export default connect()(BookingPanelistDetailsPage);
