import React, { useState, useRef, Fragment } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { useMount } from 'ahooks';
import PropTypes from 'prop-types';

import { Form, InputNumber, Input } from 'antd';

import { Calendar, DateObject } from 'react-multi-date-picker';
import DatePanel from 'react-multi-date-picker/plugins/date_panel';
import Weekends from 'react-multi-date-picker/plugins/weekends';

import './style.scss';
import VisitConfig from '../../../components/VisitConfig';

const Recurring = (props) => {
    const { form } = props;
    const calendarRef = useRef();
    const [dateMultiple, setDateMultiple] = useState([]);
    const [noWeeksCampaign, setNoWeeksCampaign] = useState(1);
    const [numVisit, setNumVisit] = useState(0);
    const dateMinMax = {
        min: new DateObject(),
    };

    useMount(() => {
        setTimeout(() => _handleRemoveCalendarComma(), 0);
    });

    const _handleRemoveCalendarComma = (month) => {
        let x = ReactDOM.findDOMNode(calendarRef.current);
        const calendarHeader = x.querySelector('.rmdp-header-values span:first-child');

        if (calendarHeader) {
            if (month) {
                const dateObject = new DateObject(month);

                calendarHeader.innerHTML = dateObject.month.name;
            } else {
                calendarHeader.innerHTML = calendarHeader.innerHTML.replace(',', '');
            }
        }
    };

    const onChangeVisit = (e) => {
        let arr = [];

        for (let i = 1; i <= e; i++) {
            arr.push({
                date: null,
                timeStartEnd: [],
                duration: null,
                timeInterval: null,
                panelistPerSlot: 1,
            });
        }

        form.setFieldsValue({ visit_config_rec: arr });
        setNumVisit(arr.length);
    };

    const onCalendarChange = (values) => {
        setDateMultiple(values);
    };

    return (
        <Fragment>
            <div className="sched-details-page-cont">
                <div className="sched-details-page-cont-left">
                    <Form.Item label="Weeks per project" name="weeksPerProject">
                        <InputNumber
                            value={noWeeksCampaign}
                            tabIndex={1}
                            min={1}
                            max={52}
                            onChange={setNoWeeksCampaign}
                        />
                    </Form.Item>

                    <Form.Item label="" name="selectedDays" hidden={noWeeksCampaign === 0} rules={[{ required: true, message: 'select date it\'s required' }]}>
                        <Calendar
                            ref={calendarRef}
                            multiple
                            weekStartDayIndex={0}
                            minDate={dateMinMax.min}
                            value={dateMultiple}
                            onChange={onCalendarChange}
                            onMonthChange={_handleRemoveCalendarComma}
                            mapDays={({ date }) => {
                                let props = {},
                                    isWeekend = [0, 6].includes(date.weekDay.index);

                                if (isWeekend) {
                                    props.className = 'highlight highlight-red';
                                }

                                return props;
                            }}
                            plugins={[<DatePanel sort="date" />, <Weekends weekends={[0, 6]} />]}
                        />
                    </Form.Item>

                    <Form.Item label="Location" name="location" rules={[{ required: false, message: '' }]}>
                        <Input tabIndex={4} showCount maxLength={255} />
                    </Form.Item>
                </div>

                <div className="sched-details-page-cont-right">
                    <Form.Item
                        label="Visits per slot"
                        name="visits_per_slot"
                        rules={[{ required: true, message: 'Weeks per project is required' }]}
                    >
                        <InputNumber value={numVisit} onChange={onChangeVisit} tabIndex={3} min={0} max={1000} />
                    </Form.Item>
                    <Form.Item label="" name="visit_config_rec">
                        <VisitConfig IsRecurring={true} />
                    </Form.Item>
                </div>
            </div>
        </Fragment>
    );
};

Recurring.defaultProps = {};

Recurring.propTypes = {
    form: PropTypes.object,
};

export default connect()(Recurring);
