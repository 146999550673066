import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Col, Row , Select ,InputNumber, DatePicker, TimePicker , Input, Form ,Modal, Space} from 'antd';
import './index.scss';
import _ from 'lodash';
import moment from 'moment';



const MOMENTFORMAT = {
    time: 'hh:mm A',
    time24: 'HH:mm',
    date: 'DD MMM YYYY',
};
const AddNewTimeSlot = (props) => {

    const [form] = Form.useForm();
    const {IsRecurring , totalWeeks , VisibleAddNewTimeSlot , onCancelNewTimeSlot ,onCreateNewTimeSlot} = props
    
    const [selectVisit , setSelectVisit] = useState(null)
    const [location , setLocation] = useState(null)
    const [selectTime , setSelectTime] = useState([])
    const [duration , setDuration] = useState(0)
    const [timeInterval , setTimeInterval] = useState(0)
    const [panelistPerSlot , setPanelistPerSlot] = useState(1)
    const [selectDate , setSelectDate] = useState([])

    const onSubmitValues = (values) => {        
        if(values) {
            onCreateNewTimeSlot(values)
        }
    }
    
    const onSelect = (e) => {
        setSelectVisit(e)
    }
    const onChangeLocation = (e) => {
        setLocation(e.target.value)
    }
    const onChangeDate = (e , w) => {
        setSelectDate(e)
    }
    const onChangeTimeSlot = (e,w) => {
        setSelectTime(w)
    }
    const onChangeDuration = (e) => {
        setDuration(e)
    }
    const onChangeTimeInt = (e) => {
        setTimeInterval(e)
    }
    const onChangePanelSlot = (e) => {
        setPanelistPerSlot(e)
    }
    const disabledDate = (d) => {
        return !d.isAfter(moment().subtract(1, 'd'));
    };
    const datePickerValue = (arrTime) => {
        if (_.isArray(arrTime) === true && _.size(arrTime) === 2) {
            return [moment(arrTime[0], MOMENTFORMAT.time24), moment(arrTime[1], MOMENTFORMAT.time24)];
        }

        return [];
    };

    return (
        <>
      {!IsRecurring  &&
        <Modal
                title ="Add new Time Slot(s) to an existing visit"
                width = {480}
                visible={VisibleAddNewTimeSlot}
                centered={true}
                destroyOnClose={true}
                okText={`Add Time Slot`}
                cancelText="Cancel"
                className='ant-modal-add-slots'
                onCancel={onCancelNewTimeSlot}
                onOk={
                    async() => { 
                        form.submit()
                    }
                   
                }
        >
                <Form
                    name="AddNewVists"
                    form={form}
                    layout="vertical"
                    onFinish={onSubmitValues}
                    initialValues={{panelistPerSlot : 1}}
                >
                    <div id="add-new-slots">
                        <Row gutter={[24,16]}>
                            <Col span={12}>
                                <Form.Item  label={'Select Visit'} name="select_visit" rules={[{ required: true, message: 'Visit is required' }]}>
                                    <Select 
                                        value={selectVisit} 
                                        placeholder="Select Visit"
                                        allowClear={true} 
                                        onChange={onSelect}
                                        style={{ width: '100%' }}
                                        options={totalWeeks}
                                    >
                                    </Select>
                                </Form.Item>
                            </Col>
                        <Col span={12}>
                            <Form.Item label={'Select Date'} name="date" rules={[{ required: true, message: 'Date is required' }]}>
                                <DatePicker
                                    name={'date'}
                                    size={'default'}
                                    disabledDate={disabledDate}
                                    format={MOMENTFORMAT.date}
                                    value={
                                        ![null, NaN].includes(selectDate)
                                            ? moment(selectDate, MOMENTFORMAT.date)
                                            : null
                                    }
                                    inputReadOnly={true}
                                    onChange={onChangeDate}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label={'Select Time'} name="timeStartEnd" rules={[{ required: true, message: 'Time is required' }]}>
                                <TimePicker.RangePicker
                                    name={'Time'}
                                    value={datePickerValue(selectTime)}
                                    format={MOMENTFORMAT.time24}
                                    inputReadOnly={false}
                                    onChange={onChangeTimeSlot}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                        <Form.Item label={'Duration'} name="duration" rules={[{ required: true, message: 'duration is required' }]}>
                                    <InputNumber
                                        placeholder="Duration"
                                        value={duration}
                                        min={1}
                                        max={1000}
                                        onChange={onChangeDuration}
                                        style={{ width: '100%' }}
                                    />
                        </Form.Item>
                        </Col>
                        <Col span={12}>
                        <Form.Item label={'Time Interval'} name="timeInterval" rules={[{ required: true, message: 'Time Interval is required' }]}>
                                    <InputNumber
                                        placeholder="Time Interval"
                                        value={timeInterval}
                                        min={1}
                                        max={1000}
                                        onChange={onChangeTimeInt}
                                        style={{ width: '100%' }}
                                    />
                        </Form.Item>
                        </Col>
                        <Col span={24}>
                        <Form.Item label={'Location'} name="location" rules={[{ required: false, message: '' }]}>
                            <Input
                                name={'location'}
                                value={location}
                                onChange={onChangeLocation}
                                showCount={255}
									maxLength={255}
                                
                            />
                         </Form.Item>
                         </Col>
                         <Col span={24}>
                        <Form.Item  label="Panelist per slot" name="panelistPerSlot">
                            <InputNumber
                                value={panelistPerSlot}
                                min={0}
                                max={1000}
                                onChange={onChangePanelSlot}
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                        </Col>
                     </Row>
                    </div>  
                </Form>

        </Modal>
     }
     </>
    );
};

export default connect()(AddNewTimeSlot);
