import React, {useState, Fragment, useRef} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useMount, useUpdateEffect } from 'ahooks';
import { useSnackbar } from 'notistack';
import { Input , Form } from 'antd';
import { Button, Paper, Dialog, DialogContent, IconButton, AppBar, Typography, Toolbar , Backdrop , CircularProgress} from '@material-ui/core';
import {CopyToClipboard} from 'react-copy-to-clipboard';

import { Add, Close } from '@material-ui/icons';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import UploadFile from '../../../components/UploadFile';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './style.scss';

import EmailTemplatesDropdown from '../../../components/EmailTemplatesDropdown'
import templateFormat from './templateFormat';
import Modal from 'antd/lib/modal/Modal';

import api from '../../../library/api';
import urls from '../../../utility/urls';
import {v4 as uuidv4} from 'uuid'

const StepFour = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    const { step, onBack, onSubmit, defaultValue, onSubmitDraft , form,isLoading , files  , savedFiles  ,setFiles , UploadFiles} = props;

    const projectData = useRef(defaultValue);
    const emailTemplateContentObj = useRef({
        subject: 'Invitation to Book Your Time Slot ',
        emailContent: '',
    });
    const [emailSubject, setEmailSubject] = useState('');
    const [emailEditor, setEmailEditor] = useState(false);
    const [editorState, setEditorState] = useState(EditorState.createWithContent(convertFromRaw(templateFormat)));
    const [customTemplate , setCustomsTemplate] = useState([])
    const [templateName,setTemplateName]= useState('')
    const [showModalTemplateName,setShowModalTemplateName] = useState(false)
    const [isChanged , setisChanged] = useState(false)
    const [isSaved , setIsSaved] = useState(false)
    const [idCopyProject , setIdCopyProject] = useState(null)
    const [copyAdminUrl, setCopyAdminUrl] = useState({
        value : '',
        copied: false,
    });
    const [copyPanelistUrl, setCopyPanelistUrl] = useState({
        value : '',
        copied: false,
    });

    //modal invition 
    const [showModalPublish,setShowModalPublish] = useState(false)

    useMount(() => {
        setEmailSubject(`${emailTemplateContentObj.current.subject} <Project Name>, <Project ID>`);
        projectData.current.emailTemplate = emailTemplateContentObj.current;
        setIdCopyProject(uuidv4())
    });
    useUpdateEffect(() => {
        emailTemplateContentObj.current.subject = emailSubject;
    }, [emailSubject]);

    useUpdateEffect(() => {
        if(emailEditor) {
            api.email
            .getEmailTemplates()
            .then(res => {
                if(res.status === "success") {
                   const updateData = res.data.map(item =>  {
                         return {  
                            name : item.name,
                            value :  EditorState.createWithContent(convertFromRaw(JSON.parse(item.email_content))),
                        }
                   })
                   setCustomsTemplate(updateData)
                }               
            }).catch(err => {
            })
        }
    },[emailEditor])
    const onSave = (e) => {
        if(isChanged) { 
            projectData.current.has_custom_template = 1;
            emailTemplateContentObj.current.emailContent = convertToRaw(editorState.getCurrentContent());
            projectData.current.emailTemplate = emailTemplateContentObj.current;
        }
        projectData.current.isSaved = isSaved
        projectData.current.is_published = true;
        onSubmit(projectData.current);
    };
    const onSaveCopyProject = (e) => {
        projectData.current.is_published = true;
        projectData.current.isCopyUrlProject = true
        projectData.current.idprojectUuid = idCopyProject
        onSubmit(projectData.current);
    };
    const onSaveTemplateName =() => {
            if(templateName !== '' || templateName !== null) {
                projectData.current.template_name = templateName
                emailTemplateContentObj.current.emailContent = convertToRaw(editorState.getCurrentContent());
                projectData.current.emailTemplate = emailTemplateContentObj.current;
                projectData.current.has_custom_template = 1;
                enqueueSnackbar('Successfully Saved Template', {
                     variant: 'success',
                     autoHideDuration: 2000,
                    });
                setIsSaved(true)
                setShowModalTemplateName(false)
            }else {
                enqueueSnackbar('name is required' , {variant:'warning'})
            }       
    }

    const onSaveDraft = (e) => {
        projectData.current.is_published = false;
        onSubmitDraft(projectData.current);
    };

    const onSubjectChange = (e) => {
        setEmailSubject(e.target.value);
    };

    const onOpenEditEmailTemplate = (e) => {
        setEmailEditor(true);
        setShowModalPublish(false)
    };

    const onCLoseEditEmailTemplate = () => {
        setEmailEditor(false);
    };

    const onSaveEmailTemplate = () => {
        setShowModalTemplateName(true)
    };

    const onEditorStateChange = (e) => {
        setisChanged(true)
        emailTemplateContentObj.current.emailContent = convertToRaw(e.getCurrentContent());
        setEditorState(e);
    };
    const _handleBack = () => {
        onBack(projectData.current);
    };
    const onSetFiles = (selectedFiles) => {
        let array = [] ; 
        if(selectedFiles.length === 0) {
            setFiles(array)
        }else {
            setFiles(selectedFiles)
        }
    }
    const onChangeTemplateName = (e) => {
        setTemplateName(e.target.value)
    }

    const setTempalteProps = (name) => { 
        setTemplateName(name)
    }
    return (
        <Fragment>
            <Paper className="paper-cont" elevation={0}>
                <div className="paper-cont-bar"></div>
                <div className="paper-cont-content">
                    <div className="proj-create-page-confirmation">
                        <h2>
                            Project information is complete <br /> Do you want to send the invitation now?
                        </h2>
                        <div className="proj-create-page-confirmation-btn">
                            <Button
                                tabIndex={1}
                                className="btn-next"
                                variant="contained"
                                color="primary"
                                fullWidth={false}
                                onClick={() =>   setShowModalPublish(true)}
                            >
                                Yes
                            </Button>
                            <Button
                                tabIndex={2}
                                variant="contained"
                                color="primary"
                                fullWidth={false}
                                onClick={onSaveDraft}
                            >
                                No, Save as Draft
                            </Button>
                        </div>
                    </div>
                </div>
            </Paper>
            { 
            showModalPublish &&  ( 
            <Modal 
                title = 'How do you want to share the project?'
                width = {600}
                visible={showModalPublish}
                centered={true}
                destroyOnClose={true}
                footer={null}
                keyboard={false}
                onCancel={()=>   setShowModalPublish(false)}
            > 
                <CopyToClipboard text={api.utils.getURL(`${urls.ADMIN_BOOKING_DETAILS}/${idCopyProject}`)} onCopy={() =>
                        {
                            setCopyAdminUrl({value : api.utils.getURL(`${urls.ADMIN_BOOKING_DETAILS}/${idCopyProject}`), copied: true })
                            onSaveCopyProject()
                        }}
                >
                    <Button variant="contained" style={{background :'#3f51b5',color : 'white', width : '300px', marginLeft : '20px', marginTop :'20px'}} startIcon={<Add />}>
                        copy Project Link for Admin
                    </Button>
                </CopyToClipboard>
                {copyAdminUrl.copied && <div  className="link-copied">Link to '{projectData.current.projName}' copied</div>}
                <CopyToClipboard 
                        text={api.utils.getURL(`${urls.PARTICIPANTS_HOME}/${idCopyProject}`)} onCopy={() =>
                        {
                            setCopyPanelistUrl({value : api.utils.getURL(`${urls.PARTICIPANTS_HOME}/${idCopyProject}`), copied: true })
                            onSaveCopyProject()
                        }}>
                    <Button variant="contained" style={{background :'#3f51b5',color : 'white',width : '300px', marginTop :'20px'}} startIcon={<Add />}>
                        copy Project Link for Panelist
                    </Button>
                </CopyToClipboard>
                {copyPanelistUrl.copied && <div  className="link-copied">Link to '{projectData.current.projName}' copied</div>}
                <Button variant="contained" startIcon={<Add />} onClick={() => onOpenEditEmailTemplate()} style={{marginTop :'20px'}}>
                    Invite by OntheDot email editor
                </Button>
             </Modal>
            )}
            <Dialog id="email-editor-dialog" style={{zIndex : 200}} fullScreen open={emailEditor} disableEnforceFocus={true} onClose={onCLoseEditEmailTemplate}>
                <AppBar className="email-editor-dialog-appbar">
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={onCLoseEditEmailTemplate} aria-label="close">
                            <Close />
                        </IconButton>
                        <Typography variant="h6" className="email-editor-dialog-appbar-header">
                            Email Template Editor
                        </Typography>
                        <Button autoFocus color="inherit" onClick={onSaveEmailTemplate}>
                            Save
                        </Button>
                        <Button tabIndex={1} className="btn-next" variant="contained" color="inherit" onClick={onSave}>
                                Send
                        </Button>
                    </Toolbar>
                </AppBar>

                <DialogContent dividers={true} className="email-editor-dialog-content">
                    <Input
                        value={emailSubject}
                        className="email-editor-subject"
                        onChange={onSubjectChange}
                        placeholder="Subject"
                    />
                    <Editor
                        toolbar={{
                            options: ['inline','fontFamily', 'fontSize', 'list', 'textAlign', 'colorPicker','history','link'],
                            inline: {
                                options: ['bold','italic', 'underline', 'strikethrough', 'monospace', 'superscript', 'subscript'],
                            },
                            fontFamily: {
                                options: ['Arial','Calibri','Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana','montserratregular'],
                            },
                            link:{
                                inDropdown: false,
                                className: undefined,
                                component: undefined,
                                popupClassName: undefined,
                                dropdownClassName: undefined,
                                showOpenOptionOnHover: true,
                                defaultTargetOption: '_self',
                                options: ['link', 'unlink'],
                            },
                            list: {
                                options: ['unordered', 'indent', 'outdent']
                            },
                            history: {
                                options: ['undo', 'redo'],
                            },
                        }}


                        toolbarCustomButtons={[<EmailTemplatesDropdown  templates={customTemplate} setTemplatename={setTempalteProps} name={templateName} />]}
                        editorState={editorState}
                        wrapperClassName="email-editor-cont"
                        editorClassName="email-editor-wysywyg"
                        onEditorStateChange={onEditorStateChange}
                    />
                    <UploadFile onSetFiles={onSetFiles} />
                    <Modal   title ="Add Name for Template"
                        visible={showModalTemplateName}
                        centered={true}
                        destroyOnClose={true}
                        okText="Ok"
                        onOk={ async() => { 
                            form.submit()
                        }}
                        onCancel={() => setShowModalTemplateName(false)}
                        >
                             <Form
                                name="Add Name for template"
                                form={form}
                                layout="vertical"
                                onFinish={onSaveTemplateName}
                            >
                                <div>
                                    <Form.Item label={'Template Name'} name="Template Name" hasFeedback rules={[{ required: true,max : 20}]}>
                                        <Input  placeholder="enter template name" value={templateName} onChange={onChangeTemplateName}/>
                                    </Form.Item>
                                </div>
                            </Form>
                    </Modal>
                    <Backdrop id="loading-dialog" open={isLoading}>
                        <CircularProgress color="inherit"  />
                    </Backdrop>
                </DialogContent>
            </Dialog>
            <div className="proj-create-page-content-controls">
                <Button className="btn-back" variant="contained" disabled={step === 0} onClick={_handleBack}>
                    Back
                </Button>
            </div>
        </Fragment>
    );
};

StepFour.defaultProps = {};

StepFour.propTypes = {
    defaultValue: PropTypes.object.isRequired,
    onSubmitDraft: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default connect()(StepFour);
