const template = {
    blocks: [
        {
            key: '637gr',
            text: 'Dear <Panelist>,',
            type: 'unstyled',
            depth: 0,
            inlineStyleRanges: [
                { offset: 0, length: 16, style: 'fontfamily-Calibri' },
                { offset: 0, length: 16, style: 'fontsize-14' },
                { offset: 5, length: 10, style: 'color-rgb(44,130,201)' },
            ],
            entityRanges: [],
            data: {},
        },
        {
            key: '8aihd',
            text: 'Thank you for your interest in joining this <Project Name>, <Project ID>',
            type: 'unstyled',
            depth: 0,
            inlineStyleRanges: [
                { offset: 0, length: 72, style: 'fontfamily-Calibri' },
                { offset: 0, length: 72, style: 'fontsize-14' },
                { offset: 44, length: 14, style: 'color-rgb(44,130,201)' },
                { offset: 60, length: 12, style: 'color-rgb(44,130,201)' },
            ],
            entityRanges: [],
            data: {},
        },
        {
            key: 'e71l3',
            text: 'To confirm your participation, please submit your booking now.',
            type: 'unstyled',
            depth: 0,
            inlineStyleRanges: [
                { offset: 0, length: 62, style: 'fontfamily-Calibri' },
                { offset: 0, length: 62, style: 'fontsize-14' },
            ],
            entityRanges: [],
            data: {},
        },
        {
            key: '9v4c2',
            text: '<Appointment Link>',
            type: 'unstyled',
            depth: 0,
            inlineStyleRanges: [
                { offset: 0, length: 18, style: 'color-rgb(44,130,201)' },
                { offset: 0, length: 18, style: 'fontfamily-Calibri' },
                { offset: 0, length: 18, style: 'fontsize-14' },
            ],
            entityRanges: [],
            data: {},
        },
        {
            key: 'emgbo',
            text: 'Ensure that you read the booking instructions carefully and provide your correct contact details.',
            type: 'unstyled',
            depth: 0,
            inlineStyleRanges: [
                { offset: 0, length: 97, style: 'fontfamily-Calibri' },
                { offset: 0, length: 97, style: 'fontsize-14' },
            ],
            entityRanges: [],
            data: {},
        },
        {
            key: '8o0r6',
            text: 'To help us identify that you are the correct panelist, please use this same email account <Panelist Email> when making your booking.',
            type: 'unstyled',
            depth: 0,
            inlineStyleRanges: [
                { offset: 0, length: 132, style: 'fontfamily-Calibri' },
                { offset: 0, length: 132, style: 'fontsize-14' },
                { offset: 90, length: 16, style: 'color-rgb(44,130,201)' },
            ],
            entityRanges: [],
            data: {},
        },
        {
            "key": "5tkfv",
            "text": "Make sure to bookmark https://onthedot.pg.com/panelist for ease of logging in.",
            "type": "unstyled",
            "depth": 0,
            "inlineStyleRanges": [
                {
                    "offset": 0,
                    "length": 78,
                    "style": "color-rgba(0,0,0,0.9)"
                },
                {
                    "offset": 0,
                    "length": 78,
                    "style": "bgcolor-rgb(255,255,255)"
                },
                {
                    "offset": 0,
                    "length": 78,
                    "style": "fontfamily-Calibri"
                },
                {
                    "offset": 0,
                    "length": 78,
                    "style": "fontsize-14"
                },
                {
                    "offset": 0,
                    "length": 78,
                    "style": "BOLD"
                }
            ],
            "entityRanges": [],
            "data": {
                "text-align": "start"
            }
        },
        {
            "key": "be6i1",
            "text": "This is an unmonitored email, please do not reply. Contact your research/project organizer directly if you need further assistance. ",
            "type": "unstyled",
            "depth": 0,
            "inlineStyleRanges": [
                {
                    "offset": 0,
                    "length": 131,
                    "style": "color-rgba(0,0,0,0.9)"
                },
                {
                    "offset": 0,
                    "length": 131,
                    "style": "bgcolor-rgb(255,255,255)"
                },
                {
                    "offset": 0,
                    "length": 132,
                    "style": "fontfamily-Calibri"
                },
                {
                    "offset": 0,
                    "length": 132,
                    "style": "fontsize-14"
                },
                {
                    "offset": 0,
                    "length": 132,
                    "style": "BOLD"
                }
            ],
            "entityRanges": [],
            "data": {}
        }
    ],
    entityMap: {},
};

export default template;
