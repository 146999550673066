import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { useMount, useUpdateEffect } from "ahooks";
import { Promise } from "bluebird";
import moment from "../../library/moment";
import _ from "lodash";
import ordinal from "ordinal";
import codify from "../../../src/library/codify";

import {
  PageHeader,
  Table,
  Select,
  Descriptions,
  Breadcrumb,
  Modal,
  Tooltip,
  Tag,
  Button,
  DatePicker,
} from "antd";

import { Backdrop, IconButton, CircularProgress } from "@material-ui/core";
import { DeleteForever, Schedule } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import urls from "../../utility/urls";
import { errorMapper } from "../../utility";
import api from "../../library/api";

import "./style.scss";

import BookingsPreview from "../BookingSupport/BookingsPreview";
import PopConfirm from "../../components/PopConfirm";
import AuthHelper from "../../library/helpers/AuthHelper";
import { ROLES } from "../../utility/constant";
import swal from "sweetalert";

const DefaultPanelist = {
  id: "",
  email: "",
  first_name: "",
  last_name: "",
  mobile: "",
  comment: "",
};

const statusColor = {
  open: "green",
  booked: "blue",
};
class Dropdown extends React.PureComponent {
  handleChange = (value) => {
    if (value) {
      this.props.setSelectedKeys([value]);
      setTimeout(() => this.props.confirm(), 10);
    } else {
      this.props.setSelectedKeys([]);
      setTimeout(() => this.props.confirm(), 10);
    }
  };
  render() {
    return (
      <DatePicker.RangePicker
        format="YYYY-MM-DD"
        placeholder={["from date", "to date"]}
        onChange={this.handleChange}
        ranges={{
          Today: [moment(), moment()],
          "2 days": [
            moment().subtract(2, "days").startOf("day"),
            moment().endOf("day"),
          ],
          "This Month": [moment().startOf("month"), moment().endOf("month")],
        }}
      />
    );
  }
}

const BookingDetailsPage = (props) => {
  let { projectId } = useParams();
  const history = useHistory();

  const projectObj = useRef({
    project: {},
    appointments: {},
  });
  const bookingObj = useRef({
    panelist: DefaultPanelist,
    schedules: [],
  });
  const reschedObj = useRef({
    selected: {},
    api: {
      to_appointment_id: "",
      from_appointment_id: "",
      participant_id: "",
      comments: "",
    },
  });

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [columns, setColumns] = useState([]);
  const [totalWeeks, setTotalWeeks] = useState([]);
  const [isRecurring, setIsRecurring] = useState(false);
  const [idPanelist, setIdPanelist] = useState(null);
  // const [showPanelistInfo, setShowPanelistInfo] = useState(false);
  // const [showBookingInfo, setShowBookingInfo] = useState(false);
  const [showReschedule, setShowReschedule] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [locationFilter, setLocationFilter] = useState([]);
  const [detailsFilter, setDetailsFilter] = useState([]);
  const [visitColumns, setVisitColumns] = React.useState(false);
  const [numberOfBooked, setNumberOfBooked] = useState(0)
  const [iSBeforeNowDate, setISBeforeNowDate] = useState(false);
  const [showModalRemoveAll, setShowModalRemoveAll] = useState(false);
  const [contactAdmins, setContactAdmins] = useState(false);

  const auth = new AuthHelper();
  const { enqueueSnackbar } = useSnackbar();

  useMount(() => {
    fetchData(projectId);
  });

  useUpdateEffect(() => {
    if (setSelectedRowKeys.length > 1) {
    }
  }, [setSelectedRowKeys]);

  useUpdateEffect(() => {
    setColumns(columnsProvider(visitColumns, isRecurring));
  }, [locationFilter, detailsFilter]);

  useUpdateEffect(() => {
    fetchData(projectId);
  }, [idPanelist]);

  const processedRawData = (proj, appnt, pane) => {
    const visitColumns = appnt.length > 0 ? appnt[0].visit.length : 0,
      totalDays = Math.abs(
        proj.project_end_date.diff(proj.project_start_date, "days")
      ),
      weeksTotal = totalDays > 0 ? Math.ceil(totalDays / 7) : 1,
      is_recurring = proj.is_recurring;

    setIsRecurring(proj.is_recurring);

    //sort date
    appnt.sort((a, b) => a.date.diff(b.date));
    //sort time
    appnt.sort((a, b) => {
      return moment(
        moment.utc(a.date).local().format("YYYY-MM-DD") +
        " " +
        moment.utc(a.visit[0].start_time).local().format("HH:mm:ss")
      ).diff(
        moment(
          moment.utc(b.date).local().format("YYYY-MM-DD") +
          " " +
          moment.utc(b.visit[0].start_time).local().format("HH:mm:ss")
        )
      ) != 0
        ? moment(
          moment.utc(a.date).local().format("YYYY-MM-DD") +
          " " +
          moment.utc(a.visit[0].start_time).local().format("HH:mm:ss")
        ).diff(
          moment(
            moment.utc(b.date).local().format("YYYY-MM-DD") +
            " " +
            moment.utc(b.visit[0].start_time).local().format("HH:mm:ss")
          )
        )
        : moment(
          moment.utc(a.date).local().format("YYYY-MM-DD") +
          " " +
          moment.utc(a.visit[0].end_time).local().format("HH:mm:ss")
        ).diff(
          moment(
            moment.utc(b.date).local().format("YYYY-MM-DD") +
            " " +
            moment.utc(b.visit[0].end_time).local().format("HH:mm:ss")
          )
        );
    });

    const checkedISBefore = appnt.filter(i => pane.id === i.participant?.id || null)
    const arrChecked = checkedISBefore.map((item) => {
      if (moment.utc(item.date).isBefore(moment())) {
        return true
      } else {
        return false
      }
    })
    const containsTrue = arrChecked.includes(true);

    setNumberOfBooked(
      checkedISBefore
    )

    setISBeforeNowDate(
      containsTrue
    )

    let totalWeeksArr = [];

    if (is_recurring) {
      for (let i = 0; i < weeksTotal; i++) {
        totalWeeksArr.push({
          label: `${ordinal(i + 1)} Week`,
          value: i + 1,
        });
      }
    } else {
      for (let i = 0; i < proj.projectsched.length; i++) {
        totalWeeksArr.push({
          label: `${ordinal(i + 1)} Visit`,
          value: i + 1,
        });
      }
    }

    setTotalWeeks(totalWeeksArr);
    let arrLocationFilter = [];
    let arrDetailsFilter = [];

    _.each(appnt, (v, i) => {
      const weeks = Math.abs(v.date.diff(proj.project_start_date, "weeks")) + 1;
      appnt[i].week = weeks;

      const indexSched = _.findIndex(proj.projectsched, (o) => {
        return o.id === v.projectsched_id;
      });

      appnt[i].visits =
        indexSched !== -1 ? proj.projectsched[indexSched].visit_number : -1;
      if (appnt[i].location !== "") {
        arrLocationFilter.push({
          text: appnt[i].location,
          value: appnt[i].location,
        });
      }
      if (appnt[i].details !== "") {
        arrDetailsFilter.push({
          text: appnt[i].details,
          value: appnt[i].details,
        });
      }
    });
    setVisitColumns(visitColumns);
    projectObj.current.project = proj;
    projectObj.current.appointments = appnt;
    setColumns(columnsProvider(visitColumns, is_recurring));
    let userDetails = JSON.parse(
      codify.decode(window.localStorage.getItem("profile_user"))
    );
    let fullName = "";
    fullName = userDetails?.first_name + " " + userDetails?.last_name;
    setData(
      appnt.filter(
        (iter) =>
          iter.panelist.trim().length > 0 &&
          iter.panelist.trim().toLowerCase() === fullName.toLowerCase()
      )
    );
    var resultLocation = arrLocationFilter.reduce((unique, o) => {
      if (!unique.some((obj) => obj.text === o.text && obj.value === o.value)) {
        unique.push(o);
      }
      return unique;
    }, []);
    var resultDeatils = arrDetailsFilter.reduce((unique, o) => {
      if (!unique.some((obj) => obj.text === o.text && obj.value === o.value)) {
        unique.push(o);
      }
      return unique;
    }, []);
    setLocationFilter(resultLocation);
    setDetailsFilter(resultDeatils);
  };

  const fetchData = (id) => {
    setIsLoading(true);
    Promise.all([
      api.projects.fetchById(id),
      api.appointments.panelist.fetchAppointmentsByProjectId(id),
      api.appointments.panelist.fetchParticipantByEmail(
        auth.getUserDetails().id || ""
      ),
    ])
      .then((d) => {
        setIsLoading(false);
        if (
          d[0].messages === "success" &&
          d[1].messages === "success" &&
          d[2].messages === "success"
        ) {
          setIdPanelist(d[2].data.id);
          processedRawData(d[0].data, d[1].data, d[2].data);
        } else {
          enqueueSnackbar(
            errorMapper(d[0].messages) + errorMapper(d[1].messages),
            { variant: "warning" }
          );
        }
      })
      .catch((e) => {
        setIsLoading(false);
        enqueueSnackbar(
          "An error occurred while getting appointments. Please contact system admin for help.",
          { variant: "error" }
        );
      });
  };

  // const bookedPanelist = (data) => {
  //     const defaultData = {
  //         project_id: projectId,
  //         appointment_id: "",
  //         participant_id: data.panelist.id,
  //         email: data.panelist.email,
  //         first_name: data.panelist.first_name,
  //         last_name: data.panelist.last_name,
  //         mobile: data.panelist.mobile,
  //         comment: data.panelist.comment
  //     };

  //     let arrPromise = [];

  //     _.each(data.schedules, (v) => {
  //         const itemData = defaultData;

  //         itemData.appointment_id = v.id;
  //         arrPromise.push(api.appointments.book(itemData));
  //     });

  //     setIsLoading(true);

  //     Promise.mapSeries(arrPromise, (arr, index, arrLength) => {
  //         return arr;
  //     })
  //         .then((d) => {
  //             setIsLoading(false);
  //             fetchData(projectId);
  //         })
  //         .catch((e) => {
  //             setIsLoading(false);
  //             enqueueSnackbar("Server error!", { variant: 'error' });
  //         });
  // };

  const cancelAppointment = (id) => {
    setIsLoading(true);

    api.appointments.panelist
      .cancelAppointment(id)
      .then((d) => {
        setIsLoading(false);
        if (d.messages === "success") {
          fetchData(projectId);
          enqueueSnackbar("Schedule cancelled", { variant: "success" });
        } else {
          swal({ text: d.messages, icon: "warning" });
        }
      })
      .catch((e) => {
        setIsLoading(false);
        enqueueSnackbar(
          "An error occurred while canceling appointment. Please contact system admin for help.",
          { variant: "error" }
        );
      });
  };
  const cancelMultipleAppointment = (arrID) => {
    //multiple cancle appoitment
    setIsLoading(true);
    let arr = []
    _.each(arrID, (v) => {
      arr.push(v.id)
    })
    api
      .appointments
      .panelist
      .multipleCancelAppointment(arr, projectId)
      .then((d) => {
        setIsLoading(false);
        if (d.messages === "success") {
          enqueueSnackbar("Schedule cancelled", { variant: "success" });
          fetchData(projectId);
          setShowModalRemoveAll(false)
          history.push(`${urls.USER_PROJECT_LIST}`)
        } else {
          swal({ text: d.messages, icon: "warning" });
        }
      })
      .catch((e) => {
        setIsLoading(false);
        setShowModalRemoveAll(false)
        enqueueSnackbar(
          "An error occurred while canceling appointment. Please contact system admin for help.",
          { variant: "error" }
        );
      });
  }

  const reschedAppointment = (data) => {
    setIsLoading(true);

    api.appointments.panelist
      .rescheduleAppointment(data)
      .then((d) => {
        setIsLoading(false);

        if (d.messages === "success") {
          history.push(
            `${urls.PARTICIPANTS_SCHEDULE_DETAIL}/${data.to_appointment_id}`
          );
          enqueueSnackbar("Reschedule successfull", { variant: "success" });
        } else {
          swal({ text: errorMapper(d.messages), icon: "warning" });
        }
      })
      .catch((e) => {
        setIsLoading(false);
        enqueueSnackbar(
          "An error occurred while rescheduling appointment. Please contact system admin for help.",
          { variant: "error" }
        );
      });
  };

  const onCancelSchedule = (rec) => {
    if (numberOfBooked && numberOfBooked.length <= 1) {
      cancelAppointment(rec.id);
    } else if (numberOfBooked && numberOfBooked.length > 1 && iSBeforeNowDate) {
      setContactAdmins(true)
    } else {
      setShowModalRemoveAll(true)
    }
  };

  const onReSchedule = (rec) => {
    reschedObj.current.selected = rec;
    setShowReschedule(true);
  };

  useEffect(() => {
    document.title = "OnTheDot Panelist Portal";
  }, []);

  const columnsProvider = (visitNum, recurring) => {
    let infoArr = [
      {
        title: recurring ? "Week" : "Visits",
        align: "left",
        width: 100,
        dataIndex: recurring ? "week" : "visits",
        key: recurring ? "week" : "visits",
        render: (text, record) => {
          return recurring
            ? `${ordinal(text)} Week`
            : `${ordinal(record.visits)} Visit`;
        },
      },
      {
        title: "Date",
        align: "center",
        width: 140,
        dataIndex: "date",
        key: "date",
        defaultSortOrder: "ascend",
        sorter: {
          compare: (a, b) => {
            if (a.date.isBefore(b.date, "day")) {
              return -1;
            }

            if (a.date.isAfter(b.date, "day")) {
              return 1;
            }

            return 0;
          },
          multiple: 2,
        },
        filterDropdown: (props) => <Dropdown {...props} />,
        onFilter: (value, item) => {
          if (value) {
            const [from, to] = value;
            if (!from || !to) return true;
            if (to.isBefore(item.date, "day")) {
              return false;
            }
            if (from.isAfter(item.date, "day")) {
              return false;
            }
            return true;
          } else {
            return true;
          }
        },
        sortDirections: ["descend", "ascend"],
        render: (text, record) => {
          return moment(text).format(api.utils.MOMENTFORMAT.date);
        },
      },
      /*{
                  title: 'Item',
                  align: 'center',
                  width: 140,
                  dataIndex: 'item',
                  key: 'item',
                  sorter : {
                      compare : (a,b) => a.item - b.item,
                      multiple : 1
                  },
                  sortDirections: ['descend', 'ascend'],
              },*/
      {
        title: "Detail",
        align: "center",
        width: 140,
        dataIndex: "details",
        key: "details",
        filters: detailsFilter,
        onFilter: (value, record) => record.details.startsWith(value),
        filterSearch: (input, record) => record.details.indexOf(input) > -1,
        filterMode: "tree",
        sorter: {
          compare: (a, b) => {
            const idA = (a.details || "").toUpperCase();
            const idB = (b.details || "").toUpperCase();

            if (idA < idB) {
              return -1;
            }

            if (idA > idB) {
              return 1;
            }

            return 0;
          },
          multiple: 2,
        },
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Full Name",
        align: "center",
        width: 150,
        dataIndex: "participant",
        key: "participant",
        render: (text, record) => {
          let fn = "",
            ln = "";
          if (record.participant_id === idPanelist) {
            if (text) {
              fn = text.first_name || "";
              ln = text.last_name || "";

              if (fn.length > 0 || ln.length > 0) {
                return `${fn} ${ln}`;
              } else {
                return `${text.email || ""}`;
              }
            }
          }

          return "";
        },
      },
    ],
      actionArr = [
        {
          title: "Location",
          align: "center",
          width: 300,
          dataIndex: "location",
          key: "location",
          sorter: {
            compare: (a, b) => {
              const idA = (a.location || "").toUpperCase();
              const idB = (b.location || "").toUpperCase();

              if (idA < idB) {
                return -1;
              }

              if (idA > idB) {
                return 1;
              }

              return 0;
            },
            multiple: 2,
          },
          sortDirections: ["descend", "ascend"],
          filters: locationFilter,
          onFilter: (value, record) => record.location.startsWith(value),
          filterSearch: true,
          filterMode: "tree",
        },
        {
          title: "Status",
          align: "center",
          width: 300,
          dataIndex: "status",
          key: "Status",
          filters: [
            {
              text: "Available",
              value: "open",
            },
            {
              text: "Fully Booked",
              value: "booked",
            },
          ],
          onFilter: (value, record) => record.status.startsWith(value),
          filterSearch: true,
          filterMode: "tree",
          render: (text, record) => (
            <Tag color={statusColor[text]}>
              {text === "open"
                ? String("Available").toUpperCase()
                : String("Fully Booked").toUpperCase()}
            </Tag>
          ),
        },
        {
          title: "Action",
          width: 100,
          editable: false,
          align: "center",
          fixed: "right",
          hidden: moment(
            projectObj?.current?.project?.project_end_date
          ).isBefore(moment(), "day")
            ? true
            : false,
          key: "action",
          render: (text, record) => {
            return (
              <div className="booking-manage-page-table-row-ctrl">
                <Tooltip
                  title={<p style={{ fontSize: "14px" }}>{"Reschedule"}</p>}
                >
                  <IconButton
                    size="small"
                    disableRipple={true}
                    aria-label="Reschedule"
                    disabled={record.participant_id !== idPanelist}
                    style={{
                      display: record.participant_id !== idPanelist && "none",
                    }}
                    onClick={onReSchedule.bind(this, record)}
                  >
                    <Schedule fontSize="small" />
                  </IconButton>
                </Tooltip>

                <PopConfirm
                  title={"Are you sure you want to cancel this schedule?"}
                  onConfirm={onCancelSchedule.bind(this, record)}
                >
                  <Tooltip
                    title={
                      <p style={{ fontSize: "14px" }}>{"Cancel Schedule"}</p>
                    }
                  >
                    <IconButton
                      size="small"
                      disableRipple={true}
                      style={{
                        display: record.participant_id !== idPanelist && "none",
                      }}
                      disabled={String(record.participant_id) !== idPanelist}
                    >
                      <DeleteForever fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </PopConfirm>
              </div>
            );
          },
        },
      ].filter((item) => !item.hidden);

    for (let i = 1; i <= visitNum; i++) {
      if (visitNum === 1) {
        infoArr.push({
          title: "Start Time",
          align: "center",
          width: 140,
          dataIndex: "visit",
          key: "visit",
          render: (v, r) => {
            if (v.length >= i) {
              return moment
                .utc(v[i - 1].start_time)
                .local()
                .format(api.utils.MOMENTFORMAT.time24);
            }

            return "";
          },
        });
        infoArr.push({
          title: "End Time",
          align: "center",
          width: 140,
          dataIndex: "visit",
          key: "visit",
          render: (v, r) => {
            if (v.length >= i) {
              return moment
                .utc(v[i - 1].end_time)
                .local()
                .format(api.utils.MOMENTFORMAT.time24);
            }

            return "";
          },
        });
      } else {
        infoArr.push({
          title: `${ordinal(i)} Visit`,
          align: "center",
          width: 140,
          dataIndex: "visit",
          key: "visit",
          render: (v, r) => {
            if (v.length >= i) {
              const start = moment
                .utc(v[i - 1].start_time)
                .local()
                .format(api.utils.MOMENTFORMAT.time24),
                end = moment
                  .utc(v[i - 1].end_time)
                  .local()
                  .format(api.utils.MOMENTFORMAT.time24);

              return `${start} - ${end}`;
            }

            return "";
          },
        });
      }
    }

    return _.concat(infoArr, actionArr);
  };

  const rowSelection = {
    checkStrictly: true,
    fixed: true,
    selectedRowKeys,
    onSelect: (record, selected, selectedRows) => {
      let arr = Array.from(selectedRowKeys);

      if (record.participant_id.length < 36) {
        if (selected) {
          arr.push(record.id);
          setSelectedRowKeys(arr);
          bookingObj.current.schedules = selectedRows;
        } else {
          bookingObj.current.schedules = _.filter(
            selectedRows,
            (v) => v.id !== record.id
          );
          setSelectedRowKeys(_.filter(arr, (v) => v !== record.id));
        }
      }
    },
    getCheckboxProps: (record, selected) => ({
      disabled: record.participant_id !== idPanelist,
    }),
  };

  const onFilterWeeks = (value) => {
    if (value) {
      const filteredData = _.filter(projectObj.current.appointments, (obj) => {
        return isRecurring ? obj.week === value : obj.visits === value;
      });
      let userDetails = JSON.parse(
        codify.decode(window.localStorage.getItem("profile_user"))
      );
      let fullName = "";
      fullName = userDetails?.first_name + " " + userDetails?.last_name;
      setData(
        filteredData.filter(
          (iter) =>
            iter.panelist.trim().length > 0 &&
            iter.panelist.trim().toLowerCase() === fullName.toLowerCase()
        )
      );
    }
  };

  const onClearFilter = () => {
    let userDetails = JSON.parse(
      codify.decode(window.localStorage.getItem("profile_user"))
    );
    let fullName = "";
    fullName = userDetails?.first_name + " " + userDetails?.last_name;
    setData(
      projectObj.current.appointments.filter(
        (iter) =>
          iter.panelist.trim().length > 0 &&
          iter.panelist.trim().toLowerCase() === fullName.toLowerCase()
      )
    );
  };

  // const _generateExportData = () => {
  //     const fileName = `booking-detail-${projectObj.current.project.name}${selectedWeek
  //         ? `-${ordinal(selectedWeek)} ${projectObj.current.project.is_recurring ? "Week" : "Visit"
  //         }`
  //         : ""
  //         }`;
  //     const headers = [
  //         ...(projectObj.current.project.is_recurring
  //             ? [{ label: "Week", key: "week" }]
  //             : [{ label: "Visit", key: "visits" }]),
  //         { label: "Date", key: "date" },
  //         { label: "Item", key: "item" },
  //     ];

  //     if (!projectObj.current.project.is_recurring) {
  //         headers.push(
  //             { label: "Start Time", key: "start_time" },
  //             { label: "End Time", key: "end_time" }
  //         );
  //     }

  //     data.forEach((project) => {
  //         project.visit.forEach((vis, index) => {
  //             if (projectObj.current.project.is_recurring) {
  //                 if (
  //                     !headers.find((head) => head.key === `${ordinal(index + 1)} Visit`)
  //                 ) {
  //                     headers.push({
  //                         label: `${ordinal(index + 1)} Visit`,
  //                         key: `${ordinal(index + 1)} Visit`,
  //                     });
  //                 }
  //             }
  //         });
  //     });

  //     headers.push(
  //         { label: "Location", key: "location" },
  //         { label: "Full Name", key: "panelist" }
  //     );

  //     const outputData = _.cloneDeep(data).map((project) => {
  //         const output = {};
  //         headers.forEach(head => {
  //             output[head.key] = project[head.key];
  //         });
  //         output.date = moment(project.date).format(api.utils.MOMENTFORMAT.date);

  //         if (projectObj.current.project.is_recurring) {
  //             output.week = `${ordinal(project.week)} Week`;
  //         }
  //         else {
  //             output.visits = `${ordinal(project.visits)} Visit`;
  //         }

  //         project.visit.forEach((vis, index) => {
  //             const start = moment(vis.start_time).format(
  //                 api.utils.MOMENTFORMAT.time24
  //             ),
  //                 end = moment(vis.end_time).format(api.utils.MOMENTFORMAT.time24);

  //             if (projectObj.current.project.is_recurring) {
  //                 output[`${ordinal(index + 1)} Visit`] = `${start} - ${end}`;
  //             }
  //             else {
  //                 output.start_time = start;
  //                 output.end_time = end;
  //             }
  //         });

  //         return output;
  //     });

  //     return {
  //         fileName,
  //         header: [['Name', projectObj.current.project.name], ['Type', projectObj.current.project.type], ['Number of panelist to be scheduled', projectObj.current.project.panel_count], [], headers.map(head => head.label)],
  //         data: outputData
  //     };
  // };

  // const onAddBookingClick = () => {
  //     setShowPanelistInfo(true);
  // };

  // const onSavePersonalinfo = () => {
  //     bookedPanelist(bookingObj.current);
  //     setShowPanelistInfo(false);
  //     setSelectedRowKeys([]);
  // };

  const onConfirmResched = () => {
    reschedAppointment(reschedObj.current.api);
    setShowReschedule(false);
  };

  const onRemoveAllVistis = () => {
    cancelMultipleAppointment(numberOfBooked)
  }

  return (
    <div id="booking-manage-page">
      <Breadcrumb>
        <Breadcrumb.Item>
          <a
            href={
              auth.getRole() === ROLES.PANELIST
                ? urls.USER_HOME
                : urls.PARTICIPANTS_HOME
            }
          >
            {" "}
            {auth.getRole() === ROLES.PANELIST ? "Project" : "Panelist"}{" "}
          </a>
        </Breadcrumb.Item>
        <Breadcrumb.Item>My Appointments</Breadcrumb.Item>
      </Breadcrumb>
      <PageHeader
        ghost={false}
        title="My Appointments"
        onBack={() => {
          history.push(
            auth.getRole() === ROLES.PANELIST
              ? urls.USER_HOME
              : urls.PARTICIPANTS_HOME
          );
        }}
      >
        <div className="page-header-desc">
          <div className="page-header-desc-left">
            <Descriptions size="default" column={1}>
              <Descriptions.Item label="Project ID">
                {projectObj.current.project.pr_id}
              </Descriptions.Item>
              <Descriptions.Item label="Project Type" span={1}>
                {projectObj.current.project.type}
              </Descriptions.Item>
              <Descriptions.Item label="Instruction">
                {projectObj.current.project.book_instructions}
              </Descriptions.Item>
            </Descriptions>
          </div>
          <div className="page-header-desc-right">
            <Descriptions size="default" column={1}>
              <Descriptions.Item label="Project Name">
                {projectObj.current.project.name}
              </Descriptions.Item>
              <Descriptions.Item label="Panelist">
                {projectObj.current.project.panel_count}
              </Descriptions.Item>
              <Descriptions.Item label="Booked">
                {projectObj.current.project.booked_cnt}
              </Descriptions.Item>
            </Descriptions>
          </div>
        </div>
      </PageHeader>

      <div className="booking-manage-page-content">
        <div className="booking-manage-page-table-container">
          <div className="booking-manage-page-table-controls">
            <div className="booking-manage-page-table-controls-left">
              <Select
                placeholder={isRecurring ? "Select Week" : "Select Visit"}
                labelInValue={false}
                onChange={onFilterWeeks}
                onClear={onClearFilter}
                allowClear={true}
                options={totalWeeks}
              ></Select>
            </div>

            {/* <div className="booking-manage-page-table-controls-right">
                            <Button
                                startIcon={<Add />}
                                onClick={onAddBookingClick}
                                disabled={selectedRowKeys.length === 0}
                                disableRipple={true}>
                                Add Booking
                            </Button>
                            <Button
                                startIcon={<VerticalAlignBottom />}
                                disableRipple={true}
                                onClick={() => exportExcel(_generateExportData())}>
                                Export
                            </Button>
                        </div> */}
          </div>

          <Table
            className="booking-manage-page-table"
            columns={columns}
            bordered={false}
            rowKey={"id"}
            sticky={true}
            showHeader={true}
            tableLayout="fixed"
            rowClassName={(record, index) =>
              record.participant_id !== idPanelist ? "" : "selected-color"
            }
            rowSelection={{ ...rowSelection }}
            pagination={{
              showTotal: (total, range) => {
                return `${range[0]}-${range[1]} of ${total} items`;
              },
              defaultCurrent: 1,
              defaultPageSize: 50,
              pageSizeOptions: [10, 20, 30, 50, 100],
              showSizeChanger: true,
              position: ["bottomRight"],
              total: data.length,
            }}
            dataSource={data}
            scroll={{
              y: "37vh",
              x: "100%",
            }}
          ></Table>
        </div>
      </div>

      {/* <Modal
                title="Personal Information"
                visible={showPanelistInfo}
                centered={true}
                destroyOnClose={true}
                okText="Confirm"
                cancelText="Reset"
                onOk={onSavePersonalinfo}
                onCancel={() => {
                    bookingObj.current.panelist = DefaultPanelist;
                    setShowPanelistInfo(false);
                }}>
                <PanelistModal
                    defaultValue={bookingObj.current.panelist}
                    onChange={(v) => {
                        bookingObj.current.panelist = v;
                    }} />
            </Modal> */}

      <Modal
        title="Reschedule"
        width={800}
        visible={showReschedule}
        centered={true}
        destroyOnClose={true}
        okText="Confirm"
        cancelText="Cancel"
        onOk={onConfirmResched}
        onCancel={() => {
          setShowReschedule(false);
        }}
      >
        <BookingsPreview
          selected={reschedObj.current.selected}
          defaultValue={projectObj.current}
          onChange={(v) => {
            reschedObj.current.api = v;
          }}
        />
      </Modal>

      {/* <Modal
                title="Bookings"
                visible={showBookingInfo}
                centered={true}
                destroyOnClose={true}
                okText="Yes"
                cancelText="No"
                onOk={() => {
                    setShowBookingInfo(false);
                }}
                onCancel={() => {
                    setShowBookingInfo(false);
                }}>

            </Modal> */}

      <Modal
        title="Visit Cancellation"
        visible={showModalRemoveAll}
        centered={true}
        destroyOnClose={true}
        okText="Cancel group of visits"
        cancelText="Close"
        onOk={onRemoveAllVistis}
        onCancel={() => {
          setShowModalRemoveAll(false);
        }}>
        <p>
          You can’t cancel only this visit because it is part of a group of visits. You can either choose to reschedule it or cancel and rebook all the visits altogether.  For further assistance, contact your project coordinator directly.
        </p>
      </Modal>


      <Modal
        title="Visit Cancellation"
        visible={contactAdmins}
        centered={true}
        destroyOnClose={true}
        footer={[
          <>
            <Button key="submit" type="primary" onClick={() => setContactAdmins(false)}>
              Close
            </Button>
          </>

        ]}>
        <p>
          You can’t cancel this visit because it is part of a group of visits some of which has already been completed. You can only reschedule it. For further assistance, contact your project coordinator directly.
        </p>
      </Modal>


      <Backdrop id="loading-dialog" open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default connect()(BookingDetailsPage);
