import React , {useRef, useState} from 'react';
import { Route, Redirect } from 'react-router-dom';
import IdleTimer from 'react-idle-timer';
import { useAuth } from '../Auth/auth';
import urls from '../../utility/urls';
import AuthHelper from '../../library/helpers/AuthHelper';
import {
    ROLES,
    WAIT_INACTIVE_USER,
    SESSION_EXPIRES_IN,
    DEBOUNCE
}from '../../utility/constant'
import History from '../../library/helpers/History';
import ExpireModal from '../ModalExpireToken'


const UserLayout = ({ component: Component, ...rest }) => {
    const { authTokens } = useAuth();
    const authHelper = new AuthHelper();
    let userRole = authHelper.getRole();
    const [isShowModal, setIsShowModal] = useState(false);

    const sessionTimeOutRef = useRef(null)

    const handleOnIdle = e => {
        //show Modale 5 min
        sessionTimeOutRef.current = setTimeout(() => authHelper.logout(History),WAIT_INACTIVE_USER)
        setIsShowModal(true)
    }
    const handleOnAction = e => {
        //console.log('user did something', e)
    }
    const handleOnActive = e => {
        //console.log('user did something', e)
    }

    const onOk = () => {
        // we can refresh token but we already build it in interceptor so just reload page 
        setIsShowModal(false)
        clearTimeout(sessionTimeOutRef.current)
    }   
    
    const onLogout = () => {
        setIsShowModal(false)
        clearTimeout(sessionTimeOutRef.current)
        authHelper.logout(History)
    }
    return (
        <>
            <IdleTimer
                element={document}
                onIdle={handleOnIdle}
                debounce={DEBOUNCE}
                onAction = {handleOnAction}
                onActive = {handleOnActive}
                crossTab={{
                    type: 'localStorage',
                    emitOnAllTabs: true
                  }}
                startOnMount={true}
                timeout={SESSION_EXPIRES_IN} />
            <ExpireModal 
                isShowModal = {isShowModal}
                onLogout={onLogout}
                onOk={onOk}
            />
            <Route
                {...rest}
                render={(props) => {
                    if(authTokens && userRole === ROLES.adminGlobal) {
                        return <Redirect to={{ pathname:urls.ADMIN_HOME, state: { referer: props.location } }} />
                    }

                    // authorised so return component
                    return (
                        authTokens ? <Component {...props} /> 
                        :
                        <Redirect to={{ pathname: urls.USER_LOGIN, state: { referer: props.location } }} />
                    )
                }}
            />
        </>
      
    );
};

export default UserLayout;
