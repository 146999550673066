import React, { useState, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Form, Input, InputNumber } from 'antd';

import './index.scss';
import VisitConfig from '../../components/VisitConfig';

const NonRegForm = (props) => {
    const { form , noOfVisits } = props;

    const [numVisit, setNumVisit] = useState(0);

    const onChangeVisit = (e) => {
        let arr = [];
        
        for (let i = 1; i <= e; i++) {
            arr.push({
                date: null,
                timeStartEnd: [],
                duration: null,
                timeInterval: null,
                panelistPerSlot: 1,
            });
        }

        form.setFieldsValue({ visit_config: arr });
        setNumVisit(arr.length);
    };

    return (
        <Fragment>
            <div className="add-new-visits-cont">
                <div className="add-new-visits-left">
                    <Form.Item
                        label="Number of visits"
                        name="number_of_visits"
                        rules={[{ required: true, message: 'Number of visits is required' }]}
                    >
                        <InputNumber value={numVisit} onChange={onChangeVisit} tabIndex={1} min={0} max={1000} />
                    </Form.Item>
                    <Form.Item label="Location" name="location" rules={[{ required: false, message: '' }]}>
                        <Input tabIndex={2}  showCount={255}
									maxLength={255}/>
                    </Form.Item>
                </div>

                <div className="add-new-visits-right">
                    <Form.Item label="" name="visit_config">
                        <VisitConfig IsRecurring={false} noOfVisits={noOfVisits}  isAddNewVisit={true} />
                    </Form.Item>
                </div>
            </div>
        </Fragment>
    );
};

NonRegForm.defaultProps = {};

NonRegForm.propTypes = {
    form: PropTypes.object,
};

export default connect()(NonRegForm);
