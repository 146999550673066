import React, { useState , useEffect} from 'react';
import { Route, Switch, useLocation, Redirect, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { Layout } from 'antd';

import urls from '../../utility/urls';
import HeaderAdmin from '../../components/HeaderAdmin';
import FooterComp from '../../components/Footer';
import ParticipantsSchedulePage from './Schedule';
import ParticipantsAppointmentDetailPage from './AppointmentDetail';
import ParticipantsBookingDetailPage from './BookingDetail';
import ParticipantsAppointmentPage from './Appointment';
import ParticipantsHelpPage from './Help';

import './style.scss';
import AuthHelper from '../../library/helpers/AuthHelper';
import {ROLES} from '../../utility/constant'
import SideBarAdmin from '../../components/SideBarAdmin';
import SideBar from '../../components/SideBar';

const { Header, Content, Footer , Sider } = Layout;

const ParticipantsPage = () => {
    let location = useLocation();
    const historyLink = useHistory();
    const auth = new AuthHelper()
    const [, setProjId] = useState('');

    useEffect(() => {
        document.title = "OnTheDot Panelist Portal"
      }, [])

    return (
        <Layout id="admin-layout">
            <Header>
                <HeaderAdmin />
            </Header>
            <Layout>
                 {
                    auth.getRole() === ROLES.PANELIST ? 
                    <Sider className="sidebar" width={125} theme="light"> 
                             <SideBar />
                    </Sider>
                    :
                    <Sider className="sidebar" width={140} theme="light">
                            <SideBarAdmin />
                    </Sider>
                }
            <Layout>            
                <Content>
                    <Switch location={location}>
                        <Route
                            exact
                            path={`${urls.PARTICIPANTS_HOME}/:projectId`}
                            render={(props) => {
                                const projectId = props.match.params['projectId'] || '';
                                if (projectId.length === 36) {
                                    setProjId(projectId);
                                    localStorage.setItem('ProjectID', projectId);
                                    historyLink.replace(`${urls.PARTICIPANTS_SCHEDULE}/${projectId}`);
                                }
                            }}
                        />
                        <Route
                            exact
                            path={`${urls.PARTICIPANTS_SCHEDULE}/:projectId`}
                            component={ParticipantsSchedulePage}
                        />
                        <Route
                            exact
                            path={`${urls.PARTICIPANTS_SCHEDULE_DETAIL}/:appointmentId`}
                            component={ParticipantsAppointmentDetailPage}
                        />
                        <Route
                            exact
                            path={`${urls.PARTICIPANTS_SCHEDULE_DETAIL}/:participantId/:projectId`}
                            component={ParticipantsBookingDetailPage}
                        />
                        <Route
                            exact
                            path={`${urls.PARTICIPANTS_APPOINTMENT}/:projectId`}
                            component={ParticipantsAppointmentPage}
                        />
                        <Route exact path={`${urls.PARTICIPANTS_HELP}/:projectId`} component={ParticipantsHelpPage} />
                        <Redirect to={urls.PARTICIPANTS_HOME} />
                    </Switch>
                </Content>
                <Footer>
                    <FooterComp isAdmin={false} />
                </Footer>
            </Layout>
        </Layout>
    </Layout>
    );
};

export default connect()(ParticipantsPage);
