import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useMount, useUpdateEffect } from 'ahooks';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';

import { Select, Space, Table } from 'antd';

import './style.scss';

import api from '../../library/api';
import ordinal from 'ordinal';
import RegexTextField from '../../components/RegexTextField';

const BookingsPreview = (props) => {
    const { defaultValue, onChange, selected } = props;


    const reschedObj = useRef({
        to_appointment_id: '',
        from_appointment_id: '',
        participant_id: '',
        project_id: '',
        comments: '',
    });
    const [data, setData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [comments, setComments] = useState('');


    useMount(() => {
        processData(defaultValue);
        setComments(selected.participant?.project_participant ? selected.participant?.project_participant[0]?.comment : ""
        )
        reschedObj.current.from_appointment_id = selected.id || '';
        reschedObj.current.participant_id = selected.participant_id || '';
    });

    useUpdateEffect(() => {
        if (selectedRows.length > 0) {
            reschedObj.current.from_appointment_id = selected.id;
            reschedObj.current.to_appointment_id = selectedRowKeys[0];
            reschedObj.current.project_id = selected.project_id;
            onChange(reschedObj.current);
        }
    }, [selectedRows]);

    const processData = (obj) => {
        const IsRecurring = obj.project.is_recurring,
            appnt = Array.from(obj.appointments),
            visitColumns = appnt.length > 0 ? appnt[0].visit.length : 0;
        //sort date  
        appnt.sort((a, b) => a.date.diff(b.date))
        //sort time
        appnt.sort((a, b) => {
            if (moment(a.date).isSame(moment(b.date), 'day') && moment(a.date).isSame(moment(b.date), 'year') && moment(a.date).isSame(moment(b.date), 'month')) {
                if (moment(a.visit[0].start_time).isSame(moment(b.visit[0].start_time))) {
                    return a.item - b.item
                }
                return moment(a.visit[0].start_time) - moment(b.visit[0].start_time)
            }
        })
        //filter data based on select slot
        if (defaultValue?.project?.select_slot === 'project') {
            setData(
                _.filter(appnt, (v) => {
                    return v.participant_id < 36 && v.status !== 'booked';
                })
            );
        } else if (defaultValue?.project?.select_slot === 'day') {
            setData(
                _.filter(appnt, (v) => {
                    return v.participant_id < 36 && v.status !== 'booked' && (moment(v.date).isSame(selected.date, 'day'));
                })
            );
        } else if (defaultValue?.project?.select_slot === 'visit') {
            setData(
                _.filter(appnt, (v) => {
                    return v.participant_id < 36 && v.status.trim() !== 'booked' && (defaultValue.project.is_recurring ? selected.week === v.week : selected.visits === v.visits);
                })
            );
        }

        setColumns(columnsProvider(visitColumns, IsRecurring));
    };

    const columnsProvider = (visitNum, recurring) => {
        let infoArr = [
            {
                title: recurring ? 'Week' : 'Visits',
                align: 'left',
                width: 100,
                dataIndex: recurring ? 'week' : 'visits',
                key: recurring ? 'week' : 'visits',
                render: (text, record) => {
                    return recurring ? `${ordinal(text)} Week` : `${ordinal(record.visits)} Visit`;
                },
            },
            {
                title: 'Date',
                align: 'center',
                width: 140,
                dataIndex: 'date',
                key: 'date',
                render: (text, record) => {
                    return moment(text).format(api.utils.MOMENTFORMAT.date);
                },
            },
            /*{
                title: 'Item',
                align: 'center',
                width: 140,
                dataIndex: 'item',
                key: 'item',
            },*/
        ],
            actionArr = [
                {
                    title: 'Location',
                    align: 'center',
                    width: 300,
                    dataIndex: 'location',
                    key: 'location',
                },
            ];

        for (let i = 1; i <= visitNum; i++) {
            if (visitNum === 1) {
                infoArr.push({
                    title: 'Start Time',
                    align: 'center',
                    width: 140,
                    dataIndex: 'visit',
                    key: 'visit',
                    render: (v, r) => {
                        if (v.length >= i) {
                            return moment.utc(v[i - 1].start_time).local().format(api.utils.MOMENTFORMAT.time24);
                        }

                        return '';
                    },
                });
                infoArr.push({
                    title: 'End Time',
                    align: 'center',
                    width: 140,
                    dataIndex: 'visit',
                    key: 'visit',
                    render: (v, r) => {
                        if (v.length >= i) {
                            return moment.utc(v[i - 1].end_time).local().format(api.utils.MOMENTFORMAT.time24);
                        }

                        return '';
                    },
                });
            } else {
                infoArr.push({
                    title: `${ordinal(i)} Visit`,
                    align: 'center',
                    width: 140,
                    dataIndex: 'visit',
                    key: 'visit',
                    render: (v, r) => {
                        if (v.length >= i) {
                            const start = moment.utc(v[i - 1].start_time).local().format(api.utils.MOMENTFORMAT.time24),
                                end = moment.utc(v[i - 1].end_time).local().format(api.utils.MOMENTFORMAT.time24);

                            return `${start} - ${end}`;
                        }

                        return '';
                    },
                });
            }
        }

        return _.concat(infoArr, actionArr);
    };

    const rowSelection = {
        checkStrictly: true,
        fixed: true,
        type: 'radio',
        selectedRowKeys,
        onChange: (selectedKeys, selectedRows) => {
            setSelectedRowKeys(selectedKeys);
            setSelectedRows(selectedRows);
        },
        onSelect: (record, selected, selectedRows) => { },
    };

    return (
        <div id="edit-schedule-section">
            {/*<Space direction={"vertical"} size={0}>
							<label htmlFor={"location"}>Location : </label>
							<Input
									name={"location"}
									value={scheduleData.location}
									onChange={(e) => {
										setScheduleData({
											location : e.target.value
										});
									}}/>
						</Space>
						*/}

            <Space direction={'vertical'} size={0}>
                <label>Current schedule :</label>
            </Space>

            <Table
                className="booking-manage-page-resched-table"
                columns={columns}
                bordered={false}
                rowKey={'id'}
                sticky={true}
                showHeader={true}
                tableLayout="fixed"
                dataSource={[selected]}
                scroll={{
                    y: '37vh',
                    x: '100%',
                }}
            ></Table>

            <Space direction={'vertical'} size={0}>
                <label>Select Schedule to change to:</label>
            </Space>

            <Table
                className="booking-manage-page-resched-table"
                columns={columns}
                bordered={false}
                rowKey={'id'}
                sticky={true}
                showHeader={true}
                tableLayout="fixed"
                rowSelection={{ ...rowSelection }}
                pagination={{
                    showTotal: (total, range) => {
                        return `${range[0]}-${range[1]} of ${total} items`;
                    },
                    defaultCurrent: 1,
                    defaultPageSize: 50,
                    pageSizeOptions: [10, 20, 30, 50, 100],
                    showSizeChanger: true,
                    position: ['bottomRight'],
                    total: data.length,
                }}
                dataSource={data}
                scroll={{
                    y: '37vh',
                    x: '100%',
                }}
            ></Table>

            <Space direction={'vertical'} size={0}>
                <label>Comments:</label>
            </Space>

            <RegexTextField
                required={false}
                value={comments}
                onChange={(e) => {
                    setComments(e.target.value);
                    reschedObj.current.comments = e.target.value
                }}
                placeholder="Comments"
                variant="outlined"
                style={{ width: '50%' }}
            />

        </div>
    );
};

BookingsPreview.defaultProps = {};

BookingsPreview.propTypes = {
    selected: PropTypes.object.isRequired,
    defaultValue: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default connect()(BookingsPreview);
