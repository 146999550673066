import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useMount } from 'ahooks';
import _ from 'lodash';

import { Backdrop, CircularProgress, Stepper, Step, StepLabel } from '@material-ui/core';
import { Form } from 'antd';
import { useSnackbar } from 'notistack';

import urls from '../../utility/urls';
import { errorMapper } from '../../utility';
import api from '../../library/api';

import StepOne from './StepOne/StepOne';
import StepThree from './StepThree/StepThree';
import StepFour from './StepFour/StepFour';
import SchedulingDetails from './SchedulingDetails/SchedulingDetails';

import './style.scss';
import StepFive from './Step Five';

const steps = [
    {
        id: 0,
        label: 'Project Details',
    },
    {
        id: 1,
        label: 'Scheduling Details',
    },
    {
        id: 2,
        label: 'Scheduling Conditions',
    },
    {
        id:3 ,
        label : 'Invite Panelist'
    }
];

const DEFAULT_STEP_PAGE = 0;

const ProjectCreatePage = () => {
    const historyLink = useHistory();

    const formData = useRef({});

    const [isLoading, setIsLoading] = useState(false);
    const [activeStep, setActiveStep] = useState(DEFAULT_STEP_PAGE);
    const [, setSubmitForm] = useState(false);
    const [files , setFiles] = useState([])

    const { enqueueSnackbar } = useSnackbar();
    const [form] = Form.useForm();

    useMount(() => {
        formData.current = formDefaultValues;
    });

    const formDefaultValues = {
        emails: [],
        recurring: false,
        weeksPerProject: 1,
        timeAllowanceUnit: 'm',
        notifRecipients: [],
    };

    const onNext = (value) => {
        formData.current = _.extend(formData.current, value);

        setActiveStep((prevActiveStep) => {
            if (steps.length - 1 === prevActiveStep) {
                setSubmitForm(true);
            }

            return prevActiveStep + 1;
        });
    };

    const onBack = (value) => {
        formData.current = _.extend(formData.current, value);
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const onSaveData = () => {
        setIsLoading(true)
        const data = new FormData();
        for (let i = 0; i < files.length; i++) {
            data.append('files', files[i]);
        }
        api.projects
            .create(data  , formData.current)
            .then((d) => {
                setIsLoading(false)
                if (d.messages === 'success') {
                    formData.current = formDefaultValues;
                    enqueueSnackbar('Successfully Created Project', { variant: 'success' });
                    historyLink.push(urls.ADMIN_PROJECT_LIST);
                } else {
                    if(d.code === 500) {
                        if (d?.messages.errors[0].type === 'unique violation') {
                            enqueueSnackbar("You can't use invalid or duplicate emails.", { variant: 'warning' });
                        }else {
                            enqueueSnackbar('An error occurred while creating panelist. Please contact system admin for help.', { variant: 'warning' });
                        }
                    } else {
                        enqueueSnackbar(errorMapper(d.messages), { variant: 'warning' });
                    }
                }
            })
            .catch((e) => {
                setIsLoading(false)
                enqueueSnackbar('An error occurred while creating project. Please contact system admin for help.', { variant: 'error' });
            });
    };

    const onSaveDraftData = () => {
        onSaveData();
    };

    const STEP_PAGES_LIST = [
       
        <StepOne defaultValue={formData.current} step={activeStep} onSubmit={onNext} />,
        <SchedulingDetails defaultValue={formData.current} step={activeStep} onBack={onBack} onSubmit={onNext} />,
        <StepThree defaultValue={formData.current} step={activeStep} onBack={onBack} onSubmit={onNext} />,
        <StepFive 
            defaultValue={formData.current} step={activeStep} onBack={onBack} onSubmit={onNext}
        />,
        <StepFour
            defaultValue={formData.current}
            step={activeStep}
            onBack={onBack}
            onSubmit={onSaveData}
            onSubmitDraft={onSaveDraftData}
            isLoading={isLoading}
            form={form}
            files= {files}
            setFiles={setFiles}
        />,

    ];
    return (
        <div id="proj-create-page">
            <div className="proj-create-page-header">
                <h1>Create Project</h1>
            </div>

            <div className="proj-create-page-stepper">
                <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((value) => (
                        <Step key={value.id}>
                            <StepLabel>{value.label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </div>

            <div className="proj-create-page-content">{STEP_PAGES_LIST[activeStep]}</div>
            <Backdrop id="loading-dialog" open={isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
};

export default connect()(ProjectCreatePage);
