import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useMount, useUpdateEffect } from 'ahooks';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { Input, Tag } from 'antd';
import { PlusOutlined, MailOutlined, CloseOutlined } from '@ant-design/icons';

import './style.scss';

const EmailFields = (props) => {
    const { onChange, value, addlabel } = props;

    const inputRef = useRef({});

    const [emailsList, setEmailsList] = useState([]);
    const [IsVisible, setIsVisible] = useState(false);
    const [IsListEmpty, setIsListEmpty] = useState(true);

    useMount(() => {
        if (_.isArray(value)) {
            setEmailsList(value);
        }
    });

    useUpdateEffect(() => {
        if (_.isArray(value)) {
            setEmailsList(value);
        }
    }, [value]);

    useUpdateEffect(() => {
        setIsListEmpty(emailsList.length > 0);
        onChange(emailsList);
    }, [emailsList]);

    useUpdateEffect(() => {
        if (IsVisible) {
            inputRef.current.focus();
        }
    }, [IsVisible]);

    const IsValidEmail = (str) => {
        // eslint-disable-next-line no-useless-escape
        return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            str
        );
    };

    const onAddEmail = () => {
        setIsVisible(true);
    };

    const onInputPressEnter = (e) => {
        const inputData = e.target.value.split(';');

        if (_.isArray(inputData)) {
            _.each(inputData, (v) => {
                if (IsValidEmail(_.trim(v))) {
                    setEmailsList((prev) => {
                        return [...prev, _.trim(v.toLowerCase())];
                    });
                }
            });
        }

        setIsVisible(false);
    };

    const onInputBlur = () => {
        setIsVisible(false);
    };

    const onRemoveEmail = (v) => {
        const newList = _.filter(emailsList, (d) => {
            return d !== v;
        });

        setEmailsList(newList);
    };

    const onAllowEmailInput = (e) => {
        // eslint-disable-next-line no-useless-escape
        const re = /[A-Z0-9a-z@\.\;\_\-\s+]/.test(e.key);

        if (!re) {
            e.preventDefault();
        }
    };

    return (
        <div className="emails-fields-container">
            {IsVisible && (
                <Input
                    ref={inputRef}
                    onKeyPress={onAllowEmailInput}
                    onPressEnter={onInputPressEnter}
                    onBlur={onInputBlur}
                />
            )}

            {!IsVisible && (
                <Tag className="emails-fields-addnew" onClick={onAddEmail}>
                    <PlusOutlined /> {addlabel}
                </Tag>
            )}

            {IsListEmpty && emailsList.length > 0 && (
                <div className="emails-fields-list-wrapper">
                    <ul className="emails-fields-list-container">
                        {emailsList&&emailsList.map((v, i) => (
                            <li key={i}>
                                <Tag
                                    closable={true}
                                    closeIcon={<CloseOutlined />}
                                    onClose={onRemoveEmail.bind(this, v, i)}
                                >
                                    <MailOutlined /> {v}
                                </Tag>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

EmailFields.defaultProps = {
    addlabel: 'Add Email',
    value: [],
};

EmailFields.propTypes = {
    addlabel: PropTypes.string,
    value: PropTypes.array.isRequired,
    onChange: PropTypes.func,
};

export default connect()(EmailFields);
