import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useMount, useUpdateEffect } from 'ahooks';
import _ from 'lodash';
import moment from 'moment';
import ordinal from 'ordinal';
import { Resizable } from 'react-resizable';

import { PageHeader, Table, Breadcrumb, Input, Descriptions } from 'antd';

import { Backdrop, CircularProgress, Button } from '@material-ui/core';
import { SearchOutlined, VerticalAlignBottom, } from '@material-ui/icons';
import { useSnackbar } from 'notistack';

import urls from '../../utility/urls';
import { errorMapper } from '../../utility';
import api from '../../library/api';
import { exportExcel } from '../../utility/xslx';

import './style.scss';


const ResizableTitle = (props) => {
    const { onResize, width, ...restProps } = props;

    if (!width) {
        return <th {...restProps} />;
    }

    return (
        <Resizable
            width={width}
            height={0}
            handle={
                <span
                    className="react-resizable-handle"
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                />
            }
            onResize={onResize}
            draggableOpts={{
                enableUserSelectHack: false,
            }}
        >
            <th {...restProps} />
        </Resizable>
    );
};
const ProjectLogTracker = (props) => {
    const historyLink = useHistory();
    const projectObj = useRef({
        project: {},
    });

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [columns, setColumns] = useState([]);

    const { enqueueSnackbar } = useSnackbar();

    useMount(async () => {
        await fetchData();
    });

    useUpdateEffect(() => {
        fetchData();
    }, []);
    const handleResize =
        (index) =>
            (_, { size }) => {
                const newColumns = [...columns];
                newColumns[index] = { ...newColumns[index], width: size.width };
                setColumns(newColumns);
            };
    const mergeColumns = columns.map((col, index) => ({
        ...col,
        onHeaderCell: (column) => (
            {
                width: column.width,
                onResize: handleResize(index),
            }),
    }));



    const fetchData = (id) => {
        setIsLoading(true);
        api.logs
            .fetchProjectLogActivity()
            .then((d) => {
                setIsLoading(false);
                if (d.messages === 'success') {
                    processedRawData(d.data);
                } else {
                    enqueueSnackbar(errorMapper(d.messages), { variant: 'warning' });
                }
            })
            .catch((e) => {
                setIsLoading(false);
                enqueueSnackbar('An error occurred while getting project information. Please contact system admin for help.', { variant: 'error' });
            });
    };

    const processedRawData = (proj_logs) => {

        let updateProject = []
        proj_logs.map((item) => {
            updateProject.push({
                ...item,
                name: item.log_activity.name,
                Action: item.log_activity.Action,
                ActionBy: item.log_activity.ActionBy || item.log_activity.email,
                pr_id: item.log_activity.pr_id
            })
        })

        if (updateProject) {
            updateProject = updateProject?.sort((a, b) => moment(a.insert_dttm) - moment(b.insert_dttm))
            updateProject = updateProject.reverse()
        }

        setData(updateProject)
        projectObj.current.project = updateProject;
        setColumns(columnsProvider());
    }


    const flattenObject = (obj, parent = '', res = {}) => {
        for (let key in obj) {
            let propName = parent ? `${parent}.${key}` : key;
            if (typeof obj[key] === 'object' && obj[key] !== null) {
                flattenObject(obj[key], propName, res);
            } else {
                res[propName] = obj[key];
            }
        }
        return res;
    };


    const columnsProvider = () => {
        let infoArr = [
            {
                title: 'ID',
                align: 'left',
                width: 250,
                dataIndex: 'pr_id',
                key: 'pr_id',
                sorter: {
                    compare: (a, b) => {
                        const idA = a.pr_id.toUpperCase();
                        const idB = b.pr_id.toUpperCase();

                        if (idA < idB) {
                            return -1;
                        }

                        if (idA > idB) {
                            return 1;
                        }

                        return 0;
                    },
                    multiple: 5,
                },
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: 'Project Name',
                align: 'left',
                width: 300,
                dataIndex: 'name',
                key: 'name',
            },
        ],
            actionArr = [
                {
                    title: 'Action',
                    align: 'left',
                    width: 140,
                    dataIndex: 'Action',
                    key: 'Action',
                },
                {
                    title: 'Action By',
                    align: 'left',
                    width: 140,
                    dataIndex: 'ActionBy',
                    key: 'ActionBy'
                },
                {
                    title: 'Timestamp',
                    align: 'left',
                    width: 140,
                    dataIndex: 'insert_dttm',
                    key: 'insert_dttm',
                    defaultSortOrder: 'descend',
                    sorter: {
                        compare: (a, b) => {
                            if (moment(a.insert_dttm).isBefore(b.insert_dttm, 'min')) {
                                return -1;
                            }

                            if (moment(a.insert_dttm).isAfter(b.insert_dttm, 'min')) {
                                return 1;
                            }

                            return 0;
                        },
                        multiple: 2,
                    },
                    render: (text, record) => {
                        return moment.utc(text).local().format(api.utils.MOMENTFORMAT.time24Date);
                    },
                }
            ];

        return _.concat(infoArr, actionArr);
    }

    const _handleSearch = (e) => {
        const text = e.target.value;

        setData(
            projectObj.current.project.filter(
                (project) =>
                    (project?.pr_id && project?.pr_id.toLowerCase().includes(text.toLowerCase())) ||
                    (project?.name && project?.name.toLowerCase().includes(text.toLowerCase())) ||
                    (project?.Action && String(project.Action).toLowerCase().includes(text.toLowerCase())) ||
                    (project?.ActionBy && String(project.ActionBy).toLowerCase().includes(text.toLowerCase()))
            ),
        );
    };



    const _handleClearSearch = (e) => {
        const text = e.target.value;
        if (text === '') {
            setData(projectObj.current.project);
        }
    }


    const _generateExportData = () => {
        const fileName = `project-log-tracker`;

        const headers = [
            { label: 'ID', key: 'pr_id' },
            { label: 'Project Name', key: 'name' },
            { label: 'Action', key: 'Action' },
            { label: 'Action By', key: 'ActionBy' },
            { label: 'Timestamp', key: 'insert_dttm' },
        ];
        const outputData = _.cloneDeep(data).map((project) => {
            const output = {};
            headers.forEach((head) => {
                output[head.key] = project[head.key];
            });
            output.insert_dttm = moment.utc(project.insert_dttm).local().format(api.utils.MOMENTFORMAT.time24Date);
            return output;
        });
        return {
            fileName,
            header: [headers.map((head) => head.label)],
            data: outputData,
        };
    }

    return (
        <div id="project-management-page">
            <Breadcrumb>
                <Breadcrumb.Item>
                    <a href={urls.ADMIN_PROJECT_LIST}>Project List</a>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Project Log Tracker</Breadcrumb.Item>
            </Breadcrumb>
            <PageHeader
                ghost={false}
                title="Project Log Tracker"
                onBack={() => {
                    historyLink.push(`${urls.ADMIN_PROJECT_LIST}`);
                }}
            />
            <div className="project-management-page-content">
                <div className="project-management-page-table-container">
                    <div className="project-management-page-table-controls">
                        <div className="project-management-page-table-controls-left">
                            <Input
                                className="searchbox"
                                size="middle"
                                prefix={<SearchOutlined />}
                                placeholder="Search"
                                onPressEnter={_handleSearch}
                                onChange={_handleClearSearch}
                            />
                        </div>
                        <div className="project-management-page-table-controls-right">
                            <Button
                                startIcon={<VerticalAlignBottom />}
                                disableRipple={true}
                                onClick={() => exportExcel(_generateExportData())}
                            >
                                Export
                            </Button>
                        </div>
                    </div>

                    <Table
                        className="project-management-page-table"
                        columns={mergeColumns}
                        bordered={false}
                        rowKey={'id' + 'Action'}
                        sticky={true}
                        showHeader={true}
                        filterMultiple={true}
                        components={{
                            header: {
                                cell: ResizableTitle,
                            }
                        }}
                        tableLayout="fixed"
                        pagination={{
                            showTotal: (total, range) => {
                                return `${range[0]}-${range[1]} of ${total} items`;
                            },
                            defaultCurrent: 1,
                            defaultPageSize: 10,
                            pageSizeOptions: [10, 20, 30, 50, 100],
                            showSizeChanger: true,
                            position: ['bottomRight'],
                            total: data.length,
                        }}
                        dataSource={data}
                        scroll={{
                            y: '50vh',
                            x: '100%',
                        }}
                    ></Table>
                </div>
            </div>
            <Backdrop id="loading-dialog" open={isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
};

export default connect()(ProjectLogTracker);
