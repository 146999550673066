import React from 'react';
import { Modal } from 'antd';

import './index.scss';

const CustomModal = ({
    visible,
    modalClass = '',
    headerClass = '',
    header,
    footer,
    footerClass = '',
    contentClass = '',
    children,
    ...rest
}) => {
    return (
        <Modal className={`cst-modal ${modalClass}`} visible={visible} closable={false} footer={null} {...rest}>
            {header && <div className={`modal-header ${headerClass}`}>{header}</div>}
            <div className={`modal-content ${contentClass}`}>{children}</div>
            {footer && <div className={`modal-footer ${footerClass}`}>{footer}</div>}
        </Modal>
    );
};

export default CustomModal;
