import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Form, Row, Col } from 'antd';
import { Button, Paper } from '@material-ui/core';
import { InfoCircleOutlined } from '@ant-design/icons';
import ExcelButton from '../../../components/ExcelButton';

import EmailFields from '../../../components/EmailFields';

import '../StepThree/style.scss';
import Divider from '../../../components/DividerWithText';
import { useSnackbar } from 'notistack';

const StepFive = (props) => {
    const { onSubmit, step, onBack, defaultValue } = props;
    const { enqueueSnackbar } = useSnackbar();

    const [form] = Form.useForm();
    const IsSubmitted = useRef(false);

    const [ fileExcel , setFileExcel]  = useState(null)
    const [email, setEmail] = useState('');


    const onFormSubmit = (values) => {
        if(typeof fileExcel === "string"){
            enqueueSnackbar(fileExcel, { variant: 'warning' });
            return;
        }
        if(fileExcel) {
            values.emails = fileExcel
            if (IsSubmitted.current) {
                onSubmit(values);
            } else {
                onBack(values);
            }
        }else {
            if (IsSubmitted.current) {
                onSubmit(values);
            } else {
                onBack(values);
            }
        }
    };

    const onFormSubmitFailed = () => {};

    const onClickBack = () => {
        IsSubmitted.current = false;
    };

    const onClickSubmit = () => {
        IsSubmitted.current = true;
    };

    const onRemove = () => {
        setFileExcel(null)
    }

    const importFile = (file) => {
        setFileExcel(file)
    }
    return (
        <Form
            id="proj-create-form"
            form={form}
            name="ProjectCreate"
            layout="horizontal"
            initialValues={defaultValue}
            onFinish={onFormSubmit}
            onFinishFailed={onFormSubmitFailed}
        >
            <Paper className="paper-cont" elevation={0}>
                <div className="paper-cont-bar"></div>
                <div className="paper-cont-content">
                    <Row gutter={[24, 16]}>
                        <Col span={12} xs={24} sm={12}>
                            {/*<Form.Item
                                label="Notifications recipient"
                                name="notifRecipients"
                                tooltip={{
                                    title: 'Enter email address who will receive booking notification.',
                                    icon: <InfoCircleOutlined />,
                                }}
                            >
                                <EmailFields />
                            </Form.Item>*/}
                            <Form.Item
                                label="Panelist Email Address"
                                name="emails"
                                tooltip={{
                                    title: 'Enter panelist’s email address in this step if you want to invite panelist once project is launched. Use semicolon ( ; ) to add multiple emails',
                                    icon: <InfoCircleOutlined />,
                                }}
                            >
                                <EmailFields />
                              
                            </Form.Item>
                            <Divider>Or</Divider>
                                <p>
                                    You can download this <a className='sample-excel'  href={`${process.env.PUBLIC_URL}/assets/files/Sample.xlsx`} download="Sample">sample Excel file</a>, modify it and upload it to the system.
                                </p>
                                    <ExcelButton 
                                        fileExcel = {fileExcel} 
                                        onRemove = {onRemove}
                                        enqueueSnackbar = {enqueueSnackbar}
                                        setFileExcel={importFile}
                                        setEmail = {setEmail}
                                    />                                 
                            
                        </Col>
                    </Row>
                </div>
            </Paper>

            <div className="proj-create-page-content-controls">
                <Button
                    type="submit"
                    className="btn-back"
                    variant="contained"
                    disabled={step === 0}
                    onClick={onClickBack}
                >
                    Back
                </Button>
                <Button type="submit" className="btn-next" variant="contained" color="primary" onClick={onClickSubmit}>
                    Next
                </Button>
            </div>
        </Form>
    );
};

StepFive.defaultProps = {};

StepFive.propTypes = {
    initialData: PropTypes.object,
    defaultValue: PropTypes.object,
    step: PropTypes.number.isRequired,
    onBack: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default connect()(StepFive);
