import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Form, Row, Switch } from 'antd';
import { Button, Paper } from '@material-ui/core';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { useSnackbar } from 'notistack';
import ordinal from 'ordinal';

import './style.scss';

import Recurring from './recurring';
import NonRecurring from './nonrecurring';

const SchedulingDetails = (props) => {
    const { onSubmit, step, onBack, defaultValue } = props;
    const [form] = Form.useForm();

    const [IsRecurring, setIsRecurring] = useState(false);
    const [showFeatures, setShowFeatures] = useState(false);
    const IsSubmitted = useRef(false);

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (defaultValue.hasOwnProperty('recurring')) {
            setIsRecurring(defaultValue.recurring);
        }
    }, [defaultValue]);

    const onFormSubmit = (values) => {
        if (IsSubmitted.current) {
            let boolArray = [false]
            if(values.recurring) {
                values.visit_config_rec.map((e,idx) => {
                    if(e.timeStartEnd.length === 2&&!e.timeStartEnd.includes("")&&e.duration !== null && e.timeInterval !== null){
                        boolArray[idx] = true
                    }else{
                        boolArray[idx]=false
                        enqueueSnackbar(`Fill ${ordinal(idx+1)} visit information, it's required`,{variant :'error'})
                    }
                })
            }else{
            values.visit_config.map((e,idx) => {
                if(e.date !== null &&e.timeStartEnd.length === 2&&!e.timeStartEnd.includes("")&&e.duration !== null && e.timeInterval !== null){
                    boolArray[idx] = true
                }else{
                    boolArray[idx]=false
                    enqueueSnackbar(`Fill ${ordinal(idx+1)} visit information, it's required`,{variant :'error'})
                }
            })
        }
           const isAllValueTrue =  boolArray.every(e => {
                return e === true
            })
            if(isAllValueTrue) {
                onSubmit(values)
            }
        } else {
            onBack(values);
        }
    };

    const onFormSubmitFailed = (values) => {};

    const onRecurring = (e) => {
        setIsRecurring(e);
    };

    const onClickBack = () => {
        IsSubmitted.current = false;
    };

    const onClickSubmit = () => {
        IsSubmitted.current = true;
    };

    return (
        <Form
            name="PCSchedulingDetails"
            form={form}
            layout="vertical"
            initialValues={defaultValue}
            onFinish={onFormSubmit}
            onFinishFailed={onFormSubmitFailed}
        >
            <div id="sched-details-page">
                <Paper className="paper-cont" elevation={0}>
                    <div className="paper-cont-bar"></div>
                    <div className="paper-cont-content">
                        <Row>
                            <Form.Item label="When to best use the 2 features" className="flex-direction-row" />
                            {showFeatures ? (
                                <EyeOutlined className="show-features" onClick={() => setShowFeatures(!showFeatures)} />
                            ) : (
                                <EyeInvisibleOutlined
                                    className="show-features"
                                    onClick={() => setShowFeatures(!showFeatures)}
                                />
                            )}
                        </Row>
                        {showFeatures && (
                            <img className={"image-responsive"} src={`${process.env.PUBLIC_URL}/assets/show-feature.png`} alt="show-features" />
                        )}
                        <Row>
                            <Form.Item className="flex-direction-row" label="Regulated Schedule" name="recurring">
                                <Switch className="regulated-schedule" checked={IsRecurring} onChange={onRecurring} />
                            </Form.Item>
                        </Row>
                        {IsRecurring ? <Recurring form={form} /> : <NonRecurring form={form} />}
                    </div>
                </Paper>
            </div>

            <div className="proj-create-page-content-controls">
                <Button
                    type="submit"
                    className="btn-back"
                    variant="contained"
                    disabled={step === 0}
                    onClick={onClickBack}
                >
                    Back
                </Button>
                <Button type="submit" className="btn-next" variant="contained" color="primary" onClick={onClickSubmit}>
                    Next
                </Button>
            </div>
        </Form>
    );
};

SchedulingDetails.defaultProps = {
    defaultValue: {},
};

SchedulingDetails.propTypes = {
    step: PropTypes.number.isRequired,
    onBack: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default connect()(SchedulingDetails);
