import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, useLocation, Redirect } from 'react-router-dom';

import { Layout } from 'antd';

import HeaderAdmin from '../../components/HeaderAdmin';
import SideBar from '../../components/SideBar';
import FooterComp from '../../components/Footer'
import {isBrowser,resizeDebounceTime , smWindow ,lgWindow} from '../../utility/settings'
import urls from '../../utility/urls';
import { debounce} from 'lodash';


import './index.scss'
import ProjectsForUser from '../Participants/ProjectsForUser';
import ParticipantsSchedulePage from '../Participants/Schedule'
import ParticipantsAppointmentPage from '../Participants/Appointment'

const { Header, Sider, Content, Footer } = Layout;

const UserPage = () => {

    let location = useLocation();
    const [visible , setVisible] = useState(false) 
    const [sizeWindow , setSizeWindow] = useState(window.innerWidth)

    useEffect(() => {
        window.addEventListener('resize', handleResize);
       return () => {
          isBrowser && window.removeEventListener('resize', handleResize);
       }
    })
    const handleResize = debounce(() => {
        setSizeWindow(window.innerWidth)
    }, resizeDebounceTime);

    
    const showDrawer = () => {
        setVisible(!visible)
    }

    return ( 
            <Layout id="user-layout">
                 <Header>
                     <HeaderAdmin showDrawer={showDrawer}  sizeWindow={sizeWindow}/>
                </Header>
                {sizeWindow&& sizeWindow > lgWindow ?
                <Layout>
                        <Sider className="sidebar" width={125} theme="light"> 
                            <SideBar />
                        </Sider>
                <Layout>
                    <Content>
                        <Switch location={location}>
                            <Route exact path={`${urls.USER_PROJECT_LIST_DETAILS}/:projectId`} component={ParticipantsSchedulePage} />
                            <Route
                                exact
                                path={`${urls.USER_APPOINTMENT}/:projectId`}
                                component={ParticipantsAppointmentPage}
                                />
                            <Route exact path={urls.USER_PROJECT_LIST} component={ProjectsForUser} />
                            <Redirect to={urls.USER_HOME} />
                        </Switch>
                    </Content>

                    <Footer>
                        <FooterComp  />
                    </Footer>
                </Layout>
            </Layout>
            :
            <Layout>
                   {sizeWindow > smWindow && sizeWindow <= lgWindow &&   
                    <Sider className="responsiveSidebar" width={"100%"} theme="light">
                        <SideBar />
                    </Sider>  
                }
                {visible && sizeWindow <= smWindow && 
                    <Sider className="responsiveSidebar" width={"100%"} theme="light">
                        <SideBar />
                    </Sider>  
                }
                 <Layout>
                    <Content width={"100%"}>
                            <Switch location={location}>
                                <Route exact path={`${urls.USER_PROJECT_LIST_DETAILS}/:projectId`} component={ParticipantsSchedulePage} />
                                <Route exact path={urls.USER_PROJECT_LIST} render={(props) => <ProjectsForUser sizeWindow={sizeWindow} />} />
                                <Route
                                    exact
                                    path={`${urls.USER_APPOINTMENT}/:projectId`}
                                    component={ParticipantsAppointmentPage}
                                 />
                                <Redirect to={urls.USER_HOME} />
                            </Switch>
                    </Content>      
                </Layout>
                    <Footer>
                        <FooterComp isAdmin={true} />
                    </Footer>
                </Layout>
            }
        </Layout>
    )
}
export default  connect()(UserPage);;