import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { Login } from '@microsoft/mgt-react';
import { Providers } from '@microsoft/mgt-element';
import { Form, Modal , Select  } from 'antd';
import { Backdrop, CircularProgress,TextField } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useMount } from 'ahooks';

import { useAuth } from '../../../components/Auth/auth';

import urls from '../../../utility/urls';

import {errorMapper, regex , validateDomain} from '../../../utility'
import api from '../../../library/api'

import '../style.scss';
import AuthHelper from '../../../library/helpers/AuthHelper';
import TextArea from 'antd/lib/input/TextArea';
import FooterComp from '../../../components/Footer';

const { Option } = Select;

const LoginAdminPage = (props) => {

    const [isLoggedIn, setLoggedIn] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [requestAdminModal,setRequestAdminModal] = useState(false)
    const [lastName , setLastName] = useState("") 
    const [firstName , setFirstName] = useState("") 
    const [reasonOfAccess , setReasonOfAccess] = useState("") 
    const [site,setSite] = useState('')
    const [email,setEmail] = useState('')
    const [sites,setSites] = useState([])
    const { enqueueSnackbar } = useSnackbar();
    const { setAuthTokens  } = useAuth();
    const [form] = Form.useForm();
    const authHelper = new AuthHelper();
    useMount(() => {
    
    api.admins
        .getSitesAdmin()
        .then((d) => {
            if (d.messages === 'success') {
                setSites(d.data)
            }
        })
        .catch((e) => {
            enqueueSnackbar('Server error!', { variant: 'error' });
        });
    });

    if (isLoggedIn) {
        const checkState = props.location.state || { referer: null };
        const referer = checkState.referer || urls.ADMIN_HOME;
        return <Redirect to={referer} />;
    }

    Providers.globalProvider.onStateChanged(() => {
        /*if(Providers.globalProvider.state === ProviderState.SignedIn){
			Providers.globalProvider.getAccessToken()
					.then((v) => {
						setAuthTokens(v);
					})
					.catch((e) => {
						console.info(e);
						enqueueSnackbar("No access token", {variant : 'error'});
					});
			
			setLoggedIn(true);
		}*/
    });
    const onLoginInitiated = () => {
        setIsLoading(true);
    };

    const onLoginCompleted = () => {
        //once PingId completed
        setIsLoading(true);
        Providers.globalProvider.graph.client.api('me').get()
        .then(async (adminDetails) =>  {
                const res = await api.user.Login({
                    email :  adminDetails.mail,
                    password : '' ,
                    role :'admin'
                })
                if(res.status === 'success') { 
                    authHelper.setRole(res.user.role)
                    authHelper.setUserDetails(adminDetails)
                    authHelper.setUserDetailsAdmin(res.user)
                    authHelper.setRefreshToken(res.refreshToken)
                    setLoggedIn(true)
                    setAuthTokens(res.token)
                    setIsLoading(false);
                }else {
                    setIsLoading(false);
                    enqueueSnackbar("You do not have access privileges to project" , { variant: 'error' });
                    setLoggedIn(false)
                }
        })
       
    }

    const onLoginFailed = () => {
        setIsLoading(false);
    };

    const onLogoutInitiated = () => {};

    const onLogoutCompleted = () => {
        setAuthTokens();
    };

    const onRequestAdmin = (values) =>  {
        setIsLoading(true);
        api.admins.requestSiteAdmin(values)
            .then( res => {
                setIsLoading(false);

                if (res.messages === 'success') {
                    enqueueSnackbar("Send admin request access successfully", { variant :'success'});
                    setRequestAdminModal(false)
                } else {
                    enqueueSnackbar(errorMapper(res.messages), { variant: 'warning' });
                }
            }).catch(err => {
                setIsLoading(false);
                enqueueSnackbar('An error occurred while sending email. Please contact system admin for help.', { variant: 'error' });
            })
    }

    const onSubmitValues = (values) => {
        console.log(values)
        if(values) {
            onRequestAdmin(values)
        }
    }
    return (
        <div id="login-page">
            <div className="login-container">
                <div className="login-cont-left">
                    <img src="/assets/login_banner_bg_1.png" alt="P&G People" />
                </div>
                <div className="login-cont-right">
                    <div className="login-content">
                        <div className="login-logo">
                            <img src="/assets/png_logo@2x.png" alt="P&G Logo" />
                        </div>

                        <h2>
                            Welcome <br /> Please login to your account.
                        </h2>
                        <div className="login-form">
                            <div className="login-btn">
                                <Login
                                    loginInitiated={onLoginInitiated}
                                    loginCompleted={onLoginCompleted}
                                    loginFailed={onLoginFailed}
                                    logoutInitiated={onLogoutInitiated}
                                    logoutCompleted={onLogoutCompleted}
                                />
                            </div>
                        </div>
                        <div className='request-admin'>
                          Don't have an admin account? <a onClick={() => setRequestAdminModal(true)} className={'request-text'} >Request here</a>
                        </div>
                    </div>
                </div>
                <FooterComp />
            </div>

        <Modal
                 title ="Request Access"
                 width={450}
                 centered={true}
                 visible={requestAdminModal}
                 okText="save"
                 onOk={async() => { 
                    form.submit()
                }}
                 onCancel = { () => setRequestAdminModal(false)}
            >

            <Form
                       labelCol={{ span: 8 }}
                       wrapperCol={{ span: 14 }}
                       layout="horizontal"
                       form={form}
                       onFinish={onSubmitValues}
                       initialValues={{email : '' , site : '' , firstName : '' , lastName :'' ,reasonOfAccess : ''}}
                >
                    <Form.Item 
                         label={'Email Address'}
                         name={'email'}
                         labelAlign={'left'}
                         rules={[{ required: true, message: 'Email is required' }]}
                    >
                            <TextField
                            required={true}
                            value={email}
                            placeholder ={'sample@pg.com'}
                            onChange={(e) => {
                                setEmail(e.target.value);
                            }}
                            variant="outlined"
                            fullWidth={true}
                            error={email !== '' && (!validateDomain(email,"pg")) }
                            helperText={email && (!validateDomain(email,"pg") ) ? 'Invalid format.' : ''}
                        />
                    </Form.Item>
                    <Form.Item
                        label={'First Name'}
                        name={'firstname'}
                        labelAlign={'left'}
                        rules={[{ required: true, message: 'First Name is required' }]}
                    >
                        <TextField
                        required={true}
                        value={firstName}
                        onChange={(e) => {
                            setFirstName(e.target.value);
                        }}
                        variant="outlined"
                        fullWidth={true}
                    />
                    </Form.Item>
                  
                <Form.Item
                      label="Last Name"
                      name="lastname"
                      labelAlign={'left'}
                      rules={[{ required: true, message: 'Last Name is required' }]}
                >
                    <TextField
                    required={true}
                    value={lastName}
                    onChange={(e) => {
                        setLastName(e.target.value);
                    }}
                    variant="outlined"
                    fullWidth={true}
                    />
                </Form.Item>
                   
                <Form.Item
                     label="Site"
                     name="site"
                     labelAlign={'left'}
                     rules={[{ required: true, message: 'Site is required' }]}
                >
                        <Select
                            placeholder={'Select site'}
                            allowClear={true} 
                            value={site}
                            style={{ width: '100%', marginBottom : '10px'}}
                            dropdownStyle={{ zIndex: 2000 }} 
                            onChange={(e) => {
                                setSite(e)
                            }}
                            onClear={e => {
                                setSite('')
                            }}
                            options={sites || []}
                        />
                </Form.Item>
                <Form.Item
                                label="Reason of access"
                                name="reasonOfAccess"
                                labelAlign={'left'}
                            >
                                <TextArea value={reasonOfAccess} onChange={(e) => setReasonOfAccess(e.target.value)} rows={5} />
                            </Form.Item>
            </Form>
        </Modal>
            <Backdrop id="loading-dialog" open={isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
};

export default connect()(LoginAdminPage);
