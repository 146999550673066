
import React, { useEffect, useState } from "react";
import {Form , Button , Input} from 'antd'
import Mail from '@material-ui/icons/Mail'
import {useSnackbar } from 'notistack'

import './index.scss'
import '../../pages/Login/style.scss'
import api from "../../library/api";
import urls from "../../utility/urls";
import { errorMapper } from '../../utility';
import { Backdrop, CircularProgress } from "@material-ui/core";
import FooterComp from '../../components/Footer';

const ForgetPassword = (props) => {
    
    const { history } = props;
    const [form] = Form.useForm();
    const [isLoading , setIsLoading] = useState(false)
    useEffect(() => {
        document.title = "OnTheDot Panelist Portal"
      }, [])
    const handleReset = async (data) => {
        setIsLoading(true)
        await  api.user.forgetPassword(data)
            .then((res) => {
                if(res.messages === "success") {
                    enqueueSnackbar(`${res.data}` , {variant : 'success'})
                    history.push(urls.USER_LOGIN);  
                }else {
                    // enqueueSnackbar(`${res.data}` , {variant : 'warning'})
                    enqueueSnackbar(errorMapper(res.messages),{
                        variant: 'error',
                        autoHideDuration: 9000,
                });
                }
                setIsLoading(false)               
            }).catch((e) => {
                console.error(e);
                enqueueSnackbar('Failed' , {variant : 'error'})
                setIsLoading(false)    
            }) 
            
    };

    const onFinishFailed = (errInfo) => {
        enqueueSnackbar('Failed:'+errInfo.message , {variant : 'error'})
    }

    const { enqueueSnackbar } = useSnackbar();
  
    return (
     <div id="login-page" className="forgot_password">
        <div className="login-container">
            <div className="login-cont-left">
                <img src="/assets/login_banner_bg_1.png" alt="P&G People" />
            </div>
            <div className="login-cont-right">
                <div className="login-content">
                    <div className="login-logo">
                        <img src="/assets/png_logo@2x.png" alt="P&G Logo" />
                    </div>

                    <p>
                        Reset Password <br /> Please insert the email address you used to register and we'll send you
                        a reset password link.
                    </p>
                    <Form
                            name="ForgotPassword"
                            form={form}
                            layout="vertical"
                            onFinish={handleReset}
                            onFinishFailed={onFinishFailed}
                    >
                        <div className="text-center">
                            <Form.Item
                                label="Email"
                                name="email"
                                className="email"
                                rules={[{pattern :/^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/i, message: 'Please enter a valid E-mail!',}]}
                            >
                                <Input
                                    prefix={<Mail className='icon-email'/>} placeholder="Email"  size='large'
                                />
                            </Form.Item>
                            <Form.Item
                            >
                                <Button
                                    className="btn-sign-in"
                                    type="primary" htmlType="submit"
                                >
                                Reset
                                </Button>
                            </Form.Item>
                         </div>
                    </Form>
                </div>
            </div>
      </div>
      <FooterComp/>
      <Backdrop id="loading-dialog" open={isLoading}>
                <CircularProgress color="inherit" />
      </Backdrop>
    </div>
    );
  }
  
  export default ForgetPassword;