import React from "react"
export const ROLES = {
    adminGlobal: "global-admin",
    adminSuper: "super-admin",
    siteAdmin : "site-admin",
    projAdmin: "proj-admin",
    adminFull: "proj-admin-full",
    adminView: "proj-admin-view",
    adminEdit : "proj-admin-edit",
    adminFnameOnly: "proj-admin-view-fname-only",
    PANELIST: 'view_only'
}

//const error message 
export const error_massage_password = (showLastOption) =>  
        <ul>
            Passwords must:
            <li>a) be at least 8 characters in length</li>
            <li>b) include at least 1 uppercase character (A-Z)</li>
            <li>c) include at least 1 uppercase character (a-z)</li>
            <li>d) include at least 1 number character (0-9)</li>
            <li>e) include at least 1 special character: ! # $ ( ) , - / : ? _</li>
            {showLastOption && <li>f) not match any of your last 8 passwords</li> }
        </ul>
export const WAIT_INACTIVE_USER = 300000
export const SESSION_EXPIRES_IN  = 1500 * 1000  // 1500 * 1000  25 min
export const DEBOUNCE = 250

// Allowed extensions for input file
export const allowedExtensions = ["application/vnd.ms-excel","application/vnd.openxmlformats-officedocument.spreadsheetml.sheet","text/csv"]

//Emoplye Status 
export const EMPLOYEE_STATUS = [{ label : 'FTE' , value :'FTE'},{ label : 'Intern' , value :'Intern'}, { label : 'Contractor' , value :'Contractor'}, { label : 'External' , value :'External'}];

// SIZE LIMT FILE UPLOAD
export const FILE_SIZE_LIMIT_UPLOAD = 100000000

//LIST PDF
export const LIST_PDF = [{filename : 'OnTheDot - Panelist User Guide for dashboard upload (eng and Jp).pdf' }]