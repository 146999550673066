import React from 'react';

import { SvgIcon } from '@material-ui/core';

const BurgerIcon = (props) => {
    return (
        <SvgIcon {...props}>
            <path id="prefix__Path_846" fill="none" d="M0 0h24v24H0z" data-name="Path 846" />
            <path
                id="prefix__Path_847"
                fill="#014071"
                d="M15.776 17H4v2h11.776zM11.4 9H4v2h7.4zM4 15h16v-2H4z"
                data-name="Path 847"
                transform="translate(0 -2)"
            />
        </SvgIcon>
    );
};

export default BurgerIcon;
