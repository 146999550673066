import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useMount, useUpdateEffect } from 'ahooks';

import { PageHeader, Table, Tag, Breadcrumb, Input, Modal, Form, Row, Col, Descriptions } from 'antd';

import { Backdrop, Button, IconButton, CircularProgress, TextField } from '@material-ui/core';
import { SearchOutlined, DeleteForever, Edit, Add, Search } from '@material-ui/icons';
import { useSnackbar } from 'notistack';

import urls from '../../utility/urls';
import { errorMapper, regex } from '../../utility';
import api from '../../library/api';
import AuthHelper from '../../library/helpers/AuthHelper'
import PopConfirm from '../../components/PopConfirm';
import RegexTextField from '../../components/RegexTextField';
import _ from 'lodash'
import './style.scss';
import EmailFields from '../../components/EmailFields';
import { ROLES } from '../../utility/constant';
import CustomTemplate from './CustomTemplate';
import ExcelButton from '../../components/ExcelButton';
import Divider from '../../components/DividerWithText';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const statusColor = {
    open: 'green',
    booked: 'blue',
    invited: 'yellow',
    cancelled: 'red',
    disabled: 'gray',
};

const PanelistDetailsPage = (props) => {
    const historyLink = useHistory();
    let { ProjectID } = useParams();
    const projectObj = useRef({
        project: {},
        project1: {}
    });
    const [form] = Form.useForm();
    const currentRowData = useRef({});

    const [data, setData] = useState({ original: [], filtered: [] });
    const [isLoading, setIsLoading] = useState(false);
    const [openCreatePanelModal, setOpenCreatePanelModal] = useState(false);
    const [IsCreateModal, setIsCreateModal] = useState(true);

    const [email, setEmail] = useState('');
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [mobile, setMobile] = useState('');
    const [comments, setComments] = useState('');
    const [showLastName, setShowLastName] = useState(true);
    const [showActions, setShowActions] = React.useState(true)
    const [emailEditor, setEmailEditor] = useState(false);
    const [showCustomTemplateModal, setShowCustomTemplateModal] = useState(false)

    const [fileExcel, setFileExcel] = useState(null)
    const [showModalPublish, setShowModalPublish] = useState(false)
    const [files, setFiles] = useState([])
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [copyAdminUrl, setCopyAdminUrl] = useState({
        value: '',
        copied: false,
    });
    const [copyPanelistUrl, setCopyPanelistUrl] = useState({
        value: '',
        copied: false,
    });

    const auth = new AuthHelper()

    const { enqueueSnackbar } = useSnackbar();

    useMount(async () => {
        await fetchData(ProjectID);
    });

    useEffect(() => {
        (async () => {
            (auth.getRole() === ROLES.projAdmin || auth.getRole() === ROLES.adminSuper) && await checkUserIsFirstnameOnly(ProjectID)

        })();
    }, [])
    useUpdateEffect(() => {
        fetchData(ProjectID);
    }, []);
    useUpdateEffect(() => { }, [email, firstname, lastname, mobile]);

    const fetchData = (id) => {
        setIsLoading(true);

        Promise.all([api.projects.fetchById(id), api.participants.fetchById(id)])
            .then((d) => {
                setIsLoading(false);

                if (d[0].messages === 'success' && d[1].messages === 'success') {
                    projectObj.current.project1 = d[0].data
                    setData({ original: d[1].data, filtered: d[1].data });
                    projectObj.current.project = d[0].data;
                } else {
                    enqueueSnackbar(
                        errorMapper(d[0].messages) + errorMapper(d[1].messages),
                        { variant: 'warning' }
                    );
                }
            })
            .catch((e) => {
                setIsLoading(false);
                enqueueSnackbar('An error occurred while getting project information. Please contact system admin for help.', { variant: 'error' });
            });
    };
    const checkUserIsFirstnameOnly = (id) => {
        setIsLoading(true);

        api.AdminPerProject
            .getProjectAdminByProjectId(id)
            .then((d) => {
                setIsLoading(false);

                if (d.messages === 'success') {
                    if (d.data === ROLES.adminFnameOnly) {
                        setShowLastName(false)
                        setShowActions(false)
                    } else if (d.data === ROLES.adminView) {
                        setShowActions(false)
                        setShowLastName(true)
                    } else {
                        setShowLastName(true)
                        setShowActions(true)
                    }
                } else {
                    enqueueSnackbar(errorMapper(d.messages), { variant: 'warning' });
                }
            })
            .catch((e) => {
                setIsLoading(false);
                enqueueSnackbar('Server error!', { variant: 'error' });
            });
    }
    const deleteParticipants = (id) => {
        setIsLoading(true);

        api.participants
            .delete(id, ProjectID)
            .then((d) => {
                setIsLoading(false);

                if (d.messages === 'success') {
                    fetchData(ProjectID);
                    enqueueSnackbar('Deleted successfully', { variant: 'success' });
                    setSelectedRowKeys([])
                } else {
                    enqueueSnackbar(errorMapper(d.messages), { variant: 'warning' });
                }
            })
            .catch((e) => {
                setIsLoading(false);
                enqueueSnackbar('An error occurred while deleting panelist. Please contact system admin for help.', { variant: 'error' });
            });
    };

    const deleteMultipleParticipants = (arrID) => {
        let arr = [];

        setIsLoading(true);

        api.participants.deleteMultipleParticipants(arrID, ProjectID)
            .then((d) => {
                setIsLoading(false);

                if (d.messages === 'success') {
                    fetchData(ProjectID);
                    enqueueSnackbar('Successfully deleted Panelist/s', { variant: 'success' });
                    setSelectedRowKeys([])
                } else {
                    enqueueSnackbar(errorMapper(d.messages), { variant: 'warning' });
                }
            })
            .catch((e) => {
                setIsLoading(false);
                enqueueSnackbar('An error occurred while deleting panelist. Please contact system admin for help.', { variant: 'error' });
            });
    };


    const createParticipants = (obj) => {
        setIsLoading(true);
        obj.filtered = []
        obj.original = []

        const form_data = new FormData();
        for (let i = 0; i < files.length; i++) {
            form_data.append('files', files[i]);
        }
        setFiles([])
        api.participants
            .create(obj, form_data)
            .then((d) => {
                setIsLoading(false);

                if (d.messages === 'success') {
                    fetchData(ProjectID);
                    setEmail([])
                    setOpenCreatePanelModal(false);
                    enqueueSnackbar('Created successfully', { variant: 'success' });
                    historyLink.push(`${urls.ADMIN_PROJECT_LIST_PANELIST_DETAILS}/${ProjectID}`);
                    setIsLoading(false)
                    setEmailEditor(false)
                    setSelectedRowKeys([])
                } else {
                    if (d.code === 500) {
                        if (d?.messages.errors[0].type === 'unique violation') {
                            enqueueSnackbar("You can't use invalid or duplicate emails.", { variant: 'warning' });
                        } else {
                            enqueueSnackbar('An error occurred while creating panelist. Please contact system admin for help.', { variant: 'warning' });
                        }
                    } else {
                        enqueueSnackbar(errorMapper(d.messages), { variant: 'warning' });
                    }
                    setIsLoading(false)
                    setEmailEditor(false)
                }
            })
            .catch((e) => {
                setEmailEditor(false)
                setIsLoading(false)
                enqueueSnackbar('An error occurred while creating panelist. Please contact system admin for help.', { variant: 'error' });
            });


    };

    const updateParticipants = (obj) => {
        setIsLoading(true);
        api.participants
            .update(obj)
            .then((d) => {
                setIsLoading(false);

                if (d.messages === 'success') {
                    currentRowData.current = {};
                    fetchData(ProjectID);
                    setOpenCreatePanelModal(false);
                    enqueueSnackbar('Updated successfully', { variant: 'success' });
                    setSelectedRowKeys([])
                } else {
                    enqueueSnackbar(errorMapper(d.messages), { variant: 'warning' });
                }
            })
            .catch((e) => {
                setIsLoading(false);
                enqueueSnackbar('An error occurred while updating panelist. Please contact system admin for help.', { variant: 'error' });
            });
    };
    const getColumns = () => [
        {
            title: 'Email Address',
            align: 'left',
            width: 250,
            fixed: 'left',
            dataIndex: 'email',
            key: 'email',
            render: (text, record) => <span>{`${text}`}</span>,
        },
        {
            title: 'Last Name',
            align: 'left',
            width: 250,
            dataIndex: 'last_name',
            key: 'last_name',
            className: !showLastName ? 'hide' : '',
            render: (text, record) => <span>{text}</span>,
        },
        {
            title: 'First Name',
            align: 'left',
            width: 250,
            dataIndex: 'first_name',
            key: 'first_name',
            render: (text, record) => <span>{text}</span>,
        },
        {
            title: 'Phone Number',
            align: 'center',
            width: 230,
            dataIndex: 'mobile',
            key: 'mobile',
        },
        {
            title: 'Status',
            align: 'center',
            width: 140,
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => <Tag color={statusColor[text]}>{String(text).toUpperCase()}</Tag>,
        },
        {
            title: 'Panelist ID',
            align: 'left',
            width: 250,
            dataIndex: 'panelist_id',
            key: 'panelist_id',
            render: (text, record) => <span>{text}</span>,
        },
        {
            title: 'T-number',
            align: 'left',
            width: 250,
            dataIndex: 't_number',
            key: 't_number',
            render: (text, record) => <span>{text}</span>,
        },
        {
            title: 'Comments',
            align: 'left',
            width: 300,
            dataIndex: 'project_participant[0].comment',
            key: 'comment',
            render: (text, record) => {
                return record.project_participant[0].comment
            }
        },
        {
            title: 'Action',
            width: 100,
            editable: false,
            fixed: 'right',
            align: 'center',
            className: !showActions || (selectedRowKeys.length > 1) ? 'hide' : '',
            render: (text, record) => showActions && (
                <div className="project-management-page-table-row-ctrl">
                    <IconButton
                        size="small"
                        disableRipple={true}
                        aria-label="Edit"
                        onClick={onEditPanelist.bind(this, record)}
                    >
                        <Edit fontSize="small" />
                    </IconButton>
                    <PopConfirm
                        title="Are you sure you want to delete this panelist?"
                        onConfirm={onDeletePanelist.bind(this, record)}
                    >
                        <IconButton size="small" disableRipple={true} aria-label="delete" color="secondary">
                            <DeleteForever />
                        </IconButton>
                    </PopConfirm>
                </div>
            ),
        },
    ];

    const onEditPanelist = (rec) => {
        currentRowData.current = rec;
        setIsCreateModal(false);
        setOpenCreatePanelModal(true);
        setEmail(currentRowData.current.email);
        setFirstname(currentRowData.current.first_name || '');
        setLastname(currentRowData.current.last_name || '');
        setMobile(currentRowData.current.mobile || '');
        setComments(currentRowData.current?.project_participant[0]?.comment || '');
    };

    const onDeletePanelist = (rec) => {
        currentRowData.current = rec;
        deleteParticipants(rec.id);
    };

    const rowSelection = {
        checkStrictly: true,
        fixed: true,
        selectedRowKeys: selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
        },
        onSelect: (record, selected, selectedRows) => {
            if (selected) {
                setSelectedRowKeys([...selectedRowKeys, record.id]);
            } else {
                setSelectedRowKeys(selectedRowKeys.filter((r) => r !== record.id));
            }
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
        },
    };
    const onAddPanelistClick = () => {
        setEmail('');
        setFirstname('');
        setLastname('');
        setMobile('');
        setComments('');
        setIsCreateModal(true);
        setOpenCreatePanelModal(true);
    };
    const onCLoseEditEmailTemplate = () => {
        setEmailEditor(false);
    };
    const onSaveCreatePanelist = () => {
        if (!fileExcel) {
            if (!email) {
                enqueueSnackbar('Email Address is required!', { variant: 'warning' });
                return;
            }

            if (!regex.email.test(email) && !IsCreateModal) {
                enqueueSnackbar('Email Address is invalid!', { variant: 'warning' });
                return;
            }
        }
        if (typeof fileExcel === "string") {
            enqueueSnackbar(fileExcel, { variant: 'warning' });
            return;
        }
        if (IsCreateModal) {
            if (projectObj.current.project.is_published) {
                setShowModalPublish(true)
            } else {
                setShowModalPublish(false)
                createParticipants({
                    project_id: ProjectID,
                    isCreated: true,
                    email: email || fileExcel,
                    first_name: firstname,
                    last_name: lastname,
                    mobile: mobile,
                    ...data
                });
            }

        } else {
            updateParticipants({
                id: currentRowData.current.id,
                isCreated: false,
                project_id: ProjectID,
                email: email,
                first_name: firstname,
                last_name: lastname,
                mobile: mobile,
                comment: comments
            });
        }
    };


    const onOpenEditEmailTemplate = (e) => {

        if (projectObj.current.project.is_published) {
            //show custom template if is created panelist to send attachments.
            setShowCustomTemplateModal(true)
            setEmailEditor(true)
            setShowModalPublish(false)
            setOpenCreatePanelModal(false)
        } else {
            createParticipants({
                project_id: ProjectID,
                isCreated: true,
                email: email || fileExcel,
                first_name: firstname,
                last_name: lastname,
                mobile: mobile,
                ...data
            });
        }
    };

    const onSaveData = (data) => {
        setIsLoading(true)
        createParticipants({
            project_id: ProjectID,
            isCreated: true,
            email: email || fileExcel,
            first_name: firstname,
            last_name: lastname,
            mobile: mobile,
            ...data
        })
    }

    const onResetCreatePanelist = (e) => {
        const closeBtn = e.currentTarget.getAttribute('aria-label') === 'Close';

        if (closeBtn) {
            setOpenCreatePanelModal(false);
        } else {
            setEmail(IsCreateModal ? [] : email);
            setFirstname('');
            setLastname('');
            setMobile('');
            setComments('');
        }
    };

    const _handleSearch = (e) => {
        const text = e.target.value;

        setData({
            ...data,
            filtered: data.original.filter(
                (panelist) =>
                    (panelist?.first_name && panelist.first_name.toLowerCase().includes(text.toLowerCase())) ||
                    (panelist?.last_name && panelist.last_name.toLowerCase().includes(text.toLowerCase())) ||
                    (panelist?.email && panelist.email.toLowerCase().includes(text.toLowerCase())) ||
                    (panelist?.mobile && panelist.mobile.toLowerCase().includes(text.toLowerCase()))
            ),
        });
    };



    const _handleClearSearch = (e) => {
        const text = e.target.value;
        if (text === '') {
            setData({
                ...data,
                filtered: data.original.filter(
                    (panelist) =>
                        (panelist?.first_name && panelist.first_name.toLowerCase().includes(text.toLowerCase())) ||
                        (panelist?.last_name && panelist.last_name.toLowerCase().includes(text.toLowerCase())) ||
                        (panelist?.email && panelist.email.toLowerCase().includes(text.toLowerCase())) ||
                        (panelist?.mobile && panelist.mobile.toLowerCase().includes(text.toLowerCase()))
                ),
            });
        }
    }

    const onSaveCopyProject = (e) => {
        if (projectObj.current.project.is_published) {
            //show custom template if is created panelist to send attachments.
            setIsLoading(true)
            createParticipants({
                project_id: ProjectID,
                isCreated: true,
                email: email || fileExcel,
                first_name: firstname,
                last_name: lastname,
                mobile: mobile,
                isCopyUrlProject: true,
                ...data
            })
            setShowModalPublish(false)
        } else {
            createParticipants({
                project_id: ProjectID,
                isCreated: true,
                email: email || fileExcel,
                first_name: firstname,
                last_name: lastname,
                mobile: mobile,
                ...data
            });
        }
    };
    const onRemove = () => {
        setFileExcel(null)
    }

    const importFile = (file) => {
        setFileExcel(file)
    }

    const onMultipleDeleteSchedule = () => {
        deleteMultipleParticipants(selectedRowKeys);
    }
    const onClickPanelistProjectLogTracker = () => {
        historyLink.push(`${urls.ADMIN_PROJECT_LIST_PANELIST_lOG_TRACKER}/${ProjectID}`);

    }
    return (
        <div id="project-management-page">
            <Breadcrumb>
                <Breadcrumb.Item>
                    <a href={urls.ADMIN_PROJECT_LIST}>Project List</a>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <a href={`${urls.ADMIN_PROJECT_LIST_DETAILS}/${ProjectID}`}>Project Details</a>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Panelist Details</Breadcrumb.Item>
            </Breadcrumb>
            <PageHeader
                ghost={false}
                title="Panelist Details"
                extra={showActions && [
                    <Button
                        key="1"
                        variant="contained"
                        color="primary"
                        startIcon={<Add />}
                        disableRipple={true}
                        onClick={onAddPanelistClick}
                    >
                        Panelist
                    </Button>,
                ]}
                onBack={() => {
                    historyLink.push(`${urls.ADMIN_PROJECT_LIST_DETAILS}/${ProjectID}`);
                }}
            >
                <div className="page-header-desc">
                    <div className="page-header-desc-left">
                        <Descriptions size="default" column={1}>
                            <Descriptions.Item label="Project ID">{projectObj.current.project1.pr_id}</Descriptions.Item>
                            <Descriptions.Item label="Project Type">
                                {projectObj.current.project1.type}
                            </Descriptions.Item>
                            <Descriptions.Item label="Instruction">
                                {projectObj.current.project1.book_instructions}
                            </Descriptions.Item>
                        </Descriptions>
                    </div>
                    <div className="page-header-desc-right">
                        <Descriptions size="default" column={1}>
                            <Descriptions.Item label="Project Name">
                                {projectObj.current.project1.name}
                            </Descriptions.Item>
                            <Descriptions.Item label="Panelist">
                                {projectObj.current.project1.panel_count}
                            </Descriptions.Item>
                            <Descriptions.Item label="Booked">
                                {projectObj.current.project1.booked_cnt}
                            </Descriptions.Item>
                        </Descriptions>
                    </div>
                </div>
            </PageHeader>

            <div className="project-management-page-content">
                <div className="project-management-page-table-container">
                    <div className="project-management-page-table-controls">
                        <div className="project-management-page-table-controls-left">
                            <Input
                                className="searchbox"
                                size="middle"
                                prefix={<SearchOutlined />}
                                placeholder="Search"
                                onPressEnter={_handleSearch}
                                onChange={_handleClearSearch}
                            />
                        </div>

                        <div className="project-management-page-table-controls-right">
                            <Button
                                startIcon={<Search />}
                                disableRipple={true}
                                onClick={onClickPanelistProjectLogTracker}
                            >
                                Log Tracker
                            </Button>
                            {selectedRowKeys.length > 0 && showActions && (
                                <PopConfirm
                                    title={"Are you sure you want to delete this panelist?"}
                                    onConfirm={onMultipleDeleteSchedule}
                                >
                                    <Button startIcon={<DeleteForever />} disableRipple={true}>
                                        Delete
                                    </Button>
                                </PopConfirm>
                            )}
                        </div>
                    </div>

                    <Table
                        className="project-management-page-table"
                        columns={getColumns()}
                        bordered={false}
                        rowKey={'id'}
                        sticky={true}
                        showHeader={true}
                        tableLayout="fixed"
                        rowSelection={{ ...rowSelection }}
                        pagination={{
                            showTotal: (total, range) => {
                                return `${range[0]}-${range[1]} of ${total} items`;
                            },
                            defaultCurrent: 1,
                            defaultPageSize: 10,
                            pageSizeOptions: [10, 20, 30, 50, 100],
                            showSizeChanger: true,
                            position: ['bottomRight'],
                            total: data.filtered.length,
                        }}
                        dataSource={data.filtered}
                        scroll={{
                            y: '50vh',
                            x: '100%',
                        }}
                    ></Table>
                </div>
            </div>

            <Modal
                title={IsCreateModal ? 'Add Panelist' : 'Edit Panelist'}
                width={450}
                visible={openCreatePanelModal}
                centered={true}
                okText="Save"
                onOk={onSaveCreatePanelist}
                cancelText="Reset"
                onCancel={onResetCreatePanelist}
            >
                {!IsCreateModal ? <TextField
                    required={true}
                    label="Email Address"
                    disabled={!IsCreateModal}
                    value={email}
                    onChange={(e) => {
                        setEmail(e.target.value);
                    }}
                    placeholder="Email Address"
                    variant="outlined"
                    fullWidth={true}
                    error={email !== '' && !regex.email.test(email)}
                    helperText={email && !regex.email.test(email) ? 'Invalid format.' : ''}
                />
                    :
                    <>
                        <Row >
                            <Col>
                                <EmailFields
                                    value={email}
                                    onChange={(e) => {
                                        setEmail(e);
                                        setFileExcel(null)
                                    }}
                                />

                            </Col>
                        </Row>
                        <Divider>Or</Divider>
                        <Row>
                            <Col>
                                <p>
                                    You can download this <a className='sample-excel' href={`${process.env.PUBLIC_URL}/assets/files/Sample.xlsx`} download="Sample">sample Excel file</a>, modify it and upload it to the system.
                                </p>
                                <ExcelButton
                                    fileExcel={fileExcel}
                                    onRemove={onRemove}
                                    enqueueSnackbar={enqueueSnackbar}
                                    setFileExcel={importFile}
                                    setEmail={setEmail}
                                />
                            </Col>
                        </Row>
                    </>


                }
                {!IsCreateModal &&
                    <>
                        <RegexTextField
                            required={true}
                            label="First Name"
                            regex={regex.charsAndSpaces}
                            value={firstname}
                            onChange={(e) => {
                                setFirstname(e.target.value);
                            }}
                            placeholder="First Name"
                            variant="outlined"
                            fullWidth={true}
                        />
                        <RegexTextField
                            required={true}
                            label="Last Name"
                            regex={regex.charsAndSpaces}
                            value={lastname}
                            onChange={(e) => {
                                setLastname(e.target.value);
                            }}
                            placeholder="Last Name"
                            variant="outlined"
                            fullWidth={true}
                        />
                        <RegexTextField
                            required={false}
                            label="Mobile"
                            regex={regex.mobileNumber}
                            value={mobile}
                            onChange={(e) => {
                                setMobile(e.target.value);
                            }}
                            placeholder="Mobile"
                            variant="outlined"
                            fullWidth={true}
                        />

                        <RegexTextField
                            required={false}
                            label="Comments"
                            value={comments}
                            onChange={(e) => {
                                setComments(e.target.value);
                            }}
                            placeholder="Comments"
                            variant="outlined"
                            fullWidth={true}
                        />
                    </>
                }
            </Modal>
            {

                showCustomTemplateModal && (<CustomTemplate
                    onCLoseEditEmailTemplate={onCLoseEditEmailTemplate}
                    emailEditor={emailEditor}
                    form={form}
                    defaultValue={projectObj.current.project}
                    onSubmit={onSaveData}
                    setFiles={setFiles}
                />)
            }
            {showModalPublish && (
                <Modal
                    title='How do you want to share the project?'
                    width={600}
                    visible={showModalPublish}
                    centered={true}
                    destroyOnClose={true}
                    footer={null}
                    keyboard={false}
                    onCancel={() => setShowModalPublish(false)}
                >
                    <CopyToClipboard text={api.utils.getURL(`${urls.ADMIN_BOOKING_DETAILS}/${ProjectID}`)} onCopy={() => {
                        setCopyAdminUrl({ value: api.utils.getURL(`${urls.ADMIN_BOOKING_DETAILS}/${ProjectID}`), copied: true })
                        onSaveCopyProject()
                    }}
                    >
                        <Button variant="contained" style={{ background: '#3f51b5', color: 'white', width: '300px', marginLeft: '20px', marginTop: '20px' }} startIcon={<Add />}>
                            copy Project Link for Admin
                        </Button>
                    </CopyToClipboard>
                    {copyAdminUrl.copied && <div className="link-copied">Link to '{projectObj.current.projName}' copied</div>}
                    <CopyToClipboard
                        text={api.utils.getURL(`${urls.PARTICIPANTS_HOME}/${ProjectID}`)} onCopy={() => {
                            setCopyPanelistUrl({ value: api.utils.getURL(`${urls.PARTICIPANTS_HOME}/${ProjectID}`), copied: true })
                            onSaveCopyProject()
                        }}>
                        <Button variant="contained" style={{ background: '#3f51b5', color: 'white', width: '300px', marginTop: '20px' }} startIcon={<Add />}>
                            copy Project Link for Panelist
                        </Button>
                    </CopyToClipboard>
                    {copyPanelistUrl.copied && <div className="link-copied">Link to '{projectObj.current.projName}' copied</div>}
                    <Button variant="contained" startIcon={<Add />} onClick={() => onOpenEditEmailTemplate()} style={{ marginTop: '20px' }}>
                        Invite by OntheDot email editor
                    </Button>
                </Modal>
            )}
            <Backdrop id="loading-dialog" open={isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
};

export default connect()(PanelistDetailsPage);
