import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';

import { Providers } from '@microsoft/mgt-element';
import { MsalProvider } from '@microsoft/mgt-msal-provider';

import App from './App';
import rootReducer from './redux/root-reducer';
import * as serviceWorker from './serviceWorker';
import urls from './utility/urls';
import api from './library/api';

import 'antd/dist/antd.css';
import './styles/index.scss';

/*Providers.globalProvider = new MsalProvider({
    clientId: '2436e5a3-e48b-476c-92fc-166a0fb73490',
    scopes: ['user.read'],
    loginType: 'LoginType.Redirect',
    authority: 'https://login.microsoftonline.com/8df62e91-1e61-4245-8a84-c468306d2a09',
    redirectUri: api.utils.getURL(urls.ADMIN_PAGE),
    postLogoutRedirectUri: api.utils.getURL(urls.LOGOUT_PAGE),
    navigateToLoginRequestUrl: true,
});*/

Providers.globalProvider = new MsalProvider({
	clientId: 'ce80583f-65bf-4d34-a2b6-59b769b6383d',
	scopes: ['user.read'],
	loginType: 'LoginType.Redirect',
	authority: 'https://login.microsoftonline.com/3596192b-fdf5-4e2c-a6fa-acb706c963d8',
	redirectUri: api.utils.getURL(urls.ADMIN_PAGE),
	postLogoutRedirectUri: api.utils.getURL(urls.LOGOUT_PAGE),
	navigateToLoginRequestUrl: true,
});

const store = configureStore({
    reducer: rootReducer,
});

const rootElement = document.getElementById('root');

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    rootElement
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
