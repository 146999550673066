import React, { useEffect, useState } from 'react';
import { PageHeader, Form, Input } from 'antd';
import { useParams } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { Backdrop, CircularProgress } from '@material-ui/core';
import { useSnackbar } from 'notistack';

import api from '../../library/api';
import { errorMapper } from '../../utility';
import './Help.scss';
import AuthHelper from '../../library/helpers/AuthHelper';

const ParticipantsHelp = () => {
    const [isLoading, setIsLoading] = useState(false);
    let { projectId } = useParams();
    useEffect(() => {
        document.title = "OnTheDot Panelist Portal"
    }, []);
    const { enqueueSnackbar } = useSnackbar();
    const authHelper = new AuthHelper()
    const _handleFormSubmit = (values) => {
        setIsLoading(true);
        api.email
            .getHelp(values)
            .then((d) => {
                setIsLoading(false);
                if (d.messages === 'success') {
                    enqueueSnackbar('Send help successfully', { variant: 'success' });
                } else {
                    enqueueSnackbar(errorMapper(d.messages), { variant: 'warning' });
                }
            })
            .catch((e) => {
                setIsLoading(false);
                enqueueSnackbar('An error occurred while sending email. Please contact system admin for help.', { variant: 'error' });
            });
    };

    return (
        <div className="participants-help-page">
            <PageHeader ghost={true} title="Get Help"></PageHeader>
            <div className="participants-help-form">
                <div className="form-header"></div>
                <Form className={"form-Help"} onFinish={_handleFormSubmit} 
                     initialValues={{ project_id: projectId  , email :authHelper.getUserDetails().email , name : authHelper.getUserDetails().first_name+' '+authHelper.getUserDetails().last_name || 'Panelist'}}>
                    <div className="form-content">
                        <Form.Item name="project_id" hidden>
                            <Input />
                        </Form.Item>
                        <div class="input-fieldset" >
                            <Form.Item name="name" hidden>
                                <Input />
                            </Form.Item>
                            <span class="input-label" style={{display:'none'}}>Name</span>
                        </div>
                        <div class="input-fieldset">
                            <Form.Item name="email" hidden>
                                <Input />
                            </Form.Item>
                            <span class="input-label">Email address</span>
                        </div>
                        <div class="input-fieldset">
                            <Form.Item name="mobile">
                                <Input />
                            </Form.Item>
                            <span class="input-label">Phone Number</span>
                        </div>
                        <div class="input-fieldset">
                            <Form.Item name="inquiry" rules={[{required: true , message: 'Comment is required'}]}>
                                <Input.TextArea rows="7" />
                            </Form.Item>
                            <span class="input-label">Comments</span>
                        </div>
                    </div>
                    <div className="form-footer">
                        <Form.Item>
                            <Button variant="contained" color="primary" disableRipple={true} type="submit">
                                Send
                            </Button>
                        </Form.Item>
                    </div>
                </Form>
            </div>
            <Backdrop id="loading-dialog" open={isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
};

export default ParticipantsHelp;
