import React, { useRef, forwardRef } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useMount, useUpdateEffect, useSetState } from 'ahooks';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { Input, AutoComplete } from 'antd';

import './style.scss';

import api from '../../library/api';

const PanelistModal = forwardRef((props, ref) => {
    const defaultState = {
        id: '',
        email: '',
        first_name: '',
        last_name: '',
        mobile: '',
        comment: '',
    };

    const { defaultValue, onChange } = props;
    let { ProjectID } = useParams();

    const panelistData = useRef(defaultState);
    const panelistOption = useRef({
        raw: [],
        option: [],
    });

    const [panelistInfo, setPanelistInfo] = useSetState(defaultState);

    useMount(() => {
        fetchData(ProjectID);
        setPanelistInfo(defaultValue);
    });

    useUpdateEffect(() => {
        panelistData.current = _.cloneDeep(panelistInfo);
        onChange(panelistData.current);
    }, [panelistInfo]);

    const fetchData = (id) => {
        api.participants
            .fetchById(id)
            .then((d) => {
                if (d.messages === 'success') {
                    _.each(d.data, (v) => {
                        v.comment = v?.["project_participant"][0]?.comment || ""
                        panelistOption.current.raw.push(v);
                        panelistOption.current.option.push({
                            value: v.email,
                        });
                    });
                }
            })
            .catch((e) => {
                console.info(e);
            });
    };

    const onKeyDownAllowNumOnly = (e) => {
        const key = e.which;
        if (key > 57) {
            e.preventDefault();
        } else if (key < 48) {
            if (key !== 8 && key !== 9 && key !== 37 && key !== 39) {
                e.preventDefault();
            }
        }

        if (e.key.charCodeAt(0) >= 33 && e.key.charCodeAt(0) <= 47) {
            e.preventDefault();
        } else if ((e.key.charCodeAt(0) >= 58 && e.key.charCodeAt(0) <= 64) || e.key.charCodeAt(0) === 94) {
            e.preventDefault();
        }
    };

    ref.current.reset = () => {
        setPanelistInfo(defaultState);
    };

    return (
        <div id="edit-schedule-section" className="content">
            <div className="form-content">
                <div className="input-fieldset">
                    <AutoComplete
                        name={'email'}
                        style={{ width: '100%' }}
                        value={panelistInfo.email}
                        onSearch={(v) => {
                            setPanelistInfo({ ...panelistInfo, email: v });
                        }}
                        options={panelistOption.current.option}
                        filterOption={(inputValue, option) => {
                            return option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                        }}
                        onSelect={(v, opt) => {
                            const arrFiltered = panelistOption.current.raw.filter((d) => {
                                return d.email === v;
                            });
                            if (arrFiltered.length > 0) {
                                setPanelistInfo(_.assign(defaultState, arrFiltered[0]));
                            }
                        }}
                    />
                    <span className="input-label">Email Address</span>
                </div>
                <div className="input-fieldset">
                    <Input
                        name={'last_name'}
                        value={panelistInfo.last_name}
                        onChange={(e) => {
                            setPanelistInfo({
                                last_name: e.target.value,
                            });
                        }}
                    />
                    <span className="input-label">Last Name</span>
                </div>
                <div className="input-fieldset">
                    <Input
                        name={'first_name'}
                        value={panelistInfo.first_name}
                        onChange={(e) => {
                            setPanelistInfo({
                                first_name: e.target.value,
                            });
                        }}
                    />
                    <span className="input-label">First Name</span>
                </div>
                <div className="input-fieldset">
                    <Input
                        name={'mobile'}
                        value={panelistInfo.mobile}
                        onKeyDownCapture={onKeyDownAllowNumOnly}
                        onChange={(e) => {
                            setPanelistInfo({
                                mobile: e.target.value,
                            });
                        }}
                    />
                    <span className="input-label">Phone Number</span>
                </div>
                <div className="input-fieldset">
                    <Input
                        name={'Comment'}
                        value={panelistInfo.comment}
                        onChange={(e) => {
                            setPanelistInfo({
                                comment: e.target.value,
                            });
                        }}
                    />
                    <span className="input-label">Comment</span>
                </div>
            </div>
        </div>
    );
});

PanelistModal.defaultProps = {};

PanelistModal.propTypes = {
    defaultValue: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default connect(null, null, null, { forwardRef: true })(PanelistModal);
