import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { useAuth } from '../Auth/auth';
import urls from '../../utility/urls';

const ShareRoute = ({ component: Component, ...rest }) => {
    const { authTokens } = useAuth();
    return (
        <Route
            {...rest}
            render={(props) => {
                if(!authTokens) { 
                   return <Redirect
                        exact
                        from={urls.INDEX_PAGE}
                        to={{ pathname: urls.USER_LOGIN, state: { referer: props.location } }}
                    />
                }

                // authorised so return component
                return (
                    authTokens && <Component {...props} /> 
                )
            }}
        />
    );
};

export default ShareRoute;