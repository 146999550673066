const urls = {
    INDEX_PAGE: '/',
    PG_INDEX_PAGE: '/pg',
    LOGIN_PAGE: '/pg/admin/login',
    LOGOUT_PAGE: '/pg/logout',
    ADMIN_PAGE: '/scheduling/admin',
    ADMIN_HOME: '/scheduling/admin/project/list',
    ADMIN_PROJECT_LIST: '/scheduling/admin/project/list',
    ADMIN_PROJECT_LIST_LOG_TRACKER: '/scheduling/admin/project/list/project_logs',
    ADMIN_PROJECT_LIST_DETAILS: '/scheduling/admin/project/details',
    ADMIN_PROJECT_LIST_PANELIST_DETAILS: '/scheduling/admin/project/panelist/details',
    ADMIN_PROJECT_LIST_PANELIST_lOG_TRACKER: '/scheduling/admin/project/panelist/panelist_log/details',
    ADMIN_BOOKING_LOG_TRACKER: '/scheduling/admin/project/booking_logs/details',
    ADMIN_PROJECT_CREATE: '/scheduling/admin/project/create',
    ADMIN_BOOKING: '/scheduling/admin/booking',
    ADMIN_BOOKING_DETAILS: '/scheduling/admin/booking/details',
    ADMIN_BOOKING_PANELIST_DETAILS: '/scheduling/admin/booking/panelist/details',
    CREATE_ADMIN_PRT_TEAM: '/scheduling/admin/project/listadmins',
    ONTHEDOT_ADMIN_LOG_TRCKER: '/scheduling/admin/project/listadmins/admin_logs',
    SHOW_USERS_ACCOUNTS: '/scheduling/admin/project/listUsers',
    ONTHEDOT_USERS_LOG_TRCKER: '/scheduling/admin/project/listUsers/panelist_logs',
    PARTICIPANTS_HOME: '/panelist',
    PARTICIPANTS_SCHEDULE: '/panelist/booking',
    PARTICIPANTS_SCHEDULE_DETAIL: '/panelist/booking/details',
    PARTICIPANTS_APPOINTMENT: '/panelist/appointment',
    PARTICIPANTS_HELP: '/panelist/help',
    USER_LOGIN: '/pg/panelist/login',
    USER_PAGE: '/scheduling/panelist',
    USER_HOME: '/scheduling/panelist/project/list',
    USER_PROJECT_LIST: '/scheduling/panelist/project/list',
    USER_PROJECT_LIST_DETAILS: '/scheduling/panelist/project/details',
    USER_SCHEDULE_DETAIL: '/scheduling/panelist/booking/details',
    USER_APPOINTMENT: '/scheduling/panelist/appointment',
    FORGOT_PASSWORD: '/pg/panelist/forget-password',
    RESET_PASSWORD: '/pg/panelist/password_reset',
};

export default urls;
