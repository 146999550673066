import React from "react";
import { Modal , Select } from 'antd';
import RegexTextField from '../RegexTextField';
import {regex} from '../../utility'
import {
    EMPLOYEE_STATUS
}from '../../utility/constant'
import { TextField } from "@material-ui/core";

const UpdateProfile = (props) => {
    
    const {
        showModalUpdateProfile,
        onCancelUpdateProfileModal,
        onUpdatePanelistProfile,
        setEmail,
        setFirstname,
        setLastname,
        setMobile,
        setEmployeeStatus,
        setPanelistID,
        setTnumber,
        email,
        firstname,
        lastname,
        panelistID,
        tnumber,
        employeStatus,
        mobile,
        disableTNumber,
        disableEmStatus,
        disablePanelistID 
    } = props
    
 return (
            <Modal
            title={'Update Profile'}
            width={450}
            visible={showModalUpdateProfile}
            centered={true}
            okText="Save"
            onOk={onUpdatePanelistProfile}
            cancelText="Cancel"
            onCancel={onCancelUpdateProfileModal}
        >
            <TextField
                required={true}
                label="Email Address"
                disabled={true}
                value={email}
                onChange={(e) => {
                    setEmail(e.target.value);
                }}
                placeholder="Email Address"
                variant="outlined"
                fullWidth={true}
                error={email !== '' && !regex.email.test(email)}
                helperText={email && !regex.email.test(email) ? 'Invalid format.' : ''}
            />
            <RegexTextField
                label="First Name"
                regex={regex.charsAndSpaces}
                value={firstname}
                disabled={true}
                onChange={(e) => {
                    setFirstname(e.target.value);
                }}
                placeholder="First Name"
                variant="outlined"
                fullWidth={true}
            />
            <RegexTextField
                label="Last Name"
                regex={regex.charsAndSpaces}
                disabled={true}
                value={lastname}
                onChange={(e) => {
                    setLastname(e.target.value);
                }}
                placeholder="Last Name"
                variant="outlined"
                fullWidth={true}
            />
            <TextField
                    required={false}
                    label="Panelist ID"
                    disabled={disablePanelistID}
                    value={panelistID}
                    onChange={(e) => {
                        setPanelistID(e.target.value);
                    }}
                    placeholder="Panelist ID"
                    variant="outlined"
                    fullWidth={true}
                />
            <TextField
                    required={false}
                    label="T-number"
                    value={tnumber}
                    disabled={disableTNumber}
                    onChange={(e) => {
                        setTnumber(e.target.value);
                    }}
                    placeholder="T-number"
                    variant="outlined"
                    fullWidth={true}
            />
            <RegexTextField
                label="Mobile"
                regex={regex.mobileNumber}
                value={mobile}
                onChange={(e) => {
                    setMobile(e.target.value);
                }}
                placeholder="Mobile"
                variant="outlined"
                fullWidth={true}
            />

            <Select
                placeholder="Select Employment Status"
                allowClear={true} 
                disabled={disableEmStatus}
                value={employeStatus}
                style={{ width: '100%', marginBottom : '10px'}}
                dropdownStyle={{ zIndex: 2000 }} 
                onChange={(e) => {
                    setEmployeeStatus(e)
                }}
                onClear={e => {
                    setEmployeeStatus('')
                }}
                options={EMPLOYEE_STATUS || []}
            />
        </Modal>
    )
}
export default UpdateProfile;