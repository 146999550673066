import React, { useState } from 'react';
import {Button,Upload } from "antd";
import { UploadOutlined } from '@ant-design/icons';
import {ExcelRenderer} from 'react-excel-renderer';
import { useSnackbar } from 'notistack';
import Papa from "papaparse";
import _ from 'lodash';
import {
    allowedExtensions ,
    FILE_SIZE_LIMIT_UPLOAD
} from '../../utility/constant'


const ExcelButton = (props) => {
    const {setFileExcel , onRemove , setEmail} = props
    const { enqueueSnackbar } = useSnackbar();

    const IsValidEmail = (str) => {
        // eslint-disable-next-line no-useless-escape
        return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            str
        );
    };
    const fileHandler  = fileList => {
        let fileObj = fileList;
        let arrayValue = [];
        if (!fileObj) {
            enqueueSnackbar('No file uploaded!', { variant: 'warning' });
            return false;
        }
        if (
          !(
            fileObj.type === allowedExtensions[0] ||
            fileObj.type ===
            allowedExtensions[1] || fileObj.type === allowedExtensions[2]
          )
        ) {
            enqueueSnackbar('Unknown file format. Only Excel files are uploaded!', { variant: 'warning' });
            setFileExcel('Unknown file format. Only Excel files are uploaded!')
          return false;
        }

        if (
            !(
                fileObj.size <= FILE_SIZE_LIMIT_UPLOAD
            )
          ) {
              enqueueSnackbar('Error uploading file. Maximum file size of 100MB is allowed', { variant: 'warning' });
            return false;
          }

        //read csv file 
        if(fileObj.type === allowedExtensions[2]) {
            const reader = new FileReader();
            reader.onload = async ({ target }) => {
            const csv = Papa.parse(target.result, { header: true });
            const parsedData = csv?.data;
            const columns = parsedData.map((email,index) =>  {
                if(!IsValidEmail(_.trim(email.Email))){
                    enqueueSnackbar(`Row ${index} is not valid email`, { variant: 'warning' });
                    return false
                }else {
                    arrayValue.push(email.Email)
                    return true
                }
            })
            let uniqueEmails = [...new Set(arrayValue)];
            let checker = arrayValue => arrayValue.every(v => v === true);
            if(checker(columns)) {
                setFileExcel(uniqueEmails)
                setEmail(null)
            }else {
                setFileExcel(null)
            }
        };
        reader.readAsText(fileObj);
        }else  {
        //just pass the fileObj as parameter as excel sheet
        ExcelRenderer(fileObj, (err, resp) => {
            let arr = []
            if(err){
              console.log(err);            
            }
            else{
              let isVaild =   resp.rows.map((email,index) => {
                    if(!IsValidEmail(_.trim(email[0])) && index !== 0 && email.length > 0 ){
                        enqueueSnackbar(`Row ${index} is not valid email`, { variant: 'warning' });
                        return false
                    }else if(index !== 0 && email.length > 0) {
                        arr.push(_.trim(email[0].toLowerCase()))
                        return true
                    }else {
                        return true
                    }
                })
                const firstElement = isVaild.shift();
                let uniqueEmails = [...new Set(arr)];
                let checker = arr => arr.every(v => v === true);
                if(checker(isVaild)) {
                    setFileExcel(uniqueEmails)
                    setEmail(null)
                }else {
                    setFileExcel(null)
                }
            }
          }); 
        }
        return false;
    };
    return (
        <>
            <Upload
                multiple={false}
                maxCount = {1}
                name="file"
                onRemove = {onRemove}
                beforeUpload={fileHandler}
            
            >
                <Button>
                <UploadOutlined /> Click to upload Excel File
                </Button>
            </Upload>
            <div id='place-holder-text'>
                <p>Total size limit for the file must not exceed 100MB.<br/> Allowed file type : Excel.</p>
            </div>
        </>
       
    )
}
export default ExcelButton;