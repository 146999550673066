import React, { useEffect, useState } from 'react';
import { Route, Switch, useLocation, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import { Layout } from 'antd';

import './style.scss';

import HeaderAdmin from '../../components/HeaderAdmin';
import SideBarAdmin from '../../components/SideBarAdmin';
import ProjectManagementPage from '../ProjectManagement';
import ProjectDetailsPage from '../ProjectManagement/ProjectDetails';
import PanelistDetails from '../ProjectManagement/PanelistDetails';
import ProjectCreatePage from '../ProjectCreate';
import BookingSupportPage from '../BookingSupport';
import BookingDetailsPage from '../BookingSupport/BookingDetails';
import BookingPanelistDetailsPage from '../BookingSupport/BookingPanelistDetails';
import CreateAdminPage from '../UsersAction/CreateAdmin';
import BookingLogTracker from '../ProjectManagement/BookingLogTracker';
import PanelistProjectLogTracker from '../ProjectManagement/PanelistProjectLogTracker';
import ProjectLogTracker from '../ProjectManagement/ProjectLogTracker';
import AdminLogTracker from '../UsersAction/CreateAdmin/AdminLogTracker';
import PanelistPage from '../UsersAction/Panelist';
import FooterComp from '../../components/Footer';

import urls from '../../utility/urls';
import { isBrowser, resizeDebounceTime, smWindow, lgWindow } from '../../utility/settings'
import { debounce } from 'lodash';
import PanelistLogTracker from '../UsersAction/Panelist/PanelistLogTracker';


const { Header, Sider, Content, Footer } = Layout;
const AdminPage = () => {
    let location = useLocation();
    const [sizeWindow, setSizeWindow] = useState(window.innerWidth)
    const [visible, setVisible] = useState(false)
    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            isBrowser && window.removeEventListener('resize', handleResize);
        }
    })
    const handleResize = debounce(() => {
        setSizeWindow(window.innerWidth)
    }, resizeDebounceTime);

    const showDrawer = () => {
        setVisible(!visible)
    }

    return (
        <Layout id="admin-layout">
            <Header>
                <HeaderAdmin showDrawer={showDrawer} sizeWindow={sizeWindow} />
            </Header>
            {sizeWindow && sizeWindow > lgWindow ?
                <Layout>
                    <Sider className="sidebar" width={140} theme="light">
                        <SideBarAdmin />
                    </Sider>
                    <Layout>
                        <Content>
                            <Switch location={location}>
                                <Route
                                    exact
                                    path={`${urls.ADMIN_PROJECT_LIST_DETAILS}/:ProjectID`}
                                    component={ProjectDetailsPage}
                                />
                                <Route
                                    exact
                                    path={`${urls.ADMIN_PROJECT_LIST_PANELIST_DETAILS}/:ProjectID`}
                                    component={PanelistDetails}
                                />
                                <Route
                                    exact
                                    path={`${urls.ADMIN_PROJECT_LIST_PANELIST_lOG_TRACKER}/:ProjectID`}
                                    component={PanelistProjectLogTracker}
                                />
                                <Route
                                    exact
                                    path={`${urls.ADMIN_BOOKING_LOG_TRACKER}/:ProjectID`}
                                    component={BookingLogTracker}
                                />
                                <Route
                                    exact
                                    path={`${urls.ADMIN_PROJECT_LIST_LOG_TRACKER}`}
                                    component={ProjectLogTracker}
                                />
                                <Route
                                    exact
                                    path={`${urls.ONTHEDOT_ADMIN_LOG_TRCKER}`}
                                    component={AdminLogTracker}
                                />
                                <Route
                                    exact
                                    path={`${urls.ONTHEDOT_USERS_LOG_TRCKER}`}
                                    component={PanelistLogTracker}
                                />
                                <Route exact path={urls.ADMIN_PROJECT_LIST} component={ProjectManagementPage} />
                                <Route exact path={urls.ADMIN_PROJECT_CREATE} component={ProjectCreatePage} />
                                <Route
                                    exact
                                    path={`${urls.ADMIN_BOOKING_DETAILS}/:ProjectID`}
                                    component={BookingDetailsPage}
                                />
                                <Route
                                    exact
                                    path={`${urls.ADMIN_BOOKING_PANELIST_DETAILS}/:ProjectID`}
                                    component={BookingPanelistDetailsPage}
                                />
                                <Route exact path={urls.ADMIN_BOOKING} component={BookingSupportPage} />
                                <Route exact path={urls.CREATE_ADMIN_PRT_TEAM} component={CreateAdminPage} />
                                <Route exact path={urls.SHOW_USERS_ACCOUNTS} component={PanelistPage} />
                                <Redirect to={urls.ADMIN_HOME} />
                            </Switch>
                        </Content>

                        <Footer>
                            <FooterComp isAdmin={true} />
                        </Footer>
                    </Layout>
                </Layout>
                :
                <Layout>
                    {sizeWindow > smWindow && sizeWindow <= lgWindow &&
                        <Sider className="responsiveSidebar" width={"100%"} theme="light">
                            <SideBarAdmin />
                        </Sider>
                    }
                    {visible && sizeWindow <= smWindow &&
                        <Sider className="responsiveSidebar" width={"100%"} theme="light">
                            <SideBarAdmin />
                        </Sider>
                    }
                    <Layout>
                        <Content width={"100%"}>
                            <Switch location={location}>
                                <Route
                                    exact
                                    path={`${urls.ADMIN_PROJECT_LIST_DETAILS}/:ProjectID`}
                                    component={ProjectDetailsPage}
                                />
                                <Route
                                    exact
                                    path={`${urls.ADMIN_PROJECT_LIST_PANELIST_DETAILS}/:ProjectID`}
                                    component={PanelistDetails}
                                />
                                <Route
                                    exact
                                    path={`${urls.ADMIN_BOOKING_LOG_TRACKER}/:ProjectID`}
                                    component={BookingLogTracker}
                                />
                                <Route
                                    exact
                                    path={`${urls.ADMIN_PROJECT_LIST_PANELIST_lOG_TRACKER}/:ProjectID`}
                                    component={PanelistProjectLogTracker}
                                />
                                <Route
                                    exact
                                    path={`${urls.ADMIN_PROJECT_LIST_LOG_TRACKER}`}
                                    component={ProjectLogTracker}
                                />
                                <Route
                                    exact
                                    path={`${urls.ONTHEDOT_ADMIN_LOG_TRCKER}`}
                                    component={AdminLogTracker}
                                />
                                <Route
                                    exact
                                    path={`${urls.ONTHEDOT_USERS_LOG_TRCKER}`}
                                    component={PanelistLogTracker}
                                />
                                <Route exact path={urls.ADMIN_PROJECT_LIST} render={(props) => <ProjectManagementPage sizeWindow={sizeWindow} />} />
                                <Route exact path={urls.ADMIN_PROJECT_CREATE} component={ProjectCreatePage} />
                                <Route
                                    exact
                                    path={`${urls.ADMIN_BOOKING_DETAILS}/:ProjectID`}
                                    component={BookingDetailsPage}
                                />
                                <Route
                                    exact
                                    path={`${urls.ADMIN_BOOKING_PANELIST_DETAILS}/:ProjectID`}
                                    component={BookingPanelistDetailsPage}
                                />
                                <Route exact path={urls.ADMIN_BOOKING} render={(props) => <BookingSupportPage sizeWindow={sizeWindow} />} />
                                <Route exact path={urls.CREATE_ADMIN_PRT_TEAM} component={CreateAdminPage} />
                                <Route exact path={urls.SHOW_USERS_ACCOUNTS} component={PanelistPage} />
                                <Redirect to={urls.ADMIN_HOME} />
                            </Switch>
                        </Content>
                    </Layout>
                    <Footer>
                        <FooterComp isAdmin={true} />
                    </Footer>
                </Layout>
            }
        </Layout>
    );
};

export default connect()(AdminPage);
