import React, { useState } from 'react';
import { connect } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';

import { Providers } from '@microsoft/mgt-element';

import { Avatar, Menu, Fade, List, ListItem, ListItemIcon, ListItemText, MenuItem } from '@material-ui/core';
import { AccountCircle, ExitToApp, Home ,Person , Group , PlayArrow } from '@material-ui/icons';

import { useAuth } from '../Auth/auth';
import urls from '../../utility/urls';

import './style.scss';
import { ROLES } from '../../utility/constant';
import AuthHelper from '../../library/helpers/AuthHelper';
import api from '../../library/api';
import { Divider } from 'antd';

const navigationLink = [
    {
        id: 0,
        label: 'Project Management',
        icon: '/assets/sidebar_list.png',
        url: urls.ADMIN_PROJECT_LIST,
        role : ''
    },
    {
        id: 1,
        label: 'Create Project',
        icon: '/assets/sidebar_edit.png',
        url: urls.ADMIN_PROJECT_CREATE,
        role : ROLES.adminGlobal 
    },
    {
        id: 2,
        label: 'Booking Support',
        icon: '/assets/sidebar_calendar.png',
        url: urls.ADMIN_BOOKING,
        role : ''
    },
    {
        id: 3,
        label: 'Admin Settings',
        icon: <Person fontSize="large"/>,
        url: urls.CREATE_ADMIN_PRT_TEAM,
        role : ROLES.adminGlobal 
    },
    {
        id:4,
        label : 'Panelists',
        icon : <Group fontSize="large"/>,
        url : urls.SHOW_USERS_ACCOUNTS,
        role : ROLES.adminGlobal 
    },
    {
        id : 5, 
        label : 'How to get Started',
        icon : <PlayArrow fontSize="large"/>,
        url : 'https://pgone.sharepoint.com/sites/OnTheDot',
        role : ''
    }
];

const menuLink = [
    {
        id: 0,
        label: 'Home',
        icon: <Home fontSize="small" />,
        url: urls.ADMIN_HOME,
    },
    {
        id: 1,
        label: 'Logout',
        icon: <ExitToApp fontSize="small" />,
        url: urls.LOGOUT_PAGE,
    },
];

const SideBarAdmin = (props) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const historyLink = useHistory();
    const { setAuthTokens} = useAuth();
    const authHelper = new AuthHelper();
    let role = authHelper.getRole()
    const onMenuClose = () => {
        setAnchorEl(null);
    };

    const onLogout = async (e) => {
        await api.user.logout()
        setAuthTokens();
        Providers.globalProvider.logout();
    };

    const onClickAvatar = (e) => {
        setAnchorEl(e.currentTarget);
    };

    const onListItemClick = (e) => {
        if (e === 1) {
            onLogout();
        } else {
            historyLink.push(menuLink[e].url);
        }
    };
    

    return (
        <div id="sidebaradmin-section">
            <div className="sidebaradmin-section-navlink">
                <ul className="nav-lnk">
                    {navigationLink.map((value) => (
                        <li key={value.id} style={{display : `${value.role != '' ? ((role === ROLES.adminGlobal ||ROLES.siteAdmin ===  role || 
                                     ROLES.adminSuper ===  role || ROLES.projAdmin ===  role  ) && 
                                     value.id ===1 ) || (
                                        (role === ROLES.adminGlobal ||ROLES.siteAdmin ===  role ) && (value.id ===3  || value.id=== 4)
                                     ) ? '' : 
                                     'none' : ''}`}}>
                            {value.id === 5 ?  
                                <a href={value.url}  target='_blank'>
                                    {typeof(value.icon) === "string" ? <img src={value.icon} alt={value.label} /> 
                                   : ROLES.adminGlobal ===  role&&value.icon || ROLES.siteAdmin ===  role&&value.icon || ROLES.adminSuper ===  role&&value.icon || ROLES.projAdmin ===  role&&value.icon}
                                    <span>{typeof(value.icon) === "string" ? value.label :  ROLES.adminGlobal ===  role&&value.label || ROLES.siteAdmin === role &&value.label || ROLES.adminSuper ===  role&&value.label || ROLES.projAdmin ===  role&&value.label}</span>
                                </a>
                                :
                                <NavLink to={value.url} activeClassName="active-link" >
                                {typeof(value.icon) === "string" ? <img src={value.icon} alt={value.label} /> 
                                   : ROLES.adminGlobal ===  role&&value.icon || ROLES.siteAdmin ===  role&&value.icon}
                                <span>{typeof(value.icon) === "string" ? value.label :  ROLES.adminGlobal ===  role&&value.label || ROLES.siteAdmin === role &&value.label}</span>
                                </NavLink>
                            }
                         
                        </li>
                    ))}
                </ul>
            </div>

            <div className="sidebaradmin-section-menu">
                <Avatar src={''} onClick={onClickAvatar}>
                    <AccountCircle />
                </Avatar>
            </div>

            <Menu
                id="simple-menu"
                className="header-admin-menu"
                elevation={0}
                getContentAnchorEl={null}
                anchorEl={anchorEl}
                keepMounted={true}
                autoFocus={true}
                open={Boolean(anchorEl)}
                onClose={onMenuClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                TransitionComponent={Fade}
            >
                <List dense={true}>
                    <MenuItem>
                        {`Welcome ${(authHelper.getUserDetailsAdmin().first_name !== null && (authHelper.getUserDetailsAdmin()?.first_name + " " +authHelper.getUserDetailsAdmin()?.last_name))|| authHelper.getUserDetailsAdmin().email }`}
                    </MenuItem>
                    <Divider/>
                    {menuLink.map((value) => (
                        <ListItem key={value.id} button onClick={onListItemClick.bind(this, value.id)}>
                            <ListItemIcon>{value.icon}</ListItemIcon>
                            <ListItemText primary={value.label} />
                        </ListItem>
                    ))}
                </List>
            </Menu>
        </div>
    );
};

export default connect()(SideBarAdmin);
