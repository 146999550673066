import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useMount, useUpdateEffect } from 'ahooks';
import _ from 'lodash';
import moment from 'moment';
import ordinal from 'ordinal';
import { Resizable } from 'react-resizable';

import { PageHeader, Table, Breadcrumb, Input, Descriptions } from 'antd';

import { Backdrop, CircularProgress, Button } from '@material-ui/core';
import { SearchOutlined, VerticalAlignBottom, } from '@material-ui/icons';
import { useSnackbar } from 'notistack';

import urls from '../../utility/urls';
import { errorMapper } from '../../utility';
import api from '../../library/api';
import { exportExcel } from '../../utility/xslx';

import './style.scss';


const ResizableTitle = (props) => {
    const { onResize, width, ...restProps } = props;

    if (!width) {
        return <th {...restProps} />;
    }

    return (
        <Resizable
            width={width}
            height={0}
            handle={
                <span
                    className="react-resizable-handle"
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                />
            }
            onResize={onResize}
            draggableOpts={{
                enableUserSelectHack: false,
            }}
        >
            <th {...restProps} />
        </Resizable>
    );
};
const BookingLogTracker = (props) => {
    const historyLink = useHistory();
    let { ProjectID } = useParams();
    const projectObj = useRef({
        project: {},
        appoitment: {}
    });

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [columns, setColumns] = useState([]);
    const [totalWeeks, setTotalWeeks] = useState([]);
    const [isRecurring, setIsRecurring] = useState(false);
    const [visitColumns, setVisitColumns] = React.useState(false);

    const { enqueueSnackbar } = useSnackbar();

    useMount(async () => {
        await fetchData(ProjectID);
    });

    useUpdateEffect(() => {
        fetchData(ProjectID);
    }, []);
    const handleResize =
        (index) =>
            (_, { size }) => {
                const newColumns = [...columns];
                newColumns[index] = { ...newColumns[index], width: size.width };
                setColumns(newColumns);
            };
    const mergeColumns = columns.map((col, index) => ({
        ...col,
        onHeaderCell: (column) => (
            {
                width: column.width,
                onResize: handleResize(index),
            }),
    }));



    const fetchData = (id) => {
        setIsLoading(true);

        Promise.all([api.projects.fetchById(id), api.logs.fetchBookingLogActivityById(id)])
            .then((d) => {
                setIsLoading(false);

                if (d[0].messages === 'success' && d[1].messages === 'success') {
                    processedRawData(d[0].data, d[1].data);
                } else {
                    enqueueSnackbar(
                        errorMapper(d[0].messages) + errorMapper(d[1].messages),
                        { variant: 'warning' }
                    );
                }
            })
            .catch((e) => {
                setIsLoading(false);
                enqueueSnackbar('An error occurred while getting project information. Please contact system admin for help.', { variant: 'error' });
            });
    };
    const processedRawData = (proj, appnt) => {
        let appointment = []
        //convert time to our locally 
        appnt.map((i) => {
            appointment.push(
                i.log_activity
            )
        })
        const Update_Appointment = appointment.flat(1);
        //sort date  
        Update_Appointment.sort((a, b) => moment.utc(a.insert_dttm).local().diff(moment.utc(b.insert_dttm).local()))

        Update_Appointment.map(m => {
            return m.visit.map(j => {
                return { ...j, start_time: moment.utc(j.start_time).local(), end_time: moment.utc(j.end_time).local() }
            })
        })


        const totalDays = Math.abs(proj.project_end_date.diff(proj.project_start_date, 'days')),

            weeksTotal = totalDays > 0 ? Math.ceil(totalDays / 7) : 1,
            is_recurring = proj.is_recurring;

        let visitColumns = 0;
        setIsRecurring(proj.is_recurring);
        let totalWeeksArr = [];

        if (is_recurring) {
            for (let i = 0; i < weeksTotal; i++) {
                totalWeeksArr.push({
                    label: `${ordinal(i + 1)} Week`,
                    value: i + 1,
                });
            }
        } else {
            for (let i = 0; i < proj.projectsched.length; i++) {
                totalWeeksArr.push({
                    label: `${ordinal(i + 1)} Visit`,
                    value: i + 1,
                });
            }
        }
        setTotalWeeks(totalWeeksArr);

        _.each(Update_Appointment, (v, i) => {
            const weeks = Math.abs(moment.utc(v.date).local().diff(proj.project_start_date, 'weeks')) + 1;
            Update_Appointment[i].week = weeks;

            const indexSched = _.findIndex(proj.projectsched, (o) => {
                return o.id === v.projectsched_id;
            });

            Update_Appointment[i].visits = indexSched !== -1 ? proj.projectsched[indexSched].visit_number : -1;

            visitColumns = Update_Appointment[i].visit.length > visitColumns ? Update_Appointment[i].visit.length : visitColumns;
        });
        setColumns(columnsProvider(visitColumns, is_recurring));
        projectObj.current.project = proj;
        projectObj.current.appointments = Update_Appointment;
        setData(Update_Appointment);
        setVisitColumns(visitColumns)
    }
    const columnsProvider = (visitNum, recurring) => {
        let infoArr = [
            {
                title: recurring ? 'Week' : 'Visits',
                align: 'left',
                width: 100,
                dataIndex: recurring ? 'week' : 'visits',
                key: recurring ? 'week' : 'visits',
                sorter: {
                    compare: (a, b) => recurring ? a.week - b.week : a.visits - b.visits,
                    multiple: 3
                },
                render: (text, record) => {
                    return recurring ? `${ordinal(text)} Week` : `${ordinal(record.visits)} Visit`;
                },
            },
            {
                title: 'Date',
                align: 'left',
                width: 150,
                dataIndex: 'date',
                key: 'date',
                sorter: {
                    compare: (a, b) => {
                        if (moment(a.date).isBefore(b.date, 'day')) {
                            return -1;
                        }

                        if (moment(a.date).isAfter(b.date, 'day')) {
                            return 1;
                        }

                        return 0;
                    },
                    multiple: 2,
                },
                sortDirections: ['descend', 'ascend'],
                render: (text, record) => {
                    return moment(text).format(api.utils.MOMENTFORMAT.date);
                },
            },
            {
                title: 'Details',
                align: 'center',
                width: 100,
                dataIndex: 'details',
                key: 'details',
                sorter: {
                    compare: (a, b) => {
                        const idA = (a.details || '').toUpperCase();
                        const idB = (b.details || '').toUpperCase();

                        if (idA < idB) {
                            return -1;
                        }

                        if (idA > idB) {
                            return 1;
                        }

                        return 0;
                    },
                    multiple: 2,
                },
                sortDirections: ['descend', 'ascend'],
            },
        ],
            actionArr = [
                {
                    title: 'Location',
                    align: 'left',
                    width: 100,
                    dataIndex: 'location',
                    key: 'location',
                    sorter: {
                        compare: (a, b) => {
                            const idA = (a.location || '').toUpperCase();
                            const idB = (b.location || '').toUpperCase();

                            if (idA < idB) {
                                return -1;
                            }

                            if (idA > idB) {
                                return 1;
                            }

                            return 0;
                        },
                        multiple: 2,
                    },
                    sortDirections: ['descend', 'ascend'],
                },
                {
                    title: 'Full Name',
                    align: 'left',
                    width: 140,
                    dataIndex: 'participant',
                    key: 'participant',
                    render: (text, record) => {
                        let fn = '',
                            ln = '';

                        if (text) {
                            fn = text.first_name || '';
                            ln = text.last_name || '';
                            if (fn.length > 0 || ln.length > 0) {
                                return `${fn} ${ln}`;
                            } else {
                                return `${text.email || ''}`;
                            }
                        }
                        return '';
                    },
                },
                {
                    title: 'Action',
                    align: 'left',
                    width: 140,
                    dataIndex: 'Action',
                    key: 'Action',
                },
                {
                    title: 'Action By',
                    align: 'left',
                    width: 140,
                    dataIndex: 'ActionBy',
                    key: 'ActionBy'
                },
                {
                    title: 'Timestamp',
                    align: 'left',
                    width: 140,
                    dataIndex: 'insert_dttm',
                    key: 'insert_dttm',
                    defaultSortOrder: 'ascend',
                    sorter: {
                        compare: (a, b) => {
                            if (moment(a.insert_dttm).isBefore(b.insert_dttm, 'min')) {
                                return -1;
                            }

                            if (moment(a.insert_dttm).isAfter(b.insert_dttm, 'min')) {
                                return 1;
                            }

                            return 0;
                        },
                        multiple: 2,
                    },
                    render: (text, record) => {
                        return moment.utc(text).local().format(api.utils.MOMENTFORMAT.time24Date);
                    },
                }
            ];

        for (let i = 1; i <= visitNum; i++) {
            if (visitNum === 1) {
                infoArr.push({
                    title: 'Start Time',
                    align: 'left',
                    width: 140,
                    dataIndex: 'visit',
                    key: 'visit',
                    render: (v, r) => {
                        if (v.length >= i) {
                            return moment.utc(v[i - 1].start_time).local().format(api.utils.MOMENTFORMAT.time24);
                        }
                        return '';
                    },
                });
                infoArr.push({
                    title: 'End Time',
                    align: 'left',
                    width: 140,
                    dataIndex: 'visit',
                    key: 'visit',
                    render: (v, r) => {
                        if (v.length >= i) {
                            return moment.utc(v[i - 1].end_time).local().format(api.utils.MOMENTFORMAT.time24);
                        }

                        return '';
                    },
                });
            } else {
                infoArr.push({
                    title: `${ordinal(i)} Visit`,
                    align: 'left',
                    width: 140,
                    dataIndex: 'visit',
                    key: 'visit',
                    defaultSortOrder: 'ascend',
                    render: (v, r) => {
                        if (v.length >= i) {
                            const start = moment.utc(v[i - 1].start_time).local().format(api.utils.MOMENTFORMAT.time24),
                                end = moment.utc(v[i - 1].end_time).local().format(api.utils.MOMENTFORMAT.time24);

                            return `${start} - ${end}`;
                        }

                        return '';
                    },
                });
            }
        }

        return _.concat(infoArr, actionArr);
    }

    const _handleSearch = (e) => {
        const text = e.target.value;

        setData(
            projectObj.current.appointments.filter(
                (appt) =>
                    (appt?.participant?.first_name && appt.participant.first_name.toLowerCase().includes(text.toLowerCase())) ||
                    (appt?.participant?.last_name && appt.participant.last_name.toLowerCase().includes(text.toLowerCase())) ||
                    (appt?.visits && String(`${ordinal(appt.visits)} Visit`).toLowerCase().includes(text.toLowerCase())) ||
                    (appt?.week && String(`${ordinal(appt.week)} Week`).toLowerCase().includes(text.toLowerCase())) ||
                    (appt?.location && String(appt.location).toLowerCase().includes(text.toLowerCase())) ||
                    (appt?.Action && String(appt.Action).toLowerCase().includes(text.toLowerCase())) ||
                    (appt?.ActionBy && String(appt.ActionBy).toLowerCase().includes(text.toLowerCase())) ||
                    (appt?.details && String(appt.details).toLowerCase().includes(text.toLowerCase()))
            ),
        );
    };



    const _handleClearSearch = (e) => {
        const text = e.target.value;
        if (text === '') {
            setData(projectObj.current.appointments);
        }
    }


    const _generateExportData = () => {
        const fileName = `project-logs-${projectObj.current.project.name}${projectObj.current.project.id
            }`;
        const headers = [
            ...(projectObj.current.project.is_recurring
                ? [{ label: 'Week', key: 'week' }]
                : [{ label: 'Visit', key: 'visits' }]),
            { label: 'Date', key: 'date' },
            //{ label: 'Item', key: 'item' },
            {
                label: 'Details',
                key: 'details',
            },
        ];

        /* if (projectObj.current.project.is_recurring) {
             headers.push({ label: 'Start Time', key: 'start_time' }, { label: 'End Time', key: 'end_time' });
         }*/

        data.forEach((project) => {
            project.visit.forEach((vis, index) => {
                if (!headers.find((head) => head.key === `${ordinal(index + 1)} Visit`)) {
                    headers.push({
                        label: `${ordinal(index + 1)} Visit`,
                        key: `${ordinal(index + 1)} Visit`,
                    });
                }
            });
        });
        headers.push(
            { label: 'Location', key: 'location' },
            { label: 'Full Name', key: 'panelist' },
            { label: 'Action', key: 'Action' },
            { label: 'Action By', key: 'ActionBy' },
            {
                label: 'Timestamp', key: 'insert_dttm'
            },
            {
                label: 'Last Name',
                key: 'participant.last_name',
            },
            {
                label: 'First Name',
                key: 'participant.first_name',
            },
            {
                label: 'Email Address',
                key: 'participant.email',
            },
            {
                label: 'Phone Number',
                key: 'participant.mobile',
            },
        );

        const outputData = _.cloneDeep(data).map((project) => {
            const output = {};

            headers.forEach((head) => {
                if (!head.key.includes('participant')) {
                    output[head.key] = project[head.key];
                }
            });

            headers.forEach((head) => {
                if (!head.key.includes('participant')) {
                    output[head.key] = project[head.key];
                } else {
                    output[head.key] = _.get(project, head.key);
                }
            });
            output.date = moment.utc(project.date).local().format(api.utils.MOMENTFORMAT.date);
            output.insert_dttm = moment.utc(project.insert_dttm).local().format(api.utils.MOMENTFORMAT.time24Date);

            output.panelist = output['participant.first_name'] + output['participant.last_name'];
            if (projectObj.current.project.is_recurring) {
                output.week = `${ordinal(project.week)} Week`;
            } else {
                output.visits = `${ordinal(project.visits)} Visit`;
            }

            project.visit.forEach((vis, index) => {
                const start = moment.utc(vis.start_time).local().format(api.utils.MOMENTFORMAT.time24),
                    end = moment.utc(vis.end_time).local().format(api.utils.MOMENTFORMAT.time24);
                if (projectObj.current.project.is_recurring) {
                    output[`${ordinal(index + 1)} Visit`] = `${start} - ${end}`;
                } else {
                    output[`${ordinal(index + 1)} Visit`] = `${start} - ${end}`;
                }
            });
            return output;
        });

        return {
            fileName,
            header: [
                ['ID', projectObj.current.project.pr_id],
                ['Name', projectObj.current.project.name],
                ['Type', projectObj.current.project.type],
                ['Instruction', projectObj.current.project.book_instructions],
                ['Number of panelist to be scheduled', projectObj.current.project.panel_count],
                ['Number of booked', projectObj.current.project.booked_cnt],
                [],
                headers.map((head) => head.label),
            ],
            data: outputData,
        };
    }

    return (
        <div id="project-management-page">
            <Breadcrumb>
                <Breadcrumb.Item>
                    <a href={urls.ADMIN_PROJECT_LIST}>Project List</a>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <a href={`${urls.ADMIN_PROJECT_LIST_DETAILS}/${ProjectID}`}>Project Details</a>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Booking Log Tracker</Breadcrumb.Item>
            </Breadcrumb>
            <PageHeader
                ghost={false}
                title="Booking Log Tracker"
                onBack={() => {
                    historyLink.push(`${urls.ADMIN_PROJECT_LIST_DETAILS}/${ProjectID}`);
                }}
            >
                <div className="page-header-desc">
                    <div className="page-header-desc-left">
                        <Descriptions size="default" column={1}>
                            <Descriptions.Item label="Project ID">{projectObj.current.project.pr_id}</Descriptions.Item>
                            <Descriptions.Item label="Project Type">
                                {projectObj.current.project.type}
                            </Descriptions.Item>
                            <Descriptions.Item label="Instruction">
                                {projectObj.current.project.book_instructions}
                            </Descriptions.Item>
                        </Descriptions>
                    </div>
                    <div className="page-header-desc-right">
                        <Descriptions size="default" column={1}>
                            <Descriptions.Item label="Project Name">
                                {projectObj.current.project.name}
                            </Descriptions.Item>
                            <Descriptions.Item label="Panelist">
                                {projectObj.current.project.panel_count}
                            </Descriptions.Item>
                            <Descriptions.Item label="Booked">
                                {projectObj.current.project.booked_cnt}
                            </Descriptions.Item>
                        </Descriptions>
                    </div>
                </div>
            </PageHeader>

            <div className="project-management-page-content">
                <div className="project-management-page-table-container">
                    <div className="project-management-page-table-controls">
                        <div className="project-management-page-table-controls-left">
                            <Input
                                className="searchbox"
                                size="middle"
                                prefix={<SearchOutlined />}
                                placeholder="Search"
                                onPressEnter={_handleSearch}
                                onChange={_handleClearSearch}
                            />
                        </div>
                        <div className="project-management-page-table-controls-right">
                            <Button
                                startIcon={<VerticalAlignBottom />}
                                disableRipple={true}
                                onClick={() => exportExcel(_generateExportData())}
                            >
                                Export
                            </Button>
                        </div>
                    </div>

                    <Table
                        className="project-management-page-table"
                        columns={mergeColumns}
                        bordered={false}
                        rowKey={'id' + 'Action'}
                        sticky={true}
                        showHeader={true}
                        filterMultiple={true}
                        components={{
                            header: {
                                cell: ResizableTitle,
                            }
                        }}
                        tableLayout="fixed"
                        pagination={{
                            showTotal: (total, range) => {
                                return `${range[0]}-${range[1]} of ${total} items`;
                            },
                            defaultCurrent: 1,
                            defaultPageSize: 10,
                            pageSizeOptions: [10, 20, 30, 50, 100],
                            showSizeChanger: true,
                            position: ['bottomRight'],
                            total: data.length,
                        }}
                        dataSource={data}
                        scroll={{
                            y: '50vh',
                            x: '100%',
                        }}
                    ></Table>
                </div>
            </div>
            <Backdrop id="loading-dialog" open={isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
};

export default connect()(BookingLogTracker);
