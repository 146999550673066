import React, { useState } from 'react';
import { Popover, Input } from 'antd';
import { Button } from '@material-ui/core';
import { FileCopyOutlined } from '@material-ui/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import './style.scss';

const Clipboard = ({ label = '', value = '', children }) => {
    const [copy, setCopy] = useState({
        value,
        copied: false,
    });

    return (
        <Popover
            placement="bottom"
            content={
                <>
                    {copy.copied && <div className="link-copied">Link to '{label}' copied</div>}
                    <Input
                        value={copy.value}
                        onChange={({ target: { value } }) => setCopy({ value, copied: false })}
                        addonAfter={
                            <CopyToClipboard text={copy.value} onCopy={() => setCopy({ ...copy, copied: true })}>
                                <div className="copy-trigger">Copy</div>
                            </CopyToClipboard>
                        }
                    />
                </>
            }
            trigger="click"
        >
            {children || (
                <Button startIcon={<FileCopyOutlined />} disableRipple={true}>
                    Copy Link
                </Button>
            )}
        </Popover>
    );
};

export default Clipboard;
