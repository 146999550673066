
import React, { useEffect, useState } from "react";
import { Form, Button, Input } from 'antd'
import { useSnackbar } from 'notistack'

import { CircularProgress, Backdrop } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";

import * as queryString from 'query-string';
import api from "../../library/api";
import urls from "../../utility/urls";
import RegexTextField from '../RegexTextField';
import {
    error_massage_password
} from '../../utility/constant'
import { regex } from '../../utility'

import '../../pages/Login/style.scss'
import './index.scss'
import Footer from "../Footer";

const ResetPassword = (props) => {

    const { history } = props;
    const [form] = Form.useForm();
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [showPass, setShowPass] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        document.title = "OnTheDot Panelist Portal"
    }, [])

    const handleChangePassword = async (data) => {
        if (!password) {
            enqueueSnackbar('Password is required!', { variant: 'warning' });
            return;
        }

        if (!confirmPassword) {
            enqueueSnackbar('confirmPassword is required!', { variant: 'warning' });
            return;
        }
        if (!(password === confirmPassword)) {
            enqueueSnackbar('Passwords do not match.', { variant: 'warning' });
            return;
        }
        if (!regex.password.test(password) && !regex.password.test(confirmPassword)) {
            enqueueSnackbar('Password is invalid!', { variant: 'warning' });
            return;
        }
        setIsLoading(true)
        let quary = queryString.parse(props.location.search)
        let token = quary.token
        let payload = {
            token: token,
            password: confirmPassword
        }
        await api.user.resetPassword(payload)
            .then(res => {
                if (res.messages === "success") {
                    enqueueSnackbar(`Your password has been updated, redirecting to login...`, { variant: 'success' })
                    setTimeout(async () => {
                        history.push(urls.USER_LOGIN);
                        console.log('Returning to login');
                        setIsLoading(false)
                    }, 4000);

                } else {
                    enqueueSnackbar('Failed to update password. Please try again.', { variant: 'warning' })
                    setIsLoading(false)
                }

            }).catch(err => {
                enqueueSnackbar('An unexpected error has occurred', { variant: 'error' })
                setIsLoading(false)
                console.error(err);
            })

    };

    const onFinishFailed = (errInfo) => {
        enqueueSnackbar('Failed:' + errInfo.message, { variant: 'error' })
    }

    const handleClickShowPassword = () => {
        setShowPass(!showPass)
    };

    const handleClickShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword)
    };

    const { enqueueSnackbar } = useSnackbar();

    return (
        <div id="login-page" className="reset_password">
            <div className="login-container">
                <div className="login-cont-left">
                    <img src="/assets/login_banner_bg_1.png" alt="P&G People" />
                </div>
                <div className="login-cont-right">
                    <div className="login-content">
                        <div className="login-logo">
                            <img src="/assets/png_logo@2x.png" alt="P&G Logo" />
                        </div>

                        <p className="prag-1">
                            Enter your new password and click Reset Password. <br />
                        </p>
                        <Form
                            name="ForgotPassword"
                            form={form}
                            layout="vertical"
                            onFinish={handleChangePassword}
                            onFinishFailed={onFinishFailed}
                        >
                            <div className="text-center">
                                <RegexTextField
                                    required={true}
                                    label="Password"
                                    value={password}
                                    type={showPass ? "text" : "password"}
                                    onChange={(e) => {
                                        setPassword(e.target.value);
                                    }}
                                    placeholder="Password"
                                    variant="outlined"
                                    fullWidth={true}
                                    autoComplete="off"
                                    error={password && !regex.password.test(password)}
                                    helperText={password && !regex.password.test(password) ? error_massage_password(true) : ''}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={handleClickShowPassword}
                                                >
                                                    {showPass ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />

                                <RegexTextField
                                    required={true}
                                    label="Confirm Password"
                                    value={confirmPassword}
                                    type={showConfirmPassword ? "text" : "password"}
                                    onChange={(e) => {
                                        setConfirmPassword(e.target.value);
                                    }}
                                    placeholder="Confirm Password"
                                    variant="outlined"
                                    fullWidth={true}
                                    error={confirmPassword && !regex.password.test(confirmPassword)}
                                    helperText={confirmPassword && !regex.password.test(confirmPassword) ? error_massage_password(true) : ''}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={handleClickShowConfirmPassword}
                                                >
                                                    {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <Form.Item
                                >
                                    <Button
                                        className="btn-sign-in"
                                        type="primary" htmlType="submit"
                                    >
                                        Reset Password
                                    </Button>
                                </Form.Item>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
            <Footer />
            <Backdrop id="loading-dialog" open={isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
}

export default ResetPassword;