import React , {useEffect, useState}  from 'react'
import classnames from 'classnames'
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import { Typography}from '@material-ui/core'
import './style.scss'
import { useSnackbar } from 'notistack';

const KILO_BYTES_PER_BYTE = 1000;
const UploadFile =({
    onSetFiles : onSetFiles 

}) => {
    const { enqueueSnackbar } = useSnackbar();



    const [selectedFiles , setSelectedFiles] = useState([])
    const  UploadFile =() => {
        document.getElementById('selectedFile').click() 
    }

    useEffect(() => {
        checkFilesSize()
    },[selectedFiles])
    const checkFilesSize = () => {
        var sumSizeFiles = 0
        for (let x in selectedFiles) {
            var filesize = ((selectedFiles[x].size/1024)/1024).toFixed(4);
            sumSizeFiles += parseFloat (((selectedFiles[x].size/1024)/1024).toFixed(4));
            if (
                !(
                    filesize <= 20
                )
              ) {
                  enqueueSnackbar('Error uploading file. Maximum file size of 20MB is allowed', { variant: 'warning' });
                  onRemoveFile(x)
                  return false
            }
        }
        if(!(sumSizeFiles <= 20)) {
            enqueueSnackbar('Error uploading file. Maximum file size of 20MB is allowed', { variant: 'warning' });
            onRemoveFile(selectedFiles[selectedFiles.length - 1])
        }
    }
    const convertBytesToKB = (bytes) => Math.round(bytes / KILO_BYTES_PER_BYTE);
    const  selectedFile= (e) => {
        let files = [...e.target.files];
        let array = [...selectedFiles , ...files]
            setSelectedFiles([
                ...array
            ])
            onSetFiles([
                ...array
            ])    
        }
       
    const onRemoveFile = (index) => {
        var newFileList = Array.from(selectedFiles);
        newFileList.splice(index, 1);
        setSelectedFiles(
            newFileList
        )
        onSetFiles(
            newFileList
        )
    }
    return (
        <form  onSubmit={UploadFile} encType="multipart/form-data">
        <div id='upload-file' > 
                    <Typography variant="h6" className="text-header">
                            Attachment(s)
                    </Typography>
                    <button type="button" className='icon-style' onClick={UploadFile}>
                        <i>
                            <CloudUploadIcon />
                        </i> 
                        <span>Upload files</span>
                    </button>
                    <input type={'file'} name="files"  multiple className='input-file' id ='selectedFile' onChange={selectedFile}/>
        </div>
        <div id='place-holder-text'>
            <p>Total size limit for attached files combined: 20MB. Allowed file types: Word, Excel, PPT, PDF, Image, Video, Audio.</p>
        </div>
        <div id='view-files'>
        <article>
                <section className='section-files-pre'>
                {Object.keys(selectedFiles).map((fileName, index) => {
                    let file = selectedFiles[fileName];
                    let isImageFile = file.type.split("/")[0] === "image";
                    return (
                    <section key={fileName} className='section-each-file'>
                        <div className={classnames('img-card',{'isImageFile' :isImageFile})}>
                        {isImageFile && (
                            <img
                            src={URL.createObjectURL(file)}
                            alt={`file preview ${index}`}
                            />
                        )}
                        <div className={classnames('img-card',{'isImageFile' :isImageFile})}>
                            <span>{file.name}</span>
                            <aside>
                                <span>{convertBytesToKB(file.size)} kb</span>
                                <DeleteSweepIcon  className='icon-delete' onClick={() => onRemoveFile(fileName)}/>
                            </aside>
                        </div>
                        </div>
                    </section>
                    );
                })}
                </section>
        </article> 
        </div>
        </form>
        );
}
export default UploadFile