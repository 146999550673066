import React, { useState, useRef, Fragment, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { useMount } from 'ahooks';
import PropTypes from 'prop-types';

import { Form, InputNumber, Input } from 'antd';

import { Calendar, DateObject } from 'react-multi-date-picker';
import DatePanel from 'react-multi-date-picker/plugins/date_panel';
import Weekends from 'react-multi-date-picker/plugins/weekends';

import './index.scss';
import VisitConfig from '../../components/VisitConfig';

const RegulatedForm = (props) => {
    const { form , visit_per_slot , weeks_per_project , dateMultiple ,setDateMultiple} = props;
    const calendarRef = useRef();

    const dateMinMax = {
        min: new DateObject(),
    };

    useMount(() => {
        setTimeout(() => _handleRemoveCalendarComma(), 0);
    });

    useEffect(() => {
        let arr = [];

        for (let i = 1; i <= visit_per_slot; i++) {
            arr.push({
                date: null,
                timeStartEnd: [],
                duration: null,
                timeInterval: null,
                panelistPerSlot: 1,
            });
        }

        form.setFieldsValue({ visit_config_rec: arr });
    },[])

    const _handleRemoveCalendarComma = (month) => {
        let x = ReactDOM.findDOMNode(calendarRef.current);
        const calendarHeader = x.querySelector('.rmdp-header-values span:first-child');

        if (calendarHeader) {
            if (month) {
                const dateObject = new DateObject(month);

                calendarHeader.innerHTML = dateObject.month.name;
            } else {
                calendarHeader.innerHTML = calendarHeader.innerHTML.replace(',', '');
            }
        }
    };

    const onCalendarChange = (values) => {
        setDateMultiple(values);
    };

    return (
        <Fragment>
            <div className="add-new-visits-cont">
                <div className="add-new-visits-left">
                    <Form.Item label="Weeks per project" name="weeksPerProject">
                        <InputNumber
                           disabled
                        />
                    </Form.Item>

                    <Form.Item label="" name="selectedDays" hidden={weeks_per_project === 0} rules={[{ required: true, message: 'select date it\'s required' }]}>
                        <Calendar
                            ref={calendarRef}
                            multiple
                            weekStartDayIndex={0}
                            minDate={dateMinMax.min}
                            value={dateMultiple}
                            onChange={onCalendarChange}
                            onMonthChange={_handleRemoveCalendarComma}
                            mapDays={({ date }) => {
                                let props = {},
                                    isWeekend = [0, 6].includes(date.weekDay.index);

                                if (isWeekend) {
                                    props.className = 'highlight highlight-red';
                                }

                                return props;
                            }}
                            plugins={[<DatePanel sort="date" />, <Weekends weekends={[0, 6]} />]}
                        />
                    </Form.Item>

                    <Form.Item label="Location" name="location" rules={[{ required: false, message: '' }]}>
                        <Input tabIndex={4} showCount={255}
									maxLength={255} />
                    </Form.Item>
                </div>
                      
                <div className="add-new-visits-right">
                    <Form.Item
                        label="Visits per slot"
                        name="visits_per_slot"
                        rules={[{ required: true, message: 'Weeks per project is required' }]}
                    >
                        <InputNumber  disabled />
                    </Form.Item>
                    <Form.Item label="" name="visit_config_rec">
                        <VisitConfig IsRecurring={true} />
                    </Form.Item>
                </div>
            </div>
        </Fragment>
    );
};

RegulatedForm.propTypes = {
    form: PropTypes.object,
    visit_per_slot: PropTypes.number,
    weeks_per_project:PropTypes.number
};

export default connect()(RegulatedForm);
