import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useMount, useUpdateEffect } from 'ahooks';
import moment from '../../library/moment';
import _ from 'lodash';
import { PageHeader, Table, DatePicker, Menu, Input, Dropdown } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import { Backdrop, IconButton, CircularProgress, Tooltip } from '@material-ui/core';
import {Schedule, MoreVert  , Edit, Visibility} from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import urls from '../../utility/urls';
import { errorMapper } from '../../utility';
import api from '../../library/api';

import '../ProjectManagement/style.scss';
import AuthHelper from '../../library/helpers/AuthHelper';
import { mdWindow } from '../../utility/settings';

const { RangePicker } = DatePicker;

const ProjectsForUser = (props) => {
    const { tabMenuItemKeys , sizeWindow } = props;
    const authHelper = new AuthHelper()

    const historyLink = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    const filteredData = useRef({
        upcoming: [],
        current : [],
        past: [],
    });

    const rangeFilteredData = useRef({
        upcoming: [],
        current : [],
        past: [],
    });

    const [tabSelected, setTabSelected] = useState(tabMenuItemKeys[0]);
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isDateRangeFilter, setIsDateRangeFilter] = useState(false);
    const [selectedDateRange, setSelectedDateRange] = useState();
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    useMount(() => {
        fetchData();
    });

    useUpdateEffect(() => {
        const obj = isDateRangeFilter ? rangeFilteredData : filteredData;

        if (tabSelected === 'upcoming') {
            setData(obj.current.upcoming);
        }else if (tabSelected === 'current') {
            setData(obj.current.current);
        }else {
            setData(obj.current.past);
        }
    }, [tabSelected, isDateRangeFilter]);

    const processRawData = (arr) => {
       
        filteredData.current.upcoming = _.filter(arr.data[0], (v) => {
            const currentDate = moment();
            return moment(v.project_end_date).isSameOrAfter(currentDate, 'day')
        });
        filteredData.current.current = _.filter(arr.data[2], (v) => {
            const currentDate = moment();
            return moment(v.project_end_date).isSameOrAfter(currentDate, 'day');
        });

        filteredData.current.past = _.filter(arr.data[1], (v) => {
            const currentDate = moment().subtract(29,'d').format('YYYY-MM-DD');
            return moment(v.project_end_date).isSameOrAfter(currentDate, 'day') &&  moment(v.project_end_date).isBefore(moment(), 'day');
        });
        return arr
    };
    useEffect(() => {
        document.title = "OnTheDot Panelist Portal"
      }, [])
    const fetchData = async () => {
        const id = await authHelper.getUserDetails().id
        setIsLoading(true);

        api.user
            .fetchProjectByEmail(id)
            .then((d) => {
                setIsLoading(false);

                if (d.messages === 'success') {
                    processRawData(d);
                    setData(filteredData.current[tabSelected]);
                    localStorage.setItem("selectedTab","NewProjectsTab");
                } else {
                    enqueueSnackbar(errorMapper(d.messages), { variant: 'warning' });
                }
            })
            .catch((e) => {
                setIsLoading(false);
                enqueueSnackbar('An error occurred while getting project information. Please contact system admin for help.', { variant: 'error' });
            });
    };

    const onActionViewClick = (rec) => {
        localStorage.setItem("appointmentsCurrentID", rec.id);
        historyLink.push(`${urls.USER_PROJECT_LIST_DETAILS}/${rec.id}`);
    };

    const onActionMyAppointment = rec => {
        localStorage.setItem("appointmentsID", rec.id);
        historyLink.push(`${urls.USER_APPOINTMENT}/${rec.id}`);
    }

    const columns = [
        {
            title: 'ID',
            align: 'left',
            width: 250,
            dataIndex: 'pr_id',
            key: 'pr_id',
            sorter: {
                compare: (a, b) => {
                    const idA = a.pr_id.toUpperCase();
                    const idB = b.pr_id.toUpperCase();

                    if (idA < idB) {
                        return -1;
                    }

                    if (idA > idB) {
                        return 1;
                    }

                    return 0;
                },
                multiple: 5,
            },
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Project Name',
            align: 'left',
            width: 300,
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Start Date',
            align: 'center',
            width: 140,
            dataIndex: 'project_start_date',
            defaultSortOrder: 'ascend',
            sorter: {
                compare: (a, b) => {
                    if (a.project_start_date.isBefore(b.project_start_date, 'day')) {
                        return -1;
                    }

                    if (a.project_start_date.isAfter(b.project_start_date, 'day')) {
                        return 1;
                    }

                    return 0;
                },
                multiple: 4,
            },
            sortDirections: ['descend', 'ascend'],
            render: (v, r) => {
                return moment(v).format(api.utils.MOMENTFORMAT.date);
            },
        },
        {
            title: 'End Date',
            align: 'center',
            width: 140,
            dataIndex: 'project_end_date',
            sorter: {
                compare: (a, b) => {
                    if (a.project_end_date.isBefore(b.project_end_date, 'day')) {
                        return -1;
                    }

                    if (a.project_end_date.isAfter(b.project_end_date, 'day')) {
                        return 1;
                    }

                    return 0;
                },
                multiple: 3,
            },
            sortDirections: ['descend', 'ascend'],
            render: (v, r) => {
                return moment(v).format(api.utils.MOMENTFORMAT.date);
            },
        },
        /*{
            title: 'Action',
            width: 80,
            editable: false,
            fixed: 'right',
            hidden : true,
            align: 'center',
            render: (text, record, index) => {
                const menu = (
                    <Menu>
                       { !(tabSelected === 'past' ) && !(tabSelected === 'current' ) &&  <Menu.Item key="view-detail">
                            <Tooltip title={<p style={{fontSize: '14px'}}>{"Book appointment"}</p>}>
                            <IconButton
                                size="small"
                                disableRipple={true}
                                onClick={onActionViewClick.bind(this, record)}
                            >
                                <Edit />
                            </IconButton>
                            </Tooltip>
                           
                        </Menu.Item>
                        }
                        { !(tabSelected === 'upcoming' ) && !(tabSelected === 'past' ) && 
                        <Menu.Item key="view-appointment">
                            <Tooltip title={<p style={{fontSize: '14px'}}>{"View/Reschedule/Cancel appointment"}</p>} >
                                <IconButton
                                    size="small"
                                    disableRipple={true}
                                    onClick={onActionMyAppointment.bind(this, record)}
                                >
                                    <Schedule />
                                </IconButton>
                                </Tooltip>
                            </Menu.Item>
                        }

                    { (tabSelected === 'past' ) && 
                        <Menu.Item key="view-appointment">
                            <Tooltip title={<p style={{fontSize: '14px'}}>{"View booking"}</p>} >
                            <IconButton
                                size="small"
                                disableRipple={true}
                                onClick={onActionMyAppointment.bind(this, record)}
                            >
                                <Visibility />
                            </IconButton>
                                </Tooltip>
                            </Menu.Item>
                        }
                        </Menu> 
                );

                return (
                    <Dropdown trigger={['click']} overlay={menu}>
                        <MoreVert />
                    </Dropdown>
                );
            },
        }*/,
    ];

    const onTabMenuClick = (e) => {
        setTabSelected(e.key);
        if(e.key==='upcoming'){
            localStorage.setItem("selectedTab","NewProjectsTab");
        }else if(e.key==='current'){
            localStorage.setItem("selectedTab","ActiveBookingTab");
        }else{
            localStorage.setItem("selectedTab",e.key);
        }

    };

    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys(selectedRowKeys);
        },
        onSelect: (record, selected, selectedRows) => {},
        onSelectAll: (selected, selectedRows, changeRows) => {},
    };

    const onRowTable = (record, rowIndex) => {
        return {
            onClick: (e) => {
                if((tabSelected === 'past' )) {
                    onActionMyAppointment(record)
                }else if((tabSelected === 'current' )) {
                    onActionMyAppointment(record)
                }else {
                    onActionViewClick(record)
                }
            },
            onDoubleClick: (e) => {},
            onContextMenu: (e) => {},
            onMouseEnter: (e) => {},
            onMouseLeave: (e) => {},
        };
    };

    const onFilterDateChange = (mode) => (d, s) => {
        if (d) {
            const startD = _.cloneDeep(d)[0].subtract(1, 'days'),
                endD = _.cloneDeep(d)[1].add(1, 'days');

            rangeFilteredData.current.upcoming = _.filter(filteredData.current.upcoming, (v) => {
                if (mode === 'start') {
                    return moment(v.project_start_date).isBetween(startD, endD);
                }
                if (mode === 'end') {
                    return moment(v.project_end_date).isBetween(startD, endD);
                }
            });

            rangeFilteredData.current.past = _.filter(filteredData.current.past, (v) => {
                if (mode === 'start') {
                    return moment(v.project_start_date).isBetween(startD, endD);
                }
                if (mode === 'end') {
                    return moment(v.project_end_date).isBetween(startD, endD);
                }
            });

            setData(rangeFilteredData.current[tabSelected]);
            setSelectedDateRange({
                ...selectedDateRange,
                ...(mode === 'start' && { start: [startD, endD] }),
                ...(mode === 'end' && { end: [startD, endD] }),
            });
            setIsDateRangeFilter(true);
        } else {
            setSelectedDateRange(null);
            setIsDateRangeFilter(false);
        }
    };

    const _handleSearch = (e) => {
        const text = e.target.value;
        const obj = isDateRangeFilter ? rangeFilteredData : filteredData;

        setData(
            obj.current[tabSelected].filter(
                (project) =>
                    project.name.toLowerCase().includes(text.toLowerCase()) ||
                    project.pr_id.toLowerCase().includes(text.toLowerCase())
            )
        );
    };
    
    const _handleClearSearch = (e) => {
        const text = e.target.value;
        if(text === '') {
            const obj = isDateRangeFilter ? rangeFilteredData : filteredData;

            setData(
                obj.current[tabSelected]
            );
        }
    }
    return (
        <div id="project-management-page">
            <PageHeader ghost={false} className={"site-page-header-responsive"} title="Project Management"> </PageHeader>

            <div className="project-management-page-content">
                <div className="project-management-page-table-container">
                    <Menu
                        className="project-management-page-table-tabmenu"
                        mode={sizeWindow  && sizeWindow < mdWindow  ? "vertical" : "horizontal"}
                        selectedKeys={[tabSelected]}
                        onClick={onTabMenuClick}
                    >
                        {tabMenuItemKeys.map((v) => (
                            <Menu.Item key={v}>{v === 'upcoming'? 'NEW PROJECTS' : v==='current'? 'ACTIVE BOOKING' : 'PAST'}</Menu.Item>
                        ))}
                    </Menu>

                    <div className="project-management-page-table-controls">
                        <div className="project-management-page-table-controls-left">
                            <Input
                                className="searchbox"
                                size="middle"
                                prefix={<SearchOutlined />}
                                placeholder="Enter Project ID or Project Name"
                                onPressEnter={_handleSearch}
                                onChange={_handleClearSearch}
                            />
                        </div>

                        <div className="project-management-page-table-controls-right homePageadmin-export">
                            <div>
                                <div className="table-controls-row">
                                    <span className="label">Start Date</span>
                                    <RangePicker
                                        allowClear={true}
                                        placeholder={['From', 'To']}
                                        size="middle"
                                        inputReadOnly={false}
                                        format={api.utils.MOMENTFORMAT.date}
                                        onChange={onFilterDateChange('start')}
                                    />
                                </div>
                                <div className="table-controls-row isEndDate">
                                    <span className="label">End Date</span>
                                    <RangePicker
                                        allowClear={true}
                                        placeholder={['From', 'To']}
                                        size="middle"
                                        inputReadOnly={false}
                                        format={api.utils.MOMENTFORMAT.date}
                                        onChange={onFilterDateChange('end')}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <Table
                        className="project-management-page-table"
                        columns={columns}
                        bordered={false}
                        rowKey={'id'}
                        sticky={true}
                        showHeader={true}
                        tableLayout={sizeWindow  && sizeWindow < mdWindow ? "auto" : "fixed"}
                        rowSelection={{ ...rowSelection }}
                        onRow={onRowTable}
                        pagination={{
                            showTotal: (total, range) => {
                                return `${range[0]}-${range[1]} of ${total} items`;
                            },
                            defaultCurrent: 1,
                            defaultPageSize: 50,
                            pageSizeOptions: [10, 20, 30, 50, 100, 150, 200],
                            showSizeChanger: true,
                            position: ['bottomRight'],
                            total: data.length,
                        }}
                        dataSource={data}
                        scroll={{
                            y: '50vh',
                            x: '100%',
                        }}
                    ></Table>
                </div>
            </div>
            <Backdrop id="loading-dialog" open={isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
};

ProjectsForUser.defaultProps = {
    tabMenuItemKeys: ['upcoming','current','past'],
};

ProjectsForUser.propTypes = {
    tabMenuItemKeys: PropTypes.array,
};

export default connect()(ProjectsForUser);
