import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import './style.scss';

const links = [
  {
        label: 'Privacy',
        link: 'https://privacypolicy.pg.com/en-US/',
    },
    {
        label: 'Terms & Conditions',
        link: 'https://termsandconditions.pg.com/en-us/',
    },
    {
        label: 'AdChoice',
        image: 'https://youradchoices.com/DAA_style/YAC/icon.png',
        link: 'http://www.pg.com/privacy/english/privacy_statement.shtml#iba',
    },
];

const FooterComp = ({ isAdmin }) => {
    return (
        <div id="footer-section">
            <ul>
                {links.map((v, i) => (
                    <li key={i}>
                        <a href={v.link} target="_blank" rel="noopener noreferrer" className={!v.image? '' : 'textwithimage'}>
                            {!v.image ? v.label : <> <img className="privacy-img" alt="privacy" src={v.image} />{ v.label}</> }
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    );
};

FooterComp.defaultProps = {
    IsAdmin: false,
};

FooterComp.propTypes = {
    IsAdmin: PropTypes.bool,
};

export default connect()(FooterComp);
