import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { Backdrop, CircularProgress } from '@material-ui/core';
import { useSnackbar } from 'notistack';

import { useAuth } from '../../../components/Auth/auth';

import urls from '../../../utility/urls';

import api from '../../../library/api'

import LoginForm from '../../../components/LoginUserPage'
import '../style.scss';
import AuthHelper from '../../../library/helpers/AuthHelper';
import { ROLES } from '../../../utility/constant';
import ChangePassword from '../../../components/ChangePassword';
import {regex} from '../../../utility'
import FooterComp from '../../../components/Footer';

const LoginUserPage = (props) => {

    const [isLoggedInUser, setLoggedInUser] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showModalChangePassword, setShowModalChangePassword] = useState(false);
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [showPass , setShowPass] = useState(false) 
    const [showConfirmPassword , setShowConfirmPassword] = useState(false) 
    const [lastName , setLastName] = useState("") 
    const [firstName , setFirstName] = useState("") 
    const [panelistID , setPanelistID] = useState("") 
    const [tnumber , setTnumber] = useState("") 

    const [employeStatus , setEmployeeStatus] = useState(null) 
    const { enqueueSnackbar } = useSnackbar();
    const { setAuthTokens } = useAuth();
    const authHelper = new AuthHelper();
    useEffect(() => {
        document.title = "OnTheDot Panelist Portal"
      }, [])

   
    if(isLoggedInUser) { 
        if(Number(authHelper.getIsFirstTimeLogging())) {  
            setShowModalChangePassword(true)
            setLoggedInUser(false)
        }else {
            return <Redirect to={urls.USER_HOME} />;
        }
       
    }
    if(authHelper.getRole() === ROLES.PANELIST && authHelper.isLogging() && authHelper.getUserDetails() !== undefined && !authHelper.getIsFirstTimeLogging() ) {
        setLoggedInUser(true)
    }

    const onLogin = (vals) => {
        setIsLoading(true)
        vals.role = 'panelist'
        api.user 
            .Login(vals)
            .then(res =>  {
                if(res.status === 'success') { 
                    enqueueSnackbar('Login successfully' , {variant : 'success'})
                    setAuthTokens(res.token);
                    authHelper.setUserDetails(res.user)
                    authHelper.setRole(res.user.role)
                    authHelper.setIsFirstTimeLogging(res.user.must_change_password)
                    authHelper.setRefreshToken(res.refreshToken)
                    setLoggedInUser(true)
                }else {
                    enqueueSnackbar(res?.error, {
                        autoHideDuration : 5000,
                        variant : 'error'
                    })
                }
                setIsLoading(false);
            })
            .catch((err) => { 
                enqueueSnackbar('Login Failed: Your email or password is incorrect' , {variant : 'error'})
            })
    }

    const onChangePassword = () => {

        if (!password) {
            enqueueSnackbar('Password is required!', { variant: 'warning' });
            return;
        }

        if (!confirmPassword) {
            enqueueSnackbar('confirmPassword is required!', { variant: 'warning' });
            return;
        }
        if(!(password === confirmPassword)){
            enqueueSnackbar('Passwords do not match.', { variant: 'warning' });
            return;
        }
        if (!regex.password.test(password) && !regex.password.test(confirmPassword)) {
            enqueueSnackbar('Password is invalid!', { variant: 'warning' });
            return;
        }
        if(firstName === "") {
            enqueueSnackbar('First name is required!', { variant: 'warning' });
            return;
        }
        if(lastName === "") {
            enqueueSnackbar('Last name is required!', { variant: 'warning' });
            return;
        }
        if(employeStatus === "" || !employeStatus  ) {
            enqueueSnackbar('Employe status is required!', { variant: 'warning' });
            return;
        }
        setIsLoading(true)
        const detailsUser = authHelper.getUserDetails()
        api.user.changePassword({
            lastname : lastName,
            firstname :firstName,
            new_password : password,
            employeStatus : employeStatus,
            panelistID : panelistID || "",
            t_number:tnumber || "",
            id : detailsUser.id
        })
            .then((res) => {
                if(res.status === 'success') { 
                    enqueueSnackbar('Password Change Successful' , {variant : 'success'})
                    authHelper.setIsFirstTimeLogging(!authHelper.getIsFirstTimeLogging())
                    let user_profile  = {
                        ...detailsUser,
                        last_name : lastName ,
                        first_name : firstName,
                        panelist_id : panelistID || "",
                        t_number:tnumber || "",
                    }
                    authHelper.setUserDetails(user_profile)
                    setLoggedInUser(true)
                }else {
                    enqueueSnackbar('An error occurred while changing password. Please contact system admin for help.' , {variant : 'error'})
                }
                setIsLoading(false);
            }).catch((err) => { 
                enqueueSnackbar('An error occurred while changing password. Please contact system admin for help.' , {variant : 'error'})
            })
    }
    const handleClickShowPassword = () => {
        setShowPass(!showPass)
    };
    const handleClickShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword)
    };
    return (
        <div id="login-page">
            <div className="login-container">
                <div className="login-cont-left">
                    <img src="/assets/login_banner_bg_1.png" alt="P&G People" />
                </div>
                <div className="login-cont-right">
                    <div className="login-content">
                        <div className="login-logo">
                            <img src="/assets/png_logo@2x.png" alt="P&G Logo" />
                        </div>

                        <h2>
                            Welcome  <br /> Please login to your account.
                        </h2>
                        <div className="login-form">
                            <div>
                                <LoginForm 
                                     onLogin = {onLogin}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <FooterComp />
            </div>

        <ChangePassword 
                showModalChangePassword={showModalChangePassword}
                isFirstTime={Number(authHelper.getIsFirstTimeLogging())}
                onChangePassword={onChangePassword}
                setPassword={setPassword}
                setConfirmPassword={setConfirmPassword}
                password={password}
                setLastName={setLastName}
                setFirstName={setFirstName}
                setEmployeeStatus ={setEmployeeStatus}
                employeStatus={employeStatus}
                lastName={lastName}
                firstName={firstName}
                confirmPassword={confirmPassword}
                handleClickShowPassword={handleClickShowPassword}
                showPass={showPass}
                setShowPass={setShowPass}
                handleClickShowConfirmPassword={handleClickShowConfirmPassword}
                showConfirmPassword={showConfirmPassword}
                setPanelistID={setPanelistID}
                setTnumber={setTnumber}
                panelistID={panelistID}
                tnumber={tnumber}
            />
            <Backdrop id="loading-dialog" open={isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
};

export default connect()(LoginUserPage);
