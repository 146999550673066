import { Modal, Table, Tooltip } from 'antd'
import React from 'react'
import { LIST_PDF } from '../../utility/constant'

import './index.scss'
import { IconButton } from '@material-ui/core'
import { VerticalAlignBottom, Visibility } from '@material-ui/icons'
const HowToGetStart = (props) => {
    const {
        showHowToGetStartModal,
        onCancelModal
    } = props
    const columns = [
        {
            title: 'Name',
            dataIndex: 'filename',
            key: 'filename',
            render: (text) => <p>{text}</p>
        },
        {
            title: 'Actions',
            width: 50,
            editable: false,
            fixed: 'right',
            align: 'center',
            render: (text, record, index) => (
                <div className="how-to-get-start-table-row-ctrl">
                    <Tooltip title={<p style={{fontSize: '14px'}}>{"View"}</p>} >
                        <a target='_blank' href={`${process.env.PUBLIC_URL}/assets/files/pdf/${text.filename}`} rel="noopener noreferrer">
                            <Visibility />
                        </a>
                                        
                    </Tooltip>
                    <Tooltip title={<p style={{fontSize: '14px'}}>{"Download"}</p>} >
                        <a target='_blank' href={`${process.env.PUBLIC_URL}/assets/files/pdf/${text.filename}`} download>
                            <VerticalAlignBottom />
                        </a>
                    </Tooltip>
                </div>
            )
        }
    ]

    return (
    <Modal 
        title={
            <div>
            How to get Started
        </div>
        }
        width={600}
        visible={showHowToGetStartModal}
        centered={true}
        cancelButtonProps={{
            style: {
              display:"none",
            },
          }}
        okButtonProps={{
            style: {
                display:"none",
              },
        }}
        onCancel={onCancelModal}
    >   
        <Table
         className="how-to-get-start-table"
         bordered={false}
         rowKey={'id'}
         filterMultiple={true}
         dataSource={LIST_PDF}  
         columns={columns}
         />
    </Modal>
 )
}
export default HowToGetStart