import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useMount, useUpdateEffect } from 'ahooks';
import moment from '../../library/moment';
import _ from 'lodash';

import { PageHeader, Table, Switch, DatePicker, Menu, Input, Dropdown, Form } from 'antd';
import { Resizable } from 'react-resizable';
import { SearchOutlined } from '@ant-design/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { Backdrop, Button, IconButton, CircularProgress, Tooltip } from '@material-ui/core';
import { VerticalAlignBottom, DeleteForever, FileCopy, Link, Visibility, MoreVert, Add, TableChart, Close, Search } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import { exportExcel } from '../../utility/xslx';
import urls from '../../utility/urls';
import { errorMapper } from '../../utility';
import api from '../../library/api';
import CopyToClipboard2 from '../../components/CopyToClipboard/index';
import PopConfirm from '../../components/PopConfirm';

import './style.scss';
import { Promise } from 'bluebird';
import Modal from 'antd/lib/modal/Modal';
import AuthHelper from '../../library/helpers/AuthHelper';
import { ROLES } from '../../utility/constant';
import CustomTemplate from './CustomTemplate';
import { smWindow } from '../../utility/settings';



const { RangePicker } = DatePicker;

const ResizableTitle = (props) => {
    const { onResize, width, ...restProps } = props;

    if (!width) {
        return <th {...restProps} />;
    }

    return (
        <Resizable
            width={width}
            height={0}
            handle={
                <span
                    className="react-resizable-handle"
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                />
            }
            onResize={onResize}
            draggableOpts={{
                enableUserSelectHack: false,
            }}
        >
            <th {...restProps} />
        </Resizable>
    );
};

const ProjectManagementPage = (props) => {
    const { tabMenuItemKeys, sizeWindow } = props;

    const historyLink = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const filteredData = useRef({
        upcoming: [],
        past: [],
    });

    const rangeFilteredData = useRef({
        upcoming: [],
        past: [],
    });

    const [tabSelected, setTabSelected] = useState(tabMenuItemKeys[0]);
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isDateRangeFilter, setIsDateRangeFilter] = useState(false);
    const [selectedDateRange, setSelectedDateRange] = useState();
    const [selectedDateFilter, setSelectedDateFilter] = useState();
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [showTemplate, setshowTemplate] = useState(false)
    const [column, setColumn] = useState([])
    const [subject, setSubject] = useState({
        title: '',
        name: '',
    })
    const [, setSites] = useState([])
    const [showExportStatistics, setShowExportStatistics] = useState(false)
    const projectObj = useRef({
        project: {},
    });
    const auth = new AuthHelper()

    const [form1] = Form.useForm()
    useMount(() => {
        fetchData();
        setColumn(columns)
        api.admins
            .getSitesAdmin()
            .then((d) => {
                if (d.messages === 'success') {
                    setSites(d.data)
                }
            })
            .catch((e) => {
                enqueueSnackbar('Server error!', { variant: 'error' });
            });
    });
    const [emailEditor, setEmailEditor] = useState(false);
    const [showCustomTemplateModal, setShowCustomTemplateModal] = useState(false)
    const [showModalPublish, setShowModalPublish] = useState(false)
    const [publishValue, setPublishValue] = useState(false)
    const [files, setFiles] = useState([])
    const [copyAdminUrl, setCopyAdminUrl] = useState({
        value: '',
        copied: false,
    });
    const [copyPanelistUrl, setCopyPanelistUrl] = useState({
        value: '',
        copied: false,
    });

    useUpdateEffect(() => {
        const obj = isDateRangeFilter ? rangeFilteredData : filteredData;

        if (tabSelected === 'upcoming') {
            setData(obj.current.upcoming);
        } else {
            setData(obj.current.past);
        }
    }, [tabSelected, isDateRangeFilter]);

    const processRawData = (arr) => {
        filteredData.current.upcoming = _.filter(arr, (v) => {
            const currentDate = moment();
            return moment(v.project_end_date).isSameOrAfter(currentDate, 'day');
        });

        filteredData.current.past = _.filter(arr, (v) => {
            const currentDate = moment().subtract(29, 'd').format('YYYY-MM-DD');
            return moment(v.project_end_date).isSameOrAfter(currentDate, 'day') && moment(v.project_end_date).isBefore(moment(), 'day');
        });

        return arr;
    };

    const handleResize =
        (index) =>
            (_, { size }) => {
                const newColumns = [...column];
                newColumns[index] = { ...newColumns[index], width: size.width };
                setColumn(newColumns);
            };

    const mergeColumns = column.map((col, index) => ({
        ...col,
        onHeaderCell: (column) => (
            {
                width: column.width,
                onResize: handleResize(index),
            }),
    }));

    const fetchData = async () => {
        setIsLoading(true);

        api.projects
            .fetchAll()
            .then((d) => {
                setIsLoading(false);
                if (d.messages === 'success') {
                    processRawData(d.data);
                    setData(filteredData.current[tabSelected]);
                } else {
                    enqueueSnackbar(errorMapper(d.messages), { variant: 'warning' });
                }
            })
            .catch((e) => {
                setIsLoading(false);
                enqueueSnackbar('An error occurred while getting project information. Please contact system admin for help.', { variant: 'error' });
            });
    };
    const publishedProject = (value, ProjectId, e, rec) => {
        setIsLoading(true);
        if (value.is_published) {
            rec.is_published = 1
        } else {
            rec.is_published = 0
        }
        const form_data = new FormData();
        for (let i = 0; i < files.length; i++) {
            form_data.append('files', files[i]);
        }
        setFiles([])
        api.projects
            .publish(value, ProjectId, rec, form_data)
            .then((d) => {
                setIsLoading(false);

                if (d.messages === 'success') {
                    fetchData();
                    setEmailEditor(false)
                    setShowCustomTemplateModal(false)
                    setShowModalPublish(false)
                    enqueueSnackbar(`Successfully ${e ? 'published' : 'unpublished'} project`, { variant: 'success' });
                } else {
                    setShowModalPublish(false)
                    enqueueSnackbar(errorMapper(d.messages), { variant: 'warning' });
                }
            })
            .catch((e) => {
                setIsLoading(false);
                setShowModalPublish(false)
                enqueueSnackbar('An error occurred while attempting to publish project. Please contact system admin for help.', { variant: 'error' });
            });
    };

    const onPublished = (rec, i, e) => {
        if (e) {
            setShowModalPublish(true)
            //store rec , e , data
            setPublishValue({ rec: rec, i: i, e: e })
        } else {
            publishedProject(
                {
                    id: rec.id,
                    is_published: false,
                },
                rec.id,
                false,
                rec
            );
        }

    };

    const copyProject = () => {
        projectObj.current.project = publishValue.rec
        publishValue.rec.iscopy = true
        publishedProject(
            {
                id: publishValue.rec.id,
                is_published: true,
            },
            publishValue.rec.id,
            true,
            publishValue.rec,
        );
    }

    const invitePanelist = () => {
        setShowCustomTemplateModal(true)
        setEmailEditor(true)
        projectObj.current.project = publishValue.rec
        setShowModalPublish(false)
    }

    const onSaveData = (data) => {
        publishedProject(
            {
                id: data.id,
                is_published: true,
            },
            data.id,
            true,
            data
        );
    }

    const onCLoseEditEmailTemplate = () => {
        setEmailEditor(false);
        setShowCustomTemplateModal(false)
    };
    const onActionViewClick = (rec) => {
        historyLink.push(`${urls.ADMIN_PROJECT_LIST_DETAILS}/${rec.id}`);
    };

    const onActionDupplicateClick = (rec) => {
        setIsLoading(true);

        api.projects
            .dupplicate(rec.id)
            .then((d) => {
                setIsLoading(false);

                if (d.messages === 'success') {
                    fetchData()
                    enqueueSnackbar(`Successfully duplicated project`, { variant: 'success' });
                    onActionViewClick(d.data)
                } else {
                    enqueueSnackbar(errorMapper(d.messages), { variant: 'warning' });
                }
            })
            .catch((e) => {
                setIsLoading(false);
                enqueueSnackbar('An error occurred while duplicating project. Please contact system admin for help.', { variant: 'error' });
            });
    };

    const onActionDelClick = (rec) => {
        setIsLoading(true);

        api.projects
            .delete(rec.id)
            .then((d) => {
                setIsLoading(false);

                if (d.messages === 'success') {
                    fetchData();
                } else {
                    enqueueSnackbar(errorMapper(d.messages), { variant: 'warning' });
                }
            })
            .catch((e) => {
                setIsLoading(false);
                enqueueSnackbar('An error occurred while deleting project. Please contact system admin for help.', { variant: 'error' });
            });
    };

    const onMultipleDeleteProject = () => {
        let arr = [];

        setIsLoading(true);

        _.each(selectedRowKeys, (v) => {
            arr.push(api.projects.delete(v));
        });

        Promise.mapSeries(arr, (arr, index, arrLength) => {
            return arr;
        })
            .then((d) => {
                setIsLoading(false);
                enqueueSnackbar('Successfully deleted project/s', {
                    variant: 'success',
                });
                fetchData();
            })
            .catch((e) => {
                setIsLoading(false);
                enqueueSnackbar('An error occurred while deleting projects. Please contact system admin for help.', { variant: 'error' });
            });
    };
    const columns = [
        {
            title: 'ID',
            align: 'left',
            width: 250,
            dataIndex: 'pr_id',
            key: 'pr_id',
            sorter: {
                compare: (a, b) => {
                    const idA = a.pr_id.toUpperCase();
                    const idB = b.pr_id.toUpperCase();

                    if (idA < idB) {
                        return -1;
                    }

                    if (idA > idB) {
                        return 1;
                    }

                    return 0;
                },
                multiple: 5,
            },
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Project Name',
            align: 'left',
            width: 300,
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Start Date',
            align: 'center',
            width: 140,
            dataIndex: 'project_start_date',
            defaultSortOrder: 'ascend',
            sorter: {
                compare: (a, b) => {
                    if (a.project_start_date.isBefore(b.project_start_date, 'day')) {
                        return -1;
                    }

                    if (a.project_start_date.isAfter(b.project_start_date, 'day')) {
                        return 1;
                    }

                    return 0;
                },
                multiple: 4,
            },
            sortDirections: ['descend', 'ascend'],
            render: (v, r) => {
                return moment(v).format(api.utils.MOMENTFORMAT.date);
            },
        },
        {
            title: 'End Date',
            align: 'center',
            width: 140,
            dataIndex: 'project_end_date',
            sorter: {
                compare: (a, b) => {
                    if (a.project_end_date.isBefore(b.project_end_date, 'day')) {
                        return -1;
                    }

                    if (a.project_end_date.isAfter(b.project_end_date, 'day')) {
                        return 1;
                    }

                    return 0;
                },
                multiple: 3,
            },
            sortDirections: ['descend', 'ascend'],
            render: (v, r) => {
                return moment(v).format(api.utils.MOMENTFORMAT.date);
            },
        },
        {
            title: 'Panelist',
            align: 'center',
            width: 100,
            dataIndex: 'panel_count',
            key: 'panel_count',
        },
        {
            title: 'Booked',
            align: 'center',
            width: 100,
            dataIndex: 'booked_cnt',
            key: 'booked_cnt',
        },
        {
            title: 'Creator',
            align: 'center',
            width: 250,
            dataIndex: 'creator',
            key: 'creator',
            sorter: {
                compare: (a, b) => {
                    const idA = (a.creator || '').toUpperCase();
                    const idB = (b.creator || '').toUpperCase();

                    if (idA < idB) {
                        return -1;
                    }

                    if (idA > idB) {
                        return 1;
                    }

                    return 0;
                },
                multiple: 2,
            },
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Date Created',
            align: 'center',
            width: 140,
            dataIndex: 'insert_dttm',
            sorter: {
                compare: (a, b) => {
                    if (moment(a.insert_dttm).isBefore(moment(b.insert_dttm), 'day')) {
                        return -1;
                    }

                    if (moment(a.insert_dttm).isAfter(moment(b.insert_dttm), 'day')) {
                        return 1;
                    }

                    return 0;
                },
                multiple: 1,
            },
            sortDirections: ['descend', 'ascend'],
            render: (v, r) => {
                return moment(v).format(api.utils.MOMENTFORMAT.date);
            },
        },
        {
            title: 'Published',
            width: 110,
            editable: true,
            align: 'center',
            dataIndex: 'is_published',
            key: 'id',
            render: (v, rec, i) => < Tooltip title={<p style={{ fontSize: '14px' }}>{v ? "Toggle off to Unpublish" : "Toggle on to Publish"}</p>}><Switch key={i} defaultChecked={v} onChange={onPublished.bind(this, rec, i)} /></Tooltip>,
        },
        {
            title: 'Action',
            width: 80,
            editable: false,
            fixed: 'right',
            align: 'center',
            render: (text, record, index) => {
                const menu = (
                    <Menu>
                        <Menu.Item key="view-detail">
                            <Tooltip title={<p style={{ fontSize: '14px' }}>{"View / Edit project"}</p>}>
                                <IconButton
                                    size="small"
                                    disableRipple={true}
                                    onClick={onActionViewClick.bind(this, record)}
                                >
                                    <Visibility />
                                </IconButton>
                            </Tooltip>
                        </Menu.Item>
                        {auth.getRole() !== ROLES.projAdmin && <Menu.Item key="dupplicate">

                            <PopConfirm
                                title="Are you sure you want to duplicate this project?"
                                onConfirm={onActionDupplicateClick.bind(this, record)}
                            >
                                <Tooltip title={<p style={{ fontSize: '14px' }}>{"Duplicate project"}</p>}>
                                    <IconButton size="small" disableRipple={true}>
                                        <FileCopy />
                                    </IconButton>
                                </Tooltip>
                            </PopConfirm>
                        </Menu.Item>}
                        <Menu.Item key="copy-link">
                            <Dropdown overlay={() => {
                                return (
                                    <Menu >
                                        <Menu.Item >
                                            <CopyToClipboard2
                                                label={record.name}
                                                value={api.utils.getURL(`${urls.ADMIN_BOOKING_DETAILS}/${record.id}`)}
                                            >
                                                Share to Admin
                                            </CopyToClipboard2>
                                        </Menu.Item>
                                        <Menu.Item>
                                            <CopyToClipboard2
                                                label={record.name}
                                                value={api.utils.getURL(`${urls.PARTICIPANTS_HOME}/${record.id}`)}
                                            >
                                                Share to Panelist
                                            </CopyToClipboard2>
                                        </Menu.Item>
                                    </Menu>
                                )
                            }}
                                placement="bottomLeft" arrow={{ pointAtCenter: true }}>
                                <IconButton size="small" disableRipple={true}>
                                    <Link />
                                </IconButton>
                            </Dropdown>
                        </Menu.Item>
                        {auth.getRole() !== ROLES.projAdmin && <Menu.Item key="delete">

                            <PopConfirm
                                title="Are you sure you want to delete this project?"
                                onConfirm={onActionDelClick.bind(this, record)}
                            >
                                <Tooltip title={<p style={{ fontSize: '14px' }}>{"Delete project"}</p>}>
                                    <IconButton size="small" disableRipple={true} color="secondary">
                                        <DeleteForever />
                                    </IconButton>
                                </Tooltip>
                            </PopConfirm>
                        </Menu.Item>}
                    </Menu>
                );

                return (
                    <Dropdown trigger={['click']} overlay={menu}>
                        <MoreVert />
                    </Dropdown>
                );
            },
        },
    ];
    const onTabMenuClick = (e) => {
        setTabSelected(e.key);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys(selectedRowKeys);
        },
        onSelect: (record, selected, selectedRows) => { },
        onSelectAll: (selected, selectedRows, changeRows) => { },
    };

    const onRowTable = (record, rowIndex) => {
        return {
            onClick: (e) => { },
            onDoubleClick: (e) => { },
            onContextMenu: (e) => { },
            onMouseEnter: (e) => { },
            onMouseLeave: (e) => { },
        };
    };

    const onFilterDateChange = (mode) => (d, s) => {
        if (d) {
            const startD = _.cloneDeep(d)[0].subtract(1, 'days'),
                endD = _.cloneDeep(d)[1].add(1, 'days');

            rangeFilteredData.current.upcoming = _.filter(filteredData.current.upcoming, (v) => {
                if (mode === 'start') {
                    return moment(v.project_start_date).isBetween(startD, endD);
                }
                if (mode === 'end') {
                    return moment(v.project_end_date).isBetween(startD, endD);
                }
            });

            rangeFilteredData.current.past = _.filter(filteredData.current.past, (v) => {
                if (mode === 'start') {
                    return moment(v.project_start_date).isBetween(startD, endD);
                }
                if (mode === 'end') {
                    return moment(v.project_end_date).isBetween(startD, endD);
                }
            });

            setData(rangeFilteredData.current[tabSelected]);
            setSelectedDateRange({
                ...selectedDateRange,
                ...(mode === 'start' && { start: [startD, endD] }),
                ...(mode === 'end' && { end: [startD, endD] }),
            });
            setIsDateRangeFilter(true);
        } else {
            setSelectedDateRange(null);
            setIsDateRangeFilter(false);
        }
    };

    const onFilterExportStatis = (mode) => (d, s) => {
        if (d) {
            const startD = _.cloneDeep(d)[0].subtract(1, 'days'),
                endD = _.cloneDeep(d)[1].add(1, 'days');
            setSelectedDateFilter({
                ...selectedDateFilter,
                ...(mode === 'date' && { date: [startD, endD] }),
            });
        } else {
            setSelectedDateFilter(null)
        }

    }

    const _handleSearch = (e) => {
        const text = e.target.value;
        const obj = isDateRangeFilter ? rangeFilteredData : filteredData;

        setData(
            obj.current[tabSelected].filter(
                (project) =>
                    project.name.toLowerCase().includes(text.toLowerCase()) ||
                    project.pr_id.toLowerCase().includes(text.toLowerCase())
            )
        );
    };

    const _handleClearSearch = (e) => {
        const text = e.target.value;
        if (text === '') {
            const obj = isDateRangeFilter ? rangeFilteredData : filteredData;

            setData(
                obj.current[tabSelected]
            );
        }
    }

    const _generateExportData = () => {
        const fileName = `project-list-${tabSelected}${isDateRangeFilter
            ? `-${selectedDateRange.start[0].format(api.utils.MOMENTFORMAT.date)}-${selectedDateRange.end[1].format(
                api.utils.MOMENTFORMAT.date
            )}`
            : ''
            }`;

        const headers = [
            { label: 'ID', key: 'pr_id' },
            { label: 'Project Name', key: 'name' },
            { label: 'Start Date', key: 'project_start_date' },
            { label: 'End Date', key: 'project_end_date' },
            { label: 'Panelist', key: 'panel_count' },
            { label: 'Booked', key: 'booked_cnt' },
            { label: 'Creator', key: 'creator' },
            { label: 'Published', key: 'is_published' },
        ];

        const outputData = _.cloneDeep(data).map((project) => {
            const output = {};
            headers.forEach((head) => {
                output[head.key] = project[head.key];
            });
            output.project_start_date = moment(project.project_start_date).format(api.utils.MOMENTFORMAT.date);
            output.project_end_date = moment(project.project_end_date).format(api.utils.MOMENTFORMAT.date);

            output.is_published = project.is_published ? 'yes' : 'no';

            return output;
        });

        return {
            fileName,
            header: [headers.map((head) => head.label)],
            data: outputData,
        };
    };

    const onAddTemplate = () => {
        let data = {
            flag: true,
            trigger_when: ''
        }
        api.projects.createTempalte(data).then(d => {
            enqueueSnackbar(`Successfully Create template`, { variant: 'success' })
        }).catch(err => {
            enqueueSnackbar(`An error occurred while creating template email. Please contact system admin for help.`, { variant: 'error' })
        })
    }
    const onshowTemplate = () => {
        setshowTemplate(true)
    }
    const onOkModal = (values) => {

        let data = {
            flag: false,
            trigger_when: values.trigger_when
        }

        api.projects.createTempalte(data).then(d => {
            setSubject({
                title: d.data.subject,
                name: d.data.name,
            })
        }).catch(err => {
            enqueueSnackbar(`An error occurred while creating template email. Please contact system admin for help.`, { variant: 'error' })
        })

    }
    const showModalExportStatistics = () => {
        setShowExportStatistics(true)
    }
    const onSubmitValues = values => {
        if (values) {
            api.admins
                .exportStatistics(selectedDateFilter)
                .then((d) => {
                    setIsLoading(false);

                    if (d.messages === 'success') {
                        ExportStatisticsExcelFile(d)
                        setSelectedDateFilter(null)
                        form1.resetFields()
                        enqueueSnackbar('Successfully export statistics', { variant: 'success' });
                        setShowExportStatistics(false)
                    } else {
                        enqueueSnackbar(errorMapper(d.messages), { variant: 'warning' });
                    }
                })
                .catch((e) => {
                    console.log(e)
                    setIsLoading(false);
                    setSelectedDateFilter(null)
                    form1.resetFields()
                    enqueueSnackbar('An error occurred while attempting to export statistics. Please contact system admin for help.', { variant: 'error' });
                });
        }
    }
    const ExportStatisticsExcelFile = (values) => {
        const fileName = `Statistics-${values?.data
            ? `-${moment(values.data[0].creation_date).format(api.utils.MOMENTFORMAT.date)}-${moment(values.data[values.data.length - 1].creation_date).format(
                api.utils.MOMENTFORMAT.date
            )}`
            : ''
            }`;

        const headers = [
            { label: 'ID', key: 'ID' },
            { label: 'Project', key: 'project' },
            { label: 'Site', key: 'site' },
            { label: 'Creator name', key: 'creator' },
            { label: 'Creator email', key: 'creator_email' },
            { label: 'Creation date', key: 'creation_date' },
            { label: 'Start date', key: 'start_date' },
            { label: 'End date', key: 'end_date' },
            { label: 'Status', key: 'status' },
            { label: 'Panelist invited', key: 'panelist_emails_invited' },
            { label: 'Panelist booked', key: 'panelist_booked' },
            { label: 'Project status', key: 'project_stauts' },
        ];

        const outputData = _.cloneDeep(values.data).map((project) => {
            const output = {};
            headers.forEach((head) => {
                output[head.key] = project[head.key];
            });
            output.creation_date = moment(project.creation_date).format(api.utils.MOMENTFORMAT.date);
            output.start_date = moment(project.start_date).format(api.utils.MOMENTFORMAT.date);
            output.end_date = moment(project.end_date).format(api.utils.MOMENTFORMAT.date);
            return output;
        });

        exportExcel({
            fileName,
            header: [headers.map((head) => head.label)],
            data: outputData,
        });
    }

    const closeModal = () => {
        setShowModalPublish(false)
        copyProject()
    }


    const onClickProjectLogTracker = () => {
        historyLink.push(`${urls.ADMIN_PROJECT_LIST_LOG_TRACKER}`);
    }
    return (
        <div id="project-management-page">
            <PageHeader ghost={false} className={"site-page-header-responsive"}
                extra={[
                    (ROLES.siteAdmin === auth.getRole() || ROLES.adminGlobal === auth.getRole() || ROLES.adminSuper === auth.getRole() || ROLES.projAdmin === auth.getRole()) &&
                    <Button
                        key="1"
                        variant="contained"
                        color="primary"
                        startIcon={<Search />}
                        disableRipple={true}
                        onClick={onClickProjectLogTracker}
                    >
                        Log Tracker
                    </Button>
                    ,
                    (ROLES.siteAdmin === auth.getRole() || ROLES.adminGlobal === auth.getRole()) &&
                    <Button
                        key="1"
                        variant="contained"
                        color="primary"
                        startIcon={<TableChart />}
                        disableRipple={true}
                        onClick={showModalExportStatistics}
                    >
                        Export Statistics
                    </Button>
                ]}
                title="Project Management"> </PageHeader>

            <div className="project-management-page-content">
                <div className="project-management-page-table-container">
                    <Menu
                        className="project-management-page-table-tabmenu"
                        mode={sizeWindow && sizeWindow < smWindow ? "vertical" : "horizontal"}
                        selectedKeys={[tabSelected]}
                        onClick={onTabMenuClick}
                    >
                        {tabMenuItemKeys.map((v) => (
                            <Menu.Item key={v}>{v}</Menu.Item>
                        ))}
                    </Menu>

                    <div className="project-management-page-table-controls">
                        <div className="project-management-page-table-controls-left">
                            <Input
                                className="searchbox"
                                size="middle"
                                prefix={<SearchOutlined />}
                                placeholder="Enter Project ID or Project Name"
                                onPressEnter={_handleSearch}
                                onChange={_handleClearSearch}
                            />
                        </div>

                        <div className="project-management-page-table-controls-right homePageadmin-export">
                            <div>
                                <div className="table-controls-row">
                                    <span className="label">Start Date</span>
                                    <RangePicker
                                        allowClear={true}
                                        placeholder={['From', 'To']}
                                        size="middle"
                                        inputReadOnly={false}
                                        format={api.utils.MOMENTFORMAT.date}
                                        onChange={onFilterDateChange('start')}
                                    />
                                </div>
                                <div className="table-controls-row isEndDate">
                                    <span className="label">End Date</span>
                                    <RangePicker
                                        allowClear={true}
                                        placeholder={['From', 'To']}
                                        size="middle"
                                        inputReadOnly={false}
                                        format={api.utils.MOMENTFORMAT.date}
                                        onChange={onFilterDateChange('end')}
                                    />
                                </div>
                            </div>
                            <Button
                                startIcon={<VerticalAlignBottom />}
                                disableRipple={true}
                                onClick={() => exportExcel(_generateExportData())}
                            >
                                Export
                            </Button>

                            {selectedRowKeys.length > 0 && auth.getRole() !== ROLES.projAdmin && (
                                <PopConfirm
                                    title="Are you sure you want to delete this project/s?"
                                    onConfirm={onMultipleDeleteProject}
                                >
                                    <Button startIcon={<DeleteForever />} disableRipple={true}>
                                        Delete
                                    </Button>
                                </PopConfirm>
                            )}
                        </div>
                    </div>

                    <Table
                        className="project-management-page-table"
                        columns={mergeColumns}
                        bordered={false}
                        rowKey={'id'}
                        sticky={true}
                        components={{
                            header: {
                                cell: ResizableTitle,
                            }
                        }}
                        showHeader={true}
                        tableLayout={"fixed"}
                        rowSelection={{ ...rowSelection }}
                        onRow={onRowTable}
                        pagination={{
                            showTotal: (total, range) => {
                                return `${range[0]}-${range[1]} of ${total} items`;
                            },
                            defaultCurrent: 1,
                            defaultPageSize: 50,
                            pageSizeOptions: [10, 20, 30, 50, 100, 150, 200],
                            showSizeChanger: true,
                            position: ['bottomRight'],
                            total: data.length,
                        }}
                        dataSource={data}
                        scroll={{
                            y: '50vh',
                            x: '100%',
                        }}
                    ></Table>
                    <div className="proj-create-page-content-controls">
                        <Button type="submit" tabIndex={5} onClick={onAddTemplate} style={{ display: "none" }} variant="contained" color="primary">
                            Add Template
                        </Button>
                        <Button type="submit" tabIndex={5} onClick={onshowTemplate} style={{ display: "none" }} variant="contained" color="primary">
                            show Template
                        </Button>
                    </div>
                </div>
            </div>

            <Modal
                title="show Template"
                width={600}
                visible={showTemplate}
                centered={true}
                destroyOnClose={true}
                okText="Ok"
                onOk={async () => {
                    form1.validateFields()
                        .then(values => {
                            onOkModal(values)
                        }).catch(() => {

                        })
                }}
                onCancel={() => setshowTemplate(false)}
            >
                <Form
                    label="trigger_when1"
                    name="trigger_whe1n"
                    form={form1}
                    rules={[{ required: true, message: 'Please input your trigger_when.' }]}>
                    <Form.Item label="trigger_when"
                        name="trigger_when">
                        <Input placeholder="add Trigger_When to show template" tabIndex={2} />
                    </Form.Item>
                </Form>
                {subject && <p>
                    subject : {subject.title}
                    name : {subject.name}
                </p>}
            </Modal>
            {

                showCustomTemplateModal && (<CustomTemplate
                    onCLoseEditEmailTemplate={onCLoseEditEmailTemplate}
                    emailEditor={emailEditor}
                    form={form1}
                    defaultValue={projectObj.current.project}
                    onSubmit={onSaveData}
                    setFiles={setFiles}
                />)
            }
            {

                showModalPublish && (
                    <Modal
                        width={600}
                        visible={showModalPublish}
                        centered={true}
                        destroyOnClose={true}
                        footer={null}
                        closable={false}
                        keyboard={false}

                    >
                        <span className='pmp-close-button' onClick={closeModal}><Close /></span>
                        <CopyToClipboard text={api.utils.getURL(`${urls.ADMIN_BOOKING_DETAILS}/${publishValue.rec.id}`)} onCopy={() => {
                            setCopyAdminUrl({ value: api.utils.getURL(`${urls.ADMIN_BOOKING_DETAILS}/${publishValue.rec.id}`), copied: true })
                            copyProject()
                        }}
                        >
                            <Button variant="contained" style={{ background: '#3f51b5', color: 'white', width: '300px', marginLeft: '20px', marginTop: '20px' }} startIcon={<Add />}>
                                copy Project Link for Admin
                            </Button>
                        </CopyToClipboard>
                        {copyAdminUrl.copied && <div className="link-copied">Link to '{publishValue.rec.name}' copied</div>}
                        <CopyToClipboard
                            text={api.utils.getURL(`${urls.PARTICIPANTS_HOME}/${publishValue.rec.id}`)} onCopy={() => {
                                setCopyPanelistUrl({ value: api.utils.getURL(`${urls.PARTICIPANTS_HOME}/${publishValue.rec.id}`), copied: true })
                                copyProject()
                            }}>
                            <Button variant="contained" style={{ background: '#3f51b5', color: 'white', width: '300px', marginTop: '20px' }} startIcon={<Add />}>
                                copy Project Link for Panelist
                            </Button>
                        </CopyToClipboard>
                        {copyPanelistUrl.copied && <div className="link-copied">Link to '{publishValue.rec.name}' copied</div>}
                        <Button variant="contained" startIcon={<Add />} onClick={() => invitePanelist()} style={{ marginTop: '20px' }}>
                            Invite by OntheDot email editor
                        </Button>
                    </Modal>
                )}

            {
                showExportStatistics && (
                    <Modal
                        title="Select project date range"
                        width={600}
                        visible={showExportStatistics}
                        centered={true}
                        destroyOnClose={true}
                        okText={`Export`}
                        cancelText="Cancel"
                        className='ant-modal-add-slots'
                        onCancel={() => setShowExportStatistics(false)}
                        onOk={
                            async () => {
                                form1.submit()
                            }
                        }
                    >
                        <Form
                            form={form1}
                            onFinish={onSubmitValues}
                        >
                            <Form.Item label={'Date'} name="date" rules={[{ required: true, message: 'Date is required' }]}>
                                <RangePicker
                                    allowClear={true}
                                    placeholder={['From', 'To']}
                                    size="middle"
                                    inputReadOnly={false}
                                    format={api.utils.MOMENTFORMAT.date}
                                    onChange={onFilterExportStatis('date')}
                                />
                            </Form.Item>
                        </Form>
                    </Modal>
                )}
            <Backdrop id="loading-dialog" open={isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
};

ProjectManagementPage.defaultProps = {
    tabMenuItemKeys: ['upcoming', 'past'],
};

ProjectManagementPage.propTypes = {
    tabMenuItemKeys: PropTypes.array,
};

export default connect()(ProjectManagementPage);
