import React, { Component } from 'react';
import PropTypes from "prop-types";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './styles.scss'
class EmailTemplatesDropdown extends Component {
    state ={
        open : false,
    }
    addTemplates = (values , name) => {
        const { onChange , setTemplatename } = this.props
        setTemplatename(name)
        onChange(values)
      };
    openDropdown = () => this.setState({open: !this.state.open})
    render() {
        return (
            <div onClick={this.openDropdown} className="rdw-block-wrapper " aria-label="rdw-block-control">
            <div className="rdw-dropdown-wrapper rdw-block-custom-dropdown" aria-label="rdw-dropdown">
              <div className="rdw-dropdown-selectedtext" title="Templates">
                <span>{this.props.name || "Templates"}</span> 
                <div className={`rdw-dropdown-caretto${this.state.open? "close": "open"}`}></div>
              </div>
              <ul className={`rdw-dropdown-optionwrapper ${this.state.open? "": "templates-ul"}`}>
                   {
                    this.props.templates &&  this.props.templates.map((item,indx) => (
                        <li 
                          onClick={() => this.addTemplates(item.value , item.name)} 
                          key={indx}
                          className="rdw-dropdownoption-default templates-li"
                        >{item.name}</li>
                      ))
                   }
              </ul>
            </div>
          </div>
        );}

static propTypes = {
    onChange: PropTypes.func,
    editorState: PropTypes.object,
}
}
export default EmailTemplatesDropdown;