import React , {useState} from 'react';
import { connect } from 'react-redux';
import { NavLink , useHistory} from 'react-router-dom';

import urls from '../../utility/urls';
import { Avatar, Menu, Fade, List, ListItem, ListItemIcon,Backdrop , CircularProgress, ListItemText, Divider, MenuItem } from '@material-ui/core';
import { AccountCircle, ExitToApp, RotateLeftOutlined , Home, Add , Edit } from '@material-ui/icons';

import { useAuth } from '../Auth/auth';
import { useSnackbar } from 'notistack';

import './style.scss';
import AuthHelper from '../../library/helpers/AuthHelper';
import api from '../../library/api'
import ChangePassword from '../ChangePassword'
import {errorMapper, regex} from '../../utility'
import HowToGetStart from '../HowToGetStart';
import UpdateProfile from '../UpdateProfile';
import { ROLES } from '../../utility/constant';

const navigationLink = [
    {
        id: 0,
        label: 'Project Schedule',
        icon: '/assets/sidebar_list.png',
        url: urls.USER_HOME,
    },
    {
        id : 1, 
        label : 'How to get Started',
        icon : <Add fontSize="large"/>,
        url : urls.USER_HOME,
    }
    /*{
        id: 2,
        label: 'Booking Details',
        icon: '/assets/sidebar_edit.png',
        url: urls.USER_SCHEDULE_DETAIL,
    },*/
];

const menuLink = [
    {
        id: 0,
        label: 'Home',
        icon: <Home fontSize="small" />,
        url: urls.USER_HOME,
    },
    {
        id : 1, 
        label : 'Update Profile',
        icon : <Edit fontSize="small"/>,
    },
    {
        id: 2,
        label: 'Change Password',
        icon: <RotateLeftOutlined fontSize="small" />,
    },
   
    {
        id: 3,
        label: 'Logout',
        icon: <ExitToApp fontSize="small" />,
        url: urls.LOGOUT_PAGE,
    },

];


const SideBar = (props) => {

    const [anchorEl, setAnchorEl] = useState(null);
    const [showModalChangePassword, setShowModalChangePassword] = useState(false);
    const [password, setPassword] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState("");
    const [showPass , setShowPass] = useState(false) 
    const [showConfirmPassword , setShowConfirmPassword] = useState(false) 
    const [oldPassword , setOldPassword] = useState("") 
    const [showOldPass , setShowOldPass] = useState(false) 
    const [showHowToGetStartModal, setShowHowToGetStartModal] = useState(false)
    const [showModalUpdateProfile, setShowModalUpdateProfile] = useState(false);
    const [email, setEmail] = useState('');
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [mobile, setMobile] = useState('');
    const [employeStatus , setEmployeeStatus] = useState("") 
    const [panelistID , setPanelistID] = useState("") 
    const [tnumber , setTnumber] = useState("") 
    const [disablePanelistID , setDisablePanelistID] = useState(true) 
    const [disableEmStatus , setDisableEmStatus] = useState(true) 
    const [disableTNumber, setDisableTNumber] = useState(true) 
    const [userID , setUserID] = useState ('')
    const historyLink = useHistory();
    const { setAuthTokens } = useAuth();
    const authHelper = new AuthHelper();
    const { enqueueSnackbar } = useSnackbar();



    const handleClickShowPassword = () => {
        setShowPass(!showPass)
    };
    const handleClickShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword)
    };
    const handleClickShowOldPassword = () => {
        setShowOldPass(!showOldPass)
    };
    const onMenuClose = () => {
        setAnchorEl(null);
    };

    const onLogout = async(e) => {
        await api.user.logout()
        setAuthTokens();
    };

    const onClickAvatar = (e) => {
        setAnchorEl(e.currentTarget);
    };

    const onChangePassword = () => {
        
        if (!oldPassword) {
            enqueueSnackbar('Old password is required!', { variant: 'warning' });
            return;
        }
        if (!password) {
            enqueueSnackbar('Password is required!', { variant: 'warning' });
            return;
        }
        if (!confirmPassword) {
            enqueueSnackbar('confirm password is required!', { variant: 'warning' });
            return;
        }
        if((password === oldPassword)){
            enqueueSnackbar('New password match old password.', { variant: 'warning' });
            return;
        }
        if(!(password === confirmPassword)){
            enqueueSnackbar('Passwords do not match.', { variant: 'warning' });
            return;
        }
        if (!regex.password.test(oldPassword)) {
            enqueueSnackbar('Old password is invalid!', { variant: 'warning' });
            return;
        }
        if (!regex.password.test(password) && !regex.password.test(confirmPassword)) {
            enqueueSnackbar('Password is invalid!', { variant: 'warning' });
            return;
        }

        setIsLoading(true)
        const detailsUser = authHelper.getUserDetails()
        api.user.changePassword({
            old_password : oldPassword,
            new_password : password,
            id : detailsUser.id
        })
            .then((res) => {
                if(res.status === 'success') { 
                    enqueueSnackbar('Password Change Successful' , {variant : 'success' , autoHideDuration : 3000})
                    setShowModalChangePassword(false)
                }else {
                    enqueueSnackbar(res?.messages?.error || 'server error', {variant : 'error' , autoHideDuration : 5000})
                }
                setIsLoading(false);        
            }).catch((err) => { 
                enqueueSnackbar('server error' , {variant : 'error'})
            })
    }
    const onListItemClick = (e) => {
        if (e === 3) {
            onLogout();
        } else if(e === 2) {
            setShowModalChangePassword(true)
        } else if(e === 1) {
           showUpdateModaleProfile()
        }else{
            historyLink.push(menuLink[e].url);
        }
        setAnchorEl(null);
    };
    const onCancelModal = () => {
        setPassword("")
        setConfirmPassword("")
        setOldPassword("")
        setShowModalChangePassword(false)
    }

    const onCancelUpdateProfileModal = () =>  {
        setFirstname('');
        setLastname('');
        setMobile('');
        setPanelistID('')
        setTnumber('')
        setUserID('')
        setEmployeeStatus(null)
        setShowModalUpdateProfile(false)
    }

    const onCancelHowToGetStartedModal = () => {
        setShowHowToGetStartModal(false)
    }

    const onUpdatePanelistProfile = () => {
            setIsLoading(true);
            let obj = {
                id: userID,
                email: email,
                mobile: mobile || null,
                role : ROLES.PANELIST , 
                employee_status : employeStatus || null,
                panelist_id : panelistID || null,
                t_number :tnumber || null
            }
            api.panelist
                .updatePanelist(obj)
                .then((d) => {
                    setIsLoading(false);
    
                    if (d.messages === 'success') {
                        onCancelUpdateProfileModal()       
                        enqueueSnackbar('Updated successfully', { variant: 'success' });
                    } else {
                        enqueueSnackbar(errorMapper(d.messages), { variant: 'warning' });
                    }
                })
                .catch((e) => {
                    setIsLoading(false);
                    enqueueSnackbar('An error occurred while updating panelist. Please contact system admin for help.', { variant: 'error' });
                });
    }

    const showUpdateModaleProfile = () => {
        setIsLoading(true);

        Promise.all([
            api.appointments.panelist.fetchParticipantByEmail(authHelper.getUserDetails().id || ''),
        ])
            .then((d) => {
                setIsLoading(false);
                console.log(d)
                if (d[0].status === "success") {
                    let value = d[0].data
                    setEmail(value.email)
                    setFirstname(value.first_name)
                    setLastname(value.last_name)
                    setMobile(value.mobile)
                    setEmployeeStatus(value.employee_status)
                    setTnumber(value.t_number)
                    setPanelistID(value.panelist_id)
                    setDisablePanelistID(value.panelist_id === null || value.panelist_id === "" ? false : true)
                    setDisableTNumber(value.t_number === null || value.t_number === "" ? false : true)
                    setDisableEmStatus(value.employee_status === null || value.employee_status === "" ? false : true)
                    setUserID(value.id)
                    setShowModalUpdateProfile(true)
                } else {
                    enqueueSnackbar(errorMapper(d[0].messages), { variant: 'warning' });
                    setShowModalUpdateProfile(false)
                }
            })
            .catch((e) => {
                setIsLoading(false);
                enqueueSnackbar('An error occurred while getting project information. Please contact system admin for help.', { variant: 'error' });
            });
    }
    //const projectID = localStorage.getItem('ProjectID') || projectid;
    return (
        <div id="sidebar-section">
            <div className="sidebar-section-navlink">
                <ul className="nav-lnk">
                    {navigationLink.map((value) => (
                        <li key={value.id}>
                            {value.id === 1 ? 
                             <a onClick={() => setShowHowToGetStartModal(true)}>
                                {value.icon}
                                <span>{value.label}</span>
                            </a>
                            :
                            <NavLink to={`${value.url}`} activeClassName="active-link">
                                <img src={value.icon} alt={value.label} />
                                <span>{value.label}</span>
                            </NavLink> 
                            }
                        </li>
                    ))}
                </ul>
            </div>

            <div className="sidebar-section-menu">
                <Avatar src={''} onClick={onClickAvatar}>
                    <AccountCircle />
                </Avatar>
            </div>

            <Menu
                id="simple-menu"
                className="header-admin-menu"
                elevation={0}
                getContentAnchorEl={null}
                anchorEl={anchorEl}
                keepMounted={true}
                autoFocus={true}
                open={Boolean(anchorEl)}
                onClose={onMenuClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                TransitionComponent={Fade}
            >
                <List dense={true}>
                    <MenuItem>
                        {`Welcome ${(authHelper.getUserDetails().first_name !== null && (authHelper.getUserDetails()?.first_name + " " +authHelper.getUserDetails()?.last_name))|| authHelper.getUserDetails().email }`}
                    </MenuItem>
                    <Divider/>
                    {menuLink.map((value) => (
                        <ListItem key={value.id} button onClick={onListItemClick.bind(this, value.id)}>
                            <ListItemIcon>{value.icon}</ListItemIcon>
                            <ListItemText primary={value.label} />
                        </ListItem>
                    ))}
                </List>
            </Menu>
            <ChangePassword 
                showModalChangePassword={showModalChangePassword}
                onChangePassword={onChangePassword}
                setPassword={setPassword}
                setConfirmPassword={setConfirmPassword}
                password={password}
                confirmPassword={confirmPassword}
                isMenu = {true}
                onCancelModal = {onCancelModal}
                handleClickShowPassword={handleClickShowPassword}
                handleClickShowConfirmPassword={handleClickShowConfirmPassword}
                handleClickShowOldPassword={handleClickShowOldPassword}
                showConfirmPassword={showConfirmPassword}
                showPass={showPass}
                setShowPass={setShowPass}
                oldPassword = {oldPassword}
                showOldPass = {showOldPass}
                setOldPassword = {setOldPassword}
            />
            <HowToGetStart 
                showHowToGetStartModal = {showHowToGetStartModal}
                onCancelModal = {onCancelHowToGetStartedModal}
            />
            <UpdateProfile 
                showModalUpdateProfile = {showModalUpdateProfile}
                onCancelUpdateProfileModal = {onCancelUpdateProfileModal}
                setEmail= {setEmail}
                setFirstname ={setFirstname}
                setLastname = {setLastname}
                setMobile = {setMobile}
                setEmployeeStatus = {setEmployeeStatus}
                setPanelistID = {setPanelistID}
                setTnumber ={setTnumber}
                email={email}
                firstname={firstname}
                lastname={lastname}
                panelistID = {panelistID}
                tnumber = {tnumber}
                mobile= {mobile}
                employeStatus={employeStatus}
                onUpdatePanelistProfile = {onUpdatePanelistProfile}
                disablePanelistID ={disablePanelistID}
                disableEmStatus ={disableEmStatus}
                disableTNumber = {disableTNumber}
            />
            <Backdrop id="loading-dialog" open={isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
};

export default connect()(SideBar);
