import React from "react";
import { Modal , Select } from 'antd';
import RegexTextField from '../RegexTextField';
import {regex} from '../../utility'
import {
    error_massage_password , 
    EMPLOYEE_STATUS
}from '../../utility/constant'
import { Visibility , VisibilityOff } from "@material-ui/icons";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import { TextField } from "@material-ui/core";

const changePassword = (props) => {
    
    const {
        showModalChangePassword ,
        showPass,
        showConfirmPassword,
        handleClickShowConfirmPassword,
        handleClickShowPassword,
        isMenu ,
        onCancelModal,
        onChangePassword , 
        setPassword ,
        setConfirmPassword , 
        password ,
        confirmPassword,
        oldPassword,
        showOldPass,
        setOldPassword,
        handleClickShowOldPassword,
        isFirstTime,
        firstname,
        lastname,
        setFirstName,
        setLastName,
        setEmployeeStatus,
        employeStatus,
        setPanelistID,
        panelistID,
        setTnumber,
        tnumber
    } = props
    
 return (
    <Modal
        title={
            <div>
            {isFirstTime ? 'Update Profile' : 'Change Password'} <br /><p style={{marginBottom : '6px' , fontSize:'90%'}}>
            {!isFirstTime ? "Enter your new password and click Save. ": "Enter your new password and update your profile and click save."}
            </p>
          </div>
        }
        width={450}
        visible={showModalChangePassword}
        centered={true}
        okText="save"
        onOk={onChangePassword}
        onCancel = {isMenu && onCancelModal}
        cancelButtonProps={{
            style: {
              display:!isMenu && "none",
            },
          }}
    >
        {
            isFirstTime && 
            <>
            <TextField
                    required={true}
                    label="First Name"
                    disabled={!isFirstTime}
                    value={firstname}
                    onChange={(e) => {
                        setFirstName(e.target.value);
                    }}
                    placeholder="First Name"
                    variant="outlined"
                    fullWidth={true}
                />

                <TextField
                    required={true}
                    label="Last Name"
                    disabled={!isFirstTime}
                    value={lastname}
                    onChange={(e) => {
                        setLastName(e.target.value);
                    }}
                    placeholder="Last Name"
                    variant="outlined"
                    fullWidth={true}
                />
                 <TextField
                    required={false}
                    label="Panelist ID"
                    disabled={!isFirstTime}
                    value={panelistID}
                    onChange={(e) => {
                        setPanelistID(e.target.value);
                    }}
                    placeholder="Panelist ID"
                    variant="outlined"
                    fullWidth={true}
                />
                <TextField
                    required={false}
                    label="T-number"
                    disabled={!isFirstTime}
                    value={tnumber}
                    onChange={(e) => {
                        setTnumber(e.target.value);
                    }}
                    placeholder="T-number"
                    variant="outlined"
                    fullWidth={true}
                />
            </>
        }
       {isMenu && 
            <RegexTextField
                    required={true}
                    label="Old Password"
                    value={oldPassword}
                    type={showOldPass ? "text" : "password"}
                    onChange={(e) => {
                        setOldPassword(e.target.value);
                    }}
                    placeholder="Old Password"
                    variant="outlined"
                    fullWidth={true}
                    autoComplete="off"
                    error={oldPassword && !regex.password.test(oldPassword)}
                    helperText={oldPassword && !regex.password.test(oldPassword) ? error_massage_password(true): ''}
                    InputProps={{
                        endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                onClick={handleClickShowOldPassword}
                            >
                                {showOldPass ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                        ),
                    }}
                />
        }
        <RegexTextField
            required={true}
            label="Password"
            value={password}
            type={showPass ? "text" : "password"}
            onChange={(e) => {
                setPassword(e.target.value);
            }}
            placeholder="Password"
            variant="outlined"
            fullWidth={true}
            autoComplete="off"
            error={password && !regex.password.test(password)}
            helperText={password && !regex.password.test(password) ? isFirstTime ? error_massage_password(false) : error_massage_password(true): ''}
            InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                        onClick={handleClickShowPassword}
                    >
                        {showPass ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
        />
       
        
        <RegexTextField
            required={true}
            label="Confirm Password"
            value={confirmPassword}
            type={showConfirmPassword ? "text" : "password"}
            onChange={(e) => {
                setConfirmPassword(e.target.value);
            }}
            placeholder="Confirm Password"
            variant="outlined"
            fullWidth={true}
            error={confirmPassword && !regex.password.test(confirmPassword)}
            helperText={confirmPassword && !regex.password.test(confirmPassword) ? isFirstTime ? error_massage_password(false) : error_massage_password(true) : ''}
            InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                        onClick={handleClickShowConfirmPassword}
                    >
                        {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
        />
        {
            isFirstTime && 
            <Select
            placeholder="Select Employment Status"
            allowClear={true} 
            value={employeStatus === "" ? null : employeStatus }
            style={{ width: '100%', marginBottom : '10px'}}
            dropdownStyle={{ zIndex: 2000 }} 
            onChange={(e) => {
                setEmployeeStatus(e)
            }}
            onClear={e => {
                setEmployeeStatus('')
            }}
            options={EMPLOYEE_STATUS || []}
        />
        }
       
    </Modal>
    )
}
export default changePassword