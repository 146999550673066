import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useMount } from 'ahooks';

import { Form, Input, Row, Col, Radio, Select } from 'antd';
import { Button, Paper } from '@material-ui/core';
import { InfoCircleOutlined } from '@ant-design/icons';

import EmailFields from '../../../components/EmailFields';

import './style.scss';

const { TextArea } = Input;

const timeUnitOptions = [
    {
        label: 'Minutes',
        value: 'm',
    },
    {
        label: 'Hours',
        value: 'h',
    },
    {
        label: 'Days',
        value: 'd',
    },
];

const StepThree = (props) => {
    const { onSubmit, step, onBack, defaultValue } = props;

    const [form] = Form.useForm();
    const IsSubmitted = useRef(false);

    const [IsRecurring, setIsRecurring] = useState(false);
    const [timeUnitSelect, setTimeUnitSelect] = useState('');

    useMount(() => {
        setIsRecurring(defaultValue.recurring || false);
        setTimeUnitSelect('m');
    });

    useEffect(() => {
        form.setFieldsValue({ timeAllowanceUnit: timeUnitSelect });
    }, [timeUnitSelect, form]);

    const onFormSubmit = (values) => {
        if (IsSubmitted.current) {
            onSubmit(values);
        } else {
            onBack(values);
        }
    };

    const onFormSubmitFailed = () => { };

    const onClickBack = () => {
        IsSubmitted.current = false;
    };

    const onClickSubmit = () => {
        IsSubmitted.current = true;
    };

    const onChangeTimeUnit = (value) => {
        setTimeUnitSelect(value);
    };

    const timeUnitSelector = <Select defaultValue={'m'} options={timeUnitOptions} onChange={onChangeTimeUnit}></Select>;

    const onKeyDownAllowNumOnly = (e) => {
        const key = e.which;
        if (key > 57) {
            e.preventDefault();
        } else if (key < 48) {
            if (key !== 8 && key !== 9 && key !== 37 && key !== 39) {
                e.preventDefault();
            }
        }

        if (e.key.charCodeAt(0) >= 33 && e.key.charCodeAt(0) <= 47) {
            e.preventDefault();
        } else if ((e.key.charCodeAt(0) >= 58 && e.key.charCodeAt(0) <= 64) || e.key.charCodeAt(0) === 94) {
            e.preventDefault();
        }
    };

    return (
        <Form
            id="proj-create-form"
            form={form}
            name="ProjectCreate"
            layout="horizontal"
            initialValues={defaultValue}
            onFinish={onFormSubmit}
            onFinishFailed={onFormSubmitFailed}
        >
            <Paper className="paper-cont" elevation={0}>
                <div className="paper-cont-bar"></div>
                <div className="paper-cont-content">
                    <Row gutter={[24, 16]}>
                        <Col span={12} xs={24} sm={12}>
                            <Form.Item
                                className="time-allowance-item"
                                label="Cancelling or rescheduling time allowance"
                                name="cancelTimeAllowance"
                                tooltip={{
                                    title: 'When is the cut off time for panelist to edit/cancel booking.',
                                    icon: <InfoCircleOutlined />,
                                }}
                            >
                                <Input
                                    maxLength={4}
                                    addonAfter={timeUnitSelector}
                                    onKeyDownCapture={onKeyDownAllowNumOnly}
                                />
                            </Form.Item>
                            <Form.Item hidden={true} name="timeAllowanceUnit">
                                <Input value={timeUnitSelect} />
                            </Form.Item>
                            <Form.Item label="Allow to select one slot:" rules={[{ required: true, message: 'required' }]} name="allowSelectSlot">
                                <Radio.Group>
                                    <Radio value={'project'}>In a project</Radio>
                                    <Radio value={'day'}>In a day</Radio>
                                    <Radio value={'visit'} disabled={IsRecurring}>
                                        In a visit
                                    </Radio>
                                </Radio.Group>
                            </Form.Item>
                            {IsRecurring &&
                                <>
                                    {/*<Form.Item  label="Count of consecutive weeks:" name='count_consec_weeks'>
                                <Input
                                    name={"count_consec_weeks"}
                                    onKeyDownCapture={onKeyDownAllowNumOnly} />
                                </Form.Item>
                                <Form.Item label="Count of consecutive days:" name={'count_per_week'}>
                                <Input
                                    name={"count_per_week"}
                                    onKeyDownCapture={onKeyDownAllowNumOnly} />
                            </Form.Item>*/}
                                </>
                            }
                        </Col>

                        <Col span={12} xs={24} sm={12}>
                            <Form.Item
                                label="Booking instructions"
                                name="bookInstruction"
                                tooltip={{
                                    title: 'Instruction to help panelist book correctly ie. Please book only 1 day 1 slot per person, or choose 2 consecutive days with 2 visits each day (total 4 visits)',
                                    icon: <InfoCircleOutlined />,
                                }}
                            >
                                <TextArea rows={5} maxLength={255} showCount={255} />
                            </Form.Item>
                            {/*<Form.Item
                                label="Notifications recipient"
                                name="notifRecipients"
                                tooltip={{
                                    title: 'Enter email address who will receive booking notification.',
                                    icon: <InfoCircleOutlined />,
                                }}
                            >
                                <EmailFields />
                            </Form.Item>*/}
                            {/* <Form.Item
                                label="Panelist Email Address"
                                name="emails"
                                tooltip={{
                                    title: 'Enter email address here only if you are sending the invitation email from this app directly.',
                                    icon: <InfoCircleOutlined />,
                                }}
                            >
                                <EmailFields />
                            </Form.Item>*/}
                        </Col>
                    </Row>
                </div>
            </Paper>

            <div className="proj-create-page-content-controls">
                <Button
                    type="submit"
                    className="btn-back"
                    variant="contained"
                    disabled={step === 0}
                    onClick={onClickBack}
                >
                    Back
                </Button>
                <Button type="submit" className="btn-next" variant="contained" color="primary" onClick={onClickSubmit}>
                    Next
                </Button>
            </div>
        </Form>
    );
};

StepThree.defaultProps = {};

StepThree.propTypes = {
    initialData: PropTypes.object,
    defaultValue: PropTypes.object,
    step: PropTypes.number.isRequired,
    onBack: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default connect()(StepThree);
