import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useMount, useUpdateEffect } from 'ahooks';
import moment from '../../library/moment';
import _ from 'lodash';
import {Resizable} from 'react-resizable';
import { PageHeader, Table, DatePicker, Menu, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import { Backdrop, Button, CircularProgress } from '@material-ui/core';
import { VerticalAlignBottom } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import { exportExcel } from '../../utility/xslx';
import urls from '../../utility/urls';
import { errorMapper } from '../../utility';
import api from '../../library/api';

import './style.scss';
import { smWindow } from '../../utility/settings';

const { RangePicker } = DatePicker;

const ResizableTitle = (props) => {
    const { onResize, width, ...restProps } = props;
  
    if (!width) {
      return <th {...restProps} />;
    }
  
    return (
      <Resizable
        width={width}
        height={0}
        handle={
          <span
            className="react-resizable-handle"
            onClick={(e) => {
              e.stopPropagation();
            }}
          />
        }
        onResize={onResize}
        draggableOpts={{
          enableUserSelectHack: false,
        }}
      >
        <th {...restProps} />
      </Resizable>
    );
  };

const BookingSupportPage = (props) => {
    const { tabMenuItemKeys , sizeWindow } = props;

    const filteredData = useRef({
        upcoming: [],
        past: [],
    });

    const rangeFilteredData = useRef({
        upcoming: [],
        past: [],
    });

    const [tabSelected, setTabSelected] = useState(tabMenuItemKeys[0]);
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isDateRangeFilter, setIsDateRangeFilter] = useState(false);
    const [selectedDateRange, setSelectedDateRange] = useState();
    const [column , setColumn] = useState([])
    const historyLink = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    useMount(() => {
        fetchData();
        setColumn(columns)
    });

    useUpdateEffect(() => {
        const obj = isDateRangeFilter ? rangeFilteredData : filteredData;

        if (tabSelected === 'upcoming') {
            setData(obj.current.upcoming);
        } else {
            setData(obj.current.past);
        }
    }, [tabSelected, isDateRangeFilter]);

    const processRawData = (arr) => {
        arr = _.filter(arr, (v) => {
            return v.is_published === true;
        });

        filteredData.current.upcoming = _.filter(arr, (v) => {
            const currentDate = moment();
            return moment(v.project_end_date).isSameOrAfter(currentDate,'day');
        });

        filteredData.current.past = _.filter(arr, (v) => {
            const currentDate = moment().subtract(29,'d').format('YYYY-MM-DD');
            return moment(v.project_end_date).isSameOrAfter(currentDate, 'day') &&  moment(v.project_end_date).isBefore(moment(), 'day');
        });

        return arr;
    };

    const fetchData = () => {
        setIsLoading(true);

        api.projects
            .fetchAll()
            .then((d) => {
                setIsLoading(false);

                if (d.messages === 'success') {
                    processRawData(d.data);
                    setData(filteredData.current[tabSelected]);
                } else {
                    enqueueSnackbar(errorMapper(d.messages), { variant: 'warning' });
                }
            })
            .catch((e) => {
                setIsLoading(false);
                enqueueSnackbar('An error occurred while getting project information. Please contact system admin for help.', { variant: 'error' });
            });
    };

    const handleResize =
    (index) =>
    (_, { size }) => {
      const newColumns = [...column];
      newColumns[index] = { ...newColumns[index], width: size.width };
      setColumn(newColumns);
    };

    const mergeColumns = column.map((col, index) => ({
        ...col,
        onHeaderCell: (column) => (
        {
        width: column.width,
        onResize: handleResize(index),
        }),
    }));

    const columns = [
        {
            title: 'ID',
            align: 'left',
            width: 250,
            dataIndex: 'pr_id',
            key: 'pr_id',
            sorter: (a, b) => {
                const idA = a.pr_id.toUpperCase(),
                    idB = b.pr_id.toUpperCase();

                if (idA < idB) {
                    return -1;
                }

                if (idA > idB) {
                    return 1;
                }

                return 0;
            },
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Project Name',
            align: 'left',
            width: 300,
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Start Date',
            align: 'center',
            width: 140,
            dataIndex: 'project_start_date',
            sorter: (a, b) => {
                if (a.project_start_date.isBefore(b.project_start_date)) {
                    return -1;
                }

                if (a.project_start_date.isAfter(b.project_start_date)) {
                    return 1;
                }

                return 0;
            },
            sortDirections: ['descend', 'ascend'],
            render: (v, r) => {
                return moment(v).format(api.utils.MOMENTFORMAT.date);
            },
        },
        {
            title: 'End Date',
            align: 'center',
            width: 140,
            dataIndex: 'project_end_date',
            sorter: (a, b) => {
                if (a.project_end_date.isBefore(b.project_end_date)) {
                    return -1;
                }

                if (a.project_end_date.isAfter(b.project_end_date)) {
                    return 1;
                }

                return 0;
            },
            sortDirections: ['descend', 'ascend'],
            render: (v, r) => {
                return moment(v).format(api.utils.MOMENTFORMAT.date);
            },
        },
        {
            title: 'Panelist',
            align: 'center',
            width: 100,
            dataIndex: 'panel_count',
            key: 'panel_count',
        },
        {
            title: 'Booked',
            align: 'center',
            width: 100,
            dataIndex: 'booked_cnt',
            key: 'booked_cnt',
        },
    ];

    const onTabMenuClick = (e) => {
        setTabSelected(e.key);
    };

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
        },
        onSelect: (record, selected, selectedRows) => {
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
        },
    };

    const onRowTable = (record, rowIndex) => {
        return {
            onClick: (e) => {
                historyLink.push(`${urls.ADMIN_BOOKING_DETAILS}/${record.id}`);
            },
            onDoubleClick: (e) => {},
            onContextMenu: (e) => {},
            onMouseEnter: (e) => {},
            onMouseLeave: (e) => {},
        };
    };

    const onFilterDateChange = (mode) => (d, s) => {
        if (d) {
            const startD = _.cloneDeep(d)[0].subtract(1, 'days'),
                endD = _.cloneDeep(d)[1].add(1, 'days');

            rangeFilteredData.current.upcoming = _.filter(filteredData.current.upcoming, (v) => {
                if (mode === 'start') {
                    return moment(v.project_start_date).isBetween(startD, endD);
                }
                if (mode === 'end') {
                    return moment(v.project_end_date).isBetween(startD, endD);
                }
            });

            rangeFilteredData.current.past = _.filter(filteredData.current.past, (v) => {
                if (mode === 'start') {
                    return moment(v.project_start_date).isBetween(startD, endD);
                }
                if (mode === 'end') {
                    return moment(v.project_end_date).isBetween(startD, endD);
                }
            });

            setData(rangeFilteredData.current[tabSelected]);
            setSelectedDateRange({
                ...selectedDateRange,
                ...(mode === 'start' && { start: [startD, endD] }),
                ...(mode === 'end' && { end: [startD, endD] }),
            });
            setIsDateRangeFilter(true);
        } else {
            setSelectedDateRange(null);
            setIsDateRangeFilter(false);
        }
    };

    const _handleSearch = (e) => {
        const text = e.target.value;
        const obj = isDateRangeFilter ? rangeFilteredData : filteredData;

        setData(
            obj.current[tabSelected].filter(
                (project) =>
                    project.name.toLowerCase().includes(text.toLowerCase()) ||
                    project.pr_id.toLowerCase().includes(text.toLowerCase())
            )
        );
    };

    const _handleClearSearch = (e) => {
        const text = e.target.value;
        if(text === '') {
            const obj = isDateRangeFilter ? rangeFilteredData : filteredData;

            setData(
                obj.current[tabSelected]
            );
        }
    }

    const _generateExportData = () => {
        const fileName = `booking-support-${tabSelected}${
            isDateRangeFilter
                ? `-${selectedDateRange.start[0].format(api.utils.MOMENTFORMAT.date)}-${selectedDateRange.end[1].format(
                      api.utils.MOMENTFORMAT.date
                  )}`
                : ''
        }`;

        const headers = [
            { label: 'ID', key: 'pr_id' },
            { label: 'Project Name', key: 'name' },
            { label: 'Start Date', key: 'project_start_date' },
            { label: 'End Date', key: 'project_end_date' },
            { label: 'Panelist', key: 'panel_count' },
            { label: 'Booked', key: 'booked_cnt' },
        ];

        const outputData = _.cloneDeep(data).map((booked) => {
            const output = {};
            headers.forEach((head) => {
                output[head.key] = booked[head.key];
            });
            output.project_start_date = moment(booked.project_start_date).format(api.utils.MOMENTFORMAT.date);
            output.project_end_date = moment(booked.project_end_date).format(api.utils.MOMENTFORMAT.date);

            return output;
        });

        return { fileName, header: [headers.map((head) => head.label)], data: outputData };
    };

    return (
        <div id="booking-manage-page">
            <PageHeader ghost={false} title="Booking Support"></PageHeader>

            <div className="booking-manage-page-content">
                <div className="booking-manage-page-table-container">
                    <Menu
                        className="booking-manage-page-table-tabmenu"
                        mode={sizeWindow  && sizeWindow < smWindow  ? "vertical" : "horizontal"}
                        selectedKeys={[tabSelected]}
                        onClick={onTabMenuClick}
                    >
                        {tabMenuItemKeys.map((v) => (
                            <Menu.Item key={v}>{v}</Menu.Item>
                        ))}
                    </Menu>

                    <div className="booking-manage-page-table-controls">
                        <div className="booking-manage-page-table-controls-left">
                            <Input
                                className="searchbox"
                                size="middle"
                                prefix={<SearchOutlined />}
                                placeholder="Search"
                                onPressEnter={_handleSearch}
                                onChange={_handleClearSearch}
                            />
                        </div>

                        <div className="booking-support-page-table-controls-right bookingPageadmin-export">
                            <div>
                                <div className="table-controls-row">
                                    <span className="label">Start Date</span>
                                    <RangePicker
                                        allowClear={true}
                                        placeholder={['From', 'To']}
                                        size="middle"
                                        inputReadOnly={false}
                                        format={api.utils.MOMENTFORMAT.date}
                                        onChange={onFilterDateChange('start')}
                                    />
                                </div>
                                <div className="table-controls-row isEndDate">
                                    <span className="label">End Date</span>
                                    <RangePicker
                                        allowClear={true}
                                        placeholder={['From', 'To']}
                                        size="middle"
                                        inputReadOnly={false}
                                        format={api.utils.MOMENTFORMAT.date}
                                        onChange={onFilterDateChange('end')}
                                    />
                                </div>
                            </div>
                            <Button
                                startIcon={<VerticalAlignBottom />}
                                disableRipple={true}
                                onClick={() => exportExcel(_generateExportData())}
                            >
                                Export
                            </Button>
                        </div>
                    </div>

                    <Table
                        className="booking-manage-page-table"
                        columns={mergeColumns}
                        bordered={false}
                        rowKey={'id'}
                        sticky={true}
                        components = {{
                            header : {
                                cell : ResizableTitle ,
                            }
                        }}
                        showHeader={true}
                        tableLayout="fixed"
                        rowSelection={{ ...rowSelection }}
                        onRow={onRowTable}
                        pagination={{
                            showTotal: (total, range) => {
                                return `${range[0]}-${range[1]} of ${total} items`;
                            },
                            defaultCurrent: 1,
                            defaultPageSize: 10,
                            pageSizeOptions: [10, 20, 30, 50, 100],
                            showSizeChanger: true,
                            position: ['bottomRight'],
                            total: data.length,
                        }}
                        dataSource={data}
                        scroll={{
                            y: '50vh',
                            x: '100%',
                        }}
                    ></Table>
                </div>
            </div>

            <Backdrop id="loading-dialog" open={isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
};

BookingSupportPage.defaultProps = {
    tabMenuItemKeys: ['upcoming', 'past'],
};

BookingSupportPage.propTypes = {
    tabMenuItemKeys: PropTypes.array,
};

export default connect()(BookingSupportPage);
