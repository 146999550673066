import React from 'react'
import { Form, Input, Button } from 'antd'
import Mail from '@material-ui/icons/Mail'
import Lock from '@material-ui/icons/Lock'
import { Link } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import './index.scss'
import urls from '../../utility/urls'

const LoginForm = (props) => {
  const [form] = Form.useForm();
  const { onLogin } = props
  return (
    <div id='login-form-user'>
      <Form
        name='login-form'
        className='login-form'
        form={form}
        initialValues={{ remember: true }}
        onFinish={(values) => onLogin(values)}
      >
        <Form.Item
          name='email'
          rules={[{ required: true, type: "email", message: "The input is not valid E-mail!" }]}
        >
          <Input prefix={<Mail className='icon-email' />} placeholder="Email" size='large' />
        </Form.Item>
        <Form.Item
          name='password'
          style={{ color: "balck" }}
          rules={[{ required: true, message: "Please input your password!" }]}
        >
          <Input.Password prefix={<Lock className="icon-password" />}
            size='large'
            placeholder="Password" />
        </Form.Item>
        <Link to={urls.FORGOT_PASSWORD} className={'login-link'}>
          <Typography >Forgot password?</Typography>
        </Link>
        <Button type="primary" htmlType="submit" className='btn-sign-in' block>
          Sign In
        </Button>
      </Form>
    </div>
  );
};
export default LoginForm;