import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useMount, useUpdateEffect, useSetState } from 'ahooks';
import PropTypes from 'prop-types';

import _ from 'lodash';

import { Row, Col, Select, Input, Radio, Space, Checkbox, Table, Menu, Modal, Dropdown, Button } from 'antd';
import { Backdrop, IconButton, CircularProgress, TextField } from '@material-ui/core';
import { VerticalAlignBottom, DeleteForever, FileCopy, Link, Inbox, MoreVert, Edit, SearchOutlined, Add } from '@material-ui/icons';
import PopConfirm from '../../components/PopConfirm';
import { useSnackbar } from 'notistack';

import { ROLES } from '../../utility/constant'
import { regex, errorMapper } from '../../utility'
import api from '../../library/api';


import './style.scss';

const { TextArea } = Input;
const { Option } = Select;

const defaultState = {
    pr_id: '',
    name: '',
    panel_count: 0,
    type: '',
    count_consec_weeks: 0,
    count_per_week: 0,
    update_time_allowance: 0,
    time_unit: 'm',
    total_slots: 0,
    select_slot: 'project',
    notif_recipient: [],
    book_instructions: '',
    is_recurring: false,
    notify_admin: false,
};

const timeUnitOptions = [
    {
        label: 'Minutes',
        value: 'm',
    },
    {
        label: 'Hours',
        value: 'h',
    },
    {
        label: 'Days',
        value: 'd',
    },
];

const EditProject = (props) => {
    const currentRowData = useRef({});

    const { showCustomEmailTemplate, defaultValue, onChange } = props
    let { ProjectID } = useParams();
    const { enqueueSnackbar } = useSnackbar();

    const projData = useRef(defaultState);

    const [projectData, setProjectData] = useSetState(defaultState);
    const [timeUnitSelect, setTimeUnitSelect] = useState('m');
    const [adminPerProject, setAdminPerProject] = useState({ original: [], filtered: [] });
    const [IsCreateModal, setIsCreateModal] = useState(true);
    const [isLoading, setIsLoading] = useState(false);


    const [addUserModal, setAddUserModal] = useState(false)
    const [email, setEmail] = useState('');
    const [role, setRole] = useState('');



    useMount(() => {
        let updateData = {
            id: ProjectID,
        };

        _.each(projectData, (v, k) => {
            updateData[k] = defaultValue[k];
        });
        projData.current = updateData;
        projData.current.time_unit = 'm'
        if (projData.current.update_time_allowance >= 1440 && projData.current.update_time_allowance % 1440 === 0) {
            projData.current.update_time_allowance = projData.current.update_time_allowance / 1440;
            projData.current.time_unit = 'd'
            setTimeUnitSelect('d');
        } else if (projData.current.update_time_allowance >= 60 && projData.current.update_time_allowance % 60 === 0) {
            projData.current.update_time_allowance = projData.current.update_time_allowance / 60;
            projData.current.time_unit = 'h'
            setTimeUnitSelect('h');
        }
        setProjectData(projData.current);
    });

    useUpdateEffect(() => {
        onChange(projectData);
    }, [projectData]);

    useMount(() => {
        fetchData(ProjectID);
    });

    const fetchData = (id) => {
        setIsLoading(true);

        api.AdminPerProject
            .getProjectAdminById(id)
            .then((d) => {
                setIsLoading(false);

                if (d.messages === 'success') {
                    setAdminPerProject({ original: d.data, filtered: d.data });
                } else {
                    enqueueSnackbar(errorMapper(d.messages), { variant: 'warning' });
                }
            })
            .catch((e) => {
                setIsLoading(false);
                enqueueSnackbar('An error occurred while getting project admins. Please contact system admin for help.', { variant: 'error' });
            });
    };

    const deleteAdminProject = (data) => {
        setIsLoading(true);

        api.AdminPerProject
            .deleteAdminProject(data)
            .then((d) => {
                setIsLoading(false);

                if (d.messages === 'success') {
                    fetchData(ProjectID);
                    enqueueSnackbar('Deleted successfully', { variant: 'success' });
                } else {
                    enqueueSnackbar(errorMapper(d.messages), { variant: 'warning' });
                }
            })
            .catch((e) => {
                setIsLoading(false);
                enqueueSnackbar('An error occurred while deleting project admin. Please contact system admin for help.', { variant: 'error' });
            });
    }

    const onKeyDownAllowNumOnly = (e) => {
        const key = e.which;
        if (key > 57) {
            e.preventDefault();
        } else if (key < 48) {
            if (key !== 8 && key !== 9 && key !== 37 && key !== 39) {
                e.preventDefault();
            }
        }

        if (e.key.charCodeAt(0) >= 33 && e.key.charCodeAt(0) <= 47) {
            e.preventDefault();
        } else if ((e.key.charCodeAt(0) >= 58 && e.key.charCodeAt(0) <= 64) || e.key.charCodeAt(0) === 94) {
            e.preventDefault();
        }
    };

    const onChangeTimeUnit = (value) => {
        let num = Number(projectData.update_time_allowance);

        setProjectData({
            update_time_allowance: num,
            time_unit: value,
        });
        setTimeUnitSelect(value);
    };

    const columns = [
        {
            title: 'Email Address',
            align: 'left',
            width: 50,
            fixed: 'left',
            dataIndex: 'email',
            key: 'email',
            render: (text, record) => <span>{`${text}`}</span>,
        },
        {
            title: 'Role',
            align: 'left',
            width: 40,
            dataIndex: 'role',
            key: 'role',
            render: (text, record) => <span>{text}</span>,
        },
        {
            title: 'Action',
            width: 28,
            editable: false,
            fixed: 'right',
            align: 'center',
            render: (text, record, index) => {
                const menu = (
                    <Menu>
                        <Menu.Item key="view-detail">
                            <IconButton
                                size="small"
                                disableRipple={true}
                                aria-label="Edit"
                                onClick={onEditAdminProject.bind(this, record)}
                            >
                                <Edit fontSize="small" />
                            </IconButton>
                        </Menu.Item>
                        <Menu.Item key="delete">
                            <PopConfirm
                                title="Are you sure you want to delete this admin?"
                                onConfirm={onDeleteAdminPerProject.bind(this, record)}
                            >
                                <IconButton size="small" disableRipple={true} color="secondary">
                                    <DeleteForever />
                                </IconButton>
                            </PopConfirm>
                        </Menu.Item>
                    </Menu>
                );

                return (
                    <Dropdown trigger={['click']} overlay={menu}>
                        <MoreVert />
                    </Dropdown>
                );
            },
        },
    ];

    const onAddAdminPerProject = () => {
        setIsCreateModal(true);
        setAddUserModal(true)
    }
    const onEditAdminProject = (rec) => {
        currentRowData.current = rec;
        setIsCreateModal(false);
        setAddUserModal(true);
        setEmail(currentRowData.current.email);
        setRole(currentRowData.current.role)
    }
    const onDeleteAdminPerProject = (rec) => {
        currentRowData.current = rec;
        deleteAdminProject({
            email: rec.email,
            user_id: rec.project_admins[0].user_id,
            project_id: ProjectID,
        });
    };

    const createAdminPerProject = () => {
        if (!email) {
            enqueueSnackbar('Email Address is required!', { variant: 'warning' });
            return;
        }
        if (!role) {
            enqueueSnackbar('Role is required!', { variant: 'warning' });
            return;
        }
        if (!regex.email.test(email)) {
            enqueueSnackbar('Email Address is invalid!', { variant: 'warning' });
            return;
        }

        if (IsCreateModal) {
            createAdminForEachProject({
                email: email,
                proj_admin_role: role,
                project_id: ProjectID,
                password: '',
            });
        } else {
            updateAdminProject({
                id: currentRowData.current.id,
                project_id: ProjectID,
                proj_admin_role: role
            });
        }
    }

    const updateAdminProject = (obj) => {
        setIsLoading(true);

        api.AdminPerProject
            .update(obj)
            .then((d) => {
                setIsLoading(false);

                if (d.messages === 'success') {
                    currentRowData.current = {};
                    fetchData(ProjectID);
                    setAddUserModal(false);
                    enqueueSnackbar('Updated successfully', { variant: 'success' });
                } else {
                    enqueueSnackbar(errorMapper(d.messages), { variant: 'warning' });
                }
            })
            .catch((e) => {
                setIsLoading(false);
                enqueueSnackbar('An error occurred while updating project admin. Please contact system admin for help.', { variant: 'error' });
            });
    };
    const createAdminForEachProject = (adminObj) => {
        setIsLoading(true);

        api.AdminPerProject.createProjectAdmins(adminObj)
            .then((d) => {
                setIsLoading(false);

                if (d.messages === 'success') {
                    fetchData(ProjectID);
                    setAddUserModal(false);
                    enqueueSnackbar('Created successfully', { variant: 'success' });
                    setEmail('')
                    setRole('')
                } else {
                    enqueueSnackbar(errorMapper(d.messages), { variant: 'warning' });
                }
            })
            .catch((e) => {
                setIsLoading(false);
                enqueueSnackbar('An error occurred while creating project admin. Please contact system admin for help.', { variant: 'error' });
            });
    }

    const _handleSearch = (e) => {
        const text = e.target.value;

        setAdminPerProject({
            ...adminPerProject,
            filtered: adminPerProject.original.filter(
                (admins) =>
                    admins.email.toLowerCase().includes(text.toLowerCase()) ||
                    admins.role.toLowerCase().includes(text.toLowerCase())
            ),
        });
    };

    const _handleClearSearch = (e) => {
        const text = e.target.value;
        if (text === '') {
            setAdminPerProject({
                ...adminPerProject,
                filtered: adminPerProject.original.filter(
                    (admins) =>
                        admins.email.toLowerCase().includes(text.toLowerCase()) ||
                        admins.role.toLowerCase().includes(text.toLowerCase())
                ),
            });
        }
    }

    const timeUnitSelector = (
        <Select value={timeUnitSelect} options={timeUnitOptions} onChange={onChangeTimeUnit}></Select>
    );

    return (
        <div id="edit-proj-section">
            <Row gutter={[24, 16]}>
                <Col span={12}>
                    <Space direction={'vertical'} size={0}>
                        <label htmlFor={'pr_id'}>Project ID: </label>
                        <Input
                            name={'pr_id'}
                            value={projectData.pr_id}
                            onChange={(e) => {
                                setProjectData({
                                    pr_id: e.target.value,
                                });
                            }}
                        />
                    </Space>

                    <Space direction={'vertical'} size={0}>
                        <label htmlFor={'name'}>Project Name: </label>
                        <Input
                            name={'name'}
                            value={projectData.name}
                            onChange={(e) => {
                                setProjectData({
                                    name: e.target.value,
                                });
                            }}
                            showCount={255}
                            maxLength={255}
                        />
                    </Space>

                    <Space direction={'vertical'} size={0}>
                        <label htmlFor={'type'}>Type: </label>
                        <Input
                            name={'type'}
                            value={projectData.type}
                            onChange={(e) => {
                                setProjectData({
                                    type: e.target.value,
                                });
                            }}
                            showCount={255}
                            maxLength={255}
                        />
                    </Space>

                    <Space direction={'vertical'} size={0}>
                        <label htmlFor={'panel_count'}>Panelist: </label>
                        <Input
                            name={'panel_count'}
                            value={projectData.panel_count}
                            onKeyDownCapture={onKeyDownAllowNumOnly}
                            onChange={(e) => {
                                setProjectData({
                                    panel_count: e.target.value,
                                });
                            }}
                        />
                    </Space>

                    {projectData &&
                        <>
                            {/*<Space direction={"vertical"} size={0}>
                                    <label htmlFor={"count_consec_weeks"}>Count of consecutive weeks: </label>
                                    <Input
                                        name={"count_consec_weeks"}
                                        value={projectData.count_consec_weeks}
                                        onKeyDownCapture={onKeyDownAllowNumOnly}
                                        onChange={(e) => {
                                            setProjectData({
                                                count_consec_weeks: e.target.value
                                            });
                                        }} />
                                    </Space>

                            <Space direction={"vertical"} size={0}>
                                <label htmlFor={"count_per_week"}>Count of consecutive days: </label>
                                <Input
                                    name={"count_per_week"}
                                    value={projectData.count_per_week}
                                    onKeyDownCapture={onKeyDownAllowNumOnly}
                                    onChange={(e) => {
                                        setProjectData({
                                            count_per_week: e.target.value
                                        });
                                    }} />
                            </Space>*/}
                        </>
                    }

                    <Space direction={'vertical'} size={0}>
                        <label htmlFor={'total_slots'}>Cancelling or rescheduling time allowance: </label>
                        <Input
                            value={projectData.update_time_allowance}
                            maxLength={7}
                            addonAfter={timeUnitSelector}
                            onChange={(e) => {
                                setProjectData({
                                    update_time_allowance: Number(e.target.value),
                                    time_unit: timeUnitSelect
                                });
                            }}
                            onKeyDownCapture={onKeyDownAllowNumOnly}
                        />
                    </Space>


                    <Space direction={'vertical'} size={0}>
                        <label htmlFor={'total_slots'}>Allow to select one slot: </label>
                        <Radio.Group
                            name="total_slots"
                            value={projectData.select_slot}
                            onChange={(e) => {
                                setProjectData({
                                    select_slot: e.target.value,
                                });
                            }}
                        >
                            <Radio value={'project'}>In a project</Radio>
                            <Radio value={'day'}>In a day</Radio>
                            <Radio value={'visit'} disabled={projectData.is_recurring}>
                                In a visit
                            </Radio>
                        </Radio.Group>
                    </Space>
                </Col>

                <Col span={12}>
                    {/* <Space direction={"vertical"} size={0}>
						<label htmlFor={"total_slots"}>Total slots in an appointment: </label>
						<Input
							name={"total_slots"}
							value={projectData.total_slots}
							onKeyDownCapture={onKeyDownAllowNumOnly}
							onChange={(e) => {
								setProjectData({
									total_slots: e.target.value
								});
							}} />
					</Space> */}

                    <Space direction={'vertical'} size={0}>
                        <label htmlFor={'book_instructions'}>Booking Instruction: </label>
                        <TextArea
                            name={'book_instructions'}
                            value={projectData.book_instructions}
                            onChange={(e) => {
                                setProjectData({
                                    book_instructions: e.target.value,
                                });
                            }}
                            rows={5}
                            showCount={255}
                            maxLength={255}
                        />
                    </Space>

                    {/*<Space direction={'vertical'} size={0}>
                        <label htmlFor={'notif_recipient'}>Notifications recipient: </label>
                        <EmailFields
                            value={projData.current.notif_recipient}
                            onChange={(e) => {
                                setProjectData({
                                    notif_recipient: e,
                                });
                            }}
                        />
                    </Space>*/}

                    <Space direction={'horizontal'} size={0}>
                        <Checkbox checked={projectData.notify_admin} className={'check-box-color'} onChange={(e) => setProjectData({ notify_admin: e.target.checked })} >
                            {"Notify me by email when people sign up or edit/delete their sign up slot"}
                        </Checkbox>
                    </Space>
                    {/*<Space direction={'horizontal'} align="center" size={91} className={"add-user"}>
                        <Button icon={<Inbox/>} style={{paddingRight:'40px'}}  onClick={showCustomEmailTemplate} >
                            Customize email template
                        </Button>
                </Space>*/}
                    <Space direction={'vertical'} size={0}>
                        <label>Project Admins: </label>
                    </Space>
                    <Space align="center" size={91} className={"add-user"}>
                        <Button type="text" icon={<Add />} onClick={onAddAdminPerProject} >
                            add User
                        </Button>
                        <Input
                            className="searchbox"
                            size="middle"
                            prefix={<SearchOutlined />}
                            placeholder="Search"
                            onPressEnter={_handleSearch}
                            onChange={_handleClearSearch}
                        />
                    </Space>
                    <Space>
                        <Table
                            className="project-management-page-table"
                            columns={columns}
                            style={{ width: 'auto' }}
                            bordered={false}
                            rowKey={'id'}
                            sticky={true}
                            size={'small'}
                            showHeader={true}
                            tableLayout="fixed"
                            pagination={{
                                showTotal: (total, range) => {
                                    return `${range[0]}-${range[1]} of ${total} items`;
                                },
                                defaultCurrent: 1,
                                defaultPageSize: 8,
                                pageSizeOptions: [8, 16, 24, 32, 40],
                                showSizeChanger: true,
                                position: ['bottomRight'],
                                total: adminPerProject.filtered.length,
                            }}
                            dataSource={adminPerProject.filtered}
                            scroll={{
                                y: '50vh',
                                x: '100%',
                            }}
                        ></Table>
                    </Space>
                </Col>
            </Row>

            <Modal
                title={IsCreateModal ? 'Create admin' : 'Edit admin'}
                width={250}
                visible={addUserModal}
                centered={true}
                okText="Save"
                onOk={createAdminPerProject}
                cancelText="cancel"
                onCancel={() => setAddUserModal(false)} >

                <TextField
                    required={true}
                    label="Email Address"
                    disabled={!IsCreateModal}
                    value={email}
                    onChange={(e) => {
                        setEmail(e.target.value);
                    }}
                    placeholder="Email Address"
                    variant="outlined"
                    fullWidth={true}
                    error={email !== '' && !regex.email.test(email)}
                    helperText={email && !regex.email.test(email) ? 'Invalid format.' : ''}
                />

                <Select
                    placeholder={'Role'}
                    allowClear={true}
                    value={role}
                    style={{ width: '100%', marginBottom: '10px' }}
                    dropdownStyle={{ zIndex: 2000 }}
                    onChange={(e) => {
                        setRole(e)
                    }}
                    onClear={e => {
                        setRole('')
                    }}
                >
                    <Option key={ROLES.adminEdit}>
                        Edit
                    </Option>
                    <Option key={ROLES.adminView}>
                        View
                    </Option>
                    <Option key={ROLES.adminFnameOnly}>
                        View First Name only
                    </Option>
                </Select>
            </Modal>
            <Backdrop id="loading-dialog" open={isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
};

EditProject.defaultProps = {};

EditProject.propTypes = {
    defaultValue: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default connect()(EditProject);
