import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useMount, useUpdateEffect } from 'ahooks';
import _ from 'lodash';
import moment from 'moment';
import ordinal from 'ordinal';
import { Resizable } from 'react-resizable';

import { PageHeader, Table, Breadcrumb, Input, Descriptions } from 'antd';

import { Backdrop, CircularProgress, Button } from '@material-ui/core';
import { SearchOutlined, VerticalAlignBottom, } from '@material-ui/icons';
import { useSnackbar } from 'notistack';

import urls from '../../utility/urls';
import { errorMapper } from '../../utility';
import api from '../../library/api';
import { exportExcel } from '../../utility/xslx';

import './style.scss';
import { ROLES } from '../../utility/constant';
import AuthHelper from '../../library/helpers/AuthHelper';


const ResizableTitle = (props) => {
    const { onResize, width, ...restProps } = props;

    if (!width) {
        return <th {...restProps} />;
    }

    return (
        <Resizable
            width={width}
            height={0}
            handle={
                <span
                    className="react-resizable-handle"
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                />
            }
            onResize={onResize}
            draggableOpts={{
                enableUserSelectHack: false,
            }}
        >
            <th {...restProps} />
        </Resizable>
    );
};
const PanelistProjectLogTracker = (props) => {
    const historyLink = useHistory();
    const auth = new AuthHelper()

    let { ProjectID } = useParams();
    const projectObj = useRef({
        project: {},
        panelists: {}
    });

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [columns, setColumns] = useState([]);
    const [showLastName, setShowLastName] = useState(true);


    const { enqueueSnackbar } = useSnackbar();

    useMount(async () => {
        await fetchData(ProjectID);
    });

    useUpdateEffect(() => {
        fetchData(ProjectID);
    }, []);


    useEffect(() => {
        (async () => {
            (auth.getRole() === ROLES.projAdmin || auth.getRole() === ROLES.adminSuper) && await checkUserIsFirstnameOnly(ProjectID)

        })();
    }, [])


    const handleResize =
        (index) =>
            (_, { size }) => {
                const newColumns = [...columns];
                newColumns[index] = { ...newColumns[index], width: size.width };
                setColumns(newColumns);
            };
    const mergeColumns = columns.map((col, index) => ({
        ...col,
        onHeaderCell: (column) => (
            {
                width: column.width,
                onResize: handleResize(index),
            }),
    }));


    const checkUserIsFirstnameOnly = (id) => {
        setIsLoading(true);

        api.AdminPerProject
            .getProjectAdminByProjectId(id)
            .then((d) => {
                setIsLoading(false);

                if (d.messages === 'success') {
                    if (d.data === ROLES.adminFnameOnly) {
                        setShowLastName(false)
                    } else if (d.data === ROLES.adminView) {
                        setShowLastName(true)
                    } else {
                        setShowLastName(true)
                    }
                } else {
                    enqueueSnackbar(errorMapper(d.messages), { variant: 'warning' });
                }
            })
            .catch((e) => {
                setIsLoading(false);
                enqueueSnackbar('Server error!', { variant: 'error' });
            });
    }

    const fetchData = (id) => {
        setIsLoading(true);

        Promise.all([api.projects.fetchById(id), api.logs.fetchProjectPanelistLogActivity(id)])
            .then((d) => {
                setIsLoading(false);

                if (d[0].messages === 'success' && d[1].messages === 'success') {
                    processedRawData(d[0].data, d[1].data);
                } else {
                    enqueueSnackbar(
                        errorMapper(d[0].messages) + errorMapper(d[1].messages),
                        { variant: 'warning' }
                    );
                }
            })
            .catch((e) => {
                setIsLoading(false);
                enqueueSnackbar('An error occurred while getting project information. Please contact system admin for help.', { variant: 'error' });
            });
    };
    const processedRawData = (proj, panelist) => {
        if (panelist) {
            panelist = panelist?.sort((a, b) => moment(a.insert_dttm) - moment(b.insert_dttm))
            panelist = panelist.reverse()
        }
        projectObj.current.project = proj;
        projectObj.current.panelists = panelist;
        setData(panelist);
        setColumns(columnsProvider());
    }
    const columnsProvider = (visitNum, recurring) => {
        let infoArr = [
            {
                title: 'Email Address',
                align: 'left',
                width: 250,
                fixed: 'left',
                dataIndex: 'email',
                key: 'email',
                render: (text, record) => <span>{`${text}`}</span>,
            },
            {
                title: 'First Name',
                align: 'left',
                width: 250,
                dataIndex: 'first_name',
                key: 'first_name',
                render: (text, record) => <span>{text}</span>,
            },
            {
                title: 'Last Name',
                align: 'left',
                width: 250,
                dataIndex: 'last_name',
                key: 'last_name',
                className: !showLastName ? 'hide' : '',
                render: (text, record) => <span>{text}</span>,
            },
            {
                title: 'Mobile',
                align: 'center',
                width: 230,
                dataIndex: 'mobile',
                key: 'mobile',
            },
            {
                title: 'Panelist ID',
                align: 'left',
                width: 250,
                dataIndex: 'panelist_id',
                key: 'panelist_id',
                render: (text, record) => <span>{text}</span>,
            },
            {
                title: 'T-number',
                align: 'left',
                width: 250,
                dataIndex: 't_number',
                key: 't_number',
                render: (text, record) => <span>{text}</span>,
            },
        ],
            actionArr = [
                {
                    title: 'Action',
                    align: 'left',
                    width: 140,
                    dataIndex: 'Action',
                    key: 'Action',
                },
                {
                    title: 'Action By',
                    align: 'left',
                    width: 140,
                    dataIndex: 'ActionBy',
                    key: 'ActionBy'
                },
                {
                    title: 'Timestamp',
                    align: 'left',
                    width: 140,
                    dataIndex: 'insert_dttm',
                    key: 'insert_dttm',
                    sorter: {
                        compare: (a, b) => {
                            if (moment(a.insert_dttm).isBefore(b.insert_dttm, 'min')) {
                                return -1;
                            }

                            if (moment(a.insert_dttm).isAfter(b.insert_dttm, 'min')) {
                                return 1;
                            }

                            return 0;
                        },
                        multiple: 2,
                    },
                    defaultSortOrder: "descend",
                    render: (text, record) => {
                        return moment.utc(text).local().format(api.utils.MOMENTFORMAT.time24Date);
                    },
                }
            ];

        return _.concat(infoArr, actionArr);
    }

    const _handleSearch = (e) => {
        const text = e.target.value;

        setData(
            projectObj.current.panelists.filter(
                (panelist) =>
                    (panelist?.first_name && panelist.first_name.toLowerCase().includes(text.toLowerCase())) ||
                    (panelist?.last_name && panelist.last_name.toLowerCase().includes(text.toLowerCase())) ||
                    (panelist?.email && panelist.email.toLowerCase().includes(text.toLowerCase())) ||
                    (panelist?.mobile && panelist.mobile.toLowerCase().includes(text.toLowerCase())) ||
                    (panelist?.ActionBy && String(panelist.ActionBy).toLowerCase().includes(text.toLowerCase())) ||
                    (panelist?.Action && panelist.Action.toLowerCase().includes(text.toLowerCase()))
            ),
        );
    };



    const _handleClearSearch = (e) => {
        const text = e.target.value;
        if (text === '') {
            setData(projectObj.current.panelists);
        }
    }


    const _generateExportData = () => {
        const fileName = `project-panelist-logs-${projectObj.current.project.name}${projectObj.current.project.id
            }`;

        const headers = [
            { label: 'Email Address', key: 'email' },
            { label: 'First Name', key: 'first_name' },
            { label: 'Last Name', key: 'last_name' },
            { label: 'Mobile', key: 'mobile' },
            { label: 'Panelist ID', key: 'panelist_id' },
            { label: 'T-Number', key: 't_number' },
            { label: 'Action', key: 'Action' },
            { label: 'Action By', key: 'ActionBy' },
            { label: 'Timestamp', key: 'insert_dttm' },
        ];


        const outputData = _.cloneDeep(data).map((project) => {
            const output = {};
            headers.forEach((head) => {
                output[head.key] = project[head.key];
            });
            output.insert_dttm = moment.utc(project.insert_dttm).local().format(api.utils.MOMENTFORMAT.time24Date);
            return output;
        });

        return {
            fileName,
            header: [headers.map((head) => head.label)],
            data: outputData,
        };
    }

    return (
        <div id="project-management-page">
            <Breadcrumb>
                <Breadcrumb.Item>
                    <a href={urls.ADMIN_PROJECT_LIST}>Project List</a>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <a href={`${urls.ADMIN_PROJECT_LIST_DETAILS}/${ProjectID}`}>Project Details</a>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <a href={`${urls.ADMIN_PROJECT_LIST_PANELIST_DETAILS}/${ProjectID}`}>Panelist Details</a>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Panelist Log Tracker</Breadcrumb.Item>
            </Breadcrumb>
            <PageHeader
                ghost={false}
                title="Panelist Log Tracker"
                onBack={() => {
                    historyLink.push(`${urls.ADMIN_PROJECT_LIST_PANELIST_DETAILS}/${ProjectID}`);
                }}
            >
                <div className="page-header-desc">
                    <div className="page-header-desc-left">
                        <Descriptions size="default" column={1}>
                            <Descriptions.Item label="Project ID">{projectObj.current.project.pr_id}</Descriptions.Item>
                            <Descriptions.Item label="Project Type">
                                {projectObj.current.project.type}
                            </Descriptions.Item>
                            <Descriptions.Item label="Instruction">
                                {projectObj.current.project.book_instructions}
                            </Descriptions.Item>
                        </Descriptions>
                    </div>
                    <div className="page-header-desc-right">
                        <Descriptions size="default" column={1}>
                            <Descriptions.Item label="Project Name">
                                {projectObj.current.project.name}
                            </Descriptions.Item>
                            <Descriptions.Item label="Panelist">
                                {projectObj.current.project.panel_count}
                            </Descriptions.Item>
                            <Descriptions.Item label="Booked">
                                {projectObj.current.project.booked_cnt}
                            </Descriptions.Item>
                        </Descriptions>
                    </div>
                </div>
            </PageHeader>

            <div className="project-management-page-content">
                <div className="project-management-page-table-container">
                    <div className="project-management-page-table-controls">
                        <div className="project-management-page-table-controls-left">
                            <Input
                                className="searchbox"
                                size="middle"
                                prefix={<SearchOutlined />}
                                placeholder="Search"
                                onPressEnter={_handleSearch}
                                onChange={_handleClearSearch}
                            />
                        </div>
                        <div className="project-management-page-table-controls-right">
                            <Button
                                startIcon={<VerticalAlignBottom />}
                                disableRipple={true}
                                onClick={() => exportExcel(_generateExportData())}
                            >
                                Export
                            </Button>
                        </div>
                    </div>

                    <Table
                        className="project-management-page-table"
                        columns={mergeColumns}
                        bordered={false}
                        rowKey={'id' + 'Action'}
                        sticky={true}
                        showHeader={true}
                        filterMultiple={true}
                        components={{
                            header: {
                                cell: ResizableTitle,
                            }
                        }}
                        tableLayout="fixed"
                        pagination={{
                            showTotal: (total, range) => {
                                return `${range[0]}-${range[1]} of ${total} items`;
                            },
                            defaultCurrent: 1,
                            defaultPageSize: 10,
                            pageSizeOptions: [10, 20, 30, 50, 100],
                            showSizeChanger: true,
                            position: ['bottomRight'],
                            total: data.length,
                        }}
                        dataSource={data}
                        scroll={{
                            y: '50vh',
                            x: '100%',
                        }}
                    ></Table>
                </div>
            </div>
            <Backdrop id="loading-dialog" open={isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
};

export default connect()(PanelistProjectLogTracker);
