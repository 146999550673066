import React, { Fragment, useState, useEffect } from 'react';
import { Row, Col, PageHeader, Table, Form, Input, Result, Select, Tooltip, Tag, DatePicker } from 'antd';
import { Backdrop, CircularProgress, Button } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import { useParams, useHistory, Link } from 'react-router-dom';
import ordinal from 'ordinal';
import _ from 'lodash';
import swal from 'sweetalert';

import moment from '../../library/moment';
import api from '../../library/api';
import { errorMapper } from '../../utility';
import urls from '../../utility/urls';
import Modal from '../../components/Modal';

import './Schedule.scss';
import AuthHelper from '../../library/helpers/AuthHelper';
const statusColor = {
    open: 'green',
    booked: 'blue',
};
class Dropdown extends React.PureComponent {
    handleChange = value => {
        if(value) {
            this.props.setSelectedKeys([value]);
            setTimeout(() => this.props.confirm(), 10);
        }else {
            this.props.setSelectedKeys([]);
            setTimeout(() => this.props.confirm(), 10);
        }
    
    };
    render() {
        return (
          <DatePicker.RangePicker
            format="YYYY-MM-DD"
            placeholder={["from date", "to date"]}
            onChange={this.handleChange}
            ranges={{
              Today: [moment(), moment()],
              "2 days": [
                moment()
                  .subtract(2, "days")
                  .startOf("day"),
                moment().endOf("day")
              ],
              'This Month': [moment().startOf('month'), moment().endOf('month')],
            }}
          />
        );
      }
}
const ParticipantsSchedule = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { projectId } = useParams();
    const history = useHistory();
    const authUsers = new AuthHelper()
    const [columns, setColumns] = useState([]);
    const [totalWeeks, setTotalWeeks] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [project, setProject] = useState();
    const [data, setData] = useState([]);
    const [schedules, setSchedules] = useState([]);
    const [addBookingModal, setAddBookingModal] = useState(false);
    const [personalInformationModal, setPersonalInformationModal] = useState(false);
    const [form] = Form.useForm();
    const [selectedRows, setSelectedRows] = useState([]);
    const [locationFilter , setLocationFilter] = useState([])
    const [detailsFilter , setDetailsFilter] = useState([])
    const [visitColumns, setVisitColumns] = React.useState(false);
    const [isRecurring, setIsRecurring] = useState(false);
    const [detailsUser , setDetailsUser] = useState([])

    useEffect(() => {
        document.title = "OnTheDot Panelist Portal"
      }, [])
    useEffect(() => {
        setColumns(columnsProvider(visitColumns, isRecurring))
    },[detailsFilter ,locationFilter ])

    useEffect(() => {
        selectedRows.sort((a,b) => a.date.diff(b.date))
        selectedRows.sort((a, b) =>  {
            return  moment(moment.utc(a.date).local().format("YYYY-MM-DD") + " " + moment.utc(a.visit[0].start_time).local().format("HH:mm:ss")).diff(moment(moment.utc(b.date).local().format("YYYY-MM-DD") + " " + moment.utc(b.visit[0].start_time).local().format("HH:mm:ss"))) != 0 
            ? moment(moment.utc(a.date).local().format("YYYY-MM-DD") + " " + moment.utc(a.visit[0].start_time).local().format("HH:mm:ss")).diff(moment(moment.utc(b.date).local().format("YYYY-MM-DD") + " " + moment.utc(b.visit[0].start_time).local().format("HH:mm:ss"))) 
            : moment(moment.utc(a.date).local().format("YYYY-MM-DD") + " " + moment.utc(a.visit[0].end_time).local().format("HH:mm:ss")).diff(moment(moment.utc(b.date).local().format("YYYY-MM-DD") + " " + moment.utc(b.visit[0].end_time).local().format("HH:mm:ss")))
        })
        setSelectedRows(selectedRows)
    },[selectedRows])

    useEffect(() => {
        if(addBookingModal) {
            setIsLoading(true);
            setDetailsUser([])

            Promise.all([
                api.appointments.panelist.fetchParticipantByEmail(authUsers.getUserDetails().id || ''),
            ])
                .then((d) => {
                    setIsLoading(false);
                    if (d[0].status === "success") {
                        setDetailsUser(d[0].data)
                        form.setFieldsValue({
                            panelist_id: d[0].data.panelist_id, 
                            t_number: d[0].data.t_number ,
                            first_name :d[0].data.first_name,
                            last_name : d[0].data.last_name,
                            mobile : d[0].data.mobile
                        });
                    } else {
                        enqueueSnackbar(errorMapper(d[0].messages), { variant: 'warning' });
                    }
                })
                .catch((e) => {
                    setIsLoading(false);
                    enqueueSnackbar('An error occurred while getting project information. Please contact system admin for help.', { variant: 'error' });
                });
        }
    },[addBookingModal])
    const fetchData = (projectId) => {
        setIsLoading(true);

        Promise.all([
            api.projects.fetchById(projectId),
            api.appointments.panelist.fetchAppointmentsByProjectId(projectId),
        ])
            .then((d) => {
                setIsLoading(false);
                if (d[0].status === 'ok' && d[1].status === 'ok') {
                    setProject(d[0].data);
                    processedRawData(d[0].data, d[1].data);
                } else {
                    enqueueSnackbar(errorMapper(d[0].messages) + errorMapper(d[1].messages), { variant: 'warning' });
                }
            })
            .catch((e) => {
                setIsLoading(false);
                enqueueSnackbar('An error occurred while getting project information. Please contact system admin for help.', { variant: 'error' });
            });
    };

    const columnsProvider = (visitNum, recurring) => {
        let infoArr = [
            {
                title: 'Date',
                align: 'center',
                width: 140,
                dataIndex: 'date',
                key: 'date',
                defaultSortOrder: 'ascend',
                    sorter: {
                    compare: (a, b) => {
                            if (a.date.isBefore(b.date, 'day')) {
                                return -1;
                            }

                            if (a.date.isAfter(b.date, 'day')) {
                                return 1;
                            }

                            return 0;
                        },
                        multiple: 2,
                    },
                    filterDropdown: props => <Dropdown {...props} />,
                    onFilter: (value, item) => {
                        if(value) {
                            const [from, to] = value;
                            if (!from || !to) return true;
                            if (to.isBefore(item.date, 'day')) {
                                return false;
                            }
                            if (from.isAfter(item.date, 'day')) {
                                return false;
                            }
                            return true
                        }else {
                            return true;
                        }
                    },
                    sortDirections: ['descend', 'ascend'],
                render: (text, record) => {
                    return moment(text).format(api.utils.MOMENTFORMAT.date);
                },
            },
            /*{
                title: 'Item',
                align: 'center',
                width: 140,
                dataIndex: 'item',
                key: 'item',
                sorter : {
                    compare : (a,b) => a.item - b.item,
                    multiple : 1
                },
                sortDirections: ['descend', 'ascend'],
            },*/
            {
                title: 'Detail',
                align: 'center',
                width: 140,
                dataIndex: 'details',
                key: 'details',
                filters :detailsFilter,
                onFilter: (value , record) => record.details.startsWith(value),
                filterSearch:(input, record) => record.details.indexOf(input) > -1,
                filterMode: 'tree',
                sorter: {
                    compare: (a, b) => {
                        const idA = (a.details || '').toUpperCase();
                        const idB = (b.details || '').toUpperCase();
    
                        if (idA < idB) {
                            return -1;
                        }
    
                        if (idA > idB) {
                            return 1;
                        }
    
                        return 0;
                    },
                    multiple: 2,
                },
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: 'Status',
                align: 'center',
                width: 300,
                dataIndex: 'status',
                key: 'Status',
                filters :[{
                    text :  'Available',
                    value:  'open'
                }, {
                    text :  'Fully Booked',
                    value: 'booked'
                }],
                onFilter: (value , record) => record.status.startsWith(value),
                filterSearch:true,
                filterMode: 'tree',
                render: (text, record) => <Tag color={statusColor[text]}>{text === 'open' ? String('Available').toUpperCase() : String('Fully Booked').toUpperCase()}</Tag>,
            },
        ];

        for (let i = 1; i <= visitNum; i++) {
            if (visitNum === 1) {
                infoArr.push({
                    title: 'Start Time',
                    align: 'center',
                    width: 140,
                    dataIndex: 'visit',
                    key: 'visit',
                    render: (v, r) => {
                        if (v.length >= i) {
                            return moment.utc(v[i - 1].start_time).local().format(api.utils.MOMENTFORMAT.time24);
                        }

                        return '';
                    },
                });
                infoArr.push({
                    title: 'End Time',
                    align: 'center',
                    width: 140,
                    dataIndex: 'visit',
                    key: 'visit',
                    render: (v, r) => {
                        if (v.length >= i) {
                            return moment.utc(v[i - 1].end_time).local().format(api.utils.MOMENTFORMAT.time24);
                        }

                        return '';
                    },
                });
            } else {
                infoArr.push({
                    title: `${ordinal(i)} Visit`,
                    align: 'center',
                    width: 140,
                    dataIndex: 'visit',
                    key: 'visit',
                    render: (v, r) => {
                        if (v.length >= i) {
                            const start = moment.utc(v[i - 1].start_time).local().format(api.utils.MOMENTFORMAT.time24),
                                end = moment.utc(v[i - 1].end_time).local().format(api.utils.MOMENTFORMAT.time24);

                            return `${start} - ${end}`;
                        }

                        return '';
                    },
                });
            }
        }

        const actionArr = [
            {
                title: 'Location',
                align: 'center',
                width: 300,
                dataIndex: 'location',
                key: 'location',
                sorter: {
                    compare: (a, b) => {
                        const idA = (a.location || '').toUpperCase();
                        const idB = (b.location || '').toUpperCase();
    
                        if (idA < idB) {
                            return -1;
                        }
    
                        if (idA > idB) {
                            return 1;
                        }
    
                        return 0;
                    },
                    multiple: 2,
                },
                sortDirections: ['descend', 'ascend'],
                filters :locationFilter,
                onFilter: (value , record) => record.location.startsWith(value),
                filterSearch: true,
                filterMode: 'tree',
            },
        ];

        return _.concat(infoArr, actionArr);
    };

    const processedRawData = (proj, sche) => {
        const visitColumns = sche.length > 0 ? sche[0].visit.length : 0;
        const totalDays = Math.abs(proj.project_end_date.diff(proj.project_start_date, 'days'));
        const weeksTotal = totalDays > 0 ? Math.ceil(totalDays / 7) : 1;
        const is_recurring = proj.is_recurring;

        //sort date  
        
        sche.sort((a,b) => a.date.diff(b.date))

        sche.sort((a, b) =>  {
            return  moment(moment.utc(a.date).local().format("YYYY-MM-DD") + " " + moment.utc(a.visit[0].start_time).local().format("HH:mm:ss")).diff(moment(moment.utc(b.date).local().format("YYYY-MM-DD") + " " + moment.utc(b.visit[0].start_time).local().format("HH:mm:ss"))) != 0 
            ? moment(moment.utc(a.date).local().format("YYYY-MM-DD") + " " + moment.utc(a.visit[0].start_time).local().format("HH:mm:ss")).diff(moment(moment.utc(b.date).local().format("YYYY-MM-DD") + " " + moment.utc(b.visit[0].start_time).local().format("HH:mm:ss"))) 
            : moment(moment.utc(a.date).local().format("YYYY-MM-DD") + " " + moment.utc(a.visit[0].end_time).local().format("HH:mm:ss")).diff(moment(moment.utc(b.date).local().format("YYYY-MM-DD") + " " + moment.utc(b.visit[0].end_time).local().format("HH:mm:ss")))
        })
 

        setIsRecurring(is_recurring);

        let totalWeeksArr = [];

        if (is_recurring) {
            for (let i = 0; i < weeksTotal; i++) {
                totalWeeksArr.push({
                    label: `${ordinal(i + 1)} Week`,
                    value: i + 1,
                });
            }
        } else {
            for (let i = 0; i < proj.projectsched.length; i++) {
                totalWeeksArr.push({
                    label: `${ordinal(i + 1)} Visit`,
                    value: i + 1,
                });
            }
        }
        let arrDetailsFilter = []
        let arrLocationFilter = []
        setTotalWeeks(totalWeeksArr);
        _.each(sche, (v, i) => {
            const weeks = Math.abs(moment(v.date).diff(proj.project_start_date, 'weeks')) + 1;
            sche[i].week = weeks;

            const indexSched = _.findIndex(proj.projectsched, (o) => {
                return o.id === v.projectsched_id;
            });

            sche[i].visits = indexSched !== -1 ? proj.projectsched[indexSched].visit_number : -1;
            if(sche[i].details !== '') {
                arrDetailsFilter.push( {text : sche[i].details , value : sche[i].details})
            }   
            if(sche[i].location !== '') {
                arrLocationFilter.push( {text : sche[i].location , value : sche[i].location})
            }
        });
        setVisitColumns(visitColumns)
        setColumns(columnsProvider(visitColumns, is_recurring));
        setData(sche.filter((iter)=>!iter.date.isBefore(new Date(),'minute')));
       
        setSchedules(sche.filter((iter)=>!iter.date.isBefore(new Date(),'minute')));
        // setSchedules(sche.filter((sche) => sche.week === 1));
        var result = arrDetailsFilter.reduce((unique, o) => {
            if(!unique.some(obj => obj.text === o.text && obj.value === o.value)) {
              unique.push(o);
            }
            return unique;
        },[]);
        var resultLocation = arrLocationFilter.reduce((unique, o) => {
            if(!unique.some(obj => obj.text === o.text && obj.value === o.value)) {
              unique.push(o);
            }
            return unique;
        },[]);
        setDetailsFilter(result)
        setLocationFilter(resultLocation)
    };

    const _handleChangeFilter = (value) => {
        if (value) {
            const filteredSchedules = _.filter(data, (obj) => {
                return project.is_recurring ? obj.week === value : obj.visits === value;
            });
            setSchedules(filteredSchedules);
        } else {
            setSchedules(data);
        }
    };

    const _handleClearFilter = () => {
        setSchedules(data);
    };

    const _handleConfirmBooking = (values) => {
        setIsLoading(true);
        api.appointments.panelist
            .addBooking({
                ...values,
                appointment_id: selectedRows.map((app) => app.id),
            })
            .then((d) => {
                if (d.messages === 'success') {
                    api.appointments.panelist.fetchAppointmentById(d.data.data[0]).then((d2) => {
                        if(authUsers.getUserDetails().first_name === null || authUsers.getUserDetails().last_name === null){
                            //update localstorage 
                            authUsers.setUserDetails({
                                ...authUsers.getUserDetails(),
                                first_name : values.first_name ,
                                last_name : values.last_name
                            })
                        }
                        if (d2.status === 'ok') {
                            history.push(
                                `${urls.PARTICIPANTS_SCHEDULE_DETAIL}/${d2.data.participant_id}/${project.id}`
                            );
                        }
                        setIsLoading(false);
                    });
                } else {
                    setIsLoading(false);
                    //enqueueSnackbar(errorMapper(d.messages), { variant: 'warning' });
                    enqueueSnackbar('An error occurred while attempting to confirm booking. Please contact system admin for help.', { variant: 'error' });
                }
            })
            .catch((e) => {
                setIsLoading(false);
                enqueueSnackbar('An error occurred while attempting to confirm booking. Please contact system admin for help.', { variant: 'error' });
            });
    };

    useEffect(() => {
        fetchData(projectId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const checkAllowedSlot = async(appointments , selectSlot) => {
        try {
        let checkAppoitments = _.cloneDeep(appointments)
        let checkSchedule = !project.is_recurring ?  _.cloneDeep(data)  : _.cloneDeep(schedules)
        switch (selectSlot) {
            case 'day' : {
                const appointmentDetails = await checkAppoitments.reduce(function (r, a) {
                    a.date = moment.utc(a.date).local().format("YYYY-MM-DD")
                    r[`${a.date}`] = r[`${a.date}`] || 0;
                    r[`${a.date}`] += 1;
                    return r;
                }, Object.create(null));
                let sameVisitAlready = [];
                await Object.keys(appointmentDetails).forEach(key => {
                    if (appointmentDetails[key] > 1) {
                        sameVisitAlready.push(key);
                    }
                });
                //check also for number must user book number
                const appointmentDetails_allRows = await checkSchedule.reduce(function (r, a) {
                    a.date = moment.utc(a.date).local().format("YYYY-MM-DD")
                    r[`${a.date}`] = r[`${a.date}`] || 0;
                    r[`${a.date}`] += 1;
                    return r;
                }, Object.create(null));
                let arrayNumbertheSameDate = [];
                await Object.keys(appointmentDetails_allRows).forEach(key => {
                    if (appointmentDetails_allRows[key] > 1) {
                        arrayNumbertheSameDate.push(key);
                    }
                });
                if (sameVisitAlready.length) {
                    swal({text : 'Oops, sorry. You must select 1 slot per day.' ,icon :"warning"}); 
                    return false
                }
                if(selectedRows.length !== arrayNumbertheSameDate.length && !project.is_recurring) {
                   swal({text : 'Are you sure you booked only 1 slot for each day? please double check' ,icon :"warning"});
                   return false
                }
                return true
            }
            case "project" :{
                if (checkAppoitments.length > 1) {
                    swal({text :'Oops, sorry. You must select 1 slot for this project.' ,icon :"warning"});
                  return false
                }
                return true
            }
            default : 
              break
        }
    }catch (err) {
        throw err;
        }
        
    }
    const checkConsecutiveWeek = async (appointments, countConsecWeeks) => {
        try {
            // check if appointments has a time gap lessthan or equal to countConsecWeeks
            let checkAppoitments = _.cloneDeep(appointments)
            const uniqueIds = [];
            const dates = await checkAppoitments.map(r => moment(r.date).utc()).sort();
            const arrayWeeks = await checkAppoitments.sort((a,b) => a.week - b.week)
            const arrayWeeks_1 = await arrayWeeks.map(element => {
                const isDuplicate = uniqueIds.includes(element.week);
              
                if (!isDuplicate) {
                  uniqueIds.push(element.week);
              
                  return true;
                }
              })
            const min = dates[0],
                max = dates[dates.length - 1];
            const duration = moment.duration(max.diff(min));
            const weeks = duration.asWeeks();
            if (weeks > countConsecWeeks || uniqueIds.length !== countConsecWeeks) {
                swal({text :`Count of consecutive weeks exceeded, please adjust bookings for ${countConsecWeeks} consecutive weeks`,icon :"warning"});
                return false
            }
            if(uniqueIds.length === countConsecWeeks) {
                let check = false ;
                for(let i =0 ; i<uniqueIds.length ; i++) {
                    if(uniqueIds[i+1] - uniqueIds[i] > 1) {
                        check = true
                    }
                }
                if(check) {
                    swal({text :'Must chooes Consecutive weeks',icon :"warning"});
                    return false
                }
            }
            return true
        } catch (err) {
            throw err;
        }
    }

    const checkDaysPerWeek = async (appointments, countPerWeek) => {
        try {
            let checkAppoitments = _.cloneDeep(appointments)            
            const days = await [...new Set(checkAppoitments.map(r => ({
                day: moment(r.date).day(),
                week: moment(r.date).weeks()
            })))];
            const daysPerWeek = await days.reduce(function (r, a) {
                r[a.week] = r[a.week] || [];
                r[a.week].push(a.day);
                return r
            }, Object.create(null));
            await Object.keys(daysPerWeek).forEach(key => {
                let dayCount = [...new Set(daysPerWeek[key])].length;
                if (dayCount !== Number(countPerWeek)) {
                    swal({text :`You need to select ${countPerWeek} consecutive days` ,icon :"warning"});
                    throw ''
                }
            });
            let check = false ;
            await Object.values(daysPerWeek).map(value => {
                value = value.sort()
                for(let i =0 ; i<value.length ; i++) {
                    if(value[i+1] - value[i] > 1) {
                        check = true
                    }
                }
                if(check) {
                    swal({text :`You need to select ${countPerWeek} consecutive days`,icon :"warning"});
                }
            })
            if(check){
                return false
            }
            return true
        } catch (err) {
            throw err;
        }
    }

    if (!isLoading && project && !project.is_published) {
        return (
            <div className="flex-center">
                <Result
                    status="warning"
                    title="Project Unavailable"
                    icon={
                        <img
                            className="img-150"
                            src={`${process.env.PUBLIC_URL}/assets/project-unavailable.png`}
                            alt="project-unavailable"
                        />
                    }
                    subTitle={
                        <div>
                            This project is no longer available. Please contact us via Get Help button or email{' '}
                            <a href="mailto: onthedot.im@pg.com">onthedot.im@pg.com</a> if you need
                            further assistance.
                        </div>
                    }
                    extra={[
                        <Link to={`${urls.PARTICIPANTS_HELP}/${project.id}`}>
                            <Button variant="contained" key="get-help">
                                Get Help
                            </Button>
                        </Link>,
                    ]}
                />
            </div>
        );
    }
    return (
        <div id="participants-schedule-page">
            <PageHeader
                className="participants-schedule-header"
                ghost={true}
                title="Project Schedule"
                extra={[
                    <div className="project-info" key="project-info">
                        <div>
                            <span className="label">Project ID: </span>
                            {project?.pr_id || ''}
                        </div>
                        <div>
                            <span className="label">Project Name: </span>
                            {project?.name || ''}
                        </div>
                    </div>,
                ]}
            ></PageHeader>
            <Row className="project-description">
                <Col span={8}>
                    <span className="label">Instruction: </span> {project?.book_instructions || ''}
                </Col>
                <Col span={8}>
                    <span className="label">Project Type: </span>
                    {project?.type || ''}
                </Col>
                <Col span={8}>
                    <span className="label label-btn">
                         <Link to={`${urls.PARTICIPANTS_HELP}/${project?.id}`}>
                            <Button color="primary" variant="contained" key="get-help">
                                Get Help
                            </Button>
                        </Link>
                    </span>
                </Col>
                <Col span={24}>
                    <span className="label label-btn">
                            <Tooltip title="Click this to book your selected slot in your appointment.">
                                <Button
                                    startIcon={<Add />}
                                    onClick={() => {
                                        if(project.panel_count > project.booked_cnt ) {
                                            if(selectedRows.length > 0 ) {
                                                if(project.is_recurring) {
                                                   if(project.select_slot === "project") {
                                                    checkAllowedSlot(selectedRows,project.select_slot).then(res => {                                                       
                                                        if(res) {
                                                            
                                                            setAddBookingModal(true)
                                                        }
                                                    })
                                                   }else {
                                                    if (Number(project.count_consec_weeks) && project.select_slot !== "project" && project.count_consec_weeks && project.count_per_week) {
                                                        checkConsecutiveWeek(selectedRows,Number(project.count_consec_weeks)).then(res => {
                                                            if(res) {
                                                                if (Number(project.count_per_week)) {
                                                                    checkDaysPerWeek(selectedRows,Number(project.count_per_week)).then(res_1 => {
                                                                        if(res_1) {
                                                                            if(project.select_slot === "visit") {
                                                                                if(selectedRows.length === project.number_of_visits){
                                                                                    setAddBookingModal(true) 
                                                                                }else {
                                                                                    swal({text :"Are you sure you booked 1 slot for each visit? please double check",icon :"warning"})
                                                                                }
                                                                            }else {
                                                                                if(project.select_slot === 'day') {
                                                                                    checkAllowedSlot(selectedRows,project.select_slot).then(res => {
                                                                                        if(res) {
                                                                                            setAddBookingModal(true) 
                                                                                        }
                                                                                    })
                                                                                }else {
                                                                                        setAddBookingModal(true) 
                                                                                    }
                                                                                }
                                                                            }
                                                                        });
                                                                    }
                                                                }
                                                            })
                                                        }else { 
                                                            if(project.select_slot === 'day') {
                                                                checkAllowedSlot(selectedRows,project.select_slot).then(res => {
                                                                    if(res) {
                                                                        setAddBookingModal(true) 
                                                                    }
                                                                })
                                                            }else {
                                                                setAddBookingModal(true)
                                                            }
                                                        }
                                                   }
                                                   
                                                }else {
                                                    if(project.select_slot === "visit") {
                                                        if(selectedRows.length === project.number_of_visits){
                                                            setAddBookingModal(true) 
                                                        }else {
                                                            swal({text :"Are you sure you booked 1 slot for each visit? please double check",icon :"warning"})
                                                        }
                                                    }else {
                                                        if(project.select_slot === 'day' || project.select_slot === 'project') {
                                                            checkAllowedSlot(selectedRows,project.select_slot).then(res => {
                                                                if(res) {
                                                                    setAddBookingModal(true) 
                                                                }
                                                            })
                                                        }else {
                                                                setAddBookingModal(true) 
                                                        }
                                                    }
                                                }
                                            }else {
                                                swal({text :"Select Appointment",icon :"warning"})
                                            }
                                     }else {
                                        swal({text :'This project has received sufficient number of panelists required and no longer accepting new registration.' ,icon :"warning"});
                                     }
                                    }}
                                    disableRipple={true}
                                    variant="contained"
                                    className='colorBtnBooking'
                                >
                                    Add Booking
                                </Button>
                            </Tooltip>
                    </span>
                </Col>
            </Row>
            <div className="participants-schedule-content">
                <div className="participants-schedule-navigator">
                    <div className="participants-schedule-table-controls">
                        <div className="participants-schedule-table-controls-left">
                            <Select
                                placeholder={`Select ${project?.is_recurring ? 'Week' : 'Visit'}`}
                                labelInValue={false}
                                onChange={_handleChangeFilter}
                                onClear={_handleClearFilter}
                                allowClear={true}
                                options={totalWeeks}
                            ></Select>
                        </div>
                    </div>
                </div>
                <Table
                    className="participants-schedule-table"
                    columns={columns}
                    bordered={false}
                    rowKey={'id'}
                    sticky={true}
                    showHeader={true}
                    tableLayout="fixed"
                    rowSelection={{
                        checkStrictly: true,
                        fixed: true,
                        selectedRowKeys: selectedRows && selectedRows.map((r) => r.id),
                        onSelect: (record, selected) => {
                            if(project.panel_count > project.booked_cnt) {
                                if (!record.participant_id.length) {
                                    if (selected) {
                                        setSelectedRows([...selectedRows, record]);
                                    } else {
                                        setSelectedRows(selectedRows.filter((r) => r.id !== record.id));
                                    }
                                } else {
                                    enqueueSnackbar('Appointment schedule already taken', { variant: 'warning' });
                                }
                            }else {
                                swal({text :'This project has received sufficient number of panelists required and no longer accepting new registration.' ,icon :"warning"});
                            }
                           
                        },
                        getCheckboxProps: (record , selected) => (
                            {
                                 disabled: record.participant_id.length , 
                            }
                        ),    
                    }}
                    pagination={{
                        showTotal: (total, range) => {
                            return `${range[0]}-${range[1]} of ${total} items`;
                        },
                        defaultCurrent: 1,
                        defaultPageSize: 100,
                        pageSizeOptions: [10, 20, 30, 50, 100, 150, 200],
                        showSizeChanger: true,
                        position: ['bottomRight'],
                        total: schedules.length,
                    }}
                    dataSource={schedules}
                    scroll={{
                        y: '37vh',
                        x: '100%',
                    }}
                ></Table>
            </div>
            <Backdrop id="loading-dialog" open={isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Modal
                visible={addBookingModal}
                header={true}
                footer={
                    <>
                        <Button
                            variant="contained"
                            color="primary"
                            disableRipple={true}
                            onClick={() => setAddBookingModal(false)}
                        >
                            No
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            disableRipple={true}
                            onClick={() => {
                                setAddBookingModal(false);
                                setPersonalInformationModal(true);
                            }}
                        >
                            Yes
                        </Button>
                    </>
                }
            >
                <div className="title no-margin">Bookings</div>
                <div className="description">
                    Please ensure that the Date, Slots, and Time is correct before proceeding.
                </div>
                <div className="content card limit">
                    {selectedRows.map((row, rowIndex) => {
                        return (
                            <Fragment key={row.id}>
                                <div className="content-block">
                                    <div className="left">
                                        <div className="label">Date</div>
                                        <div className="value">
                                            {moment(row.date).format(api.utils.MOMENTFORMAT.date)}
                                        </div>
                                        <div className="label">Slot</div>
                                        <div className="value">{row.item}</div>
                                    </div>
                                    <div className="right">
                                        {row.visit &&
                                            row.visit.length > 0 &&
                                            row.visit.map((visit, idx, rowVisits) => {
                                                return (
                                                    <div className="visit" key={visit.id}>
                                                        <div className="label">
                                                            Visit {(rowVisits.length === 1 ? rowIndex : idx) + 1} (Time)
                                                        </div>
                                                        <div className="value">
                                                            {moment.utc(visit.start_time).local().format(
                                                                api.utils.MOMENTFORMAT.time24
                                                            )}{' '}
                                                            to{' '}
                                                            {moment.utc(visit.end_time).local().format(
                                                                api.utils.MOMENTFORMAT.time24
                                                            )}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                    </div>
                                </div>
                                <hr className="separator" />
                            </Fragment>
                        );
                    })}
                </div>
            </Modal>
            <Modal
                visible={personalInformationModal}
                closable={false}
                header={true}
                footer={
                    <Form.Item>
                        <Button disableRipple={true} onClick={() => setPersonalInformationModal(false)}>
                            Cancel
                        </Button>
                        <Button disableRipple={true} onClick={() => form.resetFields()}>
                            Reset
                        </Button>
                        <Button variant="contained" color="primary" disableRipple={true} onClick={() => form.submit()}>
                            Confirm
                        </Button>
                    </Form.Item>
                }
            >
                <Form form={form} onFinish={_handleConfirmBooking} 
                                initialValues={{ project_id: projectId , 
                                                email :detailsUser && detailsUser.email,
                                                first_name :detailsUser.first_name,
                                                last_name : detailsUser.last_name,
                                                t_number :detailsUser.t_number,
                                                panelist_id : detailsUser.panelist_id,
                                                mobile : detailsUser.mobile
                                            }}>
                                               
                    <div className="title">Personal Information</div>
                    <div className="content">
                        <div className="form-content">
                            <Form.Item name="project_id" hidden>
                                <Input />
                            </Form.Item>
                            <div className="input-fieldset">
                                <Form.Item name="email">
                                    <Input disabled/>
                                </Form.Item>
                                <span className="input-label">Email Address</span>
                            </div>
                            <div className="input-fieldset">
                                <Form.Item name="last_name" rules={[{ required: true, message: 'Last name  is required' }]}>
                                    <Input  disabled={detailsUser.last_name === null ? false : true}/>
                                </Form.Item>
                                <span className="input-label">Last Name</span>
                            </div>
                            <div className="input-fieldset">
                                <Form.Item name="first_name" rules={[{ required: true, message: 'First_name  is required' }]}>
                                    <Input disabled={detailsUser.first_name === null ? false : true}/>
                                </Form.Item>
                                <span className="input-label">First Name</span>
                            </div>
                            <div className="input-fieldset">
                                <Form.Item name="mobile" rules={[{ required: true, message: 'Phone number  is required' }]}>
                                    <Input />
                                </Form.Item>
                                <span className="input-label">* Phone Number</span>
                            </div>
                            <div className="input-fieldset">
                                <Form.Item name="panelist_id" rules={[{ required: false}]}>
                                    <Input disabled={detailsUser.panelist_id === null || detailsUser.panelist_id === "" ||detailsUser.panelist_id === undefined? false : true}/>
                                </Form.Item>
                                <span className="input-label">Panelist ID</span>
                            </div>
                            <div className="input-fieldset">
                                <Form.Item name="t_number" rules={[{ required: false}]}>
                                    <Input disabled={detailsUser.t_number === null || detailsUser.t_number === "" || detailsUser.t_number === undefined? false : true}/>
                                </Form.Item>
                                <span className="input-label">T-number</span>
                            </div>
                            <div className="input-fieldset">
                                <Form.Item name="comment">
                                    <Input.TextArea rows="7" />
                                </Form.Item>
                                <span className="input-label">Comments</span>
                            </div>
                        </div>
                    </div>
                </Form>
            </Modal>
        </div>
    );
};

export default ParticipantsSchedule;
