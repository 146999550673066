import React, { useRef, useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { useMount, useUpdateEffect, useUpdate } from 'ahooks';
import PropTypes from 'prop-types';
import moment from '../../library/moment';
import _ from 'lodash';
import ordinal from 'ordinal';

import { Carousel, InputNumber, DatePicker, TimePicker, Modal, Select, Tooltip } from 'antd';

import { IconButton } from '@material-ui/core';
import { KeyboardArrowLeft, KeyboardArrowRight, FileCopyOutlined } from '@material-ui/icons';

import './style.scss';

const MOMENTFORMAT = {
    time: 'hh:mm A',
    time24: 'HH:mm',
    date: 'DD MMM YYYY',
};

const { Option } = Select;

const VisitConfig = (props) => {
    const { IsRecurring, onChange, value, setVisit , noOfVisits , isAddNewVisit} = props;

    const onDataUpdateRender = useUpdate();

    const VisitConfigData = useRef(value);
    const carouselObj = useRef(null);

    const [data, setData] = useState(value);
    const [showCopy, setShowCopy] = useState(false);
    const [carouselPage, setCarouselPage] = useState(0);
    const [copySelectTag, setCopySelectTag] = useState(null);

    useMount(() => {
        setData(value);
        VisitConfigData.current = value;
    });

    useUpdateEffect(() => {
        setData(value);
        VisitConfigData.current = value;
    }, [value]);

    useUpdateEffect(() => {
        onChange(VisitConfigData.current);
    }, [carouselPage]);

    useUpdateEffect(() => {
        carouselObj.current.goTo(setVisit, true);
        onDataUpdateRender();
    }, [setVisit]);

    const onClickNext = () => {
        carouselObj.current.next();
    };

    const onClickPrev = () => {
        carouselObj.current.prev();
    };

    const onClickCopy = () => {
        setShowCopy(true);
    };

    const onCarouselAfterChange = (e) => {
        setCarouselPage(VisitConfigData.current.length > 0 ? e : 0);
    };

    const disabledDate = (d) => {
        if (IsRecurring) {
            return !d.isSameOrBefore(moment().add(3, 'w')) || !d.isAfter(moment().subtract(1, 'd'));
        }

        return !d.isAfter(moment().subtract(1, 'd'));
    };

    const onChangeDate = (e, w) => {
        VisitConfigData.current[carouselPage].date = e;
        setData(VisitConfigData.current);
        onChange(VisitConfigData.current);
        onDataUpdateRender();
    };

    const onChangeStartEndTime = (e, w) => {
        VisitConfigData.current[carouselPage].timeStartEnd = e?w:[];
        setData(VisitConfigData.current);
        onChange(VisitConfigData.current);
        onDataUpdateRender();
    };

    const onChangeDuration = (e) => {
        VisitConfigData.current[carouselPage].duration = e;
        setData(VisitConfigData.current);
        onChange(VisitConfigData.current);
        onDataUpdateRender();
    };

    const onChangeTimeInt = (e) => {
        VisitConfigData.current[carouselPage].timeInterval = e;
        setData(VisitConfigData.current);
        onChange(VisitConfigData.current);
        onDataUpdateRender();
    };

    const onChangePanelSlot = (e) => {
        VisitConfigData.current[carouselPage].panelistPerSlot = e;
        setData(VisitConfigData.current);
        onChange(VisitConfigData.current);
        onDataUpdateRender();
    };

    const datePickerValue = (arrTime) => {
        if (_.isArray(arrTime) === true && _.size(arrTime) === 2) {
            return [moment(arrTime[0], MOMENTFORMAT.time24), moment(arrTime[1], MOMENTFORMAT.time24)];
        }

        return [];
    };

    const onClickModalOk = () => {
        VisitConfigData.current[carouselPage].date = VisitConfigData.current[copySelectTag].date;
        VisitConfigData.current[carouselPage].timeStartEnd = VisitConfigData.current[copySelectTag].timeStartEnd;
        VisitConfigData.current[carouselPage].duration = VisitConfigData.current[copySelectTag].duration;
        VisitConfigData.current[carouselPage].timeInterval = VisitConfigData.current[copySelectTag].timeInterval;
        VisitConfigData.current[carouselPage].panelistPerSlot = VisitConfigData.current[copySelectTag].panelistPerSlot;

        setData(VisitConfigData.current);
        setShowCopy(false);
        onDataUpdateRender();
    };

    const onClickModalCancel = () => {
        setShowCopy(false);
    };

    const onSelect = (e) => {
        setCopySelectTag(e);
    };

    return (
        <div className="visitconfig-container" hidden={data.length === 0}>
            <div className="visitconfig-header">
                <div className="visitconfig-title">
                    <h5>{isAddNewVisit ?ordinal(carouselPage + 1 + noOfVisits)  :  ordinal(carouselPage + 1)} Visit</h5>
                </div>
                <div className="visitconfig-controls">
                    {data.length > 1 && (
                        <IconButton size="small" onClick={onClickCopy} color="primary">
                            <FileCopyOutlined />
                        </IconButton>
                    )}

                    <IconButton size="small" onClick={onClickPrev} color="primary">
                        <KeyboardArrowLeft />
                    </IconButton>
                    <IconButton size="small" onClick={onClickNext} color="primary">
                        <KeyboardArrowRight />
                    </IconButton>
                </div>
            </div>

            <div className="visitconfig-carousel-cont">
                <Carousel
                    className="visitconfig-carousel"
                    autoplay={false}
                    effect="fade"
                    ref={carouselObj}
                    dots={false}
                    afterChange={onCarouselAfterChange}
                >
                    {data.map((v, i) => (
                        <div key={i} className="visitconfig-carousel-item">
                            {IsRecurring === false && (
                                <div className="visitconfig-carousel-item-label">
                                    <label>Select Date :</label>
                                    <DatePicker
                                        disabledDate={disabledDate}
                                        size={'default'}
                                        format={MOMENTFORMAT.date}
                                        value={
                                            ![null, NaN].includes(data[i].date)
                                                ? moment(data[i].date, MOMENTFORMAT.date)
                                                : null
                                        }
                                        inputReadOnly={true}
                                        onChange={onChangeDate}
                                    />
                                </div>
                            )}

                            <div className="visitconfig-carousel-item-label">
                                <label>Select Time :</label>
                                <TimePicker.RangePicker
                                    defaultValue={datePickerValue(data[i].timeStartEnd)}
                                    value={datePickerValue(data[carouselPage] && data[carouselPage].timeStartEnd)}
                                    format={MOMENTFORMAT.time24}
                                    inputReadOnly={false}
                                    onChange={onChangeStartEndTime}
                                />
                            </div>

                            <div className="visitconfig-carousel-item-label">
                                <label>Duration :</label>
                                <Tooltip title="Duration in minutes">
                                    <InputNumber
                                        placeholder="Duration"
                                        value={data[i].duration}
                                        min={1}
                                        max={1000}
                                        onChange={onChangeDuration}
                                    />
                                </Tooltip>
                            </div>

                            <div className="visitconfig-carousel-item-label">
                                <label>Time Interval :</label>
                                <Tooltip title="Time Interval in minutes">
                                    <InputNumber
                                        placeholder="Time Interval"
                                        value={data[i].timeInterval}
                                        min={1}
                                        max={1000}
                                        onChange={onChangeTimeInt}
                                    />
                                </Tooltip>
                            </div>

                            <div className="visitconfig-carousel-item-label">
                                <label htmlFor="panelistPerSlot">Panelist per slot: </label>
                                <InputNumber
                                    placeholder="Panelist per slot"
                                    value={data[i].panelistPerSlot}
                                    min={0}
                                    max={1000}
                                    onChange={onChangePanelSlot}
                                />
                            </div>
                        </div>
                    ))}
                </Carousel>

                <Modal
                    width={350}
                    visible={showCopy}
                    centered={true}
                    onOk={onClickModalOk}
                    onCancel={onClickModalCancel}
                >
                    <label>Copy from: </label>
                    <Select defaultValue={copySelectTag} placeholder="Visit Number" onChange={onSelect}>
                        {data.map((v, i) => (
                            <Fragment key={i}>
                                {i !== carouselPage ? (
                                    <Option key={i} value={i}>
                                        {isAddNewVisit ?
                                        `${ordinal(i + 1 + noOfVisits)} Visit`
                                        :
                                        `${ordinal(i + 1)} Visit` }
                                    </Option>
                                ) : (
                                    <Fragment key={i} />
                                )}
                            </Fragment>
                        ))}
                    </Select>
                </Modal>
            </div>
        </div>
    );
};

VisitConfig.defaultProps = {
    setVisit: 0,
    value: [],
    IsRecurring: false,
};

VisitConfig.propTypes = {
    setVisit: PropTypes.number,
    value: PropTypes.array,
    onChange: PropTypes.func,
    IsRecurring: PropTypes.bool.isRequired,
};

export default connect()(VisitConfig);
