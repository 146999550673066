import React, {useState, useRef, useEffect} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useMount, useUpdateEffect } from 'ahooks';
import { useSnackbar } from 'notistack';
import { Input , Form } from 'antd';
import { Button, Dialog, DialogContent, IconButton, AppBar, Typography, Toolbar , Backdrop , CircularProgress} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import UploadFile from '../../components/UploadFile';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './style.scss';

import EmailTemplatesDropdown from '../../components/EmailTemplatesDropdown'
import templateFormat from '../ProjectCreate/StepFour/templateFormat';
import Modal from 'antd/lib/modal/Modal';

import api from '../../library/api';



const CustomTemplate = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    const {onSubmit, defaultValue  , form , emailEditor , onCLoseEditEmailTemplate , setFiles  } = props;

    const projectData = useRef(defaultValue);
    const emailTemplateContentObj = useRef({
        subject: 'Invitation to Book Your Time Slot ',
        emailContent: '',
    });
    const [emailSubject, setEmailSubject] = useState('');
    const [editorState, setEditorState] = useState(EditorState.createWithContent(convertFromRaw(templateFormat)));
    const [customTemplate , setCustomsTemplate] = useState([])
    const [templateName,setTemplateName]= useState(null)
    const [showModalTemplateName,setShowModalTemplateName] = useState(false)
    const [isChanged , setisChanged] = useState(false)
    useMount(() => {
        setEmailSubject(`${emailTemplateContentObj.current.subject} <Project Name>, <Project ID>`);
        projectData.current.emailTemplate = emailTemplateContentObj.current;
        if(emailEditor) {
            getTemplates()
        }
    });
    useEffect(() => {
        getTemplates()
    },[])

    const getTemplates = async () => {
      await  api.email
        .getEmailTemplates()
        .then(res => {
            if(res.status === "success") {
               const updateData = res.data.map(item =>  {
                   if(projectData.current.id === item.project_id) {
                    setTemplateName(item.name)
                    setEditorState(EditorState.createWithContent(convertFromRaw(JSON.parse(item.email_content))))
                    setEmailSubject(item.subject) 
                    emailTemplateContentObj.current.emailContent = convertToRaw(editorState.getCurrentContent())
                    emailTemplateContentObj.current.subject = item.subject
                    projectData.current.emailTemplate  = emailTemplateContentObj.current
                    projectData.current.template_name = item.name
                    projectData.current.isSaved = true
                   }
                    return {  
                        name : item.name,
                        value :  EditorState.createWithContent(convertFromRaw(JSON.parse(item.email_content))),
                    }
               })
               setCustomsTemplate(updateData)
            }               
        }).catch(err => {
        })
    }
    useEffect(() => {
        if(emailEditor) {
            api.email
            .getEmailTemplates()
            .then(res => {
                if(res.status === "success") {
                const updateData = res.data.map(item =>  {
                    if(projectData.current.id === item.project_id) {
                        setTemplateName(item.name)
                        setEditorState(EditorState.createWithContent(convertFromRaw(JSON.parse(item.email_content))))
                        setEmailSubject(item.subject) 
                        emailTemplateContentObj.current.emailContent = convertToRaw(editorState.getCurrentContent())
                        emailTemplateContentObj.current.subject = item.subject
                        projectData.current.emailTemplate  = emailTemplateContentObj.current
                        projectData.current.template_name = item.name
                        projectData.current.isSaved = true
                    }
                        return {  
                            name : item.name,
                            value :  EditorState.createWithContent(convertFromRaw(JSON.parse(item.email_content))),
                        }
                })
                setCustomsTemplate(updateData)
                }               
            }).catch(err => {
            })
        }
    },[emailEditor])
    useUpdateEffect(() => {
        emailTemplateContentObj.current.subject = emailSubject;
    }, [emailSubject]);
    const onSave = (e) => {
            getTemplates ()
            if(templateName !== '' && templateName !== null) {
                projectData.current.isSaved = true
                projectData.current.template_name = templateName
            }else {
                projectData.current.isSaved = false
            }
            emailTemplateContentObj.current.emailContent = convertToRaw(editorState.getCurrentContent());
            projectData.current.emailTemplate = emailTemplateContentObj.current;
            onSubmit(projectData.current);     
        }
    const onSetFiles = (selectedFiles) => {
        let array = [] ; 
        if(selectedFiles.length === 0) {
            setFiles(array)
        }else {
            setFiles(selectedFiles)
        }

    }
    const onSaveTemplateName =() => {
            if(templateName !== '' && templateName !== null) {
                projectData.current.template_name = templateName
                emailTemplateContentObj.current.emailContent = convertToRaw(editorState.getCurrentContent());
                projectData.current.emailTemplate = emailTemplateContentObj.current;
                projectData.current.isSaved = true
                enqueueSnackbar('Successfully Saved Template', {
                     variant: 'success',
                     autoHideDuration: 2000,
                    });
                setShowModalTemplateName(false)
            }else {
                enqueueSnackbar('name is required' , {variant:'warning'})
            }       
    }
    
    const onSubjectChange = (e) => {
        setEmailSubject(e.target.value);
    };

    

    const onSaveEmailTemplate = () => {
        setShowModalTemplateName(true)
    };

    const onEditorStateChange = (e) => {
        emailTemplateContentObj.current.emailContent = convertToRaw(e.getCurrentContent());
        setEditorState(e);
    };

    const onChangeTemplateName = (e) => {
        projectData.current.isSaved = true
        setTemplateName(e.target.value)
    }

    const setTempalteProps = (name) => { 
        setTemplateName(name)
    }
    return (
            <Dialog id="email-editor-dialog" style={{zIndex : 500}} fullScreen open={emailEditor} disableEnforceFocus={true} onClose={onCLoseEditEmailTemplate}>
                <AppBar className="email-editor-dialog-appbar">
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={onCLoseEditEmailTemplate} aria-label="close">
                            <Close />
                        </IconButton>
                        <Typography variant="h6" className="email-editor-dialog-appbar-header">
                            Email Template Editor
                        </Typography>
                        <Button autoFocus color="inherit" onClick={onSaveEmailTemplate} >
                            Save
                        </Button>
                        <Button tabIndex={1} className="btn-next" variant="contained" color="inherit" onClick={onSave}>
                           Send
                        </Button>
                    </Toolbar>
                </AppBar>

                <DialogContent dividers={true} className="email-editor-dialog-content">
                    <Input
                        value={emailSubject}
                        className="email-editor-subject"
                        onChange={onSubjectChange}
                        placeholder="Subject"
                    />
                    <Editor
                        toolbar={{
                            options: ['inline','fontFamily', 'fontSize', 'list', 'textAlign', 'colorPicker','history','link'],
                            inline: {
                                options: ['bold','italic', 'underline', 'strikethrough', 'monospace', 'superscript', 'subscript'],
                            },
                            fontFamily: {
                                options: ['Arial','Calibri','Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana','montserratregular'],
                            },
                            link:{
                                inDropdown: false,
                                className: undefined,
                                component: undefined,
                                popupClassName: undefined,
                                dropdownClassName: undefined,
                                showOpenOptionOnHover: true,
                                defaultTargetOption: '_self',
                                options: ['link', 'unlink'],
                            },
                            list: {
                                options: ['unordered', 'indent', 'outdent']
                            },
                            history: {
                                options: ['undo', 'redo'],
                            },
                        }}
                        toolbarCustomButtons={[<EmailTemplatesDropdown  templates={customTemplate} setTemplatename={setTempalteProps} name={templateName} />]}
                        editorState={editorState}
                        wrapperClassName="email-editor-cont"
                        editorClassName="email-editor-wysywyg"
                        onEditorStateChange={onEditorStateChange}
                    />
                   <UploadFile onSetFiles={onSetFiles}/>
                    <Modal   title ="Add Name for Template"
                        visible={showModalTemplateName}
                        centered={true}
                        destroyOnClose={true}
                        style={{zIndex : 2110}}
                        okText="Ok"
                        onOk={ async() => { 
                            form.submit()
                        }}
                        onCancel={() => setShowModalTemplateName(false)}
                        >
                             <Form
                                name="Add Name for template"
                                form={form}
                                layout="vertical"
                                onFinish={onSaveTemplateName}
                                initialValues  = {{templateName : templateName || ''}}
                                style={{zIndex : 2110}}
                            >
                                <div>
                                    <Form.Item label={'Template Name'} name="Template Name" hasFeedback rules={[{ required: true,max : 20}]}>
                                        <Input  placeholder="enter template name" defaultValue={templateName}  value={templateName} onChange={onChangeTemplateName}/>
                                    </Form.Item>
                                </div>
                            </Form>
                    </Modal>
                </DialogContent>
            </Dialog>
    );
}
CustomTemplate.defaultProps = {};

CustomTemplate.propTypes = {
    defaultValue: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default connect()(CustomTemplate);
