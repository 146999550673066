export const errorMapper = (messages) => {
    if (typeof messages === 'string') {
        return messages;
    }

    if (Array.isArray(messages.errors)) {
        return messages.errors
            .map((err) => {
                return `${Object.keys(err)}: ${Object.values(err)}`;
            })
            .join('\r\n');
    }
    console.error(messages);
    return 'Server Error!';
};

export const regex = {
    // eslint-disable-next-line no-useless-escape
    email: /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
    mobileNumber: /[^0-9+() ]/gi,
    charsAndSpaces: /[^a-zA-Z ]/gi,
    password : /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!/%*#?&^_-]).{8,}/
};

//valid domain pg accounts 
export const  validateDomain = (email, companyName) => { 
    let match = email.match(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(pg).\w+$/);  
    return match!==null &&  !(match.indexOf("companyName") > -1);
}
